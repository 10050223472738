import classes from './DynamicDropDownInput.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk, faCircleCheck, faCircleExclamation, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'react-tooltip';
import Select from 'react-select';
import { useState } from 'react';
import { useEffect } from 'react';

export default function  DynamicDropDownInput({
          OnChangeUpdate = ()=>{}, 
          Data, 
          DDDataUpdater, 
          Title="", 
          ValueTag, 
          Icon, 
          PlaceHolder, 
          State, 
          Tip, 
          URL, 
          ForceInput = true, 
          FilterData = null,
          Dependent = false
}) 
{
    const [selectedOptions, setSelectedOptions] = useState();
      const [optionList, setOptionList] = useState([]);

      function PrepList(data) {
        const newOptionList = [];

        for (var i = 0; i < data.length; i++) {
          
          let object = data[i];
      
          let value_key = null;
          let value_lable = null;

         // console.log(object);
          for (const key in object) {

            const active = String(key);
            if (active.toLowerCase().includes('name')) {

              const val = object[key];
              value_lable = val;

            } else if (active.toLowerCase().includes('id')) {

              const val = object[key];
              value_key = val;

            }

            

            if(value_lable !== null && value_key !== null) 
            {
              newOptionList.push({ value: value_key, label: value_lable  });
              break;
            }

          }

          
        }
      
        setOptionList(newOptionList);
      }
      useEffect(() => 
        {
            QueryOption();
        }, [])

      function QueryOption() 
      {
        if(Dependent) 
        {
          if(FilterData) 
          {
            fetch(URL, { 
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ FilterID: FilterData }),
            })
              .then(res => res.json())
              .then((data) => {PrepList(data)});
          } else 
          {
            setSelectedOptions([]);
          }
        } else 
        {
          fetch(URL)
            .then(res => res.json())
            .then((data) => PrepList(data));
        }
        
        
      }
    function handleSelect(data) {
        OnChangeUpdate(data);
        setSelectedOptions(data);

        DDDataUpdater(prev => ({
            ...prev,
            [ValueTag]: data.value
        }))
    }
    const Custom_DD_Style = {
        
        control: (provided, state) => ({
            ...provided,
            width: 500,
            minHeight: 20,
            height: 35,
            fontWeight: 'bold',
            fontSize: 13,
            background: 'var(--background-dark)',
            border: 'none',
            borderColor: 'var(--background-light)',
            color: 'var(--primary_text_color)',
            borderRadius: 1,
            borderColor: 'none', 
            boxShadow: 'none', 
            '&:hover': {
                borderColor: 'none', 
                boxShadow: 'none', 
            },
            }),
            option: (provided) => ({
            ...provided,
            backgroundColor: 'var(--primary-color)', 
            color: 'var(--secondary-color)',
            }),
            option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? 'var(--body-background)' : 'var(--primary-color)', 
            color: state.isFocused ? 'var(--primary-color)' : 'var(--secondary-color)',
            transition: 'background-color 0.2s ease-in-out',
            cursor: 'pointer',
        }),
            menu: (provided) => ({
            ...provided,
            zIndex: 9999,
            backgroundColor: 'var(--primary-color)',
            color: 'var(--primary-color)',
            fontSize: 13,
            borderRadius: 1,
            fontWeight: 500,
            }),
            singleValue: styles => ({
                ...styles,
                color: 'var(--text_light_white)', // Adjust this line for selected text color
              }),
        }
    return (
        <div className={`${classes.emp_text_input_main_wrapper}`}>
            <Tooltip className={`${classes.tool_quick}`} id="input_field_tip" place="top" />
            <div className={`${classes.emp_input_title}`}>
                <li>{Title} <span className={`${classes.emp_input_force_notifyer}`}>{ForceInput ? <sup><FontAwesomeIcon icon={faAsterisk}></FontAwesomeIcon></sup> : ''}</span></li>
            </div>
                <div className={`${classes.emp_input_line_wrapper} ${State === -1 ? classes.emp_input_line_wrapper_error_border : State === 1 ? classes.emp_input_line_wrapper_suc_border : '' }`}>

                    <div className={`${classes.input_left_side_wrapper}`}>
                        <div className={`${classes.emp_input_left_icon_wrapper}`}>
                            <span className={`${classes.emp_side_icon}`}><FontAwesomeIcon icon={Icon}></FontAwesomeIcon></span>
                        </div>

                        <div
                        {...(Tip ? {'data-tooltip-id': 'input_field_tip', 'data-tooltip-content': Tip} : {})}
                        className={`${classes.emp_create_input_wrapp}`}>
                            <Select
                                styles={Custom_DD_Style}
                                onChange={handleSelect}
                                name={ValueTag}
                                options={optionList}
                                placeholder={PlaceHolder}
                                value={selectedOptions}
                                isSearchable={true}
                                required ={ForceInput}
                            />
                        </div>
                    </div>

                    <div className={`${classes.emp_icon_right_side_notify}`}>
                        <span className={`${classes.emp_side_icon_noti} ${State === 0 ? classes.emp_icon_right_side_notify_friendly : State === -1 ? classes.emp_icon_right_side_notify_error : State === 1 ? classes.emp_icon_right_side_notify_good : State === -2 ?classes.emp_icon_right_side_notify_whatever : ''}`}>
                                {State !== 0 && <FontAwesomeIcon icon={State === -1 ? faCircleExclamation : State === -2 ? faExclamation : faCircleCheck}></FontAwesomeIcon> }
                            </span>
                    </div>
                </div>
        </div>
        );
}