import classes from './ShippingDialog.module.css'
import data_win from '../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import { useReactToPrint } from 'react-to-print'
import { useAuth } from '../../../../../AuthProvider'
import { useDAControl } from '../../../../../DAControlContext'

import { useEffect, useRef } from 'react'
import { useState } from 'react'

import PaperHeader from '../../../../../SharedComp/PaperHeader/PaperHeader'
import PaperHash from '../../../../../SharedComp/PaperHash/PaperHash'
import { useToast } from '../../../../../ToastContext'

import {ShipProducedCkProduct, QueryShippedProductGroupByReqGroup} from '../../../../../SharedComp/API/Kicthen/API_FE_Kicthen'

export default function ShippingDialog({Close_CALLBACK, DataList, GroupData, UpdateView, UpdateViewToggel, Preview = false})
{

    const auth = useAuth();
    const DAControl = useDAControl();
    const notify = useToast();
    const [DispatcherInfo, SetDispatcherInfo] = useState();

    const print_ref = useRef();
    const PrintDoc = useReactToPrint({
        documentTitle: auth.activeWorkShopName + "_production_shipment" + "_" + new Date().toDateString(),
        content: () => print_ref.current,
        onAfterPrint: ()=>{
            
        }
    })


    // This only happend if only this is in the privew mode

    const FetchDisptcherInfo = async(__id) =>
    {

        try 
        {
            const fd = new FormData();
            fd.append("req_group_id", __id);
            const main_res = await QueryShippedProductGroupByReqGroup(fd, 'token');
            SetDispatcherInfo(main_res[0]);
        }catch(err) 
        {
            notify.ShowError("Failed to Fetch Disptcher Info " + err.message);
        }
    }
      

    useEffect(()=>
    {
        if(Preview) 
        {
            FetchDisptcherInfo(GroupData.CKAOGID);
        } 
    }, [Preview])

    const HandleShipment = async()=>
    {

        notify.ShowLoad("Processing your request...", 10);

        setTimeout(async()=>{
            try 
            {
    
                const fd = new FormData();
                fd.append("request_group_id", GroupData.CKAOGID);
                fd.append("report_list", JSON.stringify(DataList));
                fd.append("request_group_hash", GroupData.CKAOG_Hash);

                fd.append("request_sender_workspace_id", GroupData.CKAOG_SourceWorkspace);
                fd.append("request_receiver_workspace", GroupData.CKAOG_DestinationWorkspace);

                fd.append("user_account_id", auth.user.UserAccountID);
    
                const main_res = await ShipProducedCkProduct(fd, 'token');
                notify.ShowSuccessUpdate(main_res.Msg, 10);
                Close_CALLBACK(false);
                UpdateView(!UpdateViewToggel);
    
            }catch(err) 
            {
                notify.ShowErrorUpdate(err.message, 10);
            }
        }, 1500)
        
    }



    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>
        <div className={`${data_win.data_main} ${classes.data_main}`}>

            <div className={`${data_win.data_main_header}`}>
                <h3>
                    JAK <span className={`${data_win.data_create_name}`}>{Preview ? "Dispatch Preview" : "Production Dispatcher"} </span>
                </h3>
                <button onClick={()=>{Close_CALLBACK(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
            </div>

            <div className={`${classes.data_preview_wrapper}`}>

            <div className={`${classes.inner_preview_data_wrapper}`}>
                <div ref={print_ref} className={`${classes.paper_wrapper_dash}`}>
                    <PaperHeader/>
                    <PaperHash text={Preview ? "digital auth copy" : null}/>
                    <div className={`${classes.meta_data_wrapper}`}>
                        <h2>[#] {auth.activeWorkShopName} </h2>
                        <h2>[#] Production Dispatch</h2>
                    </div>

                    <div className={`${classes.transcation_owner_wrapper}`}>
                        <div className={`${classes.transcation_owner}`}>

                            <div className={`${classes.transcation_init}`}>
                                <h2>Source Information</h2>
                                <li>Branch: {GroupData?.BranchName}</li>
                                <li>Name: {GroupData?.FirstName} {GroupData.LastName}</li>
                                <li>Role: {GroupData?.RoleNameLazy}</li>
                                <li>Order Date: {new Date(GroupData.CKAOG_OrderDateTime).toDateString()}</li>
                               
                            </div>
                            <div className={`${classes.transcation_recive}`}>
                                <h2>Dispatcher Information</h2>
                                <li>Branch:  {Preview ? DispatcherInfo?.BranchName : auth.activeWorkShopName}</li>
                                <li>Name: {Preview ? DispatcherInfo?.FirstName + " " + DispatcherInfo?.LastName : auth.user.FirstName + " " + auth.user.LastName}</li>
                                <li>Role: {Preview ? DispatcherInfo?.Name : auth.user.RoleNameLazy}</li>
                                <li>Shipment Date: {Preview ? new Date(DispatcherInfo?.CKSRBG_Date).toDateString() :  new Date().toDateString()}</li>
                            </div>

                        </div>
                    </div>

                    <div className={`${classes.entry_detail}`}>
                        <li><span className={`${classes.entry_deatil_tag}`}>Dispatch ID</span> <span className={`${classes.entry_deatil_val}`}>{GroupData.CKAOG_Hash}</span></li>
                    </div>  
                    
                    
                    <div className={`${classes.main_paper_data_wrapper}`}>

                        <div className={`${classes.data_marging_wrapper}`}>
                            <li className={`${classes.data_header_list}`}>
                                <span>Name</span>
                                <span>Requested</span>
                                <span>Delivered</span>
                                <span>Rating</span>
                                <span>Request Status</span>
                            </li>
                          {
                            DataList.map((item, idx)=>(
                                <li key={idx}>
                                    <span className={`${classes.text_left}`}>{item.CKP_ItemName}</span>
                                    <span>{item.CKAOPID_Quantity} </span>
                                    <span>{item.CKSRBUF_Quantity} </span>
                                    <span>{parseFloat(parseFloat(item.CKSRBUF_Quantity) / parseFloat(item.CKAOPID_Quantity)) * 100 + '%' } </span>
                                    <span  className={`${classes.text_right}`}>{item.CKSRBUF_Quantity > 0 ? "Accepted" : "Rejected" }</span>
                                </li>
                            ))
                          }
                        </div>
                        
                    </div>

                    <div className={`${classes.special_footer_local_wrapper}`}>

                        <div className={`${classes.footer_user_wrap}`}>
                            <h2>Shipment Digital Authorization</h2>
                            <li className={`${classes.name_manual}`}>{Preview ? DispatcherInfo?.FirstName + " " + DispatcherInfo?.LastName : auth.user.FirstName + " " + auth.user.LastName}</li>
                            <li className={`${classes.print_date}`}>Date: {Preview ? new Date(DispatcherInfo?.CKSRBG_Date).toDateString() :  new Date().toDateString()}</li>
                            <li className={`${classes.sign_manual}`}>Signature: ______________________</li>
                        </div>

                        <div className={`${classes.footer_user_wrap}`}>
                            <h2>Production Manager</h2>
                            <li className={`${classes.name_manual}`}>Full Name: __________________</li>
                            <li className={`${classes.print_date}`}>Date: {Preview ? new Date(DispatcherInfo?.CKSRBG_Date).toDateString() :  new Date().toDateString()}</li>
                            <li className={`${classes.sign_manual}`}>Signature: ______________________</li>
                        </div>

                        <div className={`${classes.footer_user_wrap}`}>
                            <h2>Driver</h2>
                            <li className={`${classes.name_manual}`}>Full Name: __________________</li>
                            <li className={`${classes.print_date}`}>Date: {Preview ? new Date(DispatcherInfo?.CKSRBG_Date).toDateString() :  new Date().toDateString()}</li>
                            <li className={`${classes.sign_manual}`}>Signature: ______________________</li>
                        </div>

                    </div>

                    {/* <PaperFooter Name={auth.employeeData.FullName} Role={auth.employeeData.DepName} Phone={auth.employeeData.PhoneNumber}/> */}
                    <div className={`${classes.final_note_po_stock_out}`}>
                        <li>This document must contain all signatures in place to be considered valid.</li>
                    </div>

                </div>
            </div>



            </div>


            <div className={`${classes.action_button_wrapper}`}>

                <button onClick={(e)=>{PrintDoc()}} className={`${classes.button_print}`}>Print</button>
               {!Preview && <button onClick={()=>{
                                    DAControl.Trigger(`Ship Product`, 
                                    `You are about to ship products package... `,
                                    true,
                                    1, (val)=>{
                                        if(val !== null) 
                                        {
                                            HandleShipment();
                                        }
                                    }   
                                );}} className={`${classes.button_approve}`}>Ship Package</button> }
                <button onClick={()=>{Close_CALLBACK(false)}}>Close</button>

            </div>

        </div>
    </div>
    )
}