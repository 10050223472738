import classes from './OrderSavedCard.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../../AuthProvider';
import { faBan, faCheckCircle, faCube, faFilePen, faFolder, faSliders } from '@fortawesome/free-solid-svg-icons';

export default function OrderSavedCard({Data, Handle_CALLBACK}) 
{

 
    return (
    <div onClick={()=>{Handle_CALLBACK(Data)}} className={`${classes.main_product_shelf_card_wrapper}`}>
        <div className={`${classes.prod_saved_header}`}>
                <div className={`${classes.header_text_wrapper}`}>
                    <h2>{Data.POS_Name}</h2>
                </div>
                <div className={`${classes.data_owner_lab}`}>
                    <li className={`${classes.saved_mix_owner}`}>By [{Data.FirstName} {Data.LastName}]</li>
                </div>
                
        </div>
        <div className={`${classes.prod_other_info_wrpper}`}>
            <div className={`${classes.icon_wrapper}`}>
                <span><FontAwesomeIcon icon={faCube}></FontAwesomeIcon></span>
            </div>
            <div className={`${classes.action_data_wrapper}`}>
                <li><span>+ Shift</span> <span>{Data.POS_TargetShift}</span></li>
                <li><span>+ Local Share</span> <span className={`${Data.POS_LocalShare ? classes.val_yes : classes.val_no}`}>{Data.POS_LocalShare ? 'Yes' : 'No'} <FontAwesomeIcon icon={Data.POS_LocalShare ? faCheckCircle : faBan}></FontAwesomeIcon></span></li>
                <li><span>+ Global Share</span> <span className={`${Data.POS_GlobalShare ? classes.val_yes : classes.val_no}`}>{Data.POS_GlobalShare ? 'Yes' : 'No'} <FontAwesomeIcon icon={Data.POS_GlobalShare ? faCheckCircle : faBan}></FontAwesomeIcon></span></li>
            </div>
        </div>
    </div>
    )
}