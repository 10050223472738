import classes from './PictureTaker.module.css'
import Webcam from 'react-webcam';
import { useState } from 'react';
import { useCallback } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faCameraRotate, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import { useRef } from 'react';

import download from 'downloadjs';

export default function PictureTaker({Active, SetActiveController, EmpData}) 
{

    const webcamRef = useRef(null);
    const [imgSrc, setImgSrc] = useState(null);

    function SaveImage() 
    {
        if(imgSrc) 
        {
            let user_name = EmpData["full_name"];
            var joined_string = user_name.replace(/ /g, "_");
            download(imgSrc, joined_string + "_profile_pic", 'image/png');
        }
    }

    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImgSrc(imageSrc);
      }, [webcamRef]);
    return (
        <div className={`${classes.blure_back}`}>
            <div className={`${Active ? classes.profile_pic_taker_wrapper : classes.profile_pic_taker_wrapper_hide }`}>
                    <div className={`${classes.profile_pic_taker_header}`}>
                        <h2>Take Picture</h2>
                        <button onClick={() => {SetActiveController(false);setImgSrc(null);}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
                    </div>
                    <div className={`${classes.profile_pic_taker_inner_wrapper}`}>
                        <div className={`${classes.profile_pic_webcam_wrapper}`}>
                            {Active && <Webcam
                                audio={false}
                                ref={webcamRef}
                                width={300}
                                height={250}
                                screenshotFormat="image/jpeg"
                            />}

                            <div className={`${classes.camera_action_button_wrapper}`}>
                                    <button onClick={() => {capture()}}><FontAwesomeIcon icon={faCamera}></FontAwesomeIcon></button>
                                    <button onClick={() => {setImgSrc(null)}}><FontAwesomeIcon icon={faCameraRotate}></FontAwesomeIcon></button>
                            </div>

                        </div>
                        
                        <div className={`${classes.profile_pic_view}`}>
                            <img src={imgSrc} className={`${classes.profile_pic_taken}`}alt="" />
                        </div>
                    </div>

                    <div className={`${classes.photo_action_taker}`}>
                        <button onClick={() => {SaveImage(); SetActiveController(false);setImgSrc(null);}}>Save</button>
                        <button onClick={() => {SetActiveController(false);setImgSrc(null);}} className={`${classes.button_discard_pic}`}>Discard</button>
                    </div>

                </div>
        </div>
    )
}