import { useAuth } from '../../../../../AuthProvider';
import classes from './ProcessTransferRequestPackageInput.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select'

import MasterDataRegInput from '../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useEffect, useState } from 'react';
import { getBaseURL } from '../../../../../SharedComp/BaseUrlConfig';
import {Custom_DD_Style, PrepareDropDownList} from '../../../../../SharedComp/Utility'
import { faCheckCircle, faCheckSquare, faCircleExclamation, faSpinner, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

import {CheckEligibleBalanceState} from '../../../../../api'

export default function ProcessTransferRequestPackageInput({
      I_Quantity,
      I_Brand,
      I_Inv_Item,
    HandleInvItemSelect, 
    HandleInvItemStore, 
    HandlInvItemQuantity, 
    HandleIvnItemBrand,
    HandleTransferState,
    idx}) 
{
    const auth = useAuth();
    const [PossibleChoiceList, SetPossibleChoiceList] = useState();
    const [LockedItem, SetLockedItem] = useState();
    const [SelectedInventotyItem, SetSelectedInventotyItem] = useState([]);
    const [SelectedStore, SetSelectedStore] = useState(null);
    const [Quantity, SetQuantity] = useState();
    const [Brand, SetBrand] = useState();

    const [DropDownOption, SetDropDownOption] = useState([]);

    const [storeDropDownOption, setStoreDropDownOption] = useState([]);

    const [BudgetSurplus, SetBudgetSurplus] = useState(false);
    const [RemainAmount, SetRemainAmount] = useState(0);
    const [Fecthing, SetFecthing] = useState(false);


    useEffect(() => 
    {
        fetchWarehouse();
    }, [])

    const CheckItemBalance = async(data, iv_item)=>
    {
      SetFecthing(true);

        setTimeout(async()=>{

            try 
          {
            const fd = new FormData();
            fd.append("iv_item", iv_item);
            fd.append("store", data.value);
            fd.append("branch", auth.activeWorkShop);
            SetBudgetSurplus(false);
            HandleTransferState(false, idx);
            SetRemainAmount(0);
            const res = await CheckEligibleBalanceState(fd, 'token');
            if(res.length > 0) 
            {
              SetRemainAmount(res[0].AvailQuantity - I_Quantity);
              if(res[0].AvailQuantity > I_Quantity)
              {
                SetBudgetSurplus(true);
                HandleTransferState(true, idx);
              } else 
              {
                HandleTransferState(false, idx);
              }
            }
            SetFecthing(false);
          }catch(err) 
          {
            console.log("Failed to Justify Facts!");
            SetFecthing(false);
          }

        }, 50)
    }

    const LockOnItem = async(data)=>
    {
      SetSelectedInventotyItem(data);
      for(let  i = 0; i < PossibleChoiceList.length; i++) 
      {
        if(PossibleChoiceList[i].IvtID === data.value)
        {
          SetLockedItem(PossibleChoiceList[i]);
          break;
        }
      }
    }
    const fetchWarehouse = async () => {
      try {

        const response = await fetch(getBaseURL() + 'warehouse/get-branch-store', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({BranchID: auth.activeWorkShop, IncludeShared: false})
        });

        const res = await response.json();

        setStoreDropDownOption([]);
        PrepList(res, setStoreDropDownOption, 'WHName', 'WhID');

      }catch(err) 
      {

      }
    }
    const FetchIvItems = async () => 
      {
  
        try 
        {
          const response = await fetch(getBaseURL() +  'master-data/get-iv-item-branch-only', {  
              method: 'POST',
              headers: {
              'Content-Type': 'application/json',
              },
              body: JSON.stringify({ID: auth.activeWorkShop})
           });
           const res = await response.json();
           PrepList(res, SetDropDownOption, 'IVItemName', 'IvtID');
           SetPossibleChoiceList(res);
        }catch(err) 
        {
  
        }
  
      } 
    function PrepList(data, bucket, key_name, key_find) {
        const newOptionList = [];
      
        for (var i = 0; i < data.length; i++) {
          
          let object = data[i];
      
          let value_key = null;
          let value_lable = null;

          // console.log(object);
          for (const key in object) {

            const active = String(key);
            if (active.toLowerCase() === key_name.toLowerCase()) {

              const val = object[key];
              value_lable = val;

            } else if (active.toLowerCase() === key_find.toLowerCase()) {

              const val = object[key];
              value_key = val;

            }

            

            if(value_lable !== null && value_key !== null) 
            {
              newOptionList.push({ value: value_key, label: value_lable  });
              break;
            }

          }

          
        }
      
        bucket(newOptionList);
      }
    return (<div className={`${classes.input_list_wrapper}`}>

      <div className={`${classes.input_list_inner_wrapper}`}>

 

                    <div className={`${classes.input_single_wrap}`}>
                      <MasterDataRegInput input={
                          <Select
                              value={I_Inv_Item}
                              isSearchable={true}
                              styles={Custom_DD_Style}
                              required ={true}
                              readOnly
                          /> } /> 
                    </div>

                    <div className={`${classes.input_single_wrap}`}>
                      <MasterDataRegInput input={
                          <Select
                              value={SelectedStore}
                              onChange={(data)=>{
                                  SetSelectedStore(data);
                                  CheckItemBalance(data, I_Inv_Item.value); 
                                  HandleInvItemStore(data, idx)
                                }}
                              name={"dd_data"}
                              options={storeDropDownOption}
                              placeholder={"Choose Store..."}
                              isSearchable={true}
                              styles={Custom_DD_Style}
                              required ={true}
                          /> } tip={"Choose Store to Eject Stock"} />
                    </div>
                     <div className={`${classes.input_single_wrap}`}>
                      <MasterDataRegInput 
                          input={<input 
                          value={ I_Brand} 
                          name='item_brand'
                          type="text"
                          title='' 
                          autoComplete='off' 
                          readOnly
                          />} 
    
                          />
                     </div>

                  <div className={`${classes.input_single_wrap}`}>
                    <MasterDataRegInput 
                        input={<input 
                        value={I_Quantity} 
                        name='item_quantity' min={0.00001} 
                        type="number" 
                        step="any" 
                        title='' 
                        autoComplete='off' 
                        readOnly
                        required />} 
                        
                        />

                  </div>
                  <div className={`${classes.input_single_wrap_validator}`}>
                    <span className={`${Fecthing ? classes.validator_item_pend : SelectedStore ? BudgetSurplus ? classes.validator_item_surp : classes.validator_item_under  : classes.validator_item_pend}`}>
                         <span className={`${classes.icon_budget}`}> <FontAwesomeIcon spin={Fecthing} icon={
                                                                                                            Fecthing ? faSpinner : 
                                                                                                            SelectedStore ? 
                                                                                                              (BudgetSurplus ? faCheckCircle : faTriangleExclamation) 
                                                                                                              : faCircleExclamation
                                                                                                          } ></FontAwesomeIcon></span>
                          <span className={`${classes.text_budget}`}>{Fecthing ? '' : SelectedStore ? BudgetSurplus ? 'Accessible' : 'Inaccessible' : ''}</span>
                    </span>
                  </div>
                      </div>
        </div>)
}