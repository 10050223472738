import classes from './DomesticProduction.module.css'

import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPenNib, faTrash, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

// Component
import CreateDomProduct from './Component/CreateDomProduct/CreateDomProduct';
import EditDemoProduct from './Component/EditDomProduct/EditDomProduct';

import { FetchDomestickProductAll, PurgeDomesticProduct } from '../../../api';

import DecisionAssurance from '../../../SharedComp/DecisionAssurance/DecisionAssurance';
import { useToast } from '../../../ToastContext';
import { useAuth } from '../../../AuthProvider';


export default function DomesticProduction() 
{
    const [OpenProdItemData, SetOpenProdItemData] = useState(false);
    const [OpenEditProdItem, SetOpenEditProdItem] = useState(false);
    const [MainProductList, SetMainProductList] = useState([]);
    const [EditTargetID, SetEditTargetID] = useState();
    const [Referesh, SetReferesh] = useState(false);

    const notify = useToast();
    const auth = useAuth();


    // Decision Assurance
    const [daState, SetDaState] = useState(false);
    const [agreement, SetAgreement] = useState();
    const [reqCallbackData, SetReqCallbackData] = useState();
    const [ActionName, setActionName] = useState(null);

    function OpenManufactureWindow(win_name) 
    {
        SetOpenProdItemData(true);
    }

    const HandleEditProductOpen = async(data)=>{
        SetEditTargetID(data);
        SetOpenEditProdItem(true);
    }
    function HandleSubProductGroupPurge(name, id) 
    {
        SetAgreement(`
            You are about to purge the Master Product '${name}'. This action will have significant consequences:
            Any active orders related to this Product will be aborted.
            Linked recipes will remain intact, but the Product itself will be deleted.
            This action is IRREVERSIBLE. Are you sure you want to proceed?`);
        SetReqCallbackData(id);
        SetDaState(true);
        setActionName("Purge Master Product")
    }

    const QueryMainProductList = async ()=>{
        try 
        {
            const res = await FetchDomestickProductAll(auth.token);
            SetMainProductList(res);
        }catch(err) 
        {
            console.log(err.message);
        }
    }

    const RemoveMasterProduct = async(id)=> 
    {
        if(!id)
            return;

        try 
        {
            const res = await PurgeDomesticProduct(id);
            notify.ShowSuccess(res.Msg);
            SetReferesh(!Referesh);
        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }

    useEffect(()=>{

        QueryMainProductList();

    }, [OpenEditProdItem, OpenProdItemData, Referesh])


    return (
        <div className={`${classes.domestic_prod_main_wrapper}`}>

            {daState && 
                    <DecisionAssurance  
                    ActionName={ActionName}
                    CallbackData={reqCallbackData} 
                    Msg={agreement} 
                    openController={SetDaState} 
                    HandleResponse={RemoveMasterProduct}
                />
            }
            {OpenProdItemData && <CreateDomProduct OpenController={SetOpenProdItemData} />}
            {OpenEditProdItem && <EditDemoProduct OpenController={SetOpenEditProdItem} TargetID={EditTargetID}/>}
                

            <div className={`${classes.data_wrapper_upper}`}>
            

                    <div className={`${classes.list_item_product_class}`}>

                    <div className={`${classes.list_item_product_class_list_wrapper_header}`}>
                        <h2>Main <span> Product Line</span></h2>
                    </div>

                    <div className={`${classes.list_item_product_class_list_wrapper}`}>
                        {
                            MainProductList.map((item, idx)=>(
                                <li key={item.FProdName}>
                                    {/* {MutiSelectSubProduct && <input type="checkbox" /> } */}
                                    <span className={`${classes.list_item_product_class_subcat_name}`}>{item.FProdName}</span>
                                    <span className={`${classes.list_item_product_class_price}`}>{item.FProdUnitPrice.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
                                    <span className={`${classes.list_item_product_class_detail}`}>{item.ProdSubCatName}</span>
                                    <span onClick={()=>{HandleSubProductGroupPurge(item.FProdName, item.FPDID)}} className={`${classes.list_item_product_class_purge}`}> <FontAwesomeIcon icon={faTrash}/> Purge</span>
                                    <span onClick={()=>{HandleEditProductOpen(item.FPDID)}} className={`${classes.list_item_product_class_more}`}><FontAwesomeIcon icon={faPenNib}/> Manage</span>
                                </li>
                            ))
                        }
                            

                    </div>
                    <div className={`${classes.action_button_wrapper_product_class}`}>
                        <button onClick={()=>{OpenManufactureWindow('data')}}><FontAwesomeIcon icon={faPlusCircle}/> New</button>
                        <button onClick={()=>{}}><FontAwesomeIcon icon={faCopy}/></button>
                    </div>

                    </div>

            </div>
        </div>
    )
}