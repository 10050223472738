
import './AssetToolsMasterDataStyle.css'
import DataTableWrapper from '../../SharedComp/DataTable/DataTableWrapper'

export default function AssetToolsMasterData() 
{
    return (
        <div className="asset-tool-master-data">
            
            <div className="main-data-entry-page-wrapper">
            
                <DataTableWrapper
                    WrapperName={'Asset Tool [Goro]'}
                    ThemeColor={'#ef255f'}
                    WrapperWidth={'90%'}
                />

                <DataTableWrapper
                    WrapperName={'Asset Tool [Sefera]'}
                    ThemeColor={'#ef255f'}
                    WrapperWidth={'90%'}
                />

                <DataTableWrapper
                    WrapperName={'Asset Tool [Lomi Meda]'}
                    ThemeColor={'#ef255f'}
                    WrapperWidth={'90%'}
                />

            </div>

        </div>
    );
}