import { faCircleXmark } from '@fortawesome/free-regular-svg-icons'
import classes from './CollectionPreviewReport.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowTrendDown, faBurger, faCircleArrowLeft, faCircleArrowRight, faCubesStacked, faMartiniGlassCitrus, faUsersGear, faUtensils, faVault } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react'

import CatDetailShow from './Component/CatDetailShow'

export default function CollectionPreviewReport(
    {
        CLOSE_CLBK, 
        DailyCollection, 
        PittyExpense,

        ToDateProd_Bakery,
        ToDateProd_Pastry,
        ToDateProd_Merch,

        Bakery_ReadBackBuffer, 
        Pastry_ReadbackBuffer,
        Merch_ReadBackBuffer
        }) 
{

    const [ToDate, SetToDate] = useState(new Date());
    const [SoldBakeryCash, SetSoldBakeryCash] = useState(0);

    const [ActiveCatInspectionIdx, SetActiveCatInspectionIdx] = useState(0);

    function NextInspectCat() 
    {
        if(ActiveCatInspectionIdx == 2)
            SetActiveCatInspectionIdx(0);
        else 
        {
            SetActiveCatInspectionIdx(ActiveCatInspectionIdx + 1);
        }
    }
    function PrevInspectCat() 
    {
        if(ActiveCatInspectionIdx == 0)
            SetActiveCatInspectionIdx(2);
        else 
        {
            SetActiveCatInspectionIdx(ActiveCatInspectionIdx - 1);
        }
    }
    useEffect(()=>{

    }, [])

    return (
        <div className={`${classes.collection_preview_report_main_wrapper}`}>

            <div className={`${classes.collection_preview_inner_wrapper}`}>
                <div className={`${classes.collection_preview_doc_wrapper}`}>

                    <div className={`${classes.collection_preview_header}`}>
                            <h2>Sales Report Preview</h2>
                            <button onClick={()=>{CLOSE_CLBK(false)}}>
                                <span className={`${classes.side_close_icon}`}>
                                    <FontAwesomeIcon icon={faCircleXmark}></FontAwesomeIcon>
                                </span>
                            </button>
                    </div>
                    <div className={`${classes.to_date_wrapper}`}>
                        {/* <h1>
                            {
                                ToDate.toLocaleDateString('en-US', {
                                    weekday: 'long', 
                                    year: 'numeric', 
                                    month: 'long',  
                                    day: 'numeric'  
                                  })
                            }
                        </h1> */}
                    </div>
                    <div className={`${classes.preview_body_wrapper}`}>

                        <div className={`${classes.preview_doc_wrapper}`}>

                            <div className={`${classes.cash_info_wrapper}`}>

                                <div className={`${classes.cash_info_inner_wrapper}`}> 

                                    <div className={`${classes.cash_detail}`} style={{background: `var(--color-yellow)`, borderColor: `var(--color-yellow)`}} >
                                        <li><span className={`${classes.detail_icon_cash}`}><FontAwesomeIcon icon={faUsersGear}></FontAwesomeIcon></span></li>
                                        <li className={`${classes.cash_lable}`}>Production Volume</li>
                                        <li className={`${classes.cash_value}`}>
                                        {
                                            (0).toLocaleString('en-US')
                                        }
                                        </li>
                                    </div>

                                    <div className={`${classes.cash_detail}`} style={{background: `var(--color-yellow)`, borderColor: `var(--color-yellow)`}} >
                                        <li><span className={`${classes.detail_icon_cash}`}><FontAwesomeIcon icon={faBurger}></FontAwesomeIcon></span></li>
                                        <li className={`${classes.cash_lable}`}>Bakery</li>
                                        <li className={`${classes.cash_value}`}>
                                        {
                                            (SoldBakeryCash).toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'ETB',
                                            })
                                        }
                                        </li>
                                    </div>

                                    <div className={`${classes.cash_detail}`} style={{background: `var(--color-yellow)`, borderColor: `var(--color-yellow)`}} >
                                        <li><span className={`${classes.detail_icon_cash}`}><FontAwesomeIcon icon={faUtensils}></FontAwesomeIcon></span></li>
                                        <li className={`${classes.cash_lable}`}>Pastry</li>
                                        <li className={`${classes.cash_value}`}>
                                        {
                                            (0).toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'ETB',
                                            })
                                        }
                                        </li>
                                    </div>
                                    <div className={`${classes.cash_detail}`} style={{background: `var(--color-yellow)`, borderColor: `var(--color-yellow)`}} >
                                        <li><span className={`${classes.detail_icon_cash}`}><FontAwesomeIcon icon={faCubesStacked}></FontAwesomeIcon></span></li>
                                        <li className={`${classes.cash_lable}`}>Merchandise</li>
                                        <li className={`${classes.cash_value}`}>
                                        {
                                            (0).toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'ETB',
                                            })
                                        }
                                        </li>
                                    </div>
                                    <div className={`${classes.cash_detail}`} style={{background: `var(--color-yellow)`, borderColor: `var(--color-yellow)`}} >
                                        <li><span className={`${classes.detail_icon_cash}`}><FontAwesomeIcon icon={faMartiniGlassCitrus}></FontAwesomeIcon></span></li>
                                        <li className={`${classes.cash_lable}`}>Soft Drink</li>
                                        <li className={`${classes.cash_value}`}>
                                        {
                                            (0).toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'ETB',
                                            })
                                        }
                                        </li>
                                    </div>
                                    
                                    
                                    <div className={`${classes.cash_detail}`} style={{background: `var(--red-color)`, borderColor: `var(--red-color)`}}>
                                        <li><span className={`${classes.detail_icon_cash}`}><FontAwesomeIcon icon={faArrowTrendDown}></FontAwesomeIcon></span></li>
                                        <li className={`${classes.cash_lable}`}>Cash Expense</li>
                                        <li className={`${classes.cash_value}`}>
                                        {
                                            (0).toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'ETB',
                                            })
                                        }
                                        </li>
                                    </div>

                                    <div className={`${classes.cash_detail}`} style={{background: `var(--main-color)`, borderColor: `var(--main-color)`}}>
                                        <li><span className={`${classes.detail_icon_cash}`}><FontAwesomeIcon icon={faVault}></FontAwesomeIcon></span></li>
                                        <li className={`${classes.cash_lable}`}>Total in Safe</li>
                                        <li className={`${classes.cash_value}`}>
                                        {
                                            (DailyCollection).toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'ETB',
                                            })
                                        }
                                        </li>
                                    </div>
                                
                                </div>
                            </div>

                            <div className={`${classes.detail_list_main_wrapper}`}>

                                    <div className={`${classes.detail_list_inner_wrapper}`}>

                                        {ActiveCatInspectionIdx === 0 && <CatDetailShow CatID={9} iRefList={Bakery_ReadBackBuffer} iProdList={ToDateProd_Bakery} iHeader={"Bakery"}/>}
                                        {ActiveCatInspectionIdx === 1 && <CatDetailShow CatID={10} iRefList={Pastry_ReadbackBuffer} iProdList={ToDateProd_Pastry} iHeader={"Pastry"}/>}
                                        {ActiveCatInspectionIdx === 2 && <CatDetailShow CatID={12} iRefList={Merch_ReadBackBuffer} iProdList={ToDateProd_Merch} iHeader={"Merchandise"}/>} 

                                    </div>

                                    <div className={`${classes.mini_nav_wrapper}`}>
                                        <div className={`${classes.mini_nav}`}>
                                            <button onClick={()=>{PrevInspectCat()}}><FontAwesomeIcon icon={faCircleArrowLeft}/></button>
                                            <button onClick={()=>{NextInspectCat()}}><FontAwesomeIcon icon={faCircleArrowRight}/></button>
                                        </div>
                                    </div>
                            </div>

                        </div>


                    </div>

                </div>
            </div>

        </div>
    )
}