import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getBaseURL } from "./SharedComp/BaseUrlConfig";
// import { Howl } from 'howler';
import error_log_sound from './asset/audio/error-login.mp3'
import { io } from 'socket.io-client'

import { useLocation } from "react-router-dom";

import {VerifyWhatsgpsAccount, FetchWhatsgpsCarInfo, VerifyWhatsgpsCars} from './SharedComp/API/GPS/API_FE_WhatsGPS'
import {CreateUserLoginRequest, CreateSignOutRequest} from './SharedComp/API/UserAccess/API_FE_UserAuth'
import {QueryEmployeeByID} from './SharedComp/API/HR/API_FE_Employee'

import { ToastWrapper, toast } from "./SharedComp/ToastWrapper/ToastWrapper";
import { useGlobalNotification } from "./GlobalNotification";

import {MixColor, KeyColorSchemeBuffer, PaintColorSchemeBuffer} from './SharedComp/ColorScheme'
import { useToast } from "./ToastContext";

const AuthContext = createContext();

const socket = io.connect(getBaseURL());

const AuthProvider = ({children})=> {

    const location = useLocation();
    const [LastRoute, SetLastRoute] = useState(location.pathname);

    const [user, setUser] = useState(null);
    const [employeeData, setEmployeeData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [serverRes, setServerRes] = useState('##');
    const [token, setToken] = useState(localStorage.getItem("site") || "");
    const [IsOnline, SetIsOnline] = useState(window.navigator.onLine);
    const navigate = useNavigate();

    const [userAccessWorkspaceIDList, setUserAccessWorkspaceIDList] = useState([]);
    const [userAccessworkshopList, setUserAccessworkshopList] = useState([]);
    const [activeWorkShop, setActiveworkshop] = useState(0);
    const [activeWorkShopName, setActiveWorkShopName] = useState('');
    const CarInfoIntervalRef = useRef(null);


    const [ActiveAccentColor, SetActiveAccentColor] = useState(KeyColorSchemeBuffer[0].Value);
    const [ActiveSurfaceColor, SetActiveSurfaceColor] = useState(PaintColorSchemeBuffer[0].Value);

    const Gnotify = useGlobalNotification();
    const notify = useToast();


    const ToastHeader = useRef(new Map());
    const GetToastID = (__id) =>
    {
        const _rand = parseInt(Math.ceil((Math.random() * 1000)));
        ToastHeader.current.set(__id, _rand);
        return _rand;
    }
    const FlushToastID = (__id) =>
    {
        const _flush_id = parseInt(ToastHeader.current.get(__id));
        ToastHeader.current.delete(__id);
        return _flush_id;
    }


    const setThemeVariables = async(themeConfig) => {
        Object.keys(themeConfig).forEach((key) => {
            document.documentElement.style.setProperty(key, themeConfig[key]);
        });
    };


    const SetSurfaceColor = async(surface_color)=>
    {
        SetActiveSurfaceColor(surface_color);
        setThemeVariables(MixColor(surface_color, ActiveAccentColor));
    }
    const SetAccentColor = async(accent_color)=>
    {
        SetActiveAccentColor(accent_color);
        setThemeVariables(MixColor(ActiveSurfaceColor,accent_color))
    }

    // const error_sound = new Howl({
    //     src: [error_log_sound]
    // })

    const WatchCarInfo = async (gps_token, car_id) => {
        try {
            const res = await FetchWhatsgpsCarInfo(car_id, gps_token, 'my_token');

            if(res.data)
            {
                if(res.data[0].status === '5') 
                {
                    Gnotify.Disturb(`Vehicle Motion Detected`, `Your vehicle [${res.data[0].carId}] is now moving. Time of detection: ${new Date().toLocaleTimeString()}`);

                    clearInterval(CarInfoIntervalRef.current);
                    CarInfoIntervalRef.current = null; // Reset the ref after clearing
                }
                
            }
    
        } catch (err) {
            // console.log(err);
        }
    };

    // useEffect(()=>{
    //     const _gps_token = localStorage.getItem('GPS-TOKEN');
    //     const _active_car = localStorage.getItem('GPS-ACTIVE-CAR');
    //     if(_gps_token && _active_car) 
    //         {
    //             CarInfoIntervalRef.current = setInterval(async () => {
    //                 WatchCarInfo(_gps_token, _active_car, 'my_token');
                    
    //             }, 8000);
    //         }

    //         return () => {
    //             if (CarInfoIntervalRef.current) {
    //                 clearInterval(CarInfoIntervalRef.current);
    //                 CarInfoIntervalRef.current = null; // Reset the ref after clearing
    //             }
    //         }
    // }, []);

    const parseData = (data) =>
    {
        const __userAccessWorkspaceIDList = [];
        const __userAccessworkshopList = [];

        
        for (const [key, value] of Object.entries(data)) {

            if (value !== -1) {
                __userAccessWorkspaceIDList.push(value);
                __userAccessworkshopList.push({ label: key, value: value });
            }
        }
        if(__userAccessworkshopList.length > 0) 
        {
            const _starter = __userAccessworkshopList[0];
            setActiveWorkShopName(_starter.label);
            setActiveworkshop(_starter.value);
        }

        setUserAccessWorkspaceIDList(__userAccessWorkspaceIDList);
        setUserAccessworkshopList(__userAccessworkshopList);
    }
    const HandleWhatGPSAPI = async() =>
    {
        if(!IsOnline) 
        {
            return;
        }
        try 
        {
            const AccountGPSRes = await VerifyWhatsgpsAccount();
            const CarRes = await VerifyWhatsgpsCars(AccountGPSRes.data.token);
            localStorage.setItem('GPS-TOKEN', AccountGPSRes.data.token);
            localStorage.setItem('GPS-ACTIVE-CAR', CarRes.data[0].carId);

        }catch(err) 
        {
            console.log(err);
        }
    }
    const QueryEmployeeData = async (data) =>{

        try 
        {

            // Prep WhatsGPS Data
            const _gps_ress = await HandleWhatGPSAPI();

            if(data.Type === "Classic") 
                return;

            const main_res = await QueryEmployeeByID({EmpID: data.WorkspaceID}, 'token');
            setEmployeeData(main_res[0]);

        }catch(err) 
        {
            notify.ShowError("Falied to Query Employee Infomration");
        }

    }

    const ValidateToken = async() =>
    {

    }

    const SerializeUserData = async()=>
    {

    }

    const InitUser = async () => {


        setLoading(true);
        try {
            const me_user = localStorage.getItem('user');
            const me_token = localStorage.getItem('site');

            if (me_user && me_token) {
                const res = JSON.parse(me_user);
                QueryEmployeeData(res);
                storeUserID(res.UserAccountID);
                parseData(JSON.parse(res.WorkspacePrivilege)); 
                setUser(res);
            }
        } catch (error) {
            console.error("Failed to retrieve user data from local storage:", error);
        } finally {
            setLoading(false);
        }
    }

   

    const handleOnline = () => {
        
        notify.ShowSuccess("You're now back online.");
        console.log(LastRoute);
        if(LastRoute) 
        {
            navigate(LastRoute);
        }
        SetIsOnline(true);
        if(user) 
        {
            storeUserID(user.UserAccountID);
        }
    }
    const handleOffline = () => {
        navigate('/offline');
        socket.emit('web-disconnect', null);
        SetIsOnline(false);
    }

    const storeUserID = (id) => {
        socket.emit('user-tag-in', id);
    };

    useEffect(()=>{
        if(location.pathname !== '/offline')
        {
            SetLastRoute(location.pathname);
        }
    }, [location.pathname])

    
    useEffect(() => {
        

        InitUser();




        // Event handler for beforeunload event
        const handleBeforeUnload = (e) => {
            // socket.emit('web-disconnect', user);
            // localStorage.removeItem('user');
            // localStorage.removeItem('site');
            // localStorage.removeItem('ID');

        };

        // Attach event listener
        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener("online", handleOnline)
        window.addEventListener("offline", handleOffline)

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener("online", handleOnline)
            window.removeEventListener("offline", handleOffline)
        };


    }, []);

    const loginAction = async (data, time_stamp) => {

        setLoading(true);
        notify.ShowLoad("Validating Your Credentials...", GetToastID(time_stamp));

        setServerRes("Validating...");

            try 
                {

                    const main_res = await CreateUserLoginRequest(data);
                    setServerRes(main_res.Msg);
                    
                    setLoading(false);
                    
                    notify.ShowSuccessUpdate(main_res.Msg, FlushToastID(time_stamp));
                    
                    // Parse the user info
                    const pasrse_user = JSON.parse(main_res.user);

                    // Query Employee Info if the user is standard
                    QueryEmployeeData(pasrse_user);

                    // Store user info 
                    // This will help us to notify the other user this guy is online
                    storeUserID(pasrse_user.UserAccountID);

                    // Parse the user Workspace Access
                    // This will help us to know to which wrokspace this user has access to
                    parseData(JSON.parse(pasrse_user.WorkspacePrivilege));

                    // Store the user data this will be usefull if any child component want to get know
                    // who the user is
                    setUser(pasrse_user);
                    setToken(main_res.token);

                    // and store them in the LocalStorage
                    localStorage.setItem('user', main_res.user);
                    localStorage.setItem('site', main_res.token);

                    // The time the user loged in
                    const session_start_time = new Date().toISOString();
                    localStorage.setItem('sessionStart', session_start_time);


                    // and finally go to the prepared page
                    navigate(pasrse_user.UARI_LandingPageRoute);
                    notify.Close();

                } catch(err) 
                {
                    notify.ShowErrorUpdate(err.message, FlushToastID(time_stamp));
                    console.log(err.message);
                    // error_sound.play();
                    setLoading(false);
                }
        
    }

    const logOut = async() => {
        try 
        {
            const fd = new FormData();
            const __session_start = localStorage.getItem("sessionStart");
            fd.append("session_start", __session_start);
            fd.append("user_data", JSON.stringify(user))
            const main_res = await CreateSignOutRequest(fd);
        }catch(err)
        {
            notify.ShowError(err.message)
        }
        
        setLoading(true);
        setUser(null);
        setToken("");
        socket.emit('user-disconnect');
        localStorage.removeItem('user');
        localStorage.removeItem('sessionStart');
        localStorage.removeItem('site');
        localStorage.removeItem('ID');
        localStorage.removeItem('active_on_menu_idx');
        navigate("/login");
        // window.location.reload();
        setLoading(false);
    }

    return (
        <AuthContext.Provider value={
            {token, 
            user, 
            loading, 
            serverRes, 
            userAccessWorkspaceIDList, 
            userAccessworkshopList, 
            activeWorkShop,
            setActiveworkshop,
            loginAction, 
            logOut,
            activeWorkShopName,
            setActiveWorkShopName,
            employeeData,
            IsOnline,
            SetAccentColor,
            SetSurfaceColor
            }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
}