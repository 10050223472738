import { getBaseURL } from "../../BaseUrlConfig";
import axios from 'axios'


function GetRouteConfig(token)
{
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
}

export const CreateChefNotifMsg = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'msg/serve/create-chef-noti-message-pkg', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryUnseenMessage = async(data, token)=>
    {
        try  
        {
            const response = await axios.post(getBaseURL() + 'msg/serve/query-unseen-messages', data, GetRouteConfig(token));
            const res = await response.data;
            return res;
        }catch(err) 
        {
            throw err;
        }
    }