import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faFileImage, faFileImport, } from '@fortawesome/free-solid-svg-icons';
import classes from './PicAndDocCollector.module.css'
import EmpDocInput from '../EmpDocInput/EmpDocInput';
import PictureTaker from './Component/PictureTaker';
import { useState } from 'react';
import { forwardRef, useImperativeHandle } from 'react';

const PicAndDocCollector = forwardRef(({ DataPkg, DataUpdater, updateInput }, ref) => {

    const [TakePicture, SetTakePicture] = useState(false);

    function HandleCameraOpen() 
    {
        SetTakePicture(true);
    }

    const [EmployeePicture_State, SetEmployeePictureField_State]                    = useState(0);
    const [EmployeeCert_State, SetEmployeeCertField_State]                          = useState(0);
    const [EmployeeKblID_State, SetEmployeeKblIDField_State]                        = useState(0);
    const [EmployeeGuarenteKblID_State, SetEmployeeGuarenteKblIDField_State]        = useState(0);
    const [EmployeeGurDoc_State, SetEmployeeGurDocField_State]                      = useState(0);


    function PerformLocalInputGuard() 
    {
        let return_value = true;
        if(DataPkg["employee_picture"] === undefined || DataPkg["employee_picture"] === '') 
        {
            SetEmployeePictureField_State(-1);
            return_value = false;
        } else 
        {
            SetEmployeePictureField_State(1);
        }

        if(DataPkg["employee_certification"] === undefined || DataPkg["employee_certification"] === '') 
        {
            SetEmployeeCertField_State(-2);
        } else 
        {
            SetEmployeeCertField_State(1);
        }

        if(DataPkg["employee_kebele_id"] === undefined || DataPkg["employee_kebele_id"] === '') 
        {
            SetEmployeeKblIDField_State(-1);
            return_value = false;
        } else 
        {
            SetEmployeeKblIDField_State(1);
        }

        if(DataPkg["employee_guarantee_kbl_id"] === undefined || DataPkg["employee_guarantee_kbl_id"] === '') 
        {
            SetEmployeeGuarenteKblIDField_State(-1);
            return_value = false;
        } else 
        {
            SetEmployeeGuarenteKblIDField_State(1);
        }

        if(DataPkg["employee_guarantee_doc"] === undefined || DataPkg["employee_guarantee_doc"] === '') 
        {
            SetEmployeeGurDocField_State(-2);
        } else 
        {
            SetEmployeeGurDocField_State(1);
        }

        return return_value;
    }

    useImperativeHandle(ref, () => ({
        PerformLocalInputGuard,
    }))
    return (
        <div className={`${classes.pic_and_doc_main_wrapper}`}>
            <div  className={`${classes.info_header}`}>
                    <h2>Profile Picture & Support Document upload </h2>
            </div>
            <div className={`${classes.emp_section_manager}`}>
                <div className={`${classes.emp_input_section_wrapper}`}>
                   <PictureTaker Active={TakePicture} SetActiveController={SetTakePicture} EmpData={DataPkg}/>

                    

                    <EmpDocInput  
                        Tip={"The Image Size should less than 2mb, It is not forced, It is recommendation! For the good of mankind!"}
                        Data={DataPkg} 
                        DataUpdater={DataUpdater}
                        AcceptOnly={"image/png, image/jpeg, image/jpg"}
                        OnChangeUpdate={updateInput} 
                        Title='Employee Picture' 
                        useCameraProcessing={HandleCameraOpen} 
                        ValueTag={"employee_picture"} 
                        Icon={faFileImage} 
                        PlaceHolder={"Browse Employee Picture..."}
                        ForceInput={true}
                        State={EmployeePicture_State}
                    />
                    <EmpDocInput  
                        Data={DataPkg} 
                        DataUpdater={DataUpdater}
                        AcceptOnly={"application/pdf"}
                        OnChangeUpdate={updateInput} 
                        Title='Certification' 
                        ValueTag={"employee_certification"} 
                        Icon={faFileImport} 
                        PlaceHolder={"Browse Certification..."}
                        State={EmployeeCert_State}
                        
                    />
                    <EmpDocInput  
                        Data={DataPkg} 
                        DataUpdater={DataUpdater}
                        AcceptOnly={"application/pdf"}
                        OnChangeUpdate={updateInput} 
                        Title='Employee Kebele ID' 
                        ValueTag={"employee_kebele_id"} 
                        Icon={faFileImport} 
                        PlaceHolder={"Browse Employee Kebele ID..."}
                        ForceInput={true}
                        State={EmployeeKblID_State}
                    />
                    <EmpDocInput  
                        Data={DataPkg} 
                        DataUpdater={DataUpdater}
                        AcceptOnly={"application/pdf"}
                        OnChangeUpdate={updateInput} 
                        Title='Guarantee Kebele ID' 
                        ValueTag={"employee_guarantee_kbl_id"} 
                        Icon={faFileImport} 
                        PlaceHolder={"Browse Guarantee Kebele ID..."}
                        ForceInput={true}
                        State={EmployeeGuarenteKblID_State}
                        />

                    <EmpDocInput  
                        Data={DataPkg} 
                        DataUpdater={DataUpdater}
                        AcceptOnly={"application/pdf"}
                        OnChangeUpdate={updateInput} 
                        Title='Guarantee Document' 
                        ValueTag={"employee_guarantee_doc"} 
                        Icon={faFileImport} 
                        PlaceHolder={"Browse Guarantee Document..."}
                        State={EmployeeGurDoc_State}
                    />

                </div>

                </div>
        </div>
    )
})

export default PicAndDocCollector;