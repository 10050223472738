import classes from './EmpDocInput.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk, faCamera, faCircleCheck, faCircleExclamation, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'react-tooltip';
import { useState } from 'react';

export default function EmpDocInput({OnChangeUpdate, DataUpdater, useCameraProcessing, Data, Title="", ValueType="file", PlaceHolder, ValueTag, Icon, State, Tip, AcceptOnly, ForceInput = false}) 
{
    const [doc, setDoc] = useState();


    function HandleChange(e) 
    {
        // OnChangeUpdate(e);
        DataUpdater(prev => ({
            ...prev,
            [e.target.name]: e.target.files[0]
        }));
        const file = e.target.files[0];
        if (file) {
            setDoc(file.name);
        }
    }
    return (
        <div className={`${classes.emp_text_input_main_wrapper}`}>
            <Tooltip className={`${classes.tool_quick}`} id="input_field_tip" place="top" />
            <div className={`${classes.emp_input_title}`}>
                <li>{Title} <span className={`${classes.emp_input_force_notifyer}`}>{ForceInput ? <sup><FontAwesomeIcon icon={faAsterisk}></FontAwesomeIcon></sup> : ''}</span></li>
            </div>
                <div className={`${classes.emp_input_line_wrapper} ${State === -1 ? classes.emp_input_line_wrapper_error_border : State === 1 ? classes.emp_input_line_wrapper_suc_border : '' }`}>

                    <div className={`${classes.emp_input_left_side_data}`}>
                        <div className={`${classes.emp_input_left_icon_wrapper}`}>
                            <span className={`${classes.emp_side_icon}`}><FontAwesomeIcon icon={Icon}></FontAwesomeIcon></span>
                        </div>
                        
                        

                        <div
                        {...(Tip ? {'data-tooltip-id': 'input_field_tip', 'data-tooltip-content': Tip} : {})}
                        className={`${classes.emp_create_input_wrapp}`}>

                            <div className={`${classes.lable_wrapper}`}>
                                <label className={`${classes.doc_input_lable}`} For={ValueTag}>{Data[ValueTag] ? Data[ValueTag].name : PlaceHolder} </label>{useCameraProcessing && <span onClick={useCameraProcessing} className={`${classes.camera_processing}`}><FontAwesomeIcon icon={faCamera}></FontAwesomeIcon></span>}
                            </div>

                            <input 
                            accept={AcceptOnly}
                            id={ValueTag}
                            className={`${classes.emp_doc_input}`}
                            type={ValueType} 
                            name={ValueTag}
                            // value={Data[ValueTag]}
                            onChange={(e) => (HandleChange(e))}
                            required={ForceInput}
                            title=""
                            />
                        </div>
                    </div>

                    <div className={`${classes.emp_icon_right_side_notify}`}>
                        <span className={`${classes.emp_side_icon_noti} ${State === 0 ? classes.emp_icon_right_side_notify_friendly : State === -1 ? classes.emp_icon_right_side_notify_error : State === 1 ? classes.emp_icon_right_side_notify_good : State === -2 ?classes.emp_icon_right_side_notify_whatever : ''}`}>
                                {State !== 0 && <FontAwesomeIcon icon={State === -1 ? faCircleExclamation : State === -2 ? faExclamation : faCircleCheck}></FontAwesomeIcon> }
                            </span>
                    </div>
                </div>
        </div>
    )
}