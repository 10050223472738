import classes from './ProductionStack.module.css'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {FetchProductionOrderBranchStockedOut} from '../../api'
import { useAuth } from '../../AuthProvider'
import ProductionStackOrderGroupWrapper from './Component/ProductionStackOrderGroupWrapper/ProductionStackOrderGroupWrapper'
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons'



export default function ProductionStack() 
{
    const [PendningOrder, SetPendningOrder] = useState([]);
    const auth = useAuth();
    const [RefreshView, SetRefreshView] = useState(false);


    


    const FetchProdReadyOrders = async()=>
    {
        try {

            const res = await FetchProductionOrderBranchStockedOut(auth.activeWorkShop, auth.token);
            SetPendningOrder(res);
            
        }catch(err) 
        {

        }
    }

    const DataRefresh = async(_hash)=>
    {
        SetRefreshView(!RefreshView);
    }
    
    useEffect(()=>{
        FetchProdReadyOrders();
    }, [RefreshView])


    return (
        <div className={`${classes.production_stack_main_wrapper}`}>
            
            
            
            <div className={`${classes.prodution_stack_group_wrapper}`}>
                {
                    PendningOrder.map((item, idx)=>(
                        <ProductionStackOrderGroupWrapper 
                                key={item.PO_Hash} 
                                DataPoint={item} 
                                DataRefresh_Callback={DataRefresh}
                            /> 
                    ))
                    
                }

                { PendningOrder.length === 0 &&
                    <div className={`${classes.empty_stack}`}>
                        <li><FontAwesomeIcon icon={faBoxOpen}></FontAwesomeIcon></li>
                        <li>No Production Stack</li>
                    </div>
                }
            </div>
            
        </div>
    )
}