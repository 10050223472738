import classes from './Vendors.module.css'
import VendorCard from './Component/VenderCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import RegisterVenderCard from './Component/RegisterVenderCard/RegisterVenderCard'
import { useState } from 'react'
import NotificationPopUp from '../../../SharedComp/NotificationPopUp/NotificationPopUp'
import DecisionAssurance from '../../../SharedComp/DecisionAssurance/DecisionAssurance'
import { useEffect } from 'react'

import axios from 'axios';

export default function Vendors() 
{
    const[OpenModal, SetOpenModel] = useState(false);
    const [MsgPkg, SetMsgPkg] = useState({});
    const [OpenMyNoti, SetOpenMyNoti] = useState(false);
    const [RefeshList, SetRefeshList] = useState(false);

     // Decision Assurance
     const [daState, SetDaState] = useState(false);
     const [agreement, SetAgreement] = useState();
     const [reqCallbackData, SetReqCallbackData] = useState();
     const [ActionName, setActionName] = useState(null);

    const[VendorList, SetVendorList] = useState([]);

    function MsgCallback(res) 
    {
        SetOpenModel(false);
        LocalMsgTrigger(res.data.Msg, res.data.MyStateCode);
    }

    function LocalMsgTrigger(msg, stat) 
    {
        if(stat === 1) 
        {
            SetRefeshList(!RefeshList);
        }
        SetMsgPkg({Key : Math.random() * 10000, Msg: msg, State: stat})
        SetOpenMyNoti(true);
    }


    function RemoveItem(name) 
    {
        if(!name)
            return;

        const url = 'http://localhost:3333/operation-data/purge-vendor';

        const fd = new FormData();
        fd.append("purge_id", name);


        axios.post(url, fd, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            }).then(res => {
                MsgCallback(res);
            }).catch(err => {
                LocalMsgTrigger("Unknown Server Error.", -1);
                })
    }

    function InitVendorRemoval(id, name) 
    {
        SetAgreement(`You are about to Purge Vender '${name}'. Are you sure you want to do this?`);
        SetReqCallbackData(name);
        SetDaState(true);
        setActionName("Purge Vendor")
    }
    
    useEffect(()=>{

        fetch('http://localhost:3333/operation-data/get-vendor')
        .then(response => response.json())
        .then(data => SetVendorList(data))
        .catch(error => console.error(error));

    }, [RefeshList])


    return (
        <div className={`${classes.vender_main_wrapper}`}>


            {daState && 
                <DecisionAssurance  
                    ActionName={ActionName}
                    CallbackData={reqCallbackData} 
                    Msg={agreement} 
                    openController={SetDaState} 
                    HandleResponse={RemoveItem}
                />
            }

            {OpenMyNoti && <NotificationPopUp key={MsgPkg.Key} msg={MsgPkg.Msg} state={MsgPkg.State} />}
            {OpenModal && <RegisterVenderCard CloseModal_CALLBACK={SetOpenModel} MsgTrigger_CALLBACK={MsgCallback} />}



            <div className={`${classes.vender_header}`}>
                <h1>Vendors</h1>
            </div>
            
            <div className={`${classes.vender_main_inner_wrapper}`}>

               <div className={`${classes.vender_main_inner_list_wrapper}`}>

                    {
                        VendorList.map((item, idx)=>(
                                <VendorCard key={idx} ID={item.VID} ItemName={item.Vname} Tin={item.Vtin} Purge_CALLBACK={InitVendorRemoval} />
                        ))
                    }
                    
               </div>

            </div>

            <div className={`${classes.action_button}`}>
                <button onClick={()=>{SetOpenModel(true)}}><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon></button>
            </div>

        </div>
    )
}