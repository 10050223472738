import classes from './PaperHash.module.css'


export default function PaperHash({text = null}) 
{
    return (
        <div className={`${classes.paper_hash} ${classes.print_only}`}>
            {!text ? "internal use only" : text}
        </div>
    )
}