import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import classes from './ShopItem.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function ShopItem({ItemName, Quantity, Color='${var(--color-theme)}'}) 
{
    return (
        <div className={`${classes.local_shop_item_wrapper}`}>

            <div style={{backgroundColor: `${Color}`, borderColor:  `${Color}`}} className={`${classes.local_shop_item_header}`}>
                <li>{Quantity}</li>
            </div>  

            <div className={`${classes.local_shop_item_body_wrapper}`}>
                <h2>{ItemName}</h2>
            </div> 
        </div>
    )
}