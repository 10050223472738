import {  faBoxOpen, faFilter, faPlus } from '@fortawesome/free-solid-svg-icons'
import classes from './LocalShopArchive.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ShopeItemCreator from './Component/ShopItemCreate/ShopItemCreate'
import ShopItemCreateCKProd from './Component/ShopItemCreateCKProd/ShopItemCreateCKProd'

import MiniNotification from './Component/MiniNotificationBar/MiniNotification'

import NotificationPopUp from '../../../SharedComp/NotificationPopUp/NotificationPopUp'
import ShopItem from './Component/ShopItem'
import { useState } from 'react'
import { useEffect } from 'react'
import { getBaseURL } from '../../../SharedComp/BaseUrlConfig'
import axios from 'axios';

export default function LocalShopArchive() 
{
    const [OpenShopeCreateItem, SetOpenShopeCreateItem] = useState(false);
    const [OpenShopeCreateCKProd, SetOpenShopeCreateCKProd] = useState(false);
    const [MsgPkg, SetMsgPkg] = useState({});
    const [OpenMyNoti, SetOpenMyNoti] = useState(false);
    const [RefeshList, SetRefeshList] = useState(false);

    const [LocalShopItems, SetLocalShopItems] = useState([]);
    const [FilterShopItem, SetFilterShopItem] = useState([]);

    const [LocalShopPastryItem, SetLocalShopPastryItem] = useState([]);

    const [MiniNotificationViewList, SetMiniNotificationViewList] = useState([]);


    function SortByName(a, b) 
    {
        return a.LSINameLazy.localeCompare(b.LSINameLazy);
    }
    function FilterList(e)
    {
        let DataAccumulation = [];
        for (let i = 0; i < LocalShopItems.length; i++) {

            if(LocalShopItems[i].LSINameLazy.toLowerCase().includes(e.target.value.toLowerCase()))
                DataAccumulation.push({ ...LocalShopItems[i]});
        }
        DataAccumulation.sort(SortByName);
        SetFilterShopItem(DataAccumulation);
    }

    function HandleRespone(res) 
    {
        SetOpenShopeCreateItem(false);
        LocalMsgTrigger(res.data.Msg, res.data.MyStateCode);
    }
    function HandleResponeCK(res) 
    {
        SetOpenShopeCreateCKProd(false);
        LocalMsgTrigger(res.data.Msg, res.data.MyStateCode);
    }

    function LocalMsgTrigger(msg, stat) 
    {
        if(stat === 1) 
        {
            SetRefeshList(!RefeshList);
        }
        SetMsgPkg({Key : Math.random() * 10000, Msg: msg, State: stat})
        SetOpenMyNoti(true);
    }

    function SetQueryData(data) 
    {
        SetFilterShopItem(data);
        SetLocalShopItems(data);
    }

    function NotificationClear() 
    {
        const fd = new FormData();
    
        fd.append("ClientID", 1);
        fd.append("BranchID", 1);

        const url = getBaseURL() + 'operation-data/local_shop_archive_mini_notice_clear';
        axios.post(url, fd, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            })
        .then(res => {
            SetRefeshList(!RefeshList);
        })
        .catch(err => {

        })
    }
    useEffect(()=>{
        fetch(getBaseURL() + 'operation-data/query-local-store-item')
        .then(response => response.json())
        .then(data => SetQueryData(data))
        .catch(error => console.error(error));


        // Fetch user msg if any

        fetch(getBaseURL() + 'operation-data/local_shop_archive_mini_notice_view', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ClientID: "JAK0093", BranchID: "JAKB001"})
        })
        .then(res => res.json())
        .then((data)=>{
            SetMiniNotificationViewList(data);
        }).
        catch(err => {
            console.log(err)
        })

    }, [RefeshList])

    useEffect(()=>{
        fetch(getBaseURL() + 'operation-data/query-local-store-pastry-item-all')
        .then(response => response.json())
        .then(data => SetLocalShopPastryItem(data))
        .catch(error => console.error(error));
    }, [RefeshList])


    return (
        <div className={`${classes.local_shop_main_wrapper}`}>

            {OpenMyNoti && <NotificationPopUp key={MsgPkg.Key} msg={MsgPkg.Msg} state={MsgPkg.State} />}
            {OpenShopeCreateItem && <ShopeItemCreator Close_CALLBACK={SetOpenShopeCreateItem} RES_CALLBACK={HandleRespone}/>}
            {OpenShopeCreateCKProd && <ShopItemCreateCKProd Close_CALLBACK={SetOpenShopeCreateCKProd} RES_CALLBACK={HandleResponeCK}/> }
            {MiniNotificationViewList.length > 0 &&  <MiniNotification DataList={MiniNotificationViewList} ClearCallback={NotificationClear}/>}
            <div className={`${classes.local_shop_header_wrapper}`}>
                
                <div className={`${classes.local_shop_header_inner}`}>
                    <h1>Local Shop Archive</h1>
                </div>
                <div className={`${classes.shop_item_filter}`}>
                    <input onChange={(e)=>{FilterList(e)}} type="text" placeholder='search by name..' />
                    <span className={`${classes.search_icon}`}><FontAwesomeIcon icon={faFilter}></FontAwesomeIcon></span>
                </div>

            </div>

        
            <div className={`${classes.local_shop_inner_wrapper}`}>

                {
                    FilterShopItem.map((item, idx) => (
                        <ShopItem key={idx} ItemName={item.ItemName} Quantity={item.Quantity} />
                    ))
                }

                {
                    LocalShopPastryItem.map((item, idx)=>(
                        <ShopItem key={idx} ItemName={item.ItemName} Quantity={item.Quantity} Color={'var(--color-yellow)'} />
                    ))
                }

                {
                    (FilterShopItem.length === 0 && LocalShopPastryItem.length === 0) && <div className={`${classes.empty_query}`}><li>No Data, sadly.</li></div>
                }
        
            </div>

            <div className={`${classes.min_action_button_main_wrapper}`}>

                <div className={`${classes.min_action_button_inner_wrapper}`}>
                    <button onClick={()=>{SetOpenShopeCreateItem(true)}}>Add Merchandise Product </button>
                    <button onClick={()=>{SetOpenShopeCreateCKProd(true)}}>Add Pastry Product </button>
                </div>
            </div>

        </div>
    )
}