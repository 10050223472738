import classes from './ReadbackBufferPreview.module.css'
import { Tooltip } from 'react-tooltip';

export default function ReadbackBufferPreview({BufferData}) 
{
    return (
        <li>  <Tooltip className={`${classes.tool_quick}`} id="offical_doc" place="top" />
            {BufferData.Name} 
            <span className={`${classes.point_data_wrapper}`}>
                <span {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': "Unsold Product / Transferred into Tomorrow."})} className={`${classes.wrapper_unsold}`}>
                    {BufferData.UNSOLD_ITEM}
                </span>
                
                <span {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': "Disposed Product."})} className={`${classes.wrapper_damage}`}>
                    {BufferData.DAMAGED_ITEM}
                </span>
                <span {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': "Consumed Product."})} className={`${classes.wrapper_used}`}>
                    {BufferData.LOCAL_USED_ITEM}
                </span>
            </span>
        </li>
    )
}