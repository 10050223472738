import { faLayerGroup, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import classes from './PtoAction.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRef } from 'react';
import { useState } from 'react';

import axios from 'axios';
import Select from 'react-select';
import { useEffect } from 'react';
import { Tooltip } from 'react-tooltip';
import { getBaseURL } from '../../../../../../SharedComp/BaseUrlConfig';


function HandlePTOAllowanceForm(registring, formRef, HandleSubmition, PTOTypeList, SetPtoDayCount, PtoDayCount, EditDayCount, SetEditDayCount, SetPtoType) 
{
    
    function HandleDayCountCheck(e) 
    {
        if(e.target.checked) 
        {
            SetEditDayCount(false);
        } else 
        {
            SetEditDayCount(true);
        }
    }
    function HandleModDayCount(e) 
    {
        SetPtoDayCount(e.target.value);
    }
    function HandlePtoSelection(data) 
    {
        SetPtoType(data.value);
        fetch(getBaseURL() + 'master-data/filter-pto/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ID: data.value }),
        })
        .then(res => res.json())
        .then((data) => {SetPtoDayCount(data[0].AllowedDateCount)});

        SetPtoDayCount(data.value);
    }
    const Custom_DD_Style = {
        
        control: (provided, state) => ({
            ...provided,
            width: 370,
            minHeight: 20,
            height: 35,
            fontWeight: 'bold',
            fontSize: 13,
            background: 'var(--background-dark)',
            border: 'none',
            borderColor: 'var(--background-light)',
            color: 'var(--primary_text_color)',
            borderRadius: 1,
            borderColor: 'none', 
            boxShadow: 'none', 
            '&:hover': {
                borderColor: 'none', 
                boxShadow: 'none', 
            },
            }),
            option: (provided) => ({
            ...provided,
            backgroundColor: 'var(--primary-color)', 
            color: 'var(--secondary-color)',
            }),
            option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? 'var(--body-background)' : 'var(--primary-color)', 
            color: state.isFocused ? 'var(--primary-color)' : 'var(--secondary-color)',
            transition: 'background-color 0.2s ease-in-out',
            cursor: 'pointer',
        }),
            menu: (provided) => ({
            ...provided,
            zIndex: 9999,
            backgroundColor: 'var(--primary-color)',
            color: 'var(--primary-color)',
            fontSize: 13,
            borderRadius: 1,
            fontWeight: 500,
            }),
            singleValue: styles => ({
                ...styles,
                color: 'var(--text_light_white)', // Adjust this line for selected text color
              }),
        }
    return (
        <form ref={formRef} onSubmit={HandleSubmition} action="POST">
            <Tooltip className={`${classes.tool_quick}`} id="offical_doc" place="top" />
                        <div className={`${classes.pto_input_main}`}>
                            <span className={`${classes.pto_input_main_icon}`}><FontAwesomeIcon icon={faLayerGroup}></FontAwesomeIcon></span>
                            <Select
                                styles={Custom_DD_Style}
                                onChange={HandlePtoSelection}
                                name={"pto_type"}
                                options={PTOTypeList}
                                placeholder={"PTO Type.."}
                                // value={selectedOptions}
                                isSearchable={true}
                                required ={true}
                            />
                        </div>
                        <div className={`${classes.input_extra_layer}`}>
                            <li>When does this PTO kick's in?</li>
                            <div className={`${classes.pto_input_main}`}>
                                <span className={`${classes.pto_input_main_icon}`}><FontAwesomeIcon icon={faLayerGroup}></FontAwesomeIcon></span>
                                <input name='pto_start_date' min={new Date().toISOString().split('T')[0]} type="date" required />
                            </div>
                        </div>
                        <div className={`${classes.input_extra_layer}`}>
                            <div  className={`${classes.input_extra_layer_caution}`}>
                                <li>Modify day count?</li>
                                <input {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': "Modifying the default value is not recommended! If You are that eager click here!"})} onChange={HandleDayCountCheck} type="checkbox" />
                            </div>
                            <li>Number of business days for this PTO.</li>
                            <div className={`${classes.pto_input_main}`}>
                                <span className={`${classes.pto_input_main_icon}`}><FontAwesomeIcon icon={faLayerGroup}></FontAwesomeIcon></span>
                                <input min={1} onChange={HandleModDayCount} value={PtoDayCount ? PtoDayCount : ''} name='number_of_business_day' type="number" placeholder='Number of business days!' required readOnly={EditDayCount}/>
                            </div>
                        </div>
                        <div className={`${classes.pto_input_main}`}>
                            <span className={`${classes.pto_input_main_icon}`}><FontAwesomeIcon icon={faLayerGroup}></FontAwesomeIcon></span>
                            <input  name='reason' type="text" placeholder='Detail' />
                        </div>
                        <div className={`${classes.pto_input_main}`}>
                                <span className={`${classes.pto_input_main_icon}`}><FontAwesomeIcon icon={faLayerGroup}></FontAwesomeIcon></span>
                                <input name='approve_date' type="date" />
                        </div>

                        <div  className={`${classes.input_extra_layer_caution}`}>
                                <li>Consider holidays and weekends?</li>
                                <input name='ignore_non_business_day' {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': "Click here to consider holidays and weekends as regular business days! Which is not cool!"})} onChange={HandleDayCountCheck} type="checkbox" />
                        </div>

                        <div className={`${classes.pto_input_submit_action_button}`}>
                            <button disabled={registring} >{registring ? <FontAwesomeIcon icon={faSpinner} spin></FontAwesomeIcon> : "Approve PTO"}</button>
                        </div>
            </form>
    )
}

export default function PtoAction({OpenController, EmpData, MsgTrigger})
{
    const formRef = useRef();
    const [IsRegistring, SetRegistring] = useState(false);
    const [PtoTypeList, SetPtoTypeList] = useState([]);
    const [PtoDayCount, SetPtoDayCount] = useState(null);
    const [EditDayCount, SetEditDayCount] = useState(true);
    const [PtoType, SetPtoType] = useState(-1);


    function PrepList(data, DataFill) {
        const newOptionList = [];
      
        for (var i = 0; i < data.length; i++) {
          
          let object = data[i];
      
          let value_key = null;
          let value_lable = null;
  
         // console.log(object);
          for (const key in object) {
  
            const active = String(key);
            if (active.toLowerCase().includes('name')) {
  
              const val = object[key];
              value_lable = val;
  
            } else if (active.toLowerCase().includes('id')) {
  
              const val = object[key];
              value_key = val;
  
            }
  
            
  
            if(value_lable !== null && value_key !== null) 
            {
              newOptionList.push({ value: value_key, label: value_lable  });
              break;
            }
  
          }
  
          
        }
      
        DataFill(newOptionList);
      }
      function QueryOption() 
      {
          fetch('http://localhost:3333/master-data/get-pto/') 
          .then(res => res.json())
          .then((data) => PrepList(data, SetPtoTypeList));
  
      }
      useEffect(() => {
        QueryOption();
      }, [])


      function HandleSubmition(event) 
      {
        SetRegistring(true);
        event.preventDefault();

        setTimeout(() =>{

            const fd = new FormData();

            fd.append('pto_type', PtoType);
            fd.append('pto_start_date', event.target.pto_start_date.value);
            fd.append('number_of_business_day', event.target.number_of_business_day.value);
            fd.append('reason', event.target.reason.value);
            fd.append('approve_date', event.target.approve_date.value);
            fd.append('ignore_non_business_day', event.target.ignore_non_business_day.checked);
            fd.append('EmpID', EmpData.EmployeeID);

            axios.post('http://localhost:3333/employee/create_employee_pto', fd, { 
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                }).then(res => {
                    formRef.current.reset();
                    SetRegistring(false);
                    MsgTrigger(res);
                    OpenController(false);
                    
                    
                })
                .catch(err => {
                    formRef.current.reset();
                    SetRegistring(false);
                    MsgTrigger(err);
                    OpenController(false);
                    
                })

        }, 2000)
      }
    return (
        <div className={`${classes.pto_main_wrapper}`}>
            <Tooltip className={`${classes.tool_quick}`} id="offical_doc" place="top" />

            <div className={`${classes.pto_form_wrapper}`}>
                <div className={`${classes.pto_header}`}>
                    <h1>PTO Approval</h1>
                    <button onClick={()=>{OpenController(false);}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
                </div>

                <div className={`${classes.pto_action_body}`}>
                    {HandlePTOAllowanceForm(IsRegistring, formRef, HandleSubmition, PtoTypeList, SetPtoDayCount, PtoDayCount, EditDayCount, SetEditDayCount, SetPtoType)}
                </div>
            </div>

        </div>
    )
}