import classes from './AwaitingDispatch.module.css'
import { QueryCKProductKitchenID, UpdateCKProductAvailState} from '../../../SharedComp/API/CK/AP_CenteraKitchen'
import {FetchTargetOrderGroup} from '../../../SharedComp/API/CK/AP_CenteraKitchenOrder'
import {QueryAssignedChef} from '../../../SharedComp/API/Kicthen/API_FE_Kicthen'
import { useAuth } from '../../../AuthProvider'
import { useToast } from '../../../ToastContext';
import { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useDAControl } from '../../../DAControlContext';
import { useEffect } from 'react';
import { useRef } from 'react';

import OrderGroup from './Component/OrderGroup/OrderGroup';
import { getBaseURL } from '../../../SharedComp/BaseUrlConfig';
import { Empty } from '@phosphor-icons/react';

export default function AwaitingDispatch() 
{

    const auth = useAuth();
    const [OrderGroupList, SetOrderGroupList] = useState([]);
    const [MousePosition, SetMousePosition] = useState({ x: 0, y: 0 });
    const [LocalChefsList, SetLocalChefsList] = useState([]);
    const notify = useToast();

    const handleMouseMove = (event) => {
        SetMousePosition({
        x: event.clientX,
        y: event.clientY,
        });
    };
    const FetchGroupList = async() =>
    {
        try 
        {
            const main_res = await FetchTargetOrderGroup(auth.activeWorkShop);
            SetOrderGroupList(main_res);
        } catch(err) 
        {

        }
    }


    const FetchAssignedChef = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("workspace_id", auth.activeWorkShop);
            const coll = await QueryAssignedChef(fd, 'token');
            SetLocalChefsList(coll);
        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }
    useEffect(() => 
    {
        FetchAssignedChef();
    }, [])


    useEffect(()=>{
        FetchGroupList();
    }, [auth.activeWorkShop])







    return (
        <div onMouseMove={handleMouseMove} className={`${classes.dispatch_master_wrapper}`}>
            
            <div className={`${classes.order_group_wrapper_main}`}>


                {
                    OrderGroupList.map((item, idx)=>(

                        <OrderGroup ChefsList={LocalChefsList} MGPos={MousePosition} Data={item} key={idx}/>
                        
                    ))
                }
                {
                        OrderGroupList.length === 0 && <div className={`${classes.request_queue_halo}`}>
                                <h1><Empty /></h1>
                                <h2>No Order! No Dispatch!</h2>
                        </div>
                    }

            </div>



        </div>
    )
}