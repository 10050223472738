import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import classes from './FinalizeCollector.module.css'
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { faFileLines } from '@fortawesome/free-regular-svg-icons';
import { Tooltip } from 'react-tooltip';

export default function FinalizeCollector({ DataPkg}) 
{
    
    
    useEffect(() =>{
    }, [])
    return (
        <div className={`${classes.final_collector_main_Wrapper}`}>
            <Tooltip className={`${classes.tool_quick}`} id="offical_doc" place="top" />
            <div className={`${classes.info_header}`}>
                    <h2>Finalize Registration</h2>
            </div>

            <div className={`${classes.final_collector_body_wrapper}`}>

                <div className={`${classes.welcome_new_employee_card}`}>
                    <div className={`${classes.new_employee_card_pp_wrapper}`}>
                        <img className={`${classes.new_employee_pp}`} src="https://cdn.pixabay.com/photo/2015/07/20/12/53/gehlert-852762_640.jpg" alt="" />
                    </div>
                    <div className={`${classes.new_employee_card_info}`}>
                        <li>{DataPkg["full_name"]}</li>
                        <li>{DataPkg["job_position"]}</li>
                        {/* <li>{DataPkg["phone"]}</li> */}
                    </div>
                </div>

                <div {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': "You can generate this document any time you want, No Rush!"})} className={`${classes.welcome_new_employee_card_doc}`}>
                    <div className={`${classes.new_employee_card_sec_offical_doc_head}`}>
                        <h2>Prepare Offical Signature Document</h2>
                    </div>
                    <div className={`${classes.employee_offical_sign_doc}`}>
                        <button><FontAwesomeIcon icon={faFileLines}/></button>
                    </div>
                </div>
                
            </div>
        </div>
    );
}