import classes from './StockManagerStyle.module.css'
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';

// Internal Component
import StockManagerCard from './Component/Card/StockManagerCard';
import CreateRequest from './Component/CreateRequest/CreateRequest';
// New Mod Component
import RegisterStock from './Component/RegisterStock/RegisterStock';
import StockView from './Component/StockView/StockView';
import { io } from 'socket.io-client';
import { useAuth } from '../../AuthProvider';
import { getBaseURL } from '../../SharedComp/BaseUrlConfig';


function clampPercentage(value, max) {
    // Assuming 100 as 15%, and linearly interpolate between min and max
    const percentage = (value / max) * 100;

    // Clamp the percentage between 0 and 100
    const clampedPercentage = Math.max(0, Math.min(100, percentage));

    return clampedPercentage;
}
const generateAnimationDelay = (index) => `${index * 0.15}s`;
const socket = io.connect(getBaseURL());
export default function StockManager() 
{

    const [viewDataPkg, SetViewDataPkg] = useState();
    const [OpenStockView, SetOpenStockView] = useState(false);
    const [OpenRegisterStock, SetOpenRegisterStock] = useState(false);
    const [MasterInvt, SetMasterInvt] = useState([]);
    const [OpenCreateStockRequest, SetOpenCreateStockRequest] = useState(false);
    const [stockData, setStockData] = useState([]);

    const auth = useAuth();

    function CreateDataList(data) 
    {
        SetMasterInvt(Array.from(data, ([ItemID, items]) => ({
            ItemID,
            items
          })));
    }

    function SocketDynamicUpdate(data) 
    {
        FetchStockData();
    }

   const PrepDataToView = async() => {
        try 
        {
           const response = await fetch(getBaseURL() +  'inventory/get-inv-stock-branch', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({workspace_id: auth.activeWorkShop})
            });

            const res = await response.json();
            setStockData(res);
            const itemMap = new Map();
            res.forEach(item => {
                if(!itemMap.has(item.ItemID)) 
                {
                    itemMap.set(item.ItemID, []);
                }
                itemMap.get(item.ItemID).push(item);
            })

            CreateDataList(itemMap);


        }catch(err) 
        {

        }
   }

    function FetchStockData() 
    {
        PrepDataToView();
    }

    useEffect(()=>{
        // console.log(MasterInvt); 
    }, [MasterInvt])
 
    useEffect(() => {
        FetchStockData(); 

        socket.on("HANDLE_PO_PROCESS", SocketDynamicUpdate);
        return ()=>{
            socket.off("HANDLE_PO_PROCESS", SocketDynamicUpdate)
        }


    }, [OpenRegisterStock, socket, auth.activeWorkShop]);


    return (
        <div className={`${classes.main_stock_manager_wrapper}`}>

            {OpenRegisterStock && 
                <RegisterStock 
                    OpenController={SetOpenRegisterStock} 
                    /> }
            {
                OpenCreateStockRequest && 
                <CreateRequest OpenModal={SetOpenCreateStockRequest}/>
            }


            {OpenStockView && 
                <StockView 
                    OpenController={SetOpenStockView} 
                    TargetID={viewDataPkg.IvsID}
                    DataItemID={viewDataPkg.ItemID}
                    StockName={viewDataPkg.ItemName}
                /> }
            

            <div className={`${classes.stock_manager_header_wrapper}`}>

                <div className={`${classes.operation_main_wrapper}`}>
                    <button type='button' onClick={() => {SetOpenRegisterStock(true)}}>Receive Stock</button>
                    <button type='button' onClick={()=>{SetOpenCreateStockRequest(true)}}>Create Stock Request</button>
                    <button type='button' >Create Stock Transfer</button>
                </div>

                <div className={`${classes.stock_filter_wrapper}`}>
                    <div className={`${classes.stock_input_filter_wrap}`}>
                        <span  className={`${classes.stock_filter_filter_icon}`}><FontAwesomeIcon icon={faFilter}></FontAwesomeIcon></span> <input type="text" autoComplete='off' placeholder='What do you want?' />
                    </div>
                </div>
           </div>


            <div className={`${classes.sorck_manager_card_list_main_wrapper}`}>
                 <div className={`${classes.sock_card_list_upper_wrapper}`}>
                    <div className={`${classes.stock_manager_card_List_wrapper}`}>
                        <div className={`${classes.stock_manager_card_list}`}>

                            {MasterInvt?.map(({ ItemID, items }, idx) => (
                                <StockManagerCard   
                                    key={ItemID} 
                                    OpenViewController={SetOpenStockView} 
                                    AnimDelay={generateAnimationDelay(idx + 1)} 
                                    Data={items} 
                                    setViewData={SetViewDataPkg} />
                            ))}
                            
                        </div>
                    </div>
                 </div>
            </div>

        </div>
    );
}