import './MiniPopupStyle.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

export default function MiniPopup({open, msg, Theme}) 
{
    return (
        <div className={`co-add-pop-up ${open ? `co-add-pop-up-show` : `co-add-pop-up-hide`}`}>
                <div className="operation-status" style={{
                    background: Theme[0],
                    borderColor: Theme[1],
                }}>
                    <div className="co-os-icon-section" style={{
                    background: Theme[1],
                    borderColor: Theme[1],
                    color: Theme[2]
                }}>
                        <FontAwesomeIcon icon={Theme[3]} />
                    </div>
                    <div className="co-os-msg-section">
                        <li style={{
                            color: Theme[2]
                            }}>{msg}</li>
                    </div>
                </div>
        </div>
    );
}