
class SingleProductPackageOrderTemplate {
    constructor() {
        this.RootName = '';
        this.QuantityData = new Map();
        this.ItemData = new Map();
    }
    destroy() {
        this.RootName = null; // Or an empty string if needed
        this.QuantityData.clear(); // Clears all entries in the map
        this.ItemData.clear(); // Clears all entries in the map
    }

    CheckQuantity(key) {
        return this.QuantityData.has(key);
    }

    CheckItem(key) {
        return this.ItemData.has(key);
    }

    CreateQuantity(key, quantity) {
        this.QuantityData.set(key, quantity);
    }

    CreateItem(key, item) {
        this.ItemData.set(key, item);
    }

    GetQuantity(key) {
        return this.QuantityData.get(key);
    }

    GetItem(key) {
        return this.ItemData.get(key);
    }
}


export {
    SingleProductPackageOrderTemplate
};