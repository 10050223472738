import { faCartArrowDown, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { getBaseURL } from '../../../../../SharedComp/BaseUrlConfig'
import classes from './ProductCard.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'


export default function ProductCard({Data, DataCallback}) 
{

    const [ValidToOrder, SetValidToOrder] = useState(Data.CKP_OrderableState === 1);
    const [OrderCount, SetOrderCount] = useState();

    useEffect(()=>{
        SetValidToOrder(Data.CKP_OrderableState === 1);
    }, [Data])

    const AddData = async(e) =>
    {
        e.preventDefault();
        DataCallback(Data, OrderCount);
        SetOrderCount('');
    }


    return (
        <div className={`${classes.product_card_main_wrapper} ${ValidToOrder ? '' : classes.product_card_main_wrapper_invalid}`}>


            <div style={{backgroundImage: `${!Data.CKP_OrderableState ? 'linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8))' : 'linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))' }, url(${getBaseURL() + Data.CKP_IconPath})`}} className={`${classes.image_area}`} >
                <div className={`${Data.CKP_OrderableState ? classes.disbaled_prod_hide : classes.disbaled_prod}`}>
                    <li><span><FontAwesomeIcon icon={faTriangleExclamation}></FontAwesomeIcon></span> <span>Restricted</span></li>
                </div>
                <div className={`${Data.CKP_OrderableState ? classes.disbaled_prod_hide : classes.disbaled_prod}`}>
                    <h1><FontAwesomeIcon icon={faTriangleExclamation}></FontAwesomeIcon></h1>
                </div>
            </div>
            <div className={`${classes.content_area}`}>
                <div className={`${classes.content_card_prod_name}`}>
                    <h3>{Data.CKP_ItemName} <span>{Data.KV_SliceCount} Pcs</span></h3>
                    <li><span className={`${classes.cake_info_tag}`}>-Type:</span> <span>{Data.CK_PCAT_Name}</span> </li>
                    <li><span className={`${classes.cake_info_tag}`}>-Variance:</span> <span>{Data.KV_TypeName}</span> </li>
                </div>
               {ValidToOrder && <div className={`${classes.action_card_button_wrapper}`}>
                <form onSubmit={AddData} method='GET'>
                    <input value={OrderCount} onChange={(e)=>{SetOrderCount(e.target.value)}} min={1} max={1000} type="number" placeholder='Pcs' required />
                    <button type='submit'><FontAwesomeIcon icon={faCartArrowDown}></FontAwesomeIcon></button>
                </form>
                </div>}
            </div>
        </div>
    )
}