import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import classes from './AllowanceAction.module.css'
import { faLayerGroup, faSpinner, faUserGear } from '@fortawesome/free-solid-svg-icons'

import { useEffect, useRef, useState } from 'react'
import Select from 'react-select';
import axios from 'axios';


export default function AllowanceAction({Open, ActionController, EmpData, MsgTrigger}) 
{

    const CreditFormRef = useRef();
    const [selectedOptions, setSelectedOptions] = useState();
    const [optionList, setOptionList] = useState([]);
    const [registring, SetRegistring] = useState(false);
    const [AllowanceType, SetAllowanceType] = useState();
    function PrepList(data) {
      const newOptionList = [];
    
      for (var i = 0; i < data.length; i++) {
        
        let object = data[i];
    
        let value_key = null;
        let value_lable = null;

       // console.log(object);
        for (const key in object) {

          const active = String(key);
          if (active.toLowerCase().includes('name')) {

            const val = object[key];
            value_lable = val;

          } else if (active.toLowerCase().includes('id')) {

            const val = object[key];
            value_key = val;

          }

          

          if(value_lable !== null && value_key !== null) 
          {
            newOptionList.push({ value: value_key, label: value_lable  });
            break;
          }

        }

        
      }
    
      setOptionList(newOptionList);
    }
        useEffect(() => 
        {
            QueryOption();
        }, [])

    function QueryOption() 
    {
        const URL = 'http://localhost:3333/master-data/get-allowance-type/';
        fetch(URL)
        .then(res => res.json())
        .then((data) => PrepList(data));
    }
    function handleSelect(data) 
    {
        SetAllowanceType(prev => ({
            ...prev,
            ["allowance_type"]: data.value
        }))
    }
    function HandleAllownaceSubmission(event) 
    {
        SetRegistring(true);
        event.preventDefault();


        setTimeout(() => {

            const fd = new FormData();

            const url ='http://localhost:3333/employee/create_employee_allowance/'; 


            fd.append("allowance_type", AllowanceType.allowance_type);
            fd.append("allowance_amount", event.target.allowance_amount.value);
            fd.append("allowance_date", event.target.allowance_date.value);
            fd.append("allowance_detail", event.target.allowance_detail.value)
            fd.append("EmployeeID", EmpData.EmployeeID);
            
            
            
            axios.post(url, fd, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    })
                .then(res => {
                    CreditFormRef.current.reset();
                    SetRegistring(false);
                    MsgTrigger(res);
                    ActionController(false);
                    
                    
                })
                .catch(err => {
                    CreditFormRef.current.reset();
                    SetRegistring(false);
                    MsgTrigger(err);
                    ActionController(false);
                    
                })
            }, 2000)
    }
    const Custom_DD_Style = {
        
        control: (provided, state) => ({
            ...provided,
            width: 370,
            minHeight: 20,
            height: 35,
            fontWeight: 'bold',
            fontSize: 13,
            background: 'var(--background-dark)',
            border: 'none',
            borderColor: 'var(--background-light)',
            color: 'var(--primary_text_color)',
            borderRadius: 1,
            borderColor: 'none', 
            boxShadow: 'none', 
            '&:hover': {
                borderColor: 'none', 
                boxShadow: 'none', 
            },
            }),
            option: (provided) => ({
            ...provided,
            backgroundColor: 'var(--primary-color)', 
            color: 'var(--secondary-color)',
            }),
            option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? 'var(--body-background)' : 'var(--primary-color)', 
            color: state.isFocused ? 'var(--primary-color)' : 'var(--secondary-color)',
            transition: 'background-color 0.2s ease-in-out',
            cursor: 'pointer',
        }),
            menu: (provided) => ({
            ...provided,
            zIndex: 9999,
            backgroundColor: 'var(--primary-color)',
            color: 'var(--primary-color)',
            fontSize: 13,
            borderRadius: 1,
            fontWeight: 500,
            }),
            singleValue: styles => ({
                ...styles,
                color: 'var(--text_light_white)', // Adjust this line for selected text color
              }),
        }
    return (
        <div className={`${Open ? classes.allow_action_main_wrapper : classes.allow_action_main_wrapper_hide}`}>

            <div className={`${classes.allow_win_header}`}>
                <li>Allownace</li>
                <button onClick={() => {ActionController(false); CreditFormRef.current.reset();}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
            </div>

            <div className={`${classes.allownace_body}`}>
                    

                <div className={`${classes.allownace_form_wrapper}`}>
                    <form ref={CreditFormRef} onSubmit={HandleAllownaceSubmission} action="POST">
                    
                            <div className={`${classes.allowance_input_main}`}>
                                <span className={`${classes.allowance_input_main_icon}`}><FontAwesomeIcon icon={faLayerGroup}></FontAwesomeIcon></span>
                                    <Select
                                        styles={Custom_DD_Style}
                                        onChange={handleSelect}
                                        name={"allowance_type"}
                                        options={optionList}
                                        placeholder={"Select Allowance type"}
                                        value={selectedOptions}
                                        isSearchable={true}
                                        required ={true}
                                    />
                            </div>
                            <div className={`${classes.allowance_input_main}`}>
                                <span className={`${classes.allowance_input_main_icon}`}><FontAwesomeIcon icon={faLayerGroup}></FontAwesomeIcon></span>
                                <input name='allowance_amount' type="number" min={1} placeholder='Amount' required/>
                            </div>
                            <div className={`${classes.allowance_input_main}`}>
                                <span className={`${classes.allowance_input_main_icon}`}><FontAwesomeIcon icon={faLayerGroup}></FontAwesomeIcon></span>
                                <input name='allowance_detail' type="text" placeholder='Remark' />
                            </div>
                            <div className={`${classes.allowance_input_main}`}>
                                <span className={`${classes.allowance_input_main_icon}`}><FontAwesomeIcon icon={faLayerGroup}></FontAwesomeIcon></span>
                                <input name='allowance_date' type="date" />
                            </div>

                            <div className={`${classes.allowance_input_submit_action_button}`}>
                                <button disabled={registring} className={`${registring ? classes.is_registring : ''}`} >{registring ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Allow'}</button>
                            </div>
                    </form>
                </div>
            </div>

        </div>
    )
}