import { useEffect, useState } from 'react';
import classes from './Warehouse.module.css'
import { getBaseURL } from '../../../../SharedComp/BaseUrlConfig';
import WarehouseCard from './Component/WarehouseCard/WarehouseCard';
import ViewMappedItem from './Component/ViewMappedItem/ViewMappedItem';

const Warehouse = () => {


    const [WarehouseList, SetWarehouseList] = useState([]);
    const [GroupedList, SetGroupedList] = useState([]);
    const [OpenViewMappedItem, SetOpenViewMappedItem] = useState(false);
    const [ActiveWarehouse, SetActiveWarehouse] = useState();

    const generateAnimationDelay = (index) => `${index * 0.15}s`;
    const fetchWarehouse = async () => {
        try 
        {
            const response = await fetch(getBaseURL() + 'warehouse/get-branch-store-omni', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const res = await response.json();
            
            const workspace_group = new Map();
            res.forEach(item => {
                
                if(!workspace_group.has(item.BranchID))
                    workspace_group.set(item.BranchID, []);

                workspace_group.get(item.BranchID).push(item);
            });
            
            const per_workspace_data = [];
            for(const [key, val] of workspace_group) 
            {
                per_workspace_data.push(val);
            }

            SetWarehouseList(per_workspace_data);

        }catch(err) 
        {

        }
    }

    const handleViewOpen = (data)=>{
        SetActiveWarehouse(data);
        SetOpenViewMappedItem(true);
    }

    useEffect(()=>{
        fetchWarehouse();
    }, [])


    return (
        <div className={`${classes.warehouse_master_wrapper}`}>

            {OpenViewMappedItem && <ViewMappedItem TargetData={ActiveWarehouse} CloseModal={SetOpenViewMappedItem}/> }

            <div className={`${classes.stock_warehouse_body_wrapper}`}>
                {
                    WarehouseList.map((item, idx)=>(
                        <WarehouseCard 
                            key={idx} 
                            HandleClick={handleViewOpen}
                            AnimDelay={generateAnimationDelay(idx + 1)} 
                            Data={item}/>
                    ))
                }
            </div>
        </div>
    )
};

export default Warehouse;