import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classes from './CreateInventoryItem.module.css'
import data_win from './../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import {faSpinner, faTimesCircle, faObjectGroup, faObjectUngroup } from '@fortawesome/free-solid-svg-icons'
import MasterDataRegInput from '../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useRef, useState } from 'react'
import axios from 'axios';
import Select from 'react-select';
import { useEffect } from 'react'
import {mapToObject, objectToMap, Custom_DD_Style, PrepareDropDownList} from '../../../../../SharedComp/Utility'
import { FetchBranchAll, PushIVItem} from '../../../../../api'
import { useToast } from '../../../../../ToastContext'
import { useAuth } from '../../../../../AuthProvider'
export default function CreateInventoryItem({OpenModal}) 
{
    const formRef = useRef();

    const notify = useToast();
    const auth = useAuth();

    // For Dropdown selection
    const [GoodsTypeOptionList, SetGoodsTypeOptionList] = useState([]);
    const [GoodsBrandOptionList, SetGoodsBrandOptionList] = useState([]);
    const [GoodsMeasurementOptionList, SetGoodsMeasurementOptionList] = useState([]);

    // Workspace 
    const [WorkspaceList, SetWorkspaceList] = useState([]);
    const [WorkspaceChecked, SetWorkspaceChecked] = useState(new Map());
    const [ToggelListTrue, SetToggelListTrue] = useState(false);


    // If the server is requested but not answerd yet..
    const [registring, SetRegistring] = useState(false);

    // Data
    const [IVItemName, SetIVItemName] = useState();
    const [IVItemType, SetIVItemType] = useState();
    const [IvItemUOM, SetIvItemUOM] = useState();
    const [IVItemFollowParent, SetIVItemFollowParent] = useState(false);


    const handleToggle = (branchID) => {
        if(!WorkspaceChecked.has(branchID))
            return;
        SetWorkspaceChecked(prevState => {
            const updatedState = new Map(prevState);

            const update_state_inner = new Map(updatedState.get(branchID));
            update_state_inner.set("Active", !update_state_inner.get("Active"));

            updatedState.set(branchID, update_state_inner);
            return updatedState;
        });
    };
    const handleBranchSpecMaxBalance = (branchID, max_balance) => {
        if(!WorkspaceChecked.has(branchID))
            return;
        SetWorkspaceChecked(prevState => {
            const updatedState = new Map(prevState);

            const update_state_inner = new Map(updatedState.get(branchID));
            update_state_inner.set("MaxBalance", max_balance);

            updatedState.set(branchID, update_state_inner);
            return updatedState;
        });
    };
    const handleBranchSpecMinBalance = (branchID, min_balance) => {
        if(!WorkspaceChecked.has(branchID))
            return;
        SetWorkspaceChecked(prevState => {
            const updatedState = new Map(prevState);

            const update_state_inner = new Map(updatedState.get(branchID));
            update_state_inner.set("MinBalance", min_balance);

            updatedState.set(branchID, update_state_inner);
            return updatedState;
        });
    };
    const selectAll = (val) => {

        SetToggelListTrue(val);

        let updated_list = new Map();
        for(const [mp_key, mp_val] of WorkspaceChecked.entries()) 
        {
            const updated = mp_val;
            updated.set("Active", val);
            updated_list.set(mp_key, updated);
        }
        SetWorkspaceChecked(updated_list);    
    };
    const QueryWorkspace = async () => {

        try {
            const res = await FetchBranchAll('token');

            // for now prepare void list
            let init_check_list = new Map();
            for(let  i = 0; i < res.length; i++) 
            {
                if(!init_check_list.has(res[i].BranchID)) 
                    init_check_list.set(res[i].BranchID, new Map([
                        ["Active", false],
                        ["MinBalance", null],
                        ["MaxBalance", null]
                      ])); 
            }
            SetWorkspaceChecked(init_check_list);

            SetWorkspaceList(res);
        } catch (err) 
        {
            throw new Error(err);
        }
    }
    // Clear the form when ever the user click dismiss of close the window
    function CloseModal() 
    {
        formRef.current.reset();
        OpenModal(false); 
    }
    // Handle submition
    const HandleSubmition = async(e)=> 
    {
        e.preventDefault();
        SetRegistring(true);
        notify.ShowLoad("Doing Job....", 10);
        

        setTimeout( async() => {

            
            try 
            {
                const fd = new FormData();
                fd.append("goods_name", IVItemName);
                fd.append("item_type", IVItemType);
                fd.append("item_uom", IvItemUOM);
                fd.append("follow_parent", IVItemFollowParent);
                fd.append("workspace_info", JSON.stringify(mapToObject(WorkspaceChecked)))
                fd.append('created_by', auth.user.UserAccountID);
    
                const res = await PushIVItem(fd, 'token');
                notify.ShowSuccessUpdate(res.Msg, 10);
                SetRegistring(false);
            }catch(err) 
            {
                notify.ShowErrorUpdate(err.message, 10);
                SetRegistring(false);
            }

        }, 2000)
    }



      useEffect(() => 
        {
            QueryWorkspace();
            QueryOption();
        }, [])

      function QueryOption() 
      {
        fetch('http://localhost:3333/master-data/get-item-brand')
        .then(res => res.json())
        .then((data) => PrepareDropDownList(data, SetGoodsBrandOptionList));

        fetch('http://localhost:3333/master-data/get-uom')
        .then(res => res.json())
        .then((data) => PrepareDropDownList(data, SetGoodsMeasurementOptionList));


        fetch('http://localhost:3333/master-data/get-iv-type', { 
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ID: "Yes" }),
          })
            .then(res => res.json())
            .then((data) => {PrepareDropDownList(data, SetGoodsTypeOptionList); });
      } 



    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

            <div className={`${data_win.data_main}`}>
                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        Registring <span className={`${data_win.data_create_name}`}> Inventory Good</span>
                    </h3>
                    <button onClick={()=>{CloseModal()}}>
                        <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                    </button>
                </div>

                <div className={`${data_win.data_body} ${classes.data_body}`}>
                        
                

                            <form onSubmit={HandleSubmition} ref={formRef} action="POST">
                                <div className={`${classes.form_wrapper}`}> 
                                        <div  className={`${classes.input_list_wrapper}`}>

                                            <MasterDataRegInput 
                                                    input={<input 
                                                        value={IVItemName} 
                                                        onChange={(e)=>{SetIVItemName(e.target.value)}} 
                                                        name='goods_name' 
                                                        type="text" 
                                                        placeholder='Inventory Good Name' 
                                                        title='Inventory Good Name' 
                                                        autoComplete='off'
                                                        required />} />
                                            <MasterDataRegInput 
                                                    input={
                                                        <Select
                                                            onChange={(data)=>{SetIVItemType(data.value)}}
                                                            name={"iv_type"}
                                                            options={GoodsTypeOptionList}
                                                            placeholder={"Type..."}
                                                            isSearchable={true}
                                                            styles={Custom_DD_Style}
                                                            required ={true}
                                                        />
                                                
                                                }  />
                                            <MasterDataRegInput 
                                                    input={
                                                        <Select
                                                            onChange={(data)=>{SetIvItemUOM(data.value)}}
                                                            name={"iv_uom"}
                                                            options={GoodsMeasurementOptionList}
                                                            placeholder={"UOM..."}
                                                            isSearchable={true}
                                                            styles={Custom_DD_Style}
                                                            required ={true}
                                                    />
                                                    } tip={"Unit of measurement"} />
                                            <div onClick={()=>{SetIVItemFollowParent(!IVItemFollowParent)}} className={`${classes.follow_parent_wrapper}`}>
                                                <input checked={IVItemFollowParent} onChange={(e)=>{SetIVItemFollowParent(e.target.value)}} type="checkbox" />
                                                <span>Follow Parent</span>
                                            </div>
                                        </div>

                                        <div className={`${classes.master_product_type_inner_avail_workspace_list}`}>
                                            <div className={`${classes.master_product_type_inner_avail_workspace_list_header}`}>
                                                <h1>Accessibility</h1>
                                                <button type='button' onClick={()=>{selectAll(!ToggelListTrue)}}><FontAwesomeIcon icon={ToggelListTrue ? faObjectUngroup : faObjectGroup}/></button>
                                            </div>
                                            <div className={`${classes.master_prod_avail_list}`}>
                                                {
                                                    WorkspaceList.map((item, idx)=>(
                                                        <div key={idx}  className={`${classes.master_prod_avail_branch}`}>
                                                            
                                                            <div onClick={() => handleToggle(item.BranchID)} className={`${classes.workspace_tick_and_name_wrapper}`}>

                                                                <input 
                                                                    onClick={() => handleToggle(item.BranchID)}
                                                                    checked={WorkspaceChecked.get(item.BranchID).get("Active")} 
                                                                    name={item.BranchName} 
                                                                    type="checkbox"
                                                                    onChange={() => handleToggle(item.BranchID)}
                                                                    />
                                                                <li>{item.BranchName}</li>
                                                                
                                                            </div>
                                                            
                                                            <div className={`${classes.workspace_min_max_value_per_type_wrapper}`}>
                                                                <input  
                                                                    value={WorkspaceChecked.get(item.BranchID).get("MinBalance")} 
                                                                    onChange={(e)=>{handleBranchSpecMinBalance(item.BranchID, e.target.value)}} 
                                                                    type="text" 
                                                                    placeholder="Min Balance" />
                                                                <input 
                                                                    value={WorkspaceChecked.get(item.BranchID).get("MaxBalance")} 
                                                                    onChange={(e)=>{handleBranchSpecMaxBalance(item.BranchID, e.target.value)}} 
                                                                    type="text" 
                                                                    placeholder="Max Balance" />
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                    </div>
                                </div>
                                <div className={`${data_win.form_action_button_wrapper}`}>
                                    <button disabled={registring} className={`${data_win.form_action_button_save}`}>{registring ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Add'}</button>
                                    <button disabled={registring}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                                </div>
                            </form>




                </div>
            </div>

        </div>
    )
}