export class ProductItem 
{
    constructor() 
    {
        this.ItemName = "";
        this.Quantity = 0;
        this.ParentID = 0;
        this.ProductID = 0;
        this.UnitPrice = 0;
    }

    GetName() { return this.ItemName; }
    GetQuantity() { return this.Quantity;}
    GetParentID() { return this.ParentID;}
    GetProductID() { return this.ProductID; }
    GetUnitPrice() { return this.UnitPrice; }

    SetItemName(val) {
        this.ItemName = val;
    }
    SetQuantity(val) {
        this.Quantity = val;
    }
    SetParentID(val) {
        this.ParentID = val;
    }
    SetProductID(val) {
        this.ProductID = val;
    }
    SetUnitPrice(val) {
        this.UnitPrice = val;
    }
}