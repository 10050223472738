import classes from './InventoryIdentifier.module.css'
import DataTableWrapper from '../../../SharedComp/DataTable/DataTableWrapper'

import InventoryDataCreator from '../../InventoryMasterData/Component/Create/InventoryDataCreator';
import { useState } from 'react';
import MiniPopup from '../../../SharedComp/MiniPopup/MiniPopup'
import { faCircleCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

export default function InventoryIdentifier() 
{

    const [openCreateWin, setOpenCreateWin] = useState(false);
    const [currentWinName, setCurrentWinName] = useState('');
    const [showAddPopup, setShowAddpopup] = useState(false);
    const [backendResponse, setBackendResponse] = useState('');
    const [popupMsgTheme, setPopupMsgTheme] = useState(['', ''])
    const [newEntryFlag, setNewEntryFlag] = useState(false);


    function handleCreateWindowOpen(win_name) 
    {
        setCurrentWinName(win_name);
        setOpenCreateWin(!openCreateWin);
    }
    function handlePrint() 
    {
        window.print();
    }
    function setMessagePopup(success, open_popup, response_msg) 
    {
        if(success) 
        {
            setPopupMsgTheme([
                '#1a936f',
                '#114b5f',
                '#e4dcef',
                faCircleCheck
            ]);
            setNewEntryFlag(!newEntryFlag);
        } else 
        {
            setPopupMsgTheme([
                '#880e4f', // main color
                '#ad1457', // secondary color
                '#e4dcef', // text color
                faXmark
            ]);
        }
        setShowAddpopup(open_popup);
        setBackendResponse(response_msg);
        if(success)
        {
            setCurrentWinName('');
            setOpenCreateWin(false);
        }
    }



    return (
        <div className={`${classes.inventory_identifier_main_wrapper}`}>

            <InventoryDataCreator
                Open={openCreateWin} 
                setOpenCreator={setOpenCreateWin} 
                win_name={currentWinName}
                setMessagePopup={setMessagePopup}
                />

                <MiniPopup
                    open={showAddPopup}
                    msg={backendResponse}
                    Theme={popupMsgTheme}
                />
            

            <div className={`${classes.inventory_identifier_data_wrapper}`}>
                <DataTableWrapper
                        WrapperName={'Item Brand'}
                        ThemeColor={'var(--background-light)'}
                        WrapperWidth={'45%'}
                        WinName={'Item Brand'}
                        RouteAddress={'http://localhost:3333/master-data/get-item-brand'}
                        NewEntryFlag={newEntryFlag}
                        PrintCallback={handlePrint}
                        RegisterCallback={handleCreateWindowOpen}
                    />

                    <DataTableWrapper
                        WrapperName={'Unit of Measurement'}
                        WinName={'Unit of Measurement'}
                        ThemeColor={'var(--background-light)'}
                        WrapperWidth={'45%'}
                        RouteAddress={'http://localhost:3333/master-data/get-uom'}
                        NewEntryFlag={newEntryFlag}
                        RegisterCallback={handleCreateWindowOpen}
                    />
            </div>


        </div>
    )
}