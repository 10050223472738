import classes from './CreateRequest.module.css'
import data_win from '../../../../SharedComp/CommonStyle/DataCreator.module.css'
import MasterDataRegInput from '../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useRef, useState } from 'react'
import TransferRequestPackageInput from './TransferRequestPackage/TransferRequestPackageInput'

import Select from 'react-select'
import {Custom_DD_Style, PrepareDropDownList} from '../../../../SharedComp/Utility'

import {FetchBranchExcludedCurrent, CreateStockTransferRequest} from '../../../../api'
import { useAuth } from '../../../../AuthProvider'
import { useToast } from '../../../../ToastContext'

export default function CreateRequest({OpenModal}) 
{

    const [Loading, SetLoading] = useState(false);

    const auth = useAuth();
    const notify = useToast();
    const scrollRef = useRef(null);
    // Transfere Detail
    const [InventoryItemType, SetInventoryItemType] = useState(null);
    const [InventoryItemStore, SetInventoryItemStore] = useState(null);
    const [InventoryItemQuantity, SetInventoryItemQuantity] = useState(null);
    const [InventoryItemBrand, SetInventoryItemBrand] = useState(null);

    // Filter Branch List
    const [ValidBranchList, SetValidBranchList] = useState([]);
    const [SelectedBranch, SetSelectedBranch] = useState();
    const [ExpectedDate, SetExpectedDate] = useState();



    const FetchValidBranchList = async() =>
    {
        try 
        {
            const res = await FetchBranchExcludedCurrent(auth.activeWorkShop, auth.token);
            PrepareDropDownList(res, SetValidBranchList);
        }catch(err) 
        {
            notify.ShowError("Falied to Fetch valid Workspace");
        }
    }

    const [StockItem, SetStockItem] = useState(
        [
            <TransferRequestPackageInput 
                HandleInvItemSelect={HandleIvItemSelection} 
                HandleInvItemStore={HandleIvItemStore}
                HandlInvItemQuantity={HandleItemQuantitySelection} 
                HandleIvnItemBrand={HandleIvnItemBrand}
                idx={getRandomID()}/>
        ]
    );
    function HandleIvItemSelection(data, idx) 
    {
        SetInventoryItemType(prev => ({
            ...prev,
            [idx]: data
        }))
    }
    function HandleIvItemStore(data, idx) 
    {
        SetInventoryItemStore(prev => ({
            ...prev,
            [idx]: data
        }))
    }
    function HandleItemQuantitySelection(data, idx) 
    {
        SetInventoryItemQuantity(prev => ({
            ...prev,
            [idx]: data
        }))
    }
    function HandleIvnItemBrand(data, idx) 
    {
        SetInventoryItemBrand(prev => ({
            ...prev,
            [idx]: data
        }))
    }
    function AddNewInputField() 
    {
        const existing = [...StockItem];
        existing.push(
            <TransferRequestPackageInput 
                HandleInvItemSelect={HandleIvItemSelection} 
                HandleInvItemStore={HandleIvItemStore}
                HandlInvItemQuantity={HandleItemQuantitySelection} 
                HandleIvnItemBrand={HandleIvnItemBrand}
                idx={getRandomID()}/>
        );
        SetStockItem(existing);
    }
    function RemoveInputField(id_to_remove) 
    {
        if(StockItem.length === 1)
        return;
    
    
        // Clean Inventory Item
        if(InventoryItemType) 
        {
            SetInventoryItemType(prevState => {
                const { [id_to_remove]: _, ...updated } = prevState;
                return updated;
            });
        }

        // Store
        if(InventoryItemStore) 
        {
            SetInventoryItemStore(prevState => {
                const { [id_to_remove]: _, ...updated } = prevState;
                return updated;
            })
        }

        // Brand
        if(InventoryItemBrand)
        {
            SetInventoryItemBrand(prevState => {
                const { [id_to_remove]: _, ...updated } = prevState;
                return updated;
            })
        }
    


        // Clean Item Quantity
        if(InventoryItemQuantity) 
        {
            SetInventoryItemQuantity(prevState => {
                const { [id_to_remove]: _, ...updated } = prevState;
                return updated;
            });
        }


    


        SetStockItem(prevState => {
            const updated = prevState.filter(item => item.props.idx !== id_to_remove);
            return updated;
        });

    }
    function getRandomID() 
    {
        return Math.floor(Math.random() * 100000000);
    }

    function CloseModal() 
    {
        OpenModal(false);
    }
    const HandleSubmition = async(e)=> 
    {
        e.preventDefault();
        notify.ShowLoad("Processing Request...", 10);
        SetLoading(true);
        try 
        {
        
            const fd = new FormData();
            fd.append("target_workspace", JSON.stringify(SelectedBranch));
            fd.append("date", ExpectedDate);
            fd.append("sender_user", auth.employeeData.EmployeeID);
            fd.append("sender_workspace", auth.activeWorkShop);
            fd.append("payload_IVItem", JSON.stringify(InventoryItemType));
            fd.append("payload_IVItemQuantity", JSON.stringify(InventoryItemQuantity));
            fd.append("payload_IVItemBrand", JSON.stringify(InventoryItemBrand));

            const res = await CreateStockTransferRequest(fd, 'token');
            notify.ShowSuccessUpdate(res.Msg, 10);
            SetLoading(false);
            CloseModal();
        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
            SetLoading(false);
        }
    }

    const scrollToBottom = () => {
        scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
      };
    useEffect(()=>{
        FetchValidBranchList();
        scrollToBottom();
    }, [StockItem])

    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

            <div className={`${data_win.data_main} ${classes.data_main}`} >
                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        Create <span className={`${data_win.data_create_name}`}>Stock Request</span>
                    </h3>
                    <button onClick={()=>{CloseModal()}}>
                        <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                    </button>
                </div>

                <div className={`${data_win.data_body}`}>
                        
                

                            <form onSubmit={HandleSubmition} action="POST">
                                <div className={`${classes.form_wrapper}`}> 
                                        
                                        <div className={`${classes.input_list_main_wrapper}`}>
                                            <div  className={`${classes.input_list_wrapper}`}>
                                                
                                                    <div className={`${classes.input_header_single_wrapper_st_req}`}>
                                                        <MasterDataRegInput input={
                                                            <Select
                                                                onChange={(data)=>{SetSelectedBranch(data);}}
                                                                name={"dd_data"}
                                                                options={ValidBranchList}
                                                                placeholder={"Recipient..."}
                                                                isSearchable={true}
                                                                styles={Custom_DD_Style}
                                                                required ={true}
                                                            /> } 
                                                          />
                                                    </div>
                                                    
                                                    <div className={`${classes.input_header_single_wrapper_st_req}`}>
                                                        <MasterDataRegInput input={<input onChange={(e)=>{SetExpectedDate(e.target.value)}} name='need_date' type="date" required />} />
                                                    </div>

                                            </div>
                                        </div>

                                        <div className={`${classes.po_mat_list_wrapper}`}>
                                            <div className={`${classes.po_mat_list}`}>
                                                    {StockItem.map((item) => ( 
                                                        <div 
                                                            className={`${classes.input_fild_shild}`} 
                                                            key={item.props.idx}>{item}{StockItem.length > 1 ? <button type='button' onClick={()=>{RemoveInputField(item.props.idx)}} 
                                                            className={`${classes.remove_button_wrapper}`}>
                                                                <FontAwesomeIcon icon={faMinus}/></button> : <span  className={`${classes.remove_button_wrapper_fake}`}></span> }
                                                            <div ref={scrollRef}/>
                                                        </div>
                                                    ))}
                                            </div>
                                       </div>

                                      
                                        

                                        <div className={`${data_win.input_adder} ${classes.input_adder}`}>
                                            <button onClick={()=>{AddNewInputField()}} type='button'>Add Item</button>
                                        </div>
                                            
                                            
                                </div>
                                <div className={`${data_win.form_action_button_wrapper}`}>
                                    <button disabled={Loading} className={`${data_win.form_action_button_save}`}>{Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Request'}</button>
                                    <button disabled={Loading}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                                </div>
                            </form>




                </div>
            </div>

        </div>
    )
}