import classes from './PittyCashFinance.module.css'

import Chart from 'react-apexcharts'
import ReactApexChart from 'react-apexcharts';

import { useEffect, useState } from 'react';
import NotificationPopUp from '../../../SharedComp/NotificationPopUp/NotificationPopUp';
import { getBaseURL } from '../../../SharedComp/BaseUrlConfig';

import MiniPittyCashBranchCard from './Component/MiniPittyCashBranchCard';
import PittyCashBranchAnalysis from './Component/PittyCashBranchAnalysis/PittyCashBranchAnalysis';
import { useAuth } from '../../../AuthProvider';

export default function PittyCashFinanceLevel() 
{

    const auth = useAuth();
    // Notifiction
    const [MsgPkg, SetMsgPkg] = useState({});
    const [OpenMyNoti, SetOpenMyNoti] = useState(false);
    const [RefeshList, SetRefeshList] = useState(false);

    const [workspaceList, setWorkspaceList] = useState([]);
    const [targetWorkspace, setTargetWorkspace] = useState();

    // chart view flag
    const [ViewBalance, SetViewBalance] = useState(false);
    const [refereshData, setRefershData] = useState(false);
    

    // windows
    const [OpenPittyCashAnalysis, SetOpenPittyCashAnalysis] = useState(false);

    function OpenPittyCashAnalysisCallback(data) 
    {
        setTargetWorkspace(data);
        SetOpenPittyCashAnalysis(true);
    }

    const fetchBranch = async () => {
        try 
        {
            const response = await fetch(getBaseURL() + 'master-data/get-company-branch');
            const res = await response.json();
            setWorkspaceList(res);

        }catch(err) 
        {
            throw new Error("Falied to fetch Workspace data!");
        }
    }

    useEffect(()=>{
        setWorkspaceList([]);
        fetchBranch();
    }, [auth.activeWorkShop, refereshData])


    const PieChartData = [18000, 19000, 22000, 27000, 5000];
    const options = {
    chart: {
        type: 'polarArea',
    },
    dataLabels: {
        enabled: true, // Hide data labels inside the graph
        formatter: function (val, opts) {
            return opts.w.config.series[opts.seriesIndex].toLocaleString('en-US', {
                style: 'currency',
                currency: 'ETB',
            });
        }
    },
    labels: ['Branch Sefera', 'Branch Lomi Meda', 'Branch Goro', 'Branch Gerji', 'Head Office'],
    fill: {
        opacity: 0.9,
    },
    stroke: {
        show: true, // Hide the stroke around the chart
        width: 3,
        colors: 'var(--background-dark_minus)',
        // colors: ['#FF4560', '#00E396', '#008FFB', '#FEB019', '#775DD0']
    },
    yaxis: {
        show: false, // Hide the y-axis (circular grid)
    },
    colors: ['#FF4560', '#00E396', '#008FFB', '#FEB019', '#775DD0'],
    plotOptions: {
        polarArea: {
        rings: {
            strokeWidth: 0, // Hide the rings (circular grid lines)
        },
        spokes: {
            strokeWidth: 0, // Hide the spokes (lines radiating from the center)
        },
        },
    },
    };

    return (
        <div className={`${classes.pitty_cash_finance_level_master_wrapper}`}>

            
            {OpenPittyCashAnalysis && <PittyCashBranchAnalysis RefershData={setRefershData} Item={targetWorkspace} Close_CALLBACK={SetOpenPittyCashAnalysis}/>}

                <div className={`${classes.charts_wrapper}`}>
                        <div className={`${classes.single_chart_wrapper}`}>
                            <div className={`${classes.char_head_name_Wrapper}`}>
                                <h1>Pitty Cash Budget Distribution</h1>
                            </div>
                            <Chart
                                options={options}
                                series={PieChartData}
                                type="polarArea"
                                height={300}
                            />
                            <div className={`${classes.char_action_wrapper}`}>
                                <button onClick={(e)=>{SetViewBalance(!ViewBalance)}}>{ViewBalance ? 'Show Me Active Budget' : 'Show Me Available Balance' }</button>
                            </div>
                        </div>
                </div>

                <div className={`${classes.main_report_body_wrapper}`}>

                    <div className={`${classes.main_report_body_inner_wrapper}`}>

                            <div className={`${classes.branch_card_list_wrapper}`}>
                                {
                                    workspaceList.map((item, idx)=>(
                                        auth.userAccessWorkspaceIDList.includes(item.BranchID) &&
                                        <MiniPittyCashBranchCard key={idx} Item={item} OpenAnalysis={OpenPittyCashAnalysisCallback} />
                                    ))
                                }
                        
                            </div>

                    </div>

                </div>
        </div>
    )
}