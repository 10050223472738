import classes from './RegisterStock.module.css'
import data_win from '../../../../SharedComp/CommonStyle/DataCreator.module.css'
import MasterDataRegInput from '../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useRef } from 'react'
import axios from 'axios';
import { useEffect } from 'react'
import Select from 'react-select'

import NotificationPopUp from '../../../../SharedComp/NotificationPopUp/NotificationPopUp'
import DynamicStockInput from './DynamicStockInput/DynamicStockInput'
import { faMinus, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useAuth } from '../../../../AuthProvider'
import { getBaseURL } from '../../../../SharedComp/BaseUrlConfig'
import { useToast } from '../../../../ToastContext'
import {PushInventoryStock} from '../../../../api'



export default function RegisterStock({OpenController}) 
{

    const scrollRef = useRef(null);
    const auth = useAuth();
    const notify = useToast();
    const [OpenMyNoti, SetOpenMyNoti] = useState(false);
    const formRef = useRef();
    const [registring, SetRegistring] = useState(false);
    const [MsgMyPkg, SetMsgMyPkg] = useState({});

    // Inventory Item
    const [InventoryItemType, SetInventoryItemType] = useState(null);
    const [InventoryItemStore, SetInventoryItemStore] = useState(null);
    const [InventoryItemQuantity, SetInventoryItemQuantity] = useState(null);
    const [InventoryItemuUnitPrice, SetInventoryItemuUnitPrice] = useState(null);
    const [InventoryItemExDate, SetInventoryItemExDate] = useState(null);
    const [InventoryItemBrand, SetInventoryItemBrand] = useState(null);

    // Inventory Item Dynmaic Drop down List
    const [StockItem, SetStockItem] = useState(
        [
            <DynamicStockInput 
                HandleInvItemSelect={HandleIvItemSelection} 
                HandleInvItemStore={HandleIvItemStore}
                HandlInvItemQuantity={HandleItemQuantitySelection} 
                HandleUnitPrice={HandleItemPriceSelection} 
                HandleExDate={HandleInvItemExDate}
                HandleIvnItemBrand={HandleIvnItemBrand}
                idx={getRandomID()}/>
        ]
    );

    function HandleIvItemSelection(data, idx) 
    {
        SetInventoryItemType(prev => ({
            ...prev,
            [idx]: data
        }))
    }
    function HandleIvItemStore(data, idx) 
    {
        SetInventoryItemStore(prev => ({
            ...prev,
            [idx]: data
        }))
    }
    function HandleItemQuantitySelection(data, idx) 
    {
        SetInventoryItemQuantity(prev => ({
            ...prev,
            [idx]: data
        }))
    }
    function HandleItemPriceSelection(data, idx) 
    {
        SetInventoryItemuUnitPrice(prev => ({
            ...prev,
            [idx]: data
        }))
    }
    function HandleInvItemExDate(data, idx) 
    {
        SetInventoryItemExDate(prev => ({
            ...prev,
            [idx]: data
        }))
    }
    function HandleIvnItemBrand(data, idx) 
    {
        SetInventoryItemBrand(prev => ({
            ...prev,
            [idx]: data
        }))
    }

    function getRandomID() 
    {
        return Math.floor(Math.random() * 100000000);
    }

    function CloseModal() 
    {
        OpenController(false);
    }
    function AddNewInputField() 
    {
        const existing = [...StockItem];
        existing.push(
            <DynamicStockInput 
                HandleInvItemSelect={HandleIvItemSelection} 
                HandleInvItemStore={HandleIvItemStore}
                HandlInvItemQuantity={HandleItemQuantitySelection} 
                HandleUnitPrice={HandleItemPriceSelection} 
                HandleExDate={HandleInvItemExDate}
                HandleIvnItemBrand={HandleIvnItemBrand}
                idx={getRandomID()}/>
        );
        SetStockItem(existing);
    }
    function RemoveInputField(id_to_remove) 
    {
        if(StockItem.length === 1)
        return;
    
    
        // Clean Inventory Item
        if(InventoryItemType) 
        {
            SetInventoryItemType(prevState => {
                const { [id_to_remove]: _, ...updated } = prevState;
                return updated;
            });
        }

        // Store
        if(InventoryItemStore) 
        {
            SetInventoryItemStore(prevState => {
                const { [id_to_remove]: _, ...updated } = prevState;
                return updated;
            })
        }

        // Brand
        if(InventoryItemBrand)
        {
            SetInventoryItemBrand(prevState => {
                const { [id_to_remove]: _, ...updated } = prevState;
                return updated;
            })
        }
    


        // Clean Item Quantity
        if(InventoryItemQuantity) 
        {
            SetInventoryItemQuantity(prevState => {
                const { [id_to_remove]: _, ...updated } = prevState;
                return updated;
            });
        }

        // Clean Item Unit Price
        if(InventoryItemuUnitPrice) 
        {
            SetInventoryItemuUnitPrice(prevState => {
                const { [id_to_remove]: _, ...updated } = prevState;
                return updated;
            });
        }

        // Date 
        if(InventoryItemExDate) 
        {
            SetInventoryItemExDate(prevState => {
                const { [id_to_remove]: _, ...updated } = prevState;
                return updated;
            });
        }
    


        SetStockItem(prevState => {
            const updated = prevState.filter(item => item.props.idx !== id_to_remove);
            return updated;
        });

    }


    const HandleSubmition = async(e)=> 
    {
        e.preventDefault();
        SetRegistring(true);
        notify.ShowLoad("Processing Request...", 10);

        setTimeout(async ()=>{

            try 
            {
                const fd = new FormData();
                fd.append("st_seller_tin", e.target.seller_tin.value);
                fd.append("st_receipt",  e.target.receipt.value);
                fd.append("st_mrc",  e.target.mrc.value);
                fd.append("st_branch", auth.activeWorkShop);
                fd.append("st_ex_date", JSON.stringify(InventoryItemExDate));
                fd.append("st_InvType", JSON.stringify(InventoryItemType));
                fd.append("st_invStore", JSON.stringify(InventoryItemStore));
                fd.append("st_InvQuantity", JSON.stringify(InventoryItemQuantity));
                fd.append("st_InvUnitPrice", JSON.stringify(InventoryItemuUnitPrice));
                fd.append("st_InvBrand", JSON.stringify((InventoryItemBrand)));

                const res = await PushInventoryStock(fd, 'token');
                notify.ShowSuccessUpdate(res.Msg, 10);
                SetRegistring(false);
            }catch(err) 
            {
                notify.ShowSuccessUpdate(err.message, 10);
                SetRegistring(false);
            }
        }, 2000)
    }
    const scrollToBottom = () => {
        scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
      };
    useEffect(()=>{
        scrollToBottom();
    }, [StockItem])
    function MsgMyTrigger(res) 
    {

    }

    return (

        <div className={`${data_win.main_data_creator_wrapper}`}>
        {OpenMyNoti && <NotificationPopUp key={MsgMyPkg.Key} msg={MsgMyPkg.Msg} state={MsgMyPkg.State}/>}

            <div className={`${data_win.data_main} ${classes.data_main}`} >
                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        Pile [up] <span className={`${data_win.data_create_name}`}>Stock</span>
                    </h3>
                    <button onClick={()=>{CloseModal()}}>
                        <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                    </button>
                </div>

                <div className={`${data_win.data_body}`}>
                        
                

                            <form onSubmit={HandleSubmition} ref={formRef} action="POST">
                                <div className={`${classes.form_wrapper}`}> 
                                        <div  className={`${classes.input_list_wrapper}`}>

                                       

                                            <MasterDataRegInput input={<input name='seller_tin' type="text" min={1} placeholder='Seller Tin ' title='' autoComplete='off' />} />

                                            <MasterDataRegInput input={<input name='receipt' type="text" placeholder='Receipt' title='' autoComplete='off' />} />

                                            <MasterDataRegInput input={<input name='mrc' type="text" placeholder='MRC' title='' autoComplete='off' />} />

                                        </div>

                                       <div className={`${classes.po_mat_list_wrapper}`}>
                                            <div className={`${classes.po_mat_list}`}>
                                                    {StockItem.map((item) => ( 
                                                        <div 
                                                            className={`${classes.input_fild_shild}`} 
                                                            key={item.props.idx}>{item}{StockItem.length > 1 ? <button type='button' onClick={()=>{RemoveInputField(item.props.idx)}} 
                                                            className={`${classes.remove_button_wrapper}`}>
                                                                <FontAwesomeIcon icon={faMinus}/></button> : <span  className={`${classes.remove_button_wrapper_fake}`}></span> }
                                                            <div ref={scrollRef}/>
                                                        </div>
                                                    ))}
                                            </div>
                                       </div>

                                      
                                        

                                        <div className={`${data_win.input_adder} ${classes.input_adder}`}>
                                            <button onClick={()=>{AddNewInputField()}} type='button'>Add Item</button>
                                        </div>
                                            
                                            
                                </div>
                                <div className={`${data_win.form_action_button_wrapper}`}>
                                    <button disabled={registring} className={`${data_win.form_action_button_save}`}>{registring ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Register'}</button>
                                    <button disabled={registring}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                                </div>
                            </form>




                </div>
            </div>

        </div>
    )
}