import classes from './EditSubProduct.module.css'
import data_win from '../../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { faObjectGroup, faObjectUngroup, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FetchBranchAll, FetchProductLineMasterGroupAll, FetchProductLineMasterSubGroup, UpdateProductLineMasterSubGroup } from '../../../../../../api';

import {Custom_DD_Style, PrepareDropDownList} from '../../../../../../SharedComp/Utility'
import Select from 'react-select'
import MasterDataRegInput from '../../../../../../SharedComp/CommonStyle/MasterDataRegInput';

import { useAuth } from '../../../../../../AuthProvider';
import { useToast } from '../../../../../../ToastContext';

const EditSubProduct = ({OpenModal, TargetID}) => {


    const auth = useAuth();
    const notify = useToast();
    const [Loading, SetLoading] = useState(false);
    const [WorkspaceList, SetWorkspaceList] = useState([]);
    const [WorkspaceChecked, SetWorkspaceChecked] = useState(new Map());
    const [ToggelListTrue, SetToggelListTrue] = useState(false);

    const [MasterProductList, SetMasterProductList] = useState([]);

    const [SMasterProduct, SetSMasterProduct] = useState();
    const [Sname, SetSname] = useState();
    const [SDetail, SetDetail] = useState();

    const  [TargetData, SetTargetData] = useState();


    // This is to hanle indiviual select
    const handleToggle = (branchName) => {
        SetWorkspaceChecked(prevState => {
          const updatedState = new Map(prevState);
          updatedState.set(branchName, !updatedState.get(branchName));
          return updatedState;
        });
    };

    // This function handle the select all stuff
    const selectAll = (val) => {

    SetToggelListTrue(val);

    let updated_list = new Map();
    for(const [mp_key, mp_val] of WorkspaceChecked.entries()) 
    {
        updated_list.set(mp_key, val);
    }
    SetWorkspaceChecked(updated_list);    
    };

    // we need to query all the branch inorder to check which one is active
    const QueryWorkspace = async () => {

        try {
            const res = await FetchBranchAll(auth.token);

            // for now prepare void list
            let init_check_list = new Map();
            for(let  i = 0; i < res.length; i++) 
            {
                if(!init_check_list.has(res[i].BranchName)) 
                    init_check_list.set(res[i].BranchName, false); 
            }
            SetWorkspaceChecked(init_check_list);

            SetWorkspaceList(res);
        } catch (err) 
        {
            throw new Error(err);
        }
    }

    
    // Get all Master Group for the drop down
    const QueryMasterProductLineGroup = async () =>{
        try 
        {
            const res = await FetchProductLineMasterGroupAll(auth.token);
            PrepareDropDownList(res, SetMasterProductList);
        } catch(err)
        {
            console.log(err.message);
        }
    }

    const QueryTargetItem = async () => {
        try 
        {
            const res = await FetchProductLineMasterSubGroup(TargetID, auth.token);
            SetTargetData(res);
            SetSname(res.ProdSubCatName);
            SetDetail(res.ProdSubCatDescription);
            SetSMasterProduct({value: res.ProdParentTypeID, label: res.ProdCatName});

            const crt_data =  new Map(Object.entries(JSON.parse(res.SubProdAvailBranch)));

            let updated_list = new Map();
            for(let  i = 0; i < WorkspaceList.length; i++) 
            {
                const _workspace_name = WorkspaceList[i].BranchName;
                if(crt_data.has(_workspace_name))
                {
                    updated_list.set(_workspace_name, crt_data.get(_workspace_name));
                } else 
                {
                    updated_list.set(_workspace_name, false);
                }
            }
            SetWorkspaceChecked(updated_list); 

        }catch(err) 
        {
            console.log(err.message);
        }
    }


    const HandleSubmition = async (e) => 
    {

        e.preventDefault();
        notify.ShowLoad("Locking in Your Obligations...", 10);
        SetLoading(true);

        setTimeout(async ()=>{

                try 
                {
                    const fd = new FormData();
                    fd.append('name', Sname);
                    fd.append('detail', SDetail);
                    fd.append('parent', JSON.stringify(SMasterProduct));
                    fd.append('avail_workshop', JSON.stringify(Object.fromEntries(WorkspaceChecked)));
                    fd.append("target", TargetData.ProdSubCatID);


                    const res = await UpdateProductLineMasterSubGroup(fd, auth.token);
                    notify.ShowSuccessUpdate(res.Msg, 10);
                    OpenModal(false);
                    SetLoading(false);

                }catch(err) 
                {
                    console.log(err.message);
                    notify.ShowSuccessUpdate(err.message, 10);
                    SetLoading(false);
                }

        }, 3000)


    }

    useEffect(()=>{

        QueryWorkspace();
        QueryMasterProductLineGroup();
    }, [])

    useEffect(()=>{
        QueryTargetItem();
    }, [WorkspaceList])

    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>
            <div className={`${data_win.data_main}`}>
                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        Edit <span className={`${data_win.data_create_name}`}>Sub Product</span>
                    </h3>
                    <button onClick={()=>{OpenModal(false)}}>
                        <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                    </button>
                </div>

                <div className={`${data_win.data_body}`}>
                        
                

                            <form onSubmit={HandleSubmition} action="POST">
                                <div className={`${classes.form_wrapper}`}> 



                                <div className={`${classes.master_prod_type_config_wrapper}`}>
                                        <div className={`${classes.master_product_type_inner_config_wrapper}`}>
                                                <MasterDataRegInput 
                                                    input={
                                                        <Select
                                                            value={SMasterProduct}
                                                            onChange={(data)=>{SetSMasterProduct(data)}}
                                                            name={"dd_data"}
                                                            options={MasterProductList}
                                                            placeholder={"Select Group.."}
                                                            isSearchable={true}
                                                            styles={Custom_DD_Style}
                                                            required ={true}
                                                        />
                                                        
                                                    }  />
                                                <MasterDataRegInput
                                                    input={
                                                        <input onChange={(e)=>{SetSname(e.target.value)}} type="text" value={Sname} placeholder='Product Sub Catagory' />
                                                    }
                                                />

                                                 <MasterDataRegInput
                                                    input={
                                                        <textarea onChange={(e)=>{SetDetail(e.target.value)}} value={SDetail} name="detail" placeholder='Discription'></textarea>
                                                    }
                                                />
                                        </div>

                                        <div className={`${classes.master_product_type_inner_avail_workspace_list}`}>
                                            <div className={`${classes.master_product_type_inner_avail_workspace_list_header}`}>
                                                <h1>Accessibility</h1>
                                                <button type='button' onClick={()=>{selectAll(!ToggelListTrue)}}><FontAwesomeIcon icon={ToggelListTrue ? faObjectUngroup : faObjectGroup}/></button>
                                            </div>
                                            <div className={`${classes.master_prod_avail_list}`}>
                                                {
                                                    WorkspaceList.map((item, idx)=>(
                                                        <div key={idx} onClick={() => handleToggle(item.BranchName)} className={`${classes.master_prod_avail_branch}`}>
                                                            <input 
                                                            onClick={() => handleToggle(item.BranchName)}
                                                            checked={WorkspaceChecked.get(item.BranchName)} 
                                                            name={item.BranchName} 
                                                            type="checkbox"
                                                            onChange={() => handleToggle(item.BranchName)}
                                                             />
                                                            <li>{item.BranchName}</li>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>

                                       
                                </div> 
                                <div className={`${data_win.form_action_button_wrapper}`}>
                                    <button disabled={Loading} className={`${data_win.form_action_button_save}`}>{Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Update'}</button>
                                    <button disabled={Loading}  onClick={()=>{OpenModal(false)}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                                </div>
                            </form>




                </div>
            </div>

        </div>
    )
}

export default EditSubProduct;