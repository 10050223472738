import classes from './EmployeeBudleHandler.module.css'
import EmployeeMiniCardMaster from '../EmployeeMiniCardMaster/EmployeeMiniCardMaster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faPlus, faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import { useState } from 'react';
import { getBaseURL } from '../../../../SharedComp/BaseUrlConfig';

export default function EmployeeBundleHandler({BranchData, EmployeeCreatorWinController, EmpViewController, CreateFakeEmployee_DEBUG, Refresh = false}) 
{

    const [branchEmployee, SetBranchEmployee] = useState([]);
    const [OpenFilterBoard, SetOpenFilterBoard] = useState(false);
    const [updateList, SetUpdateList] = useState(false);

    const [ContractEmployee, SetContractEmployee] = useState(false);
    const [PermanentEmployee, SetPermanentEmployee] = useState(false);
    const [OfficeEmployee, SetOfficeEmployee] = useState(false);
    const [WorkshopEmployee, SetWorkshopEmployee] = useState(false);

    const [isFilterBoardHovered, SetIsFilterBoardHovered] = useState(false);


    function FilterContractEmployee(e) 
    {
      SetContractEmployee(e.target.checked);
      if(e.target.checked) 
      {
        const filteredArray = branchEmployee.filter(item => item.EmploymentType.toLowerCase().includes("contract"));
        SetBranchEmployee(filteredArray);
      } else 
      {
        SetUpdateList(!updateList);
      }
      
    }
    function FilterPermanentEmployee(e) 
    {
      SetPermanentEmployee(e.target.checked);
      if(e.target.checked)  
      {
        const filteredArray = branchEmployee.filter(item => item.EmploymentType.toLowerCase().includes("permanent"));
        SetBranchEmployee(filteredArray);
      } else 
      {
        SetUpdateList(!updateList);
      }
      
    }
    function FilterOfficeEmployee(e) 
    {
      SetOfficeEmployee(e.target.checked);
      if(e.target.checked)  
      {
        const filteredArray = branchEmployee.filter(item => item.Workspace.toLowerCase().includes("office"));
        SetBranchEmployee(filteredArray);
      } else 
      {
        SetUpdateList(!updateList);
      }
    }
    function FilterWorkshopEmployee(e) 
    {
      SetWorkshopEmployee(e.target.checked);
      if(e.target.checked)  
      {
        const filteredArray = branchEmployee.filter(item => item.Workspace.toLowerCase().includes("workshop"));
        SetBranchEmployee(filteredArray);
      } else 
      {
        SetUpdateList(!updateList);
      }
    }

    function HandleFilterBoard() 
    {
      if(OpenFilterBoard) 
      {
        SetOpenFilterBoard(false);
        SetUpdateList(!updateList);
      } else 
      {
        SetOpenFilterBoard(true);
      }
    }

    function CloseFilterBoard() 
    {
      console.log(isFilterBoardHovered);
        setTimeout(()=>{
          SetOpenFilterBoard(false);
        }, 2000)
    }

    function FilterEmployeeByName(event) 
    {
      if(event.target.value === '') 
      {
        SetUpdateList(!updateList);
      }
      const filteredArray = branchEmployee.filter(item => item.FullName.toLowerCase().includes(event.target.value.toLowerCase()));
      SetBranchEmployee(filteredArray);
    }
    useEffect(() => 
    {
        fetch(getBaseURL() + 'employee/query_employee/', { 
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ BranchID: BranchData.BranchID }),
          })
            .then(res => res.json())
            .then((data) => SetBranchEmployee(data));
    }, [Refresh, updateList])
    return (
        <div className={`${classes.employee_bundle_handler_wrapper}`}>
            <div className={`${classes.employee_master_list_wrapper}`}>

                <div className={`${classes.employee_master_list_header}`}>
                    <div className={`${classes.header_info}`}>
                       <li>{BranchData.BranchName}</li>
                    </div>
                    <div className={`${classes.header_info}`}>
                      <button onClick={()=>{CreateFakeEmployee_DEBUG(BranchData.BranchID)}}><FontAwesomeIcon icon={faUserSecret}></FontAwesomeIcon></button>
                      <button onClick={()=>{EmployeeCreatorWinController(BranchData.BranchID)}}><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon></button>
                      <button onClick={()=>{HandleFilterBoard()}}><FontAwesomeIcon icon={faFilter}></FontAwesomeIcon></button>

                        <div onMouseOver={()=>{SetIsFilterBoardHovered(true)}} onMouseLeave={()=>{SetIsFilterBoardHovered(false); CloseFilterBoard()}} className={`${OpenFilterBoard ? classes.employee_header_filter_board : classes.employee_header_filter_board_hide}`}>
                            <div className={`${classes.employee_header_filter_input}`}>
                              <div className={`${classes.employee_header_filter_input_main}`}>
                                <input onChange={FilterEmployeeByName} type="text" placeholder='Employee name...'/>
                              </div>
                              <div className={`${classes.employee_header_filter_input_main_check_input}`}>
                                <input onChange={FilterContractEmployee} checked={ContractEmployee} type="checkbox" />
                                <li>Contract Employee</li>
                              </div>
                              <div className={`${classes.employee_header_filter_input_main_check_input}`}>
                                <input onChange={FilterPermanentEmployee} checked={PermanentEmployee} type="checkbox" />
                                <li>Permanent Employee</li>
                              </div>
                              <div className={`${classes.employee_header_filter_input_main_check_input}`}>
                                <input onChange={FilterWorkshopEmployee} checked={WorkshopEmployee} type="checkbox" />
                                <li>Workshop Employee</li>
                              </div>
                              <div className={`${classes.employee_header_filter_input_main_check_input}`}>
                                <input onChange={FilterOfficeEmployee} checked={OfficeEmployee} type="checkbox" />
                                <li>Office Employee</li>
                              </div>
                            </div>
                        </div>

                    </div>
                </div>
                
                <div className={`${classes.emp_list_wrapper}`}>

                {branchEmployee.map((item, index) => (
                     <EmployeeMiniCardMaster   
                        key={index}   
                        EmpData={item}  
                        EmpViewController={EmpViewController}

                      />
                ))}

                </div>

            </div>
    </div>
    );
}