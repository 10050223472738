import { useEffect, useRef, useState } from 'react';
import classes from './TrackRequest.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faBan, faBinoculars, faCheckCircle, faCircleArrowDown, faCircleArrowUp, faCircleCheck, faDownLong, faDownload, faExpand, faHourglass, faHourglassHalf, faKey, faPrint, faTruckMoving } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios'
import {FetchTransferRequestDispatched, FetchTransferRequestDispatchedDetail, FetchTransferRequestIncoming} from '../../../../api'
import { useAuth } from '../../../../AuthProvider';
import { useToast } from '../../../../ToastContext';
import { formatDistanceToNow, formatDistance } from 'date-fns';
import ProcessTRequest from '../../Component/ProcessRequest/ProcessTRequest';
import PrintTransferRequest from '../../Component/PrintTransferRequest/PrintTransferRequest';
import QRCodeReader from '../../../../SharedComp/QRCodeReader/QRCodeReader';

import ReceiveSTRequest from '../../Component/ReceiveSTRequest/ReceiveSTRequest';

const getRelativeTime = (date) => {
    return formatDistanceToNow(new Date(date), { addSuffix: true });
};
function getTimeRemaining(date) {
    const now = new Date();
    return formatDistance(new Date(date), now, { addSuffix: true });
  }
const TrackRequest = () => {


    const auth = useAuth();
    const notify = useToast();

    const [TragetOrder, SetTragetOrder] = useState(null);
    const [TargetLoading, SetTargetLoading] = useState(false);

    const [TargetRequestHandle, SetTargetRequestHandle] = useState(null);

    const [DispachedRequestList, SetDispachedRequestList] = useState([]);
    const [IncomingRequestList, SetIncomingRequestList] = useState([]);

    const [OpenProcessRequest, SetOpenProcessRequest] = useState(false);

    const [PrintRequest, SetPrintRequest] = useState(false);
    const [RequestID, SetRequestID] = useState();

    const [OpenKeyInputWrapper, SetOpenKeyInputWrapper] = useState(false);
    const ReqKeyInputWrapperRef = useRef(null);

    // QR
    const [OpenQRCodeReader, SetOpenQRCodeReader] = useState(false);
    const [RequisitionReadbackHashKey, SetRequisitionReadbackHashKey] = useState();

    // Process Receive
    const [OpenProcessReceiveSTRQ, SetOpenProcessReceiveSTRQ] = useState(false);
    const [TargetProcessIncomingRequestKey, SetTargetProcessIncomingRequestKey] = useState('jhsjd-asjdh');

    const FetchDetailRequestData = async(id)=>
    {
        SetTargetLoading(true);
        try 
        {
            const res = await FetchTransferRequestDispatchedDetail(id, 'token');
            SetTragetOrder(res[0]);
            SetTargetLoading(false);
        }catch(err) 
        {
            
            notify.ShowError(err.message);
            SetTargetLoading(false);
        }
    }

    const FetchIncomingRequestList = async()=>
    {
        try 
        {
            const res = await FetchTransferRequestIncoming(auth.activeWorkShop, 'token');
            SetIncomingRequestList(res);
        }catch(err) 
        {
            
            notify.ShowError(err.message);
        }
    }
    const FetchDispachedList = async() =>
    {
        try 
        {
            const res = await FetchTransferRequestDispatched(auth.activeWorkShop, 'token');
            SetDispachedRequestList(res);
        }catch(err) 
        {
            
            notify.ShowError(err.message);
        }
    }
    const HandleModalDissmis = async()=>
    {
        SetOpenProcessRequest(false);
        SetTargetRequestHandle(null);
    }

    function HandleQRScanOpen() 
    {
        notify.ShowWarn("Please position the requisition paper in front of the camera, ensuring that the QR code area is clearly focused for optimal results.");
        SetOpenQRCodeReader(true);
    }

    useEffect(()=>{
        if(TargetRequestHandle) 
        {        
            SetOpenProcessRequest(true);
        }

    }, [TargetRequestHandle])

    function HandleMouseClick(e) 
    {
        if (ReqKeyInputWrapperRef.current && !ReqKeyInputWrapperRef.current.contains(e.target)) {
            SetOpenKeyInputWrapper(false);
          }
    }

    useEffect(()=>{
        FetchIncomingRequestList();
        FetchDispachedList();

        document.addEventListener('mousedown', HandleMouseClick);
        return () => {
        document.removeEventListener('mousedown', HandleMouseClick);
        };
    }, [auth.activeWorkShop, OpenProcessRequest, OpenProcessReceiveSTRQ])

    
      const handleBlur = (event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
            SetOpenKeyInputWrapper(false);
        }
      };

      function OnQrCodeScan(data) 
      {
        if(data) 
        {
            SetOpenQRCodeReader(false);
            if(data.text !== TragetOrder?.IVSTR_ReadBackHash) 
            {
                notify.ShowError("Oops! The key you provided doesn't match with the selected requisition order. Double-check it, just in case it's playing hide and seek!")
            } else 
            {
                if(TragetOrder?.IVSTR_TransferState === 1) 
                {
                    notify.ShowWarn("Looks like this requisition order has already been processed. No double-dipping allowed!")
                } else 
                {
                    SetTargetProcessIncomingRequestKey(data.text);
                    SetOpenProcessReceiveSTRQ(true);
                } 
            }
           
        }
      }
      function OnQrFailToRead(data) 
      {

      }

      function ValidateKey() 
      {
        if(TragetOrder?.IVSTR_ReadBackHash !== RequisitionReadbackHashKey)
        {
            notify.ShowError("Oops! The key you provided doesn't match with the selected requisition order. Double-check it, just in case it's playing hide and seek!")
        } else 
        {
            if(TragetOrder?.IVSTR_TransferState === 1) 
            {
                notify.ShowWarn("Looks like this requisition order has already been processed. No double-dipping allowed!")
            } else 
            {
                SetTargetProcessIncomingRequestKey(RequisitionReadbackHashKey);
                SetOpenProcessReceiveSTRQ(true);
            }  
        }
      }
    return (
        <div className={`${classes.request_track_main_wrapper}`}>

            {OpenProcessReceiveSTRQ && <ReceiveSTRequest TargetRequest={TargetProcessIncomingRequestKey} OpenModal={SetOpenProcessReceiveSTRQ} />}
            {OpenQRCodeReader && <QRCodeReader onQRRead={OnQrCodeScan} OnError={OnQrFailToRead} Open={OpenQRCodeReader} OpenController={SetOpenQRCodeReader}/>}
            {PrintRequest && <PrintTransferRequest OpenModal={SetPrintRequest} TargetID={RequestID}/>}
            {OpenProcessRequest && <ProcessTRequest TargetRequest={TargetRequestHandle} OpenModal={HandleModalDissmis}/>}

            <div className={`${classes.wrapper_master_last_log}`}>

                <div className={`${classes.two_state_wrapper}`}>

                

                    <div className={`${classes.wrapper_inner_last_log}`}>

                        {TragetOrder &&
                        
                        <>
                            <div  className={`${classes.state_log_top_main_wrapper}`}>
                                <div className={`${classes.state_log_wrapper}`}>
                                    <li className={`${classes.tag_header}`}>Status</li>
                                    <div className={`${classes.ac_state_wrapper}`}>
                                        <button className={`${TragetOrder?.IVSTR_ApproveState === -1 ? classes.declined_but : TragetOrder?.IVSTR_ApproveState === 0 ? classes.pend_but : classes.approve_but}`}>{TragetOrder?.IVSTR_ApproveState === -1 ? 'Declined' : TragetOrder?.IVSTR_ApproveState === 0 ? 'Waiting Action' : 'Approved' }</button>
                                        <button className={`${TragetOrder?.IVSTR_TransferState === 1 ? classes.ing_but_transferred : classes.ing_but}`}>{TragetOrder?.IVSTR_TransferState === 1 ? 'Transferred' : 'Not transferred'}</button>
                                    </div>
                                </div>
                                <div className={`${classes.date_log_wrapper}`}>
                                    <div className={`${classes.ac_created_date}`}>
                                            <li className={`${classes.tag_header}`}>Creation Date</li>
                                            <li className={`${classes.tag_val}`}>{new Date(TragetOrder?.IVSTR_RequestDate).toDateString()}</li>
                                    </div>
                                    <div className={`${classes.ac_required_date}`}>
                                            <li className={`${classes.tag_header}`}>Required Date</li>
                                            <li className={`${classes.tag_val}`}>{new Date(TragetOrder?.IVSTR_DeliveryDate).toDateString()}</li>
                                    </div>
                                </div>
                                <div className={`${classes.approve_date_log_wrapper}`}>
                                    <div className={`${classes.ac_approv_date}`}>
                                            <li className={`${classes.tag_header}`}>Approved Date</li>
                                            <li className={`${classes.tag_val}`}>{TragetOrder?.IVSTR_ApproveState === 1 ? new Date().toDateString() : '-'}</li>
                                    </div>
                                </div>
                            </div>

                            <div className={`${classes.state_requester_main_wrapper}`}>

                                    <div className={`${classes.ac_requester_info}`}>
                                            <li className={`${classes.tag_header}`}>Requester</li>
                                            <li className={`${classes.tag_val_bold}`}>{TragetOrder?.FullName}</li>
                                            {/* <li className={`${classes.tag_val}`}>Supervisor</li> */}
                                            <li className={`${classes.tag_val_bold}`}>{TragetOrder?.Email}</li>
                                            <li className={`${classes.tag_val}`}>{auth.activeWorkShopName} Branch</li>
                                    </div>

                                    <div className={`${classes.ac_requester_info}`}>
                                            <li className={`${classes.tag_header}`}>Recipient</li>
                                            <li className={`${classes.tag_val_bold}`}>{TragetOrder?.BranchName}</li>
                                    </div>
                                    
                            </div>
                            <div className={`${classes.ac_requester_info_recive}`}>
                                    {
                                        TragetOrder?.IVSTR_ApproveState === 1 && TragetOrder?.IVSTR_TransferState !== 1 && 
                                    <div className={`${classes.ac_requester_info_receive_method}`}> 
                                        <div 
                                            ref={ReqKeyInputWrapperRef}
                                            tabIndex={-1}
                                            onBlur={handleBlur}
                                            onFocus={()=>{SetOpenKeyInputWrapper(true)}}
                                            className={`${OpenKeyInputWrapper ? classes.take_key_order : classes.take_key_order_hide}`}>
                                            <li>Stock Requisition Key</li>
                                            <input onChange={(e)=>{SetRequisitionReadbackHashKey(e.target.value)}} type="text" placeholder='Requestion Code' />
                                            <button onClick={()=>{ValidateKey()}}>Validate</button>
                                        </div>
                                        <button onClick={(e)=>{SetOpenKeyInputWrapper(!OpenKeyInputWrapper)}}> <FontAwesomeIcon icon={faKey}></FontAwesomeIcon> Enter Key</button> 
                                        <button onClick={(e)=>{HandleQRScanOpen()}}><FontAwesomeIcon icon={faExpand}></FontAwesomeIcon> Scan Paper</button>

                                        
                                    </div>
                                    }
                            </div>
                        </>}
                        {!TragetOrder && <div className={`${classes.place_holder_main}`}>Select to Manage</div> }

                    </div>

                    <div className={`${classes.action_data_wrapper}`}>
                        
                        <div className={`${classes.action_main_req}`}>
                                <div className={`${classes.action_main_req_dash}`}>
                                    <button className={`${classes.action_new}`}>Create Stock Request</button>
                                    <button className={`${classes.action_new}`}>Direct Stock Transfer</button>
                                    <button disabled={TragetOrder === null} className={`${TragetOrder ? TragetOrder?.IVSTR_ApproveState === 1 ? classes.action_terminate_dis :  classes.action_terminate : classes.action_terminate }`}>Terminate Request</button>
                                </div>
                        </div>
                    </div>
                    
                    


                </div>

            </div>

            <div className={`${classes.request_track_inner_wrapper}`}>
                    <div className={`${classes.track_request_list_wrapper}`}>
                        <div className={`${classes.list_header}`}>
                            <h2>Dispatched Request</h2>
                            <span><FontAwesomeIcon icon={faCircleArrowUp}></FontAwesomeIcon></span>
                        </div>
                        <div className={`${classes.list_wrapper}`}>
                            {
                                DispachedRequestList.map((item, idx)=>(
                                    <li onClick={()=>{FetchDetailRequestData(item.IVSTRID)}} key={idx}>
                                        <span className={`${classes.order_id}`}><sub >DIRECTED</sub>@<span className={`${classes.name_workspace}`}>{item.BranchName}</span> </span>
                                        <span className={`${classes.order_item_time}`}>{getRelativeTime(item.IVSTR_RequestDate)}</span>
                                        <span className={`
                                            ${item.IVSTR_ApproveState === 1 && item.IVSTR_TransferState === 0 ? classes.order_moving 
                                            : item.IVSTR_ApproveState === 1 && item.IVSTR_TransferState ?  classes.order_complete 
                                            : item.IVSTR_ApproveState === -1 ? classes.order_rejected : classes.order_pending}`}>
                                             <FontAwesomeIcon 
                                                icon={
                                                    item.IVSTR_ApproveState === 1 && item.IVSTR_TransferState === 0 ? faTruckMoving 
                                                    : item.IVSTR_ApproveState === 1 && item.IVSTR_TransferState ? faCheckCircle 
                                                    : item.IVSTR_ApproveState === -1 ? faBan 
                                                    : faHourglass}></FontAwesomeIcon> 
                                             {item.IVSTR_ApproveState === 1 && item.IVSTR_TransferState === 0 && ' Underway'}
                                             {item.IVSTR_ApproveState === 1 && item.IVSTR_TransferState === 1 && ' Received'}
                                             {item.IVSTR_ApproveState === -1 && ' Rejected'}
                                             {item.IVSTR_ApproveState === 0 && ' Unresolved'}
                                        </span>
                                    </li>
                                ))
                            }
                        </div>
                    </div>
                    <div className={`${classes.track_request_list_wrapper}`}>
                        <div className={`${classes.list_header}`}>
                            <h2>Incoming Request</h2>
                            <span><FontAwesomeIcon icon={faCircleArrowDown}></FontAwesomeIcon></span>
                        </div>
                        <div className={`${classes.list_wrapper}`}>
                            {
                                IncomingRequestList.map((item, idx)=>(
                                    <li key={idx}>
                                        <span className={`${classes.order_id}`}> <sub>ORIGIN</sub>@<span className={`${classes.name_workspace}`}>{item.BranchName}</span> </span>
                                        <span className={`${classes.order_item_list}`}>{item.IVSTR_ApproveState !== 0 ? new Date(item.IVSTR_RequestDate).toDateString() : getTimeRemaining(item.IVSTR_DeliveryDate)}</span>
                                        <span className={`${classes.order_item_action}`}>
                                            {item.IVSTR_ApproveState === 1 && 
                                                <div className={`${classes.order_item_approved_action_wrapper}`}>
                                                    {item.IVSTR_TransferState !== 1 && <span onClick={()=>{SetRequestID(item.IVSTR_RequestPayloadID); SetPrintRequest(true)}} className={`${classes.order_request_print}`}><FontAwesomeIcon icon={faPrint}></FontAwesomeIcon> Print</span>}
                                                    <span className={`${classes.order_request_approved}`}><FontAwesomeIcon icon={faCircleCheck}></FontAwesomeIcon> Authorized</span>
                                                </div>
                                            }
                                            {item.IVSTR_ApproveState === 0 && 
                                                <div className={`${classes.order_item_approved_action_wrapper}`}>
                                                    <span onClick={()=>{SetTargetRequestHandle(item)}} className={`${classes.order_request_view}`}><FontAwesomeIcon icon={faBinoculars}></FontAwesomeIcon> View</span>
                                                    <span className={`${classes.order_request_pending}`}><FontAwesomeIcon icon={faHourglass}></FontAwesomeIcon> Unresolved</span>
                                                </div>
                                            }

                                            {item.IVSTR_ApproveState === -1 && 
                                                <div className={`${classes.order_item_approved_action_wrapper}`}>
                                                    {/* <span className={`${classes.order_request_print}`}><FontAwesomeIcon icon={faPrint}></FontAwesomeIcon> Print</span> */}
                                                    <span className={`${classes.order_request_decline}`}><FontAwesomeIcon icon={faBan}></FontAwesomeIcon> Refused</span>
                                                </div>
                                            }
                                               
                                        </span> 
                                    </li>
                                ))
                            }
                        </div>
                    </div>
            </div>

        </div>
    )
}


export default TrackRequest; 