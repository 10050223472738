import classes from './EmpPto.module.css'
import CircleMiniGraph from '../../../../../SharedComp/CircleMiniGraph/CircleMiniGraph';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'react-tooltip'; 
import PtoAction from './Component/PtoAction';
import { useEffect, useState } from 'react';
import CleanList from '../Credit/Component/CleanList/CleanList';
import { getBaseURL } from '../../../../../SharedComp/BaseUrlConfig';

export default function EmpPto({MsgTrigger, EmpData, ReqRefresh}) 
{
    const [OpenPto, SetOpenPto] = useState(false);

    const [EmpPto, setEmpPto] = useState([]);
    const [EmpPtoCount, SetEmpPtoCount] = useState(0);

    function HandlePTOOpen() 
    {
        SetOpenPto(true);
    }

    useEffect(() => {
    
        fetch(getBaseURL() + 'employee/query_employee_pto/', { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ EmpID: EmpData.EmployeeID }),
      })
        .then(res => res.json())
        .then((data) => {setEmpPto(data); SetEmpPtoCount(data.length); ReqRefresh()});

    }, [OpenPto])


    return (
        <div className={`${classes.emp_pto_main_wrapper}`}>
            <Tooltip className={`${classes.tool_quick}`} id="offical_doc" place="top" />

            <div className={`${classes.emp_pto_action_adder}`}>
                <button onClick={()=>{HandlePTOOpen()}}>Request Time Off</button>
            </div>

            {OpenPto && <PtoAction MsgTrigger={MsgTrigger} EmpData={EmpData} OpenController={SetOpenPto}/>}

            <div className={`${classes.emp_min_graph_wrapper}`}>
                <CircleMiniGraph Color={EmpData.RemainAnnualLeave <= 3 ? 'var(--red-color)' : 'var(--grren-color)'} rad={1.8} value={EmpData.RemainAnnualLeave} Text='AL Remain' Percentage={Math.floor((EmpData.RemainAnnualLeave / EmpData.AllowedAnnualLeave) * 100)}/>
            </div>

            <div className={`${classes.time_off_list_wrapper_outer}`}>
                {/* <div className={`${classes.time_off_list_wrapper_outer_header}`}>
                    <h1>
                        Leave History
                    </h1>
                </div> */}

                <div className={`${classes.time_off_list_wrapper_inner}`}>
                    {
                       EmpPtoCount > 0 ?  EmpPto.map((item, index) => (
                            <li key={index}>
                                <span className={`${classes.leave_date}`}>
                                    From:  {new Date(item.StartDate).toLocaleString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })} 
                                    <span  className={`${classes.start_to_end_pointer}`}><FontAwesomeIcon icon={faArrowRightLong}></FontAwesomeIcon></span>
                                    To:  {new Date(item.EndDate).toLocaleString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                                </span>
                                

                                <span {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': "This must be the reason why he ditch his post!"})} className={`${classes.leave_reason}`}>
                                    {item.Name}
                                </span>

                                <span {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': "How long the dude lost"})}  className={`${classes.leave_date_cout}`}>
                                    {item.AllowedDayCount} Days
                                </span>

                                <span className={`${classes.leave_action}`}>
                                        <button><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></button>
                                </span>
                            </li>
                        )) : <CleanList FontSize={25} Msg={"No PTO used"}/>
                    }
                    {
                        
                    }

                </div>
            </div>
        </div>
    );
}