// Styles
import classes from './MerchType.module.css'
import data_win from './../../../../../SharedComp/CommonStyle/DataCreator.module.css'

// Comp
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MasterDataRegInput from '../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useRef } from 'react'
import { useState } from 'react'
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

//lib
import axios from 'axios';

export default function MerchType({OpenController, MsgTrigger}) 
{
    const formRef = useRef();
    const [registring, SetRegistring] = useState(false);

    function CloseModal() 
    {
        formRef.current.reset();
        OpenController(false);
    }

    function HandleSubmition(e) 
    {
        e.preventDefault();

        SetRegistring(true);

        setTimeout(()=>{

            const fd = new FormData();

            fd.append("merch_cat_name", e.target.merch_cat_name.value);
            fd.append("merch_cat_remark", e.target.merch_cat_remark.value);

            const url = 'http://localhost:3333/master-data/add-merch-type-item';
            axios.post(url, fd, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                })
            .then(res => {
                SetRegistring(false);
                MsgTrigger(res);
                CloseModal();
                
                
            })
            .catch(err => {
                SetRegistring(false);
                MsgTrigger(err);
                
            })

        }, 2000)
    }

    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

            <div className={`${data_win.data_main}`}>
                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        Registring <span className={`${data_win.data_create_name}`}>Merchandise Category</span>
                    </h3>
                    <button onClick={()=>{CloseModal()}}>
                        <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                    </button>
                </div>

                <div className={`${data_win.data_body}`}>
                        
                

                            <form onSubmit={HandleSubmition} ref={formRef} action="POST">
                                <div className={`${classes.form_wrapper}`}> 
                                        <div  className={`${classes.input_list_wrapper}`}>
                                            <MasterDataRegInput input={<input name='merch_cat_name' type="text" placeholder='Category Name' title='' autoComplete='off' required />} />
                                            <MasterDataRegInput input={<input name='merch_cat_remark' type="text" placeholder='Remark' title='' autoComplete='off' />} />
                                          
                                        </div>
                                </div>
                                <div className={`${data_win.form_action_button_wrapper}`}>
                                    <button disabled={registring} className={`${data_win.form_action_button_save}`}>{registring ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Add'}</button>
                                    <button disabled={registring}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                                </div>
                            </form>




                </div>
            </div>

        </div>
    )
}