import classes from './BranchCardStyle.module.css'
import { faLocation, faLocationPin, faMapLocationDot, faPen, faPersonDigging, faWarehouse } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GoogleMapReact from 'google-map-react';
import { useEffect } from 'react';



export default function BranchCard({BranchData, DataViewPkgSet, DetailViewController, Data_CALLBK}) 
{
    return (
        <div onClick={()=>{Data_CALLBK(BranchData)}} className={`${classes.brach_card_main_wrapper}`}>
                <div className={`${classes.card_header_wrapper}`}>
                    <h1>{BranchData.BranchName}</h1>
                </div>

                <div className={`${classes.cadr_body_wrapper}`}>
                    <li>Supervisor Ramsy Bolton</li>
                    <li>Active Personal 78</li>
                </div>
        </div>
    );
}