import { faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import classes from './DecisionAssurance.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react';
import { faBell, faSkullCrossbones, faTimesSquare, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';


export default function DecisionAssurance({ActionName = null, CallbackData, Level = 1, openController, Msg, HandleResponse}) 
{

    const [TimeLeft, setTimeLeft] = useState(30);
    const [Low, SetLow] = useState(4);
    const [Mid, SetMid] = useState(8);
    const [High, SetHigh] = useState(10);

  useEffect(() => {
    const interval = setInterval(() => {
      if (TimeLeft > 1) {
        setTimeLeft((prevSeconds) => prevSeconds - 1);
      } else 
      {
        openController(false);
        HandleResponse(null)
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [TimeLeft]);
    
    return (
        <div className={`${classes.da_main_wrapper}`}>

            <div className={`${classes.da_inner_card}`}>
                <div className={`${classes.da_inner_card_header}`}>
                    <li>DAC - <span className={`${classes.action_name}`}>{ActionName}</span></li>
                    <li><button onClick={()=>{openController(false); HandleResponse(null)}}><FontAwesomeIcon icon={faTimesSquare}/></button></li>
                </div>

                <div className={`${classes.da_inner_card_body}`}>
                    <div className={`${classes.da_note_wrapper}`}>
                        <li className={`${classes.da_big_icon}`}><i style={{
                          color: Level < Low ? `var(--text_light_white)` : Level > Low && Level < Mid ? `var(--color-yellow)` : `var(--red-color)`
                        }}><FontAwesomeIcon icon={Level < Low ? faBell : Level > Low && Level < Mid ? faTriangleExclamation : faSkullCrossbones}/></i></li>
                        <li className={`${classes.da_msg}`}>{Msg} Proceed with this action?</li>
                    </div>

                    <div  className={`${classes.da_inner_card_action_button_wrapper}`}>
                        <button onClick={()=>{openController(false); HandleResponse(CallbackData)}} className={`${classes.agree}`}>Proceed</button>
                        <button onClick={()=>{openController(false); HandleResponse(null)}} className={`${classes.disagree}`}>Dismiss ({TimeLeft})</button>
                    </div>
                </div>

                
            </div>

        </div>
    )
}