import './MaterialToolsMasterDataStyle.css'
import DataTableWrapper from '../../SharedComp/DataTable/DataTableWrapper'

export default function MaterialToolsMasterData() 
{
    return (
        <div className="material-tools-master-data-wrapper">
            
            <div className="main-data-entry-page-wrapper">
            
                <DataTableWrapper
                    WrapperName={'Material Tool [Goro]'}
                    ThemeColor={'#2185d5'}
                    WrapperWidth={'90%'}
                />

                <DataTableWrapper
                    WrapperName={'Material Tool [Sefera]'}
                    ThemeColor={'#2185d5'}
                    WrapperWidth={'90%'}
                />

                <DataTableWrapper
                    WrapperName={'Material Tool [Lomi Meda]'}
                    ThemeColor={'#2185d5'}
                    WrapperWidth={'90%'}
                />

            </div>
            
        </div>
    );
}