import classes from './TransferStock.module.css'


const TransferStock = () =>{

    return (
        <div>
            <h1>Transfer Stock</h1>
        </div>
    )
}

export default TransferStock;