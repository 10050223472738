import { faBarsStaggered, faBoxOpen, faCartShopping, faCheckDouble, faPersonCircleQuestion, faRobot, faTimesCircle, faTrash } from '@fortawesome/free-solid-svg-icons'
import classes from './KitchenSupplyOrder.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import MasterDataRegInput from '../../../SharedComp/CommonStyle/MasterDataRegInput'

import {QueryCKProductKitchenID, FetchWorkspaceProductionCenterOnly} from '../../../SharedComp/API/CK/AP_CenteraKitchen'
import {CreateKitchenSupplyOrder} from '../../../SharedComp/API/CK/AP_CenteraKitchenOrder'

import ProductCard from './Component/ProductCard/ProductCard'
import { useEffect, useState } from 'react'
import { faSquareReddit } from '@fortawesome/free-brands-svg-icons'
import { useAuth } from '../../../AuthProvider'
import { useToast } from '../../../ToastContext'

export default function KitchenSupplyOrder() 
{

    const [OrderCount, SetOrderCount] = useState(0);
    const [ProductList, SetProductList] = useState([]);
    const [FilteredList, SetFilteredList] = useState([]);
    const [ViewCart, SetViewCart] = useState(false);
    const [ProductionCenterList, SetProductionCenterList] = useState([]);
    const [KicthenChoice, SetKicthenChoice] = useState();
    const [ArrivalTime, SetArrivalTime] = useState();

    const [CartItem, SetCartItem] = useState(new Map());
    
    const auth = useAuth();
    const notify = useToast();



    const SelectKitchen = async (_id) =>
    {
        SetKicthenChoice(_id); 
    } 
    const AddItemToCart = async (data, Amount) => {
        SetCartItem(prevCart => {
            const newCart = new Map(prevCart);
            newCart.set(data.CKP_ItemName, { DataPkg: data, OrderCount: Amount });
            SetOrderCount(newCart.size);
            return newCart;
        });
    };
    const RemoveItemFromCart = async (itemName) => {
        SetCartItem(prevCart => {
            const newCart = new Map(prevCart); 
            newCart.delete(itemName);
            SetOrderCount(newCart.size);
            return newCart;
        });
    };
    const VoidCart = async () =>
    {
        SetCartItem(prevCart => {
            return new Map();
        });
        SetOrderCount(0);
    }
    const FetchDataProductDataList = async() =>
    {
        try 
        {
            const res = await QueryCKProductKitchenID(KicthenChoice, 'token');
            SetProductList(res);
            SetFilteredList(res);

        }catch(err) 
        {

        }
    }
    const FilterByName = async (filter) =>
    {
        const _prod = [];
        for(let i = 0; i < ProductList.length; i++) 
        {
            if(ProductList[i].CKP_ItemName.toLowerCase().includes(filter.toLowerCase())) 
            {
                _prod.push(ProductList[i]);
            }
        }

        SetFilteredList(_prod);
    }
    const FetchActiveOrders = async () =>
    {
        
    }
    const FetchProductionCenter = async () =>
    {
        try 
        {
            const res = await FetchWorkspaceProductionCenterOnly('token');
            SetProductionCenterList(res);
            if(res.length > 0) 
            {
                SelectKitchen(res[0].BranchID);
            }
        } catch(err) 
        {

        }
    }

    useEffect(()=>{
        FetchProductionCenter();
    }, [])
    
    useEffect(()=>{

        if(KicthenChoice) 
        {
            VoidCart();
            FetchDataProductDataList();
        }
    }, [KicthenChoice])

    

    const HandleSubmit = async(e) =>
    {
        e.preventDefault();

        notify.ShowLoad("Processing Your request....", 10);

        setTimeout( async()=>{
            try 
            {

                const fd = new FormData();
                fd.append("source_workshop", auth.activeWorkShop);
                fd.append("destination_workshop", KicthenChoice);
                fd.append("order_owner", auth.user.UserAccountID);
                fd.append("arrival_time", ArrivalTime);
                fd.append("order_pkg", JSON.stringify(Array.from(CartItem.entries())));

                const main_res = await CreateKitchenSupplyOrder(fd, 'token');

                notify.ShowSuccessUpdate(main_res.Msg, 10);
                VoidCart();
                SetViewCart(false);

            }catch(err) 
            {
                notify.ShowErrorUpdate(err.message, 10);
            }
        }, 1500);

    }


    return (
        <div className={`${classes.kicthen_supply_order_main_wrapper}`}>

            <div className={`${ViewCart ? classes.cart_view_main_wrapper : classes.cart_view_main_wrapper_hide}`}>
                <div className={`${classes.cart_view_heade_wrapper}`}>
                    <h4>🛒Cart Status <sub>#{OrderCount} {OrderCount > 1 ? 'Items' : 'Item'}</sub></h4>
                    <button onClick={()=>{SetViewCart(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
                </div>

                <div className={`${classes.cart_view_list_wrapper_main}`}>
                    {
                        Array.from(CartItem.entries()).map(([key, value], idx) => (
                            <li>
                                <span className={`${classes.pr_name_cart_orders}`}>{value.DataPkg.CKP_ItemName}</span>
                                <span className={`${classes.count_cart_orders}`}>{value.OrderCount} <sub> X {value.DataPkg.KV_SliceCount}</sub></span>
                                {/* <span>{(value.OrderCount * value.DataPkg.KV_SliceCount)}</span> */}
                                <span className={`${classes.action_button_cart_orders}`}>
                                    <button onClick={()=>{RemoveItemFromCart(value.DataPkg.CKP_ItemName)}}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></button>
                                </span>
                            </li>
                        ))
                    }
                    
                </div>  

                <form onSubmit={HandleSubmit} method="post">

                    <div className={`${classes.cart_action_taker_wrapper}`}>

                        
                        <MasterDataRegInput input={<input onChange={(e)=>{SetArrivalTime(e.target.value)}} type="datetime-local"  required/>} tip={"The [date and time] you want this order to delived to your location."}/>

                    </div>

                    <div className={`${classes.cart_action_taker_wrapper}`}>
                        <button className={`${classes.order_action}`}> <span><FontAwesomeIcon icon={faCheckDouble}></FontAwesomeIcon></span> <span>Lock Order</span></button>
                        <button  type='button' onClick={()=>{VoidCart()}} className={`${classes.void_order_action}`}> <span><FontAwesomeIcon icon={faBoxOpen}></FontAwesomeIcon></span> <span>Void Cart</span></button>
                    </div>

                </form>
            </div>

            <div className={`${classes.kicthen_supply_header_wrapper}`}>

                <div className={`${classes.kicthen_supply_header_inner_action_wrapper}`}>

                    <div className={`${classes.side_action_wrapper}`}>
                        <button><span><FontAwesomeIcon icon={faPersonCircleQuestion}></FontAwesomeIcon></span> <span>Initiate Order</span></button>
                        <button> <span className={`${classes.active_order_pointer}`}>3</span> <span><FontAwesomeIcon icon={faBarsStaggered}/></span> <span>Track Order</span></button>
                        <button><span><FontAwesomeIcon icon={faRobot}></FontAwesomeIcon></span> <span>Automata</span></button>

                        <select onChange={(e)=>{SelectKitchen(e.target.value)}}>
                            {
                                ProductionCenterList.map((item, idx)=>(

                                    <option key={item.BranchName} value={item.BranchID}>{item.BranchName}</option>
                                ))
                            }
                        </select>
               
                    </div>
                    <div className={`${classes.right_side_action_wrapper}`}>
                        <input onChange={(e)=>{FilterByName(e.target.value)}} type="text" placeholder='Search Name' autoComplete='off' />
                        <div className={`${classes.dat_wrapp_cart_action}`}>
                          {(OrderCount > 0 && !ViewCart)  &&   <li> <span className={`${classes.cart_item_counter}`}>{OrderCount}</span></li> }
                            <button onClick={()=>{SetViewCart(!ViewCart)}} className={`${classes.cart_action_button}`}><span><FontAwesomeIcon icon={faCartShopping}/></span></button>

                        </div>
                    </div>
               
                </div>

            </div>

            <div className={`${classes.main_order_prod_card_list_wrapper}`}>
                    <div className={`${classes.main_inner_order_list_wrapper}`}>

                        {
                            FilteredList.map((item, idx)=>(
                                <ProductCard DataCallback={AddItemToCart} Data={item} key={item.CKP_ItemName}/>
                            ))
                        }

                    </div>
            </div>

        </div>
    )
}