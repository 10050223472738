import { formatDistanceToNow } from 'date-fns';



export default function TimeAgo({ date, show_suffix = false }) {
    // Parse the UTC date string directly
  const utcDate = new Date(date);  // ISO string from DB, interpreted as UTC

  return (
    <span>{formatDistanceToNow(utcDate, { addSuffix: show_suffix })}</span>
  );
}