import classes from './StandardUserCreate.module.css'
import data_win from '../../../../SharedComp/CommonStyle/DataCreator.module.css'
import MasterDataRegInput from '../../../../SharedComp/CommonStyle/MasterDataRegInput'
import Select from 'react-select'
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faCircleNotch, faSpinner, faTimesCircle, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react';

import { Custom_DD_Style, PrepareDropDownList } from '../../../../SharedComp/Utility';
import { getBaseURL } from '../../../../SharedComp/BaseUrlConfig';

export default function StandardUserCreate({Close_CALLBACK, MsgTrigger_CALLBACK}) 
{

    const [BranchList, SetBranchList] = useState([]);
    const [EmployeeList, SetEmployeeList] = useState([]);
    const [RoleList, SetRoleList] = useState([]);

    const [Loading, SetLoading] = useState(false);
    const [ValidatingEmail, SetValidatingEmail] = useState(false);
    const [ValidEmail, SetValidEmail] = useState(false);
    
    const [FullName, SetFullName] = useState('');
    const [Username, SetUsername] = useState('');
    const [UserEmail, SetUserEmail] = useState('');
    const [UserKey, SetUserKey] = useState('');
    const [UsrRole, SetUsrRole] = useState(null);
    const [UserBranch, SetBranch] = useState();
    const [WorkSpaceName, SetWorkSpaceName] = useState('');
    const [UsrWorkSpace, SetUsrWorkSpace] = useState(-1);


    function UpdateEmployeeField(data) 
    {
        SetUserEmail(data.Email);
    }
    function HandleEmployeeSelection(data) 
    {
        QueryEmployee(data.value);
        SetFullName(data.label);
        SetUsrWorkSpace(data.value);
    }
    function HandleBranchSelection(data) 
    {
        SetWorkSpaceName(data.label);
        SetBranch(data.value);
        QueryEmployeeList(data.value);
    }

    function GenerateStrongPasswrod(pass_leng) 
    {
        const upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const lowerCase = 'abcdefghijklmnopqrstuvwxyz';
        const numbers = '0123456789';
        const specialChars = '!@#$%^&*()_+~`|}{[]:;?><,./-=';
        const allchars = upperCase + lowerCase + numbers + specialChars;
        const char_count = allchars.length;

        let pass = '';
        for(let  i = 0; i < pass_leng; i++)
            pass += (allchars[Math.floor(Math.random() * char_count)]);

        SetUserKey(pass);
    }
    useEffect(()=>{

        // get roles
        fetch(getBaseURL() + 'master-data/query_usr_roles', {
            method: 'POST'
        })
        .then(res => res.json())
        .then((data)=>{PrepareDropDownList(data, SetRoleList)})
        .catch(err => console.log(err));

        // get branch
        fetch(getBaseURL() +  'master-data/get-company-branch')
            .then(res => res.json())
            .then((data) => PrepareDropDownList(data, SetBranchList));

    }, [])

    function QueryEmployeeList(WorkspaceID) 
    {
        fetch(getBaseURL() + 'employee/query_employee/', { 
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ BranchID: WorkspaceID }),
          })
            .then(res => res.json())
            .then((data) => PrepareDropDownList(data, SetEmployeeList));
    }
    function QueryEmployee(EmpID) 
    {
        fetch(getBaseURL() + 'employee/query_employee_id/', { 
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ EmpID: EmpID }),
          })
            .then(res => res.json())
            .then((data) => {
                UpdateEmployeeField(data[0]);
            });
    }


    function ValidateEmail() 
    {
        RegisterUser(true);
        return;
        
        SetLoading(true);
        SetValidatingEmail(true);

        const url = `https://emailvalidation.abstractapi.com/v1/?api_key=08dab80749b74fc4b905d48561cd0000&email=${UserEmail}`;
        axios.get(url).then(data => {
            if(data.data.deliverability === 'DELIVERABLE') 
            {
                SetValidEmail(true);
                console.log("Valid")
            }
            SetValidEmail(data.data.deliverability === 'DELIVERABLE');
            SetValidatingEmail(false);
            RegisterUser(data.data.deliverability === 'DELIVERABLE');
        }).catch(error => {
            console.log(error);
            SetValidEmail(false);
            SetLoading(false);
            SetValidatingEmail(false);
        })
    }

    function RegisterUser(valid_email) 
    {
        if(!valid_email) 
        { 
            SetLoading(false); 
            return; 
        }
        console.log(FullName);
        const names = FullName.split(" ");
        const fd = new FormData();
        fd.append("UserFirstName", names[0]);
        fd.append("UserLastName", names[1]);
        fd.append("Username", Username);
        fd.append("UserEmail", UserEmail);
        fd.append("UserKey", UserKey);
        fd.append("SourceWorkSpace", UserBranch);
        fd.append("UsrRole", JSON.stringify(UsrRole));
        fd.append("UsrType", "Standard");
        fd.append("SourceWorkSpaceName", WorkSpaceName);
        fd.append("UsrWorkSpace", UsrWorkSpace);

        axios.post(getBaseURL() + `reg_usr`, fd)
        .then(data => MsgTrigger_CALLBACK(data))
        .catch(err => console.log(err)); 
    }


    function HandleSubmition(e) 
    {
        e.preventDefault();
        ValidateEmail();
    }

    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>


        <div className={`${data_win.data_main}`}>

            <div className={`${data_win.data_main_header}`}>
                <h3>
                    JAK <span className={`${data_win.data_create_name}`}>Create Standard User</span>
                </h3>
                <button onClick={()=>{Close_CALLBACK(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
            </div>

            <div className={`${classes.data_body}`}>
                
                <div className={`${classes.inner_body_wrapper}`}>
                    <form onSubmit={HandleSubmition} action="POST">

                        <div className={`${classes.form_wrapper}`}>


                        <div className={`${classes.input_wrap}`}>
                            <MasterDataRegInput 
                                input={
                                    <Select
                                        onChange={(data)=>{HandleBranchSelection(data)}}
                                        name={"dd_data"}
                                        options={BranchList}
                                        placeholder={"Wrokspace ..."}
                                        isSearchable={true}
                                        styles={Custom_DD_Style}
                                        required ={true}
                                    />
                                    
                                } forceInput={true} />
                        </div>
                        <div className={`${classes.input_wrap}`}>
                            <MasterDataRegInput 
                                input={
                                    <Select
                                        onChange={(data)=>{HandleEmployeeSelection(data)}}
                                        name={"dd_data"}
                                        options={EmployeeList}
                                        placeholder={"Employee ..."}
                                        isSearchable={true}
                                        styles={Custom_DD_Style}
                                        required ={true}
                                    />
                                    
                                } forceInput={true} />
                        </div>
                            

                           
                            <div className={`${classes.input_wrap}`}>
                                <MasterDataRegInput 
                                    input={
                                        <input 
                                            onChange={(e)=>{SetUsername(e.target.value)}} 
                                            name='user_name' 
                                            type="text" 
                                            placeholder='User Name' 
                                            title='' 
                                            autoComplete='off' 
                                            required />} 
                                            forceInput={true}
                                    />
                            </div>

                            <div className={`${classes.input_wrap_email}`}>
                                <MasterDataRegInput 
                                    input={
                                        <input 
                                            onChange={(e)=>{SetUserEmail(e.target.value)}} 
                                            value={UserEmail}
                                            name='user_email' 
                                            type="email" 
                                            placeholder='Email' 
                                            title='' 
                                            autoComplete='off' 
                                            required />} 
                                            forceInput={true}
                                        />
                                    <div className={`${classes.email_validation_wrpper}`}>
                                        <span className={`${ValidatingEmail ?  classes.email_loading : ValidEmail ? classes.good_email : classes.bad_email}`}>

                                            <FontAwesomeIcon 
                                                icon={ValidatingEmail ? faCircleNotch : ValidEmail ? faCheckCircle : faTriangleExclamation} 
                                                spin={ValidatingEmail}>
                                                
                                            </FontAwesomeIcon>
                                        
                                        </span>
                                    </div>
                            </div>

                            <div className={`${classes.input_wrap} ${classes.input_wrap_pass}`}>
                                <MasterDataRegInput 
                                    input={
                                        <input 
                                            onChange={(e)=>{SetUserKey(e.target.value)}} 
                                            value={UserKey}
                                            name='user_key' 
                                            type="text" 
                                            placeholder='Password' 
                                            title='' 
                                            autoComplete='off' 
                                            required />} 
                                            forceInput={true}
                                        />
                                <button type='button' onClick={(e)=>{GenerateStrongPasswrod(10)}}>Generate</button>
                            </div>

                            <div className={`${classes.input_wrap}`}>
                                <MasterDataRegInput 
                                    input={
                                        <Select
                                            onChange={(data)=>{SetUsrRole(data)}}
                                            name={"dd_data"}
                                            options={RoleList}
                                            placeholder={"Role ..."}
                                            isSearchable={true}
                                            styles={Custom_DD_Style}
                                            required ={true}
                                        />
                                        
                                    } forceInput={true} />
                            </div>

                        </div>

                        <div className={`${data_win.form_action_button_wrapper}`}>
                            <button disabled={Loading} className={`${data_win.form_action_button_save}`}>{Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Add'}</button>
                            <button disabled={Loading}  onClick={()=>{Close_CALLBACK(false)}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                        </div>
                        </form>
                </div>

            </div>

        </div>

    </div>
    )
}