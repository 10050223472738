import classes from './PaperHeader.module.css'
import JakLogo from '../../asset/images/favicon.png'

export default function PaperHeader() 
{
    return (
        <div className={`${classes.paper_header_default}`}>
            <div className={`${classes.page_header}`}>
                <div>
                    <img className={`${classes.yam_logo}`} src={JakLogo} alt="" />
                </div>
                <div className={`${classes.yam_logo_name}`} >
                    JAK AND SONS TRADING PLC <span className={`${classes.yam_group_name}`}>Yam bakery group</span>
                </div>
                <div>
                    <img className={`${classes.yam_logo}`} src="https://yambakery.com/wp-content/uploads/elementor/thumbs/logo-wight-q7unzmwoied00tm97ojjtfi919h39u39q7u92mf1rq.png" alt="" />
                </div>
                <div className={`${classes.exclusive_tag}`}>
                    exclusive
                </div>
            </div>
        </div>
    )
}