import classes from './NonEdibleGoods.module.css'
import DataTableWrapper from '../../../SharedComp/DataTable/DataTableWrapper'
import { useState } from 'react';

import NotificationPopUp from '../../../SharedComp/NotificationPopUp/NotificationPopUp';
import NonEdibleGoodTypeData from './Component/NonEdibleGoodTypeData/NonEdibleGoodTypeData';
import NonEdibleGoodsData from './Component/NonEdibleGoodsData/NonEdibleGoodsData';

export default function NonEdibleGoods() 
{

    const [OpenNonEdibleGoodCreator, SetOpenNonEdibleGoodCreator] = useState(false);
    const [OpenNonEdibleGoodDataCreator, SetOpenNonEdibleGoodDataCreator] = useState(false);
    const [openNoti, SetOpenNoti] = useState(false);

    const [MsgPkg, SetMsgPkg] = useState({});


    function HandleEdibleWinOpne(win_name) 
    {
        SetOpenNonEdibleGoodCreator(true);
    }
    function HandleEdibleDataWinOpen(win_name) 
    {
        SetOpenNonEdibleGoodDataCreator(true);
    }

    function MsgTrigger(res)  
    {
        SetMsgPkg({
            Key: Math.random() * 1000,
            Msg: res.data.Msg,
            State:  res.data.MyStateCode
        })
        SetOpenNoti(true);
    }


    return (
        <div className={`${classes.non_edible_main_wrapper}`}>
             {openNoti && <NotificationPopUp key={MsgPkg.Key} msg={MsgPkg.Msg} state={MsgPkg.State}/>}

                {OpenNonEdibleGoodCreator && <NonEdibleGoodTypeData MsgTrigger={MsgTrigger} OpenController={SetOpenNonEdibleGoodCreator} />}
                {OpenNonEdibleGoodDataCreator && <NonEdibleGoodsData MsgTrigger={MsgTrigger} OpenController={SetOpenNonEdibleGoodDataCreator} />}
                

            <div className={`${classes.data_table_wrapper}`}>
                <DataTableWrapper
                    WrapperName={'Inventory Non-Edible  Type'}
                    WinName={'Inventory Type'}
                    ThemeColor={'var(--background-light)'}
                    WrapperWidth={'90%'}
                    RouteAddress={'http://localhost:3333/master-data/get-iv-type'}
                    Filter={"No"}
                    NewEntryFlag={OpenNonEdibleGoodCreator}
                    RegisterCallback={HandleEdibleWinOpne}
                />
            </div>

            <div className={`${classes.data_table_wrapper}`}>

                <DataTableWrapper
                    WrapperName={'Non-Edible Inventory Goods'}
                    WinName={'Inventory Item'}
                    ThemeColor={'var(--background-light)'}
                    WrapperWidth={'90%'}
                    RouteAddress={'http://localhost:3333/master-data/get-iv-item'}
                    Filter={"No"}
                    NewEntryFlag={OpenNonEdibleGoodDataCreator}
                    RegisterCallback={HandleEdibleDataWinOpen}
                />

            </div>

            


        </div>
    )
}