import classes from './BranchCreator.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import axios from 'axios';
import { Custom_DD_Style } from '../../../../SharedComp/Utility'
import MasterDataRegInput from '../../../../SharedComp/CommonStyle/MasterDataRegInput';
import data_win from '../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useRef } from 'react';
import Select from 'react-select'
import { getBaseURL } from '../../../../SharedComp/BaseUrlConfig';

export default function BranchCreator({Close_CALLBACK, RES_CALLBACK}) 
{

    const [registring, Setregistring] = useState(false);
    const formRef = useRef();

    const [SiteName, SetSiteName] = useState();
    const [SiteType, SetSiteType] = useState();
    const [SiteAddress, SetSiteAddress] = useState();


    const expenseReasonOptions = [
        { value: 'Production Center', label: 'Production Center' },
        { value: 'Outlet', label: 'Outlet' },
        { value: 'Office', label: 'Office' },
        { value: 'Storage Unit', label: 'Storage Unit' },
      ];


    function HandleSubmition(e) 
    {
        e.preventDefault();

        const fd = new FormData();
        fd.append("SiteName", SiteName);
        fd.append("SiteType", SiteType);
        fd.append("SiteAddress", SiteAddress);

        axios.post(getBaseURL() + 'master-data/add-company-branch', fd)
        .then(res => { RES_CALLBACK(res)})
        .catch(err => {
            console.log(err);
           // RES_CALLBACK(res);
        })
    }
    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>


            <div className={`${data_win.data_main}`}>

                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        JAK <span className={`${data_win.data_create_name}`}>Establishment X</span>
                    </h3>
                    <button onClick={()=>{Close_CALLBACK(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
                </div>

                <div className={`${classes.data_body}`}>

                        <form onSubmit={HandleSubmition} ref={formRef} action="POST">
                                <div className={`${classes.form_wrapper}`}> 
                                        <div  className={`${classes.input_list_wrapper}`}>
                                            
                                            <div className={`${classes.input_wrap}`}>
                                                <MasterDataRegInput input={<input onChange={(e)=>{SetSiteName(e.target.value)}} name='site_name' type="text" placeholder='Site Name' title='' autoComplete='off' required />} tip={"Just Name, No More! No Less!"}/>
                                            </div>
                                            <div className={`${classes.input_wrap}`}>
                                                <MasterDataRegInput input={<input onChange={(e)=>{SetSiteAddress(e.target.value)}} name='site_address' type="text" placeholder='Address' title='' autoComplete='off' required />} tip={"Location, Where is it?"}/>
                                            </div>
                                            

                                            <div className={`${classes.input_wrap}`}>
                                            <MasterDataRegInput 
                                                input={
                                                    <Select
                                                        onChange={(data)=>{SetSiteType(data.value)}}
                                                        name={"dd_data"}
                                                        options={expenseReasonOptions}
                                                        placeholder={"Type..."}
                                                        isSearchable={true}
                                                        styles={Custom_DD_Style}
                                                        required ={true}
                                                    />
                                                
                                            } tip={"Select Establishment Type"}  forceInput={true}/>
                                        </div>

                                            
                                            

                                            

                                        </div>
                                </div>
                                <div className={`${data_win.form_action_button_wrapper}`}>
                                    <button disabled={registring} className={`${data_win.form_action_button_save}`}>{registring ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Add'}</button>
                                    <button disabled={registring}  onClick={()=>{Close_CALLBACK(false)}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                                </div>
                            </form>

                </div>

            </div>

        </div>
    );
}