import classes from './EmployeeManager.module.css'
import EmployeeBundleHandler from './Component/EmployeeBundleHandler/EmployeeBudleHandler';
import CreateEmployee from '../../SharedComp/CreateEmployee/CreateEmployee';
import EmployeeView from './Component/View/EmployeeView';
import { useEffect, useState } from 'react';
import axios from 'axios';
import NotificationPopUp from '../../SharedComp/NotificationPopUp/NotificationPopUp';
import { getBaseURL } from '../../SharedComp/BaseUrlConfig';
import { useAuth } from '../../AuthProvider';

export default function EmployeeManager({WorkshopeAccessList}) 
{
    const [EmpCreator, SetEmpCreator] = useState(false);
    const [EmpView, SetEmpView] = useState(false);
    const [Refresh, SetRefresh] = useState(false);
    const [EmpRegNoti, SetEmpRegNoti] = useState(false);

    const [CompanyBranchs, SetCompanyBranchs] = useState([]);
    const [BranchRefreshFlag, SetBranchRefreshFlag] = useState([]);

    const [selectedBranch, SetSelectedBranch] = useState();
    const [SelectedEmployeeData, SetSelectedEmployeeData] = useState();

    const auth  = useAuth();

    const [MsgPkg, setMsgPkg] = useState
    ({
        Msg: "---",
        State: 0,
        TimeOut: 10,
        Key: 1
    });

    function HandleEmployeeOpenCreator(id) 
    {
        SetSelectedBranch(id);
        SetEmpCreator(true);
    }

    function HandleEmpViewController(shouldOpen, data) 
    {
        SetEmpView(shouldOpen);
        SetSelectedEmployeeData(data);
    }

    function HandleNoteficationDone(msg, state, c_key, duration = 10) 
    {
        SetEmpCreator(false);
        setMsgPkg({
            Msg: msg,
            State: state,
            TimeOut: duration,
            Key: c_key
        });
        SetEmpRegNoti(true);
    }
    function HandleNoteficationAlmost(msg, state, c_key, duration = 10) 
    {
        setMsgPkg({
            Msg: msg,
            State: state,
            TimeOut: duration,
            Key: c_key
        });
        SetEmpRegNoti(true);
    }

    function HandleMasterRefresh(close_view = false) 
    {
        if(close_view) 
        {
            SetEmpView(false);
        }

        SetRefresh(!Refresh);
    }
    useEffect(() => 
    {
        fetch(getBaseURL() + 'master-data/get-company-branch')
            .then(res => res.json())
            .then((data) => {SetCompanyBranchs(data); SetBranchRefreshFlag(new Array(data.length).fill(false))});
    }, [Refresh])

    function CreateFakeEmployee_DEBUG(branch_id) 
    {
                const fd = new FormData();
                fd.append("branch_id", branch_id);
                axios.post(getBaseURL() + 'employee/create_fake_user/', fd, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    })
                .then(res => {
                    // window.location.reload();
                    if(res.data.MyStateCode === 1) 
                    {
                        HandleNoteficationDone(res.data.Msg, res.data.MyStateCode, Math.random() * 1000);
                        HandleMasterRefresh(true);
                    } else //if(res.data.MyState === -1 || res.data.MyState === 0) 
                    {
                        HandleNoteficationDone(res.data.Msg, res.data.MyStateCode, Math.random() * 1000);
                    } 
                    
                })
                .catch(err => {
                    if(err.data === undefined) 
                    {
                        HandleNoteficationDone("Internal Server Error!", -1, Math.random() * 1000);
                    } else 
                    {
                        HandleNoteficationDone(err.data.Msg, err.data.MyStateCode, Math.random() * 1000);
                    }
                })
    }


    return (
        <div className={`${classes.Employee_manager_main_wrapper}`}>
        {EmpRegNoti && <NotificationPopUp key={MsgPkg.Key} msg={MsgPkg.Msg} state={MsgPkg.State}/>}
        <CreateEmployee CKey={Math.random() * 1000} branchID={selectedBranch} Open={EmpCreator} OpenController={SetEmpCreator} HandleNoteficationComplete={HandleNoteficationDone} HandleNoteficationNotQuit={HandleNoteficationAlmost}/>
       {EmpView && <EmployeeView Open={EmpView} EmpID={SelectedEmployeeData.EmployeeID} EmpViewController={SetEmpView} ParentNotification={HandleNoteficationDone} HandleMasterRefresh={HandleMasterRefresh}/>}

        <div className={`${classes.employee_bundle_wrapper}`}>
                {CompanyBranchs.map((item, index) => (
                    auth.userAccessWorkspaceIDList.includes(item.BranchID) && 
                    <EmployeeBundleHandler
                        key={index} 
                        BranchData={item} 
                        EmployeeCreatorWinController={HandleEmployeeOpenCreator} 
                        EmpViewController={HandleEmpViewController}
                        CreateFakeEmployee_DEBUG={CreateFakeEmployee_DEBUG}
                        Refresh={Refresh}
                      />
                ))}
        </div>

        

        </div>
    );
}