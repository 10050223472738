const LOCAL_DEV_URL = 'http://localhost:3333/';
const PROD_URL = process.env.REACT_APP_API_URL; 
 
function getBaseURL() {
    if (process.env.REACT_APP_ENV === 'production') {
        return PROD_URL; 
    }
    return LOCAL_DEV_URL; 
}

export { LOCAL_DEV_URL, PROD_URL, getBaseURL };
