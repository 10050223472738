import React, { useEffect } from "react";
import { createContext, useContext } from "react";

import jak_logo from './asset/images/favicon.png'
import jak_not from './asset/images/notifi_view.png'

const GlobalNotificationContext = createContext();

export const useGlobalNotification = () => useContext(GlobalNotificationContext);


export const GlobalNotificationProvider = ({children}) => 
{

    function requestNotificationPermission() {
        if (Notification.permission === 'granted') {
            // Permission already granted
            return;
        }
    
        if (Notification.permission !== 'denied') {
            Notification.requestPermission().then(permission => {
                if (permission === 'granted') {
                    console.log('Notification permission granted.');
                } else {
                    console.log('Notification permission denied.');
                }
            });
        }
    }

    function Disturb(title_, body_) {

        const title = title_;
        const options = {
            body: body_,
            icon: jak_logo,
            badge: jak_logo,
            image: jak_not,
            timestamp: Date.now(),
            vibrate: [200, 100, 200],
        };

        if (!('Notification' in window)) {
            console.log('This browser does not support notifications.');
            return;
        }
      
        if (Notification.permission === 'granted') {
            console.log("User annoyed successfully");
            new Notification(title, options);
        } else {
            console.log('Notification permission not granted.');
        }
    }

    useEffect(()=>{
        requestNotificationPermission();
    }, []);

    return (
        <GlobalNotificationContext.Provider value={{
            Disturb
        }}>
            {children}
        </GlobalNotificationContext.Provider>
    )
}