import classes from './CreateCKProductType.module.css'
import data_win from './../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { useEffect, useRef, useState } from 'react';

import {CreateCKProductType_API_EP} from '../../../../../SharedComp/API/CK/AP_CenteraKitchen'

import MasterDataRegInput from '../../../../../SharedComp/CommonStyle/MasterDataRegInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import WorkspaceAccessList from '../../../../../SharedComp/WorkspaceAccessList/WorkspaceAccessList';
import { useToast } from '../../../../../ToastContext';
import { useAuth } from '../../../../../AuthProvider';

export default function CreateCKProductType({OpenController}) 
{

    const formRef = useRef();
    const [registring, SetRegistring] = useState(false);

    const [WorkspaceList, SetWorkspaceList] = useState(new Map()); 
    const notify = useToast();
    const auth = useAuth();

    function CloseModal() 
    {
        formRef.current.reset();
        OpenController(false);
    }

    const WorkspaceDataUpdate = async(data)=>
    {
        SetWorkspaceList(data)
    }

    const HandleSubmition = async(e)=> 
    {
        e.preventDefault();

        SetRegistring(true);
        notify.ShowLoad("Processing Your Request...", 10);

        try 
        {
            const fd = new FormData();

            fd.append("ck_cat_name", e.target.ck_prod_type.value);
            fd.append("ck_cat_remark", e.target.ck_prod_cat_remark.value);
            fd.append("ck_cat_access_list",  JSON.stringify(Object.fromEntries(WorkspaceList)))
    
            const res = await CreateCKProductType_API_EP(fd, auth.token);

            notify.ShowSuccessUpdate(res.Msg, 10);
            SetRegistring(false);
            CloseModal();
            
        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
            SetRegistring(false);
        }
    }

    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

        <div className={`${data_win.data_main}`}>
            <div className={`${data_win.data_main_header}`}>
                <h3>
                    Registring <span className={`${data_win.data_create_name}`}>CK Category</span>
                </h3>
                <button onClick={()=>{CloseModal()}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

            <div className={`${data_win.data_body}`}>
                    
            

                        <form onSubmit={HandleSubmition} ref={formRef} action="POST">
                            <div className={`${classes.form_wrapper}`}> 
                                    <div  className={`${classes.input_list_wrapper}`}>
                                        <MasterDataRegInput input={<input name='ck_prod_type' type="text" placeholder='CK Cat Name' title='' autoComplete='off' required />} />
                                        <MasterDataRegInput input={<input name='ck_prod_cat_remark' type="text" placeholder='Remark' title='' autoComplete='off' />} />
                                    </div>
                                    <div  className={`${classes.input_list_wrapper}`}>

                                    <WorkspaceAccessList DataCallback={WorkspaceDataUpdate}/>

                                    </div>
                                    
                            </div>
                            <div className={`${data_win.form_action_button_wrapper}`}>
                                <button disabled={registring} className={`${data_win.form_action_button_save}`}>{registring ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Add'}</button>
                                <button disabled={registring}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                            </div>
                        </form>




            </div>
        </div>

    </div>
    )
}