import classes from './CircleMiniGraph.module.css'


export default function CircleMiniGraph({value = "1", Text="Remain", Percentage = 20, rad = 2.0, Color = 'var(--grren-color)', Tickness = 7}) 
{
    return (
        <div className={`${classes.circle_mini_graph_main_wrapper}`}>
            <div className={`${classes.emp_graph_used}`} style={
                {
                    width: `${rad * 100}px`,
                    height: `${rad * 100}px`,
                    background: `conic-gradient(${Color} 0 ${Percentage}%, var(--background-dark) 0 0)`,
                    transition: `0.3s ease-in-out`
                }
            } >
                <div style={{
                    width: `${rad * (100 - Tickness)}px`,
                    height: `${rad * (100 - Tickness)}px`,
                    content: '',
                    position: 'absolute',
                    borderRadius: `${50}%`,
                    background: `var(--background-dark_plus)`,
                    border: `2px solid var(--background-dark_plus)`,
                    opacity: `0.93`
                }} />
                    <div  className={`${classes.emp_graph_used_val}`} style={
                        {
                            width: `${rad * 100}px`,
                            height: `${rad * 100}px`,
                        }
                    }>
                            <h1 style={{
                                color: `${Color}`
                            }}>
                            <span className={`${classes.emp_val_count_value_count}`}> {value} <sub className={`${classes.emp_val_count_value_perc}`}>{Percentage}%</sub></span>
                             <br />
                            <span className={`${classes.emp_val_count_value}`}> {Text}</span>
                            </h1>
                        <div className={`${classes.emp_graph_used_puls}`} style={
                            {
                                width: `${rad * 100}px`,
                                height: `${rad * 100}px`,
                                content: '',
                                zIndex: '-1',
                                position: 'absolute',
                                borderRadius: `${50}%`,
                                background: `${Color}`,
                                border: `4px solid ${Color}`,
                                transformOrigin: `center`,
                                transform: `scale(1)`,
                                transition: `0.3s ease-in-out`,
                                                        }
                        }>
                        </div>
                    </div>
            </div> 
</div>
    )
}