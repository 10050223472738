import classes from './PittyCashBranch.module.css'
import Chart from 'react-apexcharts'

import ReleaseCash from './Component/ReleaseCash/ReleaseCash';
import ReceiptOfFunds from './Component/ReceiptOfFunds/ReceiptOfFunds';

import { useEffect, useState } from 'react';
import NotificationPopUp from '../../../SharedComp/NotificationPopUp/NotificationPopUp';
import { getBaseURL } from '../../../SharedComp/BaseUrlConfig';
import { useAuth } from '../../../AuthProvider';
import DotsGif from '../../../SharedComp/CssGif/DotsGif';

export default function PittyCashBranchLevel() 
{

    const auth = useAuth();
    const [OpenReleaseCash, SetOpenReleaseCash] = useState(false);
    const [OpenReceiptFunds, SetOpenReceiptFunds] = useState(false);
    const [loadingExpense, setLoadingExpense] = useState(false);

    // Notifiction
    const [MsgPkg, SetMsgPkg] = useState({});
    const [OpenMyNoti, SetOpenMyNoti] = useState(false);
    const [RefeshList, SetRefeshList] = useState(false);
    

    // Expense List
    const [ExpenseList, SetExpenseList] = useState([]);
    const [GrandTotalExpense, SetGrandTotalExpense] = useState(0);

    // Deposit List
    const [DepositList, SetDepositList] = useState([]);

    // Active open book
    const [ActiveBook, SetActiveBook] = useState(null);
    const [UtilizedAmount, SetUtilizedAmount] = useState(0.01);
    const [AvailableAmount, SetAvailableAmount] = useState(0.1);


    function MsgCallback(res) 
    {
        SetOpenReleaseCash(false);
        SetOpenReceiptFunds(false);
        LocalMsgTrigger(res.data.Msg, res.data.MyStateCode);
    }
    function LocalMsgTrigger(msg, stat) 
    {
        if(stat === 1) 
        {
            SetRefeshList(!RefeshList);
        }
        SetMsgPkg({Key : Math.random() * 10000, Msg: msg, State: stat})
        SetOpenMyNoti(true);
    }
    function ComputeGrandTotal(data) 
    {
        let _grand_amount= 0;
        for(let i  = 0; i < data.length; i++)
            _grand_amount += data[i].Amount;
        SetGrandTotalExpense(_grand_amount);
    }

    function QueryExpenseList() 
    {
        fetch(getBaseURL() + 'operation-data/query-pitty-cash-expense-book-open-id', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({BranchID: auth.activeWorkShop})
        }).then(res => res.json()).then((data)=>{
            SetExpenseList(data);
            ComputeGrandTotal(data);
        }).catch(err => console.log(err));
    }

    function QueryDepositList() 
    {
        fetch(getBaseURL() + 'operation-data/query-deposit-pitty-cash', { 
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({BranchID: auth.activeWorkShop})
        }).then(res => res.json()).then((data)=>{
            SetDepositList(data);
        }).catch(err => console.log(err));
    }

    function setDefaultChartValue() 
    {
        SetUtilizedAmount(0.0);
        SetAvailableAmount(0.1);
    }

    function RegisterActiveBookFG() 
    {
        if(DepositList.length == 0) 
        {
            setDefaultChartValue();
        }
        for(let i = 0; i < DepositList.length; i++) 
        {
            if(DepositList[i].IsOpen) 
            {
                SetActiveBook(DepositList[i]);
                break;
            }
        }
    }

    useEffect(()=>{
        RegisterActiveBookFG();
    }, [DepositList])
    useEffect(()=>{
        
        if(ActiveBook) 
        {
            SetUtilizedAmount(parseFloat((ActiveBook.InitialAmount + ActiveBook.TransferredIn) - ActiveBook.RemainAmount));
            SetAvailableAmount(parseFloat(ActiveBook.RemainAmount));
        }
        
    }, [ActiveBook, auth.activeWorkShop])
    useEffect(()=>{

        QueryExpenseList();
        QueryDepositList();
        
        
    }, [RefeshList, auth.activeWorkShop])


    const fetchSpecificExpenseList = async (id) => 
    {
        try{


            const reponse = await fetch(getBaseURL() + 'operation-data/query-pitty-cash-expense-book-id', 
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                body: JSON.stringify({BranchID: auth.activeWorkShop, BookID: id})});

            const res = await reponse.json();
            SetExpenseList(res);
            ComputeGrandTotal(res);
            setLoadingExpense(false);

        }catch(err) 
        {
            throw new Error("Unbale to fetch expense!");
        }
    }

    const QuerySpecList = async (id) => {
        setLoadingExpense(true);
        setTimeout(() => {
            fetchSpecificExpenseList(id);
        }, 1000);
    }


     const PieChartData = [AvailableAmount, UtilizedAmount];

        const chart_option = {
        
            labels: ['Available Budget', 'Utilized Budget'],
            chart: {
                
                type: 'pie',
            //   background: 'var(--background-dark_plus)', // Set the background color
                },
            
              dataLabels: {
                enabled: true, // Hide data labels inside the graph
                formatter: function (val, opts) {
                    return opts.w.config.series[opts.seriesIndex].toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'ETB',
                      });
                }
              },
              tooltip: {
                y: {
                  formatter: function (val) {
                    return val; // Display actual values in tooltip
                  }
                }
            },
              stroke: {
                show: true,
                width: 5, // Stroke width
                colors: ['var(--background-dark_minus)'] // Stroke color (black in this example)
              },
              tooltip: {
                theme: 'dark', // Set the theme to dark
              },
              colors: ['#2fba60', '#cc0000'], // Set the text color
          };
    return (
        <div className={`${classes.pitty_cash_branch_level_master_wrapper}`}>

            <div className={`${classes.pitty_cash_branch_level_inner_wrapper}`}>

                {OpenMyNoti && <NotificationPopUp key={MsgPkg.Key} msg={MsgPkg.Msg} state={MsgPkg.State} />}
                {OpenReleaseCash && <ReleaseCash Close_CALLBACK={SetOpenReleaseCash} Msg_Callback={MsgCallback} />}
                {OpenReceiptFunds && <ReceiptOfFunds Close_CALLBACK={SetOpenReceiptFunds} Msg_Callback={MsgCallback} />}

                <div className={`${classes.pitty_cash_bl_inner_wrapper}`}>

                        <div className={`${classes.pitty_cash_bl_allocation_View_wrapper}`}>
                        <div className={`${classes.pity_cash_chart_wrapper}`}>
                                <div className={`${classes.pitty_cash_ut_header}`}>
                                    <h2>Branch Level Pitty Cash Utilization</h2>
                                </div>
                                <Chart
                                    options={chart_option}
                                    series={PieChartData}
                                    type="pie"
                                    height={200}
                                    />
                        </div>
                        </div>

                        <div className={`${classes.pitty_cash_transaction_main_wrapper}`}>
                            <div className={`${classes.out_transaction_wrapper}`}>
                                <div className={`${classes.out_header}`}>
                                    <h2>Expenditure</h2>
                                </div>
                                <div className={`${classes.out_transaction_list_wrapper}`}>

                                    {!loadingExpense ? <div className={`${classes.out_transaction_list_inner_wrapper}`}>
                                        {
                                            ExpenseList.map((item, index)=>(
                                                <li key={index}>
                                                    <span className={`${classes.pit_date}`}>{new Date(item.Date).toDateString()}</span>
                                                    <span className={`${classes.pit_cat}`}>{item.Cat}</span>
                                                    <span className={`${classes.pit_amount}`}>-
                                                        {Intl.NumberFormat('en', {currency: 'ETB', style: 'currency'}).format(item.Amount)}
                                                        </span>

                                                    {/* <span className={`${classes.pit_action}`}>Undo</span> WITH PERMISSION / TO-DO */}
                                                </li>
                                            ))
                                        }
                                        { ExpenseList.length > 1 &&
                                            <li className={`${classes.grand_total_petty_cash_li}`}>
                                                <span className={`${classes.pit_cat_grand_total_tag}`}>Grand Total Expense</span>
                                                <span className={`${classes.pit_cat_grand_total_num}`}>{Intl.NumberFormat('en', {currency: 'ETB', style: 'currency'}).format(GrandTotalExpense)}</span>
                                            </li>
                                        }
                                        {
                                            ExpenseList.length === 0 && <div className={`${classes.out_transaction_list_empty_wrapper}`}>
                                                    No petty cash released yet!
                                            </div>
                                        }

                                    </div> :

                                   <div className={`${classes.out_transaction_list_loading_wrapper}`}>
                                     <DotsGif/>
                                   </div>
                                     
                                     }
                                    <div className={`${classes.deposit_action_button_wrapper}`}>
                                        <button onClick={(e)=>{SetOpenReleaseCash(true)}} className={`${classes.budget_release}`}>Release Cash</button>
                                    </div>
                                </div>
                            </div>


                            <div className={`${classes.out_transaction_wrapper}`}>
                                <div className={`${classes.out_header}`}>
                                    <h2>Receipt of funds</h2>
                                </div>
                                <div className={`${classes.out_transaction_list_wrapper}`}>

                                    <div className={`${classes.out_transaction_list_inner_wrapper}`}>
                                        {
                                            DepositList.map((item, idx)=>(
                                                <li key={idx} onClick={(e)=>{QuerySpecList(item.PCDID)}}>
                                                    <span className={`${classes.pit_date}`}>{new Date(item.Date).toDateString()}</span>
                                                {!item.IsOpen && <span className={`${classes.closed_deposit_tag}`}>Closed Budget</span>}
                                                    <span className={`${classes.pit_cat}`}> {item.IsOpen ? 'On Hand' : 'Allocation'}  </span>
                                                    <span className={`${item.IsOpen ? classes.pit_amount_in : classes.pit_amount_in_closed}`}>+
                                                    {Intl.NumberFormat('en', {currency: 'ETB', style: 'currency'}).format(item.IsOpen ? item.RemainAmount : item.InitialAmount)}
                                                    </span>
                                                </li>
                                            ))
                                        }
                                        

                                    </div>

                                    <div className={`${classes.deposit_action_button_wrapper}`}>
                                        <button onClick={(e)=>{SetOpenReceiptFunds(true)}} className={`${classes.budget_alloc}`}>Allocate Budget</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>

            </div>
        </div>
    )
}