import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import classes from './UnknownRoute.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function UnknownRoute()
{
    return (
        <div className={`${classes.unknown_route}`}>
            <div className={`${classes.show_case}`}>
                
                <div className={`${classes.data_wrapper}`}>
                    <div className={`${classes.error_code}`}>
                        <h1>404!</h1>
                    </div>
                    <div className={`${classes.inner_wrap}`}>
                        <div className={`${classes.show_case_icon}`}>
                            <FontAwesomeIcon icon={faTriangleExclamation}></FontAwesomeIcon>
                        </div>
                        <div className={`${classes.show_case_text}`}>
                            <div>
                                <span>Hmmm... I'm Sorry Where Is It You Want To Again?</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}