import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import classes from './RegisterVenderCard.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MasterDataRegInput from '../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import data_win from '../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { useState } from 'react'
import axios from 'axios';

export default function RegisterVenderCard({CloseModal_CALLBACK, MsgTrigger_CALLBACK}) 
{

    const[registring, SetRegistring] = useState(false);

    const[VName, SetVname] = useState();
    const[Vtin, SetVtin] = useState();


    function HandleRegistry(e) 
    {
        e.preventDefault();
        SetRegistring(true);

        const fd = new FormData();

        fd.append("vender_name", VName);
        fd.append("vender_tin", Vtin);

        const url = 'http://localhost:3333/operation-data/add-vendor';

        axios.post(url, fd, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then(res => {
            SetRegistring(false);
            MsgTrigger_CALLBACK(res);
        })
    }

    return (
        <div className={`${classes.register_vender_card_main_wrapper}`}>

            <div className={`${classes.register_vender_card_main_inner_wrapper}`}>

                <div className={`${classes.register_vender_header}`}>
                    <h2>Register Vender</h2>
                    <button onClick={()=>{CloseModal_CALLBACK(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
                </div>

                <form onSubmit={HandleRegistry}>
                    <div className={`${classes.vender_reg_main_wrapper}`}>
                        
                        <div className={`${classes.vender_reg_input_wrapper}`}>
                            <MasterDataRegInput input={<input onChange={(e)=>{SetVname(e.target.value)}} name='vender_name' type="text" placeholder='Vender Name' title='' autoComplete='off' required />} />
                            <MasterDataRegInput input={<input onChange={(e)=>{SetVtin(e.target.value)}} name='vender_tin' type="number" placeholder='Tin' title='' autoComplete='off' required />} />
                        </div>

                    </div>

                    <div className={`${classes.form_action_button_wrapper}`}>
                            <button disabled={registring} className={`${data_win.form_action_button_save}`}>{registring ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Register'}</button>
                            <button disabled={registring}  onClick={()=>{CloseModal_CALLBACK(false)}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                    </div>
                </form>

            </div>

        </div>
    )
}