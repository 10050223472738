import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

import './InputFieldStyle.css'
import Select from 'react-select';
import { useState, useEffect } from "react";

export default function DynamicDropDownInputField(
    {
         OptionURL,
         HandleInputUpdate,
         placeholder = "Select an option",
         Name, 
         icon_value, 
         ReadOnly = false, 
         isReq
        }) 
{

      const [selectedOptions, setSelectedOptions] = useState();
      const [optionList, setOptionList] = useState([]);

      function PrepList(data) {
        const newOptionList = [];
      
        for (var i = 0; i < data.length; i++) {
          
          let object = data[i];
      
          let value_key = null;
          let value_lable = null;

         // console.log(object);
          for (const key in object) {

            const active = String(key);
            if (active.toLowerCase().includes('name')) {

              const val = object[key];
              value_lable = val;

            } else if (active.toLowerCase().includes('id')) {

              const val = object[key];
              value_key = val;

            }

            

            if(value_lable !== null && value_key !== null) 
            {
              newOptionList.push({ value: value_key, label: value_lable  });
              break;
            }

          }

          
        }
      
        setOptionList(newOptionList);
      }

      useEffect(() => 
        {
            QueryOption();
        }, [])

      function QueryOption() 
      {
        fetch(OptionURL)
        .then(res => res.json())
        .then((data) => PrepList(data));
      } 
    // const formattedOptions = () = OptionData.map((opt) => ({
    //     value: opt.toLocaleLowerCase(),
    //     label: opt
    // }))
    function handleSelect(data) {

        setSelectedOptions(data);
    
        HandleInputUpdate(prev => ({
            ...prev,
            [Name]: data.value
        }))
    }
    const Custom_DD_Style = {
    control: (provided, state) => ({
        ...provided,
        width: 225,
        minHeight: 20,
        height: 35,
        fontWeight: 'bold',
        fontSize: 13,
        background: 'var(--primary-color)',
        borderColor: 'var(--primary-color)',
        color: 'var(--secondary-color)',
        borderRadius: 1,
        borderColor: 'none', 
        boxShadow: 'none', 
        '&:hover': {
            borderColor: 'none', 
            boxShadow: 'none', 
        },
        }),
        option: (provided) => ({
        ...provided,
        backgroundColor: 'var(--primary-color)', // Change the background color of options
        color: 'var(--secondary-color)', // Change the text color of options
        }),
        option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? 'var(--body-background)' : 'var(--primary-color)', // Change background color on hover
        color: state.isFocused ? 'var(--primary-color)' : 'var(--secondary-color)', // Text color
        transition: 'background-color 0.2s ease-in-out',
        cursor: 'pointer',
    }),
        menu: (provided) => ({
        ...provided,
        zIndex: 9999, // Increase the z-index if options are not displayed properly
        backgroundColor: 'var(--primary-color)',
        fontSize: 13,
        borderRadius: 1,
        fontWeight: 500,
        }),
    }
    return(
        <div className="dd-input-field">
            <div className="dd-icon-field">
                <FontAwesomeIcon icon={icon_value} className="icon-value"></FontAwesomeIcon>
            </div>
            <div className="value-field">
                
                <Select
                onChange={handleSelect}
                name={Name}
                options={optionList}
                placeholder={placeholder}
                value={selectedOptions}
                isSearchable={true}
                styles={Custom_DD_Style}
                required ={isReq}
                />

            </div>
        </div>
    );
}