import classes from './PittyCashBranchAnalysis.module.css'
import data_win from '../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartArea, faChartLine, faCheckCircle, faRotateBack, faTimesCircle, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import ApChartMini from '../../../../../SharedComp/ApexChartMiniWrapper/ApChartMini'
import DotsGif from '../../../../../SharedComp/CssGif/DotsGif'
import ReactApexChart from 'react-apexcharts'
import Chart from 'react-apexcharts'
import { useEffect, useState } from 'react'
import { getBaseURL } from '../../../../../SharedComp/BaseUrlConfig'
import PittyCashAuditView from './PittyCashAuditView/PittyCashAuditView'
import NotificationPopUp from '../../../../../SharedComp/NotificationPopUp/NotificationPopUp'
import { useAuth } from '../../../../../AuthProvider'

function GetRandomValue()
{
   return Math.floor(Math.random() * 50000)
}

export default function PittyCashBranchAnalysis({Close_CALLBACK, Item, RefershData, Msg_Callback}) 
{

  const auth = useAuth();
  const [localStateBranch, setLocalStateBranch] = useState(null);
  const [loading, setLoading] = useState(true);
  const [depositList, setDepositList] = useState([]);
  const [chartData, setChartdata] = useState();
  const [openPittyCashAudit, setOpenPittyCashAudit] = useState(false);
  const [activeBook, setActiveBook] = useState(false);

  // Notifiction
  const [MsgPkg, SetMsgPkg] = useState({});
  const [OpenMyNoti, SetOpenMyNoti] = useState(false);
  const [RefeshList, SetRefeshList] = useState(false);

      const series = [
        {
          name: 'Total Allocation',
          data: chartData,
        },
      ];


     const chart_option = {
        
        chart: {
          background: 'var(--background-dark_minus)', // Set the background color
      },
        xaxis: {
            type: 'category',
            labels: {
              style: {
                colors: 'var(--text_light_white)', // Set x-axis text color
              },
            },
          },
          yaxis: {
            show: true, // Hide y-axis
            labels: {
              style: {
                colors: 'var(--text_light_white)', // Set y-axis text color
              },
            },
          },
          grid: {
            show: false, // Hide grid lines
            
          },
          dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
                return val.toLocaleString('en-US', {
                style: 'currency',
                currency: 'ETB',
                });
            },
            style: {
                fontSize: '10px', // Adjust the font size here
            },
          },
          tooltip: {
            theme: 'dark', // Set the theme to dark
          },
        colors: ['var(--color-theme)'], // Set the text color
      };
      function MsgCallback(res) 
      {
          setOpenPittyCashAudit(false);
          LocalMsgTrigger(res.Msg, res.MyStateCode);
      }
      function LocalMsgTrigger(msg, stat) 
      {
          if(stat === 1) 
          {
              SetRefeshList(!RefeshList);
              RefershData(!RefeshList);
          }
          SetMsgPkg({Key : Math.random() * 10000, Msg: msg, State: stat})
          SetOpenMyNoti(true);
      }
      const processGraphData = (res) => {
        // Initialize an empty object to store the graph data
        const graphData = {};
      
        // Loop through the fetched data
        res.forEach(item => {
          const name = item.Cat;
          const initialValue = item.Amount;
      
          // Check if the name already exists in the graphData
          if (graphData[name]) {
            // If it exists, add the amount to the existing value
            graphData[name] += initialValue;
          } else {
            // If it doesn't exist, create a new entry with the amount
            graphData[name] = initialValue;
          }
        });
      
        return graphData;
      };
      const fetchChartSpesificData = async (data) => {
        try {
            const response = await fetch(getBaseURL() + "operation-data/query-pitty-cash-expense-book-id", {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({BranchID: Item.BranchID, BookID: data.PCDID})
            });

            const res = await response.json();
            setChartdata(
                Object.entries(processGraphData(res)).map(([key, value]) => ({
                  x: key,
                  y: value,
                }))
            );
        }catch(err) 
        {

        }
      }
      const  fecthChartData = async () => {

        try 
        {
          const response = await fetch(getBaseURL() + "operation-data/query-pitty-cash-expense", {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({BranchID: Item.BranchID})
          });

          const res = await response.json();
          setChartdata(
              Object.entries(processGraphData(res)).map(([key, value]) => ({
                x: key,
                y: value,
              }))
          );

        }catch(err) 
        {

        }

      }
      const fetchDeposit = async () => {
        try 
        {
          const response = await fetch(getBaseURL() + "operation-data/query-deposit-pitty-cash", {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({BranchID: Item.BranchID})
          });

          const res = await response.json();
          setDepositList(res);

        }catch(err) 
        {

        }
      }

      const fetchLocalBranch = async () => {
        try 
        {
          const response = await fetch(getBaseURL() + "master-data/get-company-branch-id", {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({BranchID: Item.BranchID})
          });

          const res = await response.json();

          setLocalStateBranch(res[0]);
          fetchDeposit();
          fecthChartData();
          setLoading(false);

        }catch(err) 
        {
          throw new Error("Error Fetching Workspace PittyCash Detail Data" + err);
        }
      }

      const handleCashLogViewOpen = (data) => {
        setOpenPittyCashAudit(true);
      }

      const ApproveOperation = (data) => {

        setActiveBook(data); 
          handleCashLogViewOpen(data);

          return;
        if(auth.employeeData) 
        {
          setActiveBook(data); 
          handleCashLogViewOpen(data);
        } else 
        {
          LocalMsgTrigger(`Sorry Duag, Your account type does't allow this action! Nothing Personal.` , -1);
        }
        
      }

      useEffect(()=>{

        setLoading(true);
        setTimeout(() => {
          fetchLocalBranch();
        }, 500);
      }, [RefeshList]);

      

    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

        {openPittyCashAudit && <PittyCashAuditView LocalStateBranch={localStateBranch} Item={activeBook} Msg_Callback={MsgCallback} Close_CALLBACK={setOpenPittyCashAudit}/>}

        <div className={`${data_win.data_main} ${classes.data_main}`}>


            <div className={`${data_win.data_main_header}`}>
                <h3>
                    JAK <span className={`${data_win.data_create_name}`}>Pitty Cash Analysis</span>
                </h3>
                <button onClick={()=>{Close_CALLBACK(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
            </div>
            {OpenMyNoti && <NotificationPopUp key={MsgPkg.Key} msg={MsgPkg.Msg} state={MsgPkg.State} />}
            { !loading ? <div className={`${classes.data_body}`}>

                <div className={`${classes.distro_graph_wrapper}`}>

                    <div className={`${classes.header_graph}`}>
                        <h2>Budget Usage Analysis - {localStateBranch.BranchName}</h2>
                    </div>

                    <Chart 
                        options={chart_option}
                        series={series}
                        type="area"
                        height={200}
                    />
                </div>

                <div className={`${classes.pitty_cash_audit_list_wrapper}`}>

                    <div className={`${classes.list_main}`}>
                        
                        <div className={`${classes.pitty_cash_audit_list_inner_wrapper}`}>
                          {
                            depositList.map((item, idx)=>(
                              <li key={idx}>
                                  <span className={`${classes.pit_date}`}>{new Date(item.Date).toDateString()}</span>
                                  <span className={`${item.IsOpen ? classes.pit_action_open : classes.pit_action_closed}`}>{item.IsOpen ? 'Open' : 'Closed'}</span>
                                  <span className={`${classes.pit_amount}`}>+
                                      {Intl.NumberFormat('en', {currency: 'ETB', style: 'currency'}).format(item.IsOpen ? item.RemainAmount : item.InitialAmount)}
                                      </span>

                                 {!item.IsOpen ? <span onClick={(e)=>{ApproveOperation(item)}} className={`${item.IsAudited ? classes.pit_action_good : classes.pit_action_bad}`}>
                                     {item.IsAudited ? 
                                     <>Approved <FontAwesomeIcon icon={faCheckCircle}/> </>: 
                                     <>Waiting Action  <FontAwesomeIcon icon={faTriangleExclamation}/></>}  
                                  </span> :
                                  <span className={`${classes.active_usage}`}>
                                     Active
                                  </span>}
                                  
                                  <span onClick={(e)=>{fetchChartSpesificData(item)}} className={`${classes.chart_button}`}> <FontAwesomeIcon icon={faChartLine}></FontAwesomeIcon> </span>
                              </li>

                            ))
                          }
                          

                            

                        </div>

                    </div>

                </div>

            </div> 
            : 
            <div className={`${classes.loading_placeholder}`}>
                  <DotsGif/>
             </div> }

        </div>

    </div>
    )
}