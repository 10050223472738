import classes from './ReceiveOrder.module.css'
import {QueryMasterProdCatValidToPreorder} from '../../../../../SharedComp/API/DeskView/API_FE_DeskView'
import { useState } from 'react';
import { useEffect } from 'react';
import { useAuth } from '../../../../../AuthProvider'
import { useToast } from '../../../../../ToastContext'

import OrderProductCard from '../OrderProductCard/OrderProductCard';




export default function ReceiveOrder()
{

    const [ValidGroupList, SetValidGroupList] = useState([]);
    const auth = useAuth();
    const notify = useToast();

    const QueryValidGroup = async()=>
    {
        try 
        {
            const main_res = await QueryMasterProdCatValidToPreorder(auth.token);
            SetValidGroupList(main_res);
        }catch(err) 
        {
            notify.ShowError("Failed to Query Valid Production Group List: " + err.message);
        }
    }

    useEffect(()=>
    {
        QueryValidGroup();

    }, [])




    return (
        <div className={`${classes.desk_view_card_wrapper}`}>
            {
                ValidGroupList.map((item, idx)=>(
                    <OrderProductCard key={idx} isGroup={true} TargetData={item}/>
                ))
            }
        </div>
    )
}