import classes from './MixDataEdit.module.css'

import data_win from './../../../../../SharedComp/CommonStyle/DataCreator.module.css'

// Comp
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MasterDataRegInput from '../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useRef } from 'react'
import { useState } from 'react'
import { faMinus, faSpinner,faObjectUngroup, faObjectGroup, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'
import Select from 'react-select';
import DynamicMixInputData from '../ExtraMixData/DynamicMixInputData'

import NotificationPopUp from '../../../../../SharedComp/NotificationPopUp/NotificationPopUp'

import { FetchBranchAll, UpdateYamMix, FetchYamMixID, FetchYamMixIngID } from '../../../../../api'
import {mapToObject, objectToMap} from '../../../../../SharedComp/Utility'

import { useAuth } from '../../../../../AuthProvider'
import { useToast } from '../../../../../ToastContext'
//lib
import axios from 'axios';
export default function MixDataEdit({OpenController, MsgTrigger, EditIdx}) 
{
    const formRef = useRef();
    const auth = useAuth();
    const notify = useToast();
    const [registring, SetRegistring] = useState(false);


    const [WorkspaceList, SetWorkspaceList] = useState([]);
    const [WorkspaceChecked, SetWorkspaceChecked] = useState(new Map());
    const [ToggelListTrue, SetToggelListTrue] = useState(false);
    const [ComputationMethod, SetComputationMethod] = useState(false);
    const [OrderabelState, SetOrderabelState] = useState(false);

    const [MsgMyPkg, SetMsgMyPkg] = useState({});
    const [MixDataRawMaterial, SetMixDataRawMaterial] = useState(null);
    const [MixDataQuntity, SetMixDataQuntity] = useState(null);
    const [OpenMyNoti, SetOpenMyNoti] = useState(false);

    const [QueryMixRawMat, SetQueryMixRawMat] = useState(null);
    const [QueryMixIng, SetQueryMixIng] = useState([]);


    const [MixName, SetMixName] = useState();
    const [MixDetail, SetMixDetail] = useState();
    const [MixID, SetMixID] = useState();

    const [MixIngList, SetMixIngList] = useState([]);
    

    // This is to hanle indiviual select
    const handleToggle = (branchName) => {
        SetWorkspaceChecked(prevState => {
          const updatedState = new Map(prevState);
          updatedState.set(branchName, !updatedState.get(branchName));
          return updatedState;
        });
    };

    // This function handle the select all stuff
    const selectAll = (val) => {

        SetToggelListTrue(val);

        let updated_list = new Map();
        for(const [mp_key, mp_val] of WorkspaceChecked.entries()) 
        {
            updated_list.set(mp_key, val);
        }
        SetWorkspaceChecked(updated_list);    
    };
    // we need to query all the branch inorder to check which one is active
    const QueryWorkspace = async () => {

        try {
            const res = await FetchBranchAll(auth.token);

            // for now prepare void list
            let init_check_list = new Map();
            for(let  i = 0; i < res.length; i++) 
            {
                if(!init_check_list.has(res[i].BranchName)) 
                    init_check_list.set(res[i].BranchName, false); 
            }
            SetWorkspaceChecked(init_check_list);

            SetWorkspaceList(res);
        } catch (err) 
        {
            throw new Error(err);
        }
    }
    
    function getRandomID() 
    {
        return Math.floor(Math.random() * 100000000);
    }
    

    function HandleMixRawMaterial(data, idx) 
    {
        SetMixDataRawMaterial(prev => ({
            ...prev,
            [idx]: data
        }))
        
    }
    function HandleMixQantity(data, idx) 
    {
        SetMixDataQuntity(prev => ({
            ...prev,
            [idx]: data
        }))

    }
    function AddNewInputField() 
    {
        const existing = [...MixIngList];
        existing.push(<DynamicMixInputData HandleMixQantity={HandleMixQantity} HandleMixRawMaterial={HandleMixRawMaterial} idx={getRandomID()} />);
        SetMixIngList(existing);
    }
    function AddNewInputFieldWithData(Qantity, mat_value) 
    {
        const dd_id = getRandomID();

        HandleMixRawMaterial(mat_value, dd_id);
        HandleMixQantity(Qantity, dd_id);

        SetMixIngList(prevList => [
            ...prevList,
            <DynamicMixInputData MatValue={mat_value} QantityValue={Qantity} HandleMixQantity={HandleMixQantity} HandleMixRawMaterial={HandleMixRawMaterial} idx={dd_id} />
        ]);
    }
    function HandleAvailWorkshopSerlization() 
    {
        
        const crt_data =  new Map(objectToMap(JSON.parse(QueryMixRawMat.MixAvailWorkspace)));

        let updated_list = new Map();
        for(let  i = 0; i < WorkspaceList.length; i++) 
        {
            const _workspace_id = WorkspaceList[i].BranchID;
            if(crt_data.has(_workspace_id))
            {
                updated_list.set(_workspace_id, crt_data.get(_workspace_id));
            } else 
            {
                updated_list.set(_workspace_id, false);
            }
        }
        SetWorkspaceChecked(updated_list);
    }
   function HandleDeserlizationMixMaterial(data) 
    {
         SetQueryMixRawMat(data[0]);
        
          SetMixName(data[0].MixName);
          SetMixDetail(data[0].MixRemark);
          SetMixID(data[0].MID);
          SetComputationMethod(data[0].MixComputeType === 1);
          SetOrderabelState(data[0].YamMix_Orderable === 1);
    }
    function HandleDeserlizationMixID(data) 
    {
        SetMixIngList([]);
        SetMixDataRawMaterial([]);
        SetMixDataQuntity([]);

        SetQueryMixIng(data);
        
        for(var i = 0; i < data.length; i++) 
        {
            // var mat_sel = {value: 75, label: 'Palm Oil'};
            var mat_sel = {value: data[i].InvItemID, label: data[i].ItemName};
            AddNewInputFieldWithData(data[i].Quantity, mat_sel);
        }
    }
    function RemoveInputField(id_to_remove) 
    {
        if(MixIngList.length === 1)
            return;
    
        // Remove Raw Material
        if(MixDataRawMaterial) 
        {
            SetMixDataRawMaterial(prevState => {
                const { [id_to_remove]: _, ...updated } = prevState;
                return updated;
            });
        }
        // Remove material Quantity
        if(MixDataQuntity) 
        {
            SetMixDataQuntity(prevState => {
                const { [id_to_remove]: _, ...updated } = prevState;
                return updated;
            });
        }    

        SetMixIngList(prevState => {
            const updated = prevState.filter(item => item.props.idx !== id_to_remove);
            return updated;
        });
    }

    const FetchTargetYamMix = async()=>{
        try 
        {
            const res = await FetchYamMixID(EditIdx, auth.token);
            HandleDeserlizationMixMaterial(res);
        }catch(err) 
        {
            notify.ShowError("Unable to fetch Target Mix! Please try reloading the page.");
        }
    }

    const FtechTargetIngrdent = async()=>{
        try 
        {
            const res = await FetchYamMixIngID(EditIdx, 'token');
            HandleDeserlizationMixID(res)
        }catch(err) 
        {
            notify.ShowError("Unable to fetch Target Mix Ingerident! Please try reloading the page.");
        }
    }

    function CloseModal() 
    {
        formRef.current.reset();
        OpenController(false);
    }

    const HandleSubmition = async(e)=> 
    {
        e.preventDefault();

        SetRegistring(true);
        notify.ShowLoad("Locking in Your Obligations...", 10);

        setTimeout(async ()=>{

            try 
            {
                const fd = new FormData();

                fd.append("mix_name", MixName);
                fd.append("mix_remark", MixDetail);
                fd.append('computation_method', ComputationMethod);
                fd.append('orderable', OrderabelState);
                fd.append("mix_raw_material", JSON.stringify(MixDataRawMaterial));
                fd.append("mix_quantity", JSON.stringify(MixDataQuntity));
                fd.append("Avail_workspace", JSON.stringify(Object.fromEntries(WorkspaceChecked)));
                fd.append("user_id", auth.user.UserAccountID);
                fd.append("mix_id", MixID);

                const res = await UpdateYamMix(fd, 'token');
                notify.ShowSuccessUpdate(res.Msg, 10);
                SetRegistring(false);

            }catch(err) 
            {
                notify.ShowErrorUpdate(err.message, 10);
                SetRegistring(false);
            }

        }, 3000)
    }

    useEffect(()=>{
        FetchTargetYamMix();
        FtechTargetIngrdent();
        QueryWorkspace();
    }, [])

    useEffect(()=>{
        if(QueryMixRawMat) 
        {
            HandleAvailWorkshopSerlization();
        }
        
    }, [WorkspaceList, QueryMixRawMat])


    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>
        {OpenMyNoti && <NotificationPopUp key={MsgMyPkg.Key} msg={MsgMyPkg.Msg} state={MsgMyPkg.State}/>}

            <div className={`${data_win.data_main} ${classes.data_main}`}>
                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        Edit <span className={`${data_win.data_create_name}`}> Recipe</span>
                    </h3>
                    <button onClick={()=>{CloseModal()}}>
                        <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                    </button>
                </div>

                <div className={`${data_win.data_body}`}>
                        
                

                            <form onSubmit={HandleSubmition} ref={formRef} action="POST">
                                <div className={`${classes.form_wrapper}`}> 

                                        <div  className={`${classes.input_list_wrapper}`}>
                                            <MasterDataRegInput input={<input value={MixName} onChange={(e)=>{SetMixName(e.target.value)}} name='mix_name' type="text" placeholder='Mix Name' title='' autoComplete='off' required />} />
                                            <MasterDataRegInput input={<input value={MixDetail} onChange={(e)=>{SetMixDetail(e.target.value)}} name='mix_remark' type="text" placeholder='Remark' title='' autoComplete='off' />} />
                                            <div onClick={(e)=>{SetComputationMethod(!ComputationMethod)}} className={`${classes.recipe_usage_wrapper}`}>
                                                <input checked={ComputationMethod} onClick={(e)=>{SetComputationMethod(!ComputationMethod)}} type="checkbox" />
                                                <span>Per Kilogram</span>
                                            </div>
                                            <div onClick={(e)=>{SetOrderabelState(!OrderabelState)}} className={`${classes.recipe_usage_wrapper}`}>
                                                <input checked={OrderabelState} onClick={(e)=>{SetOrderabelState(!OrderabelState)}} type="checkbox" />
                                                <span>Treat as Order</span>
                                            </div>
                                        </div>

                                        <div className={`${classes.ing_selection}`}>
                                                <div className={`${classes.mix_list_ing_wrap}`}>
                                                    {MixIngList.map((item) => (
                                                        <div className={`${classes.input_fild_shild}`} key={item.props.idx}>{item}{MixIngList.length > 1 ? <button type='button' onClick={()=>{RemoveInputField(item.props.idx);}} className={`${classes.remove_button_wrapper}`}><FontAwesomeIcon icon={faMinus}></FontAwesomeIcon></button> : <span  className={`${classes.remove_button_wrapper_fake}`}></span> }</div>
                                                    ))}
                                                </div>

                                                <div className={`${classes.master_product_type_inner_avail_workspace_list}`}>
                                                        <div className={`${classes.master_product_type_inner_avail_workspace_list_header}`}>
                                                            <h1>Accessibility</h1>
                                                            <button type='button' onClick={()=>{selectAll(!ToggelListTrue)}}><FontAwesomeIcon icon={ToggelListTrue ? faObjectUngroup : faObjectGroup}/></button>
                                                        </div>
                                                        <div className={`${classes.master_prod_avail_list}`}>
                                                            {
                                                                WorkspaceList.map((item, idx)=>(
                                                                    <div key={idx} onClick={() => handleToggle(item.BranchID)} className={`${classes.master_prod_avail_branch}`}>
                                                                        <input 
                                                                        onClick={() => handleToggle(item.BranchID)}
                                                                        checked={WorkspaceChecked.get(item.BranchID)} 
                                                                        name={item.BranchName} 
                                                                        type="checkbox"
                                                                        onChange={() => handleToggle(item.BranchID)}
                                                                        />
                                                                        <li>{item.BranchName}</li>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                 </div>
                                        </div>
                                       
                                        

                                        <div className={`${classes.input_adder}`}>
                                            <button onClick={()=>{AddNewInputField()}} type='button'>Add Ingredient</button>
                                        </div>
                                            
                                            
                                </div>
                                <div className={`${data_win.form_action_button_wrapper}`}>
                                    <button disabled={registring} className={`${data_win.form_action_button_save}`}>{registring ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Update'}</button>
                                    <button disabled={registring}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                                </div>
                            </form>




                </div>
            </div>

        </div>
    )
}