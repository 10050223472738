import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classes from './NonEdibleGoodTypeData.module.css'
import data_win from './../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import {faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import MasterDataRegInput from '../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useRef, useState } from 'react'
import axios from 'axios';


export default function NonEdibleGoodTypeData({OpenController, MsgTrigger}) 
{
    const formRef = useRef();

    // If the server is requested but not answerd yet..
    const [registring, SetRegistring] = useState(false);

    // Clear the form when ever the user click dismiss of close the window
    function CloseModal() 
    {
        formRef.current.reset();
        OpenController(false);
    }


    // Handle submition
    function HandleSubmition(e) 
    {
        e.preventDefault();
        SetRegistring(true);

        setTimeout(() => {

            const fd = new FormData();

            const url = 'http://localhost:3333/master-data/add-iv-type'; 


            fd.append("goods_type", e.target.goods_type.value);
            fd.append("goods_remark", e.target.goods_remark.value);
            fd.append("IsItemEdible", "No");
            
            
            
            axios.post(url, fd, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    })
                .then(res => {
                    SetRegistring(false);
                    MsgTrigger(res);
                    CloseModal();
                    
                    
                })
                .catch(err => {
                    SetRegistring(false);
                    MsgTrigger(err);
                    CloseModal();
                    
                })
            }, 2000)
    }




    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

            <div className={`${data_win.data_main}`}>
                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        Registring <span className={`${data_win.data_create_name}`}>Non-Edible Inventory Goods Type</span>
                    </h3>
                    <button onClick={()=>{CloseModal()}}>
                        <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                    </button>
                </div>

                <div className={`${data_win.data_body}`}>
                        


                            <form onSubmit={HandleSubmition} ref={formRef} action="POST">
                                <div className={`${classes.form_wrapper}`}> 
                                        <div  className={`${classes.input_list_wrapper}`}>
                                            <MasterDataRegInput input={<input name='goods_type' type="text" placeholder='Non-Edible Inventory Good Type Name' title='' autoComplete='off' required />} tip={"Non-Edible Inventory Good Type Name. It is like the group name!"}/>
                                            <MasterDataRegInput input={<input name='goods_remark' type="text" placeholder='Remark' autoComplete='off' />} />
                                        </div>
                                </div>
                                <div className={`${data_win.form_action_button_wrapper}`}>
                                    <button disabled={registring} className={`${data_win.form_action_button_save}`}>{registring ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Add'}</button>
                                    <button disabled={registring}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                                </div>
                            </form>




                </div>
            </div>

        </div>
    )
}