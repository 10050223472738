import classes from './CC_ProductItem.module.css'
import { faBurger, faLayerGroup, faMoneyCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useEffect, useState } from 'react'
import { Tooltip } from 'react-tooltip';
export default function CC_ProductItem({Data, DATA_BUFFER_UPDATE_CALLACBK}) 
{

    const [OpenInput, SetOpenInput] = useState(false);;
    const [PackageData, setPackageData] = useState([{Name: Data.ItemName ,UNSOLD_ITEM: 0, DAMAGED_ITEM: 0, LOCAL_USED_ITEM: 0}]);
    
   
    function ToggelHiddenInput(val) 
    {
        SetOpenInput(val);
    }
    
    function UPDATE_UNSOLD_ITEM(e) 
    {
        const updatedPackageData = [...PackageData]; // Creating a copy of PackageData array

        // Updating the UNSOLD_ITEM property of the first object
        updatedPackageData[0] = {
        ...updatedPackageData[0], // Copying the original object properties
        UNSOLD_ITEM: e.target.value // Updating the UNSOLD_ITEM property with the new value
        };

        // Setting the updated state
        setPackageData(updatedPackageData);
    }
    function UPDATE_DAMAGED_ITEM(e) 
    {
        const updatedPackageData = [...PackageData]; 

        updatedPackageData[0] = {
        ...updatedPackageData[0], 
        DAMAGED_ITEM: e.target.value 
        };

        setPackageData(updatedPackageData)
    }
    function UPDATE_USED_ITEM(e) 
    {
        const updatedPackageData = [...PackageData];

        updatedPackageData[0] = {
        ...updatedPackageData[0],
        LOCAL_USED_ITEM: e.target.value
        };
        setPackageData(updatedPackageData);
    }

    function SetUpdatedInput(e) 
    {
        e.preventDefault();
        if(OpenInput)
        {
            DATA_BUFFER_UPDATE_CALLACBK(Data.ItemName, PackageData);
            SetOpenInput(false);
        }
    }
    
    useEffect(()=>{

        

    }, [PackageData])


    return (
        <div onClick={()=>{ToggelHiddenInput(true)}} onMouseLeave={()=>{ToggelHiddenInput(false)}} className={`${classes.pd_item_wrapper}`}>
            {/* <Tooltip className={`${classes.tool_quick}`} id="offical_doc" place="top" /> */}
                <div className={`${classes.pd_item_name_wrapper}`}>
                    <h3><FontAwesomeIcon icon={faBurger}/> <span className={`${classes.name_for_tag}`}>{Data.ItemName}</span></h3>
                </div>

               <div className={`${classes.pd_item_detail}`}>
                    <li> <span className={`${classes.icc}`}><FontAwesomeIcon icon={faMoneyCheck}/></span> ETB {Data.ProdUnitPrice} - Unit Price</li>
                    <li> <span className={`${classes.icc}`}><FontAwesomeIcon icon={faLayerGroup}/></span> {Data.Quantity} - Quantity</li>
                </div>

                <div className={`${OpenInput ? classes.hidden_input_wrapper_show : classes.hidden_input_wrapper_hidden} ${classes.hidden_input_wrapper}`}>
                    <form onSubmit={SetUpdatedInput}>
                        <input min="0" max={Data.Quantity} onChange={UPDATE_UNSOLD_ITEM} id={Data.ItemName} name='unsold_count' type="number" placeholder='Unsold Count' />
                        <input min="0" max={Data.Quantity} onChange={UPDATE_DAMAGED_ITEM} id={Data.ItemName} name='damage_product' type="number" placeholder='Damanaged Count' />
                        <input min="0" max={Data.Quantity} onChange={UPDATE_USED_ITEM} id={Data.ItemName} name='local_usage' type="number" placeholder='Local Usage' />

                        <div className={`${classes.set_button_wrapper}`}>
                            <button type="submit">Set</button>
                        </div>
                    </form>
                </div>

        </div>
    )
}