import classes from './EmpBank.module.css'
import { faPenNib } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'


export default function EmpBank({EmpData}) 
{
    const [enableEdit, SetEnableEdit] = useState(false);


    return (
        <div className={`${classes.emp_bank_main_wrapper}`}>

            <div className={`${classes.emp_bank_inner_wrapper}`}>
                
                <form action="POST">

                    <div className={`${classes.emp_bank_header}`}>
                        <button type='button' onClick={()=>{SetEnableEdit(!enableEdit)}}><FontAwesomeIcon icon={faPenNib}/></button>
                    </div>

                    <div className={`${classes.input_wrapper} ${enableEdit? classes.emp_bank_enable : ''}`}>
                            <li>Bank</li>
                            <input type="text" value={EmpData.BankName} readOnly={!enableEdit} />
                    </div>

                    <div className={`${classes.input_wrapper} ${enableEdit? classes.emp_bank_enable : ''}`}>
                            <li>Account Number</li>
                            <input type="text" value={EmpData.AccountNumber} readOnly={!enableEdit}/>
                    </div>

                    <div className={`${!enableEdit ? classes.action_button_hide : classes.action_button}`}>
                            <button>Update</button>
                    </div>
                    
                </form>
            </div>


        </div>
    )
}