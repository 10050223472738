import { getBaseURL } from "../../BaseUrlConfig";
import axios from 'axios'


function GetRouteConfig(token)
{
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
}


export const API_POSaved_Save = async(data, token)=>{
    try 
    {
        const response = await axios.post(getBaseURL() + 'production/serialized-production/process-po-save', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchSavedProductionOrderConfig = async(data, token)=>{
    try 
    {
        const response = await axios.post(getBaseURL() + 'production/serialized-production/fetch-saved-po-config', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const SaveProductionOrderConfig = async(data, token)=>
{
        try 
        {
            const response = await axios.post(getBaseURL() + 'production/serialized-production/process-po-save', data, GetRouteConfig(token));
            const res = await response.data;
            return res;
        }catch(err) 
        {
            throw err;
        }
}
export const FetchSavedProductionProductOrderItem = async(hash_id, token)=>{
    try 
    {
        const response = await axios.post(getBaseURL() + 'production/serialized-production/fetch-saved-products-item', {ID: hash_id}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchProductionOrderReqMaterialSaved = async(id, token)=>{
    try 
    {
        const response = await axios.post(getBaseURL() + 'production/serialized-production/fetch-prod-order-saved-mat', {ID: id}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateSavedPOShareState = async(data, token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'production/serialized-production/update-production-order-share-state', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeSavedPO = async(data, token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'production/serialized-production/purge-saved-production-order', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}