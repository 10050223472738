import classes from './EmpDropDownStatic.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk, faCircleCheck, faCircleExclamation, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'react-tooltip';

export default function EmpDropDownStaticInputField({OnChangeUpdate, Data, Title="", ValueTag, Icon, PlaceHolder, State, Tip, ForceInput = true, list=[]}) 
{
    return (
        <div className={`${classes.emp_drop_down_input}`}>
            <Tooltip className={`${classes.tool_quick}`} id="input_field_tip" place="top" />
            <div className={`${classes.emp_input_title}`}>
                <li>{Title} <span className={`${classes.emp_input_force_notifyer}`}>{ForceInput ? <sup><FontAwesomeIcon icon={faAsterisk}></FontAwesomeIcon></sup> : ''}</span></li>
            </div>
                <div className={`${classes.emp_input_line_wrapper} ${State === -1 ? classes.emp_input_line_wrapper_error_border : State === 1 ? classes.emp_input_line_wrapper_suc_border : '' }`}>

                    <div className={`${classes.emp_input_left_icon_wrapper}`}>
                        <span className={`${classes.emp_side_icon}`}><FontAwesomeIcon icon={Icon}></FontAwesomeIcon></span>
                    </div>

                    <div
                    {...(Tip ? {'data-tooltip-id': 'input_field_tip', 'data-tooltip-content': Tip} : {})}
                     className={`${classes.emp_create_input_wrapp}`}>
                        <select value={Data[ValueTag]} onChange={OnChangeUpdate} name={ValueTag} className={`${classes.drop_down}`} required={ForceInput}>
                            <option value={''}>{PlaceHolder}</option>
                            {
                                list.map((elm) => (
                                    <option className={`${classes.option_st}`} key={elm} value={elm}>{elm}</option>
                                ))
                            }
                        </select>
                    </div>

                    <div className={`${classes.emp_icon_right_side_notify}`}>
                        <span className={`${classes.emp_side_icon_noti} ${State === 0 ? classes.emp_icon_right_side_notify_friendly : State === -1 ? classes.emp_icon_right_side_notify_error : State === 1 ? classes.emp_icon_right_side_notify_good : State === -2 ?classes.emp_icon_right_side_notify_whatever : ''}`}>
                                {State !== 0 && <FontAwesomeIcon icon={State === -1 ? faCircleExclamation : State === -2 ? faExclamation : faCircleCheck}></FontAwesomeIcon> }
                            </span>
                    </div>
                </div>
        </div>
    )
}