import classes from './CompanyProfileCollector.module.css'
import { faBank, faBuilding, faDollarSign, faFileLines, faLocationPin, faPersonHiking, faPersonShelter, faPersonWalkingArrowLoopLeft, faPhone, faSignature, faTriangleExclamation, faUserGear, faUserGraduate, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope, faUser } from '@fortawesome/free-regular-svg-icons';
import EmpTextInput from '../EmpTextInput/EmpTextInput';
import { useState, useRef, forwardRef, useImperativeHandle} from 'react';

import DynamicDropDownInput from './Component/DynamicDropDownInput/DynamicDropDownInput';
import EmpDropDownStaticInputField from '../EmpDropDownInput/EmpDropDownStaticInput';
import { useEffect } from 'react';
 

const CompanyProfileCollector = forwardRef(({ DataPkg, DataUpdater, updateInput }, ref) => {

    const [DepartmentField_State, SetDepartmentField_State]             = useState(0);
    const [JobPositionField_State, SetJobPositionField_State]           = useState(0);
    const [EmpSDField_State, SetEmpSDield_State]                        = useState(0);
    const [ShiftField_State, SetShiftField_State]                       = useState(0);
    const [WeeklyPayField_State, SetWeeklyPayField_State]               = useState(0);
    const [MonthlyPayField_State, SetMonthlyPayField_State]             = useState(0);
    const [MaxCreditField_State, SetMaxCreditField_State]               = useState(0);
    const [PtoField_State, SetPtoField_State]                           = useState(0);
    const [BankField_State, SetBankField_State]                         = useState(0);
    const [AccountNumberField_State, SetAccountNumberField_State]       = useState(0);
    const [ContractField_State, SetContractField_State]                 = useState(0);
    const [TypeOfEmployment_State, SetTypeOfEmployment_State]           = useState(0);

    const [FilterData, SetFilterData] = useState(null);
    const [DDKey, setDDKey] = useState(false);


    function PerformLocalInputGuard() 
    {
        let ret_value = true;

        if(DataPkg["department"] === undefined || DataPkg["department"] === '') 
        {
            SetDepartmentField_State(-1);
            ret_value = false;
        } else 
        {
            SetDepartmentField_State(1);
        }

        if(DataPkg["job_position"] === undefined || DataPkg["job_position"] === '') 
        {
            SetJobPositionField_State(-1);
            ret_value = false;
        } else 
        {
            SetJobPositionField_State(1);
        }

        if(DataPkg["employment_start_date"] === undefined || DataPkg["employment_start_date"] === '') 
        {
            SetEmpSDield_State(-1);
            ret_value = false;
        } else 
        {
            SetEmpSDield_State(1);
        }

        if(DataPkg["emp_shift"] === undefined || DataPkg["emp_shift"] === '') 
        {
            SetShiftField_State(-1);
            ret_value = false;
        } else 
        {
            SetShiftField_State(1);
        }

        if(DataPkg["weekly_pay"] === undefined || DataPkg["weekly_pay"] === '') 
        {
            SetWeeklyPayField_State(-1);
            ret_value = false;
        } else 
        {
            SetWeeklyPayField_State(1);
        }

        if(DataPkg["monthly_salary"] === undefined || DataPkg["monthly_salary"] === '') 
        {
            SetMonthlyPayField_State(-1);
            ret_value = false;
        } else 
        {
            SetMonthlyPayField_State(1);
        }

        if(DataPkg["max_credit_amount"] === undefined || DataPkg["max_credit_amount"] === '') 
        {
            SetMaxCreditField_State(-1);
            ret_value = false;
        } else 
        {
            SetMaxCreditField_State(1);
        }

        if(DataPkg["pto"] === undefined || DataPkg["pto"] === '') 
        {
            SetPtoField_State(-1);
            ret_value = false;
        } else 
        {
            SetPtoField_State(1);
        }

        if(DataPkg["bank"] === undefined || DataPkg["bank"] === '') 
        {
            SetBankField_State(-1);
            ret_value = false;
        } else 
        {
            SetBankField_State(1);
        }

        if(DataPkg["account_number"] === undefined || DataPkg["account_number"] === '') 
        {
            SetAccountNumberField_State(-1);
            ret_value = false;
        } else 
        {
            SetAccountNumberField_State(1);
        }

        if(DataPkg["employment_type"] === undefined || DataPkg["employment_type"] === '') 
        {
            SetTypeOfEmployment_State(-1);
            return false;
        }
        else 
        {
            SetTypeOfEmployment_State(1);

            if(DataPkg["employment_type"] === "Contract") 
            {
                if(DataPkg["employment_contract_period"] === undefined || DataPkg["employment_contract_period"] === '') 
                {
                    SetContractField_State(-1);
                    return false; 
                }
                else 
                {
                    SetContractField_State(1);
                }
            }
        }

        
        

        return ret_value;
    }

    useImperativeHandle(ref, () => ({
        PerformLocalInputGuard,
    }))

    useEffect(() =>{

    }, [FilterData])

    function OnDepartmentSelection(data) 
    {
        SetFilterData(data.value);
        setDDKey(!DDKey);
    }
    return (
        <div className={`${classes.company_profile_collector_main_wrapper}`}>
            <div  className={`${classes.info_header}`}>
                    <h2>Company Profile</h2>
            </div>
            <div className={`${classes.emp_section_manager}`}>
                    <div className={`${classes.emp_input_section_wrapper}`}> 

                        <div  className={`${classes.emp_section_wrapper}`}>

                            <DynamicDropDownInput 
                                State={DepartmentField_State}
                                URL={'http://localhost:3333/master-data/get-department'}
                                Data={DataPkg} 
                                DDDataUpdater={DataUpdater}
                                OnChangeUpdate={OnDepartmentSelection} 
                                Title={"Department"} 
                                ValueTag={"department"} 
                                Icon={faUserGear} 
                                PlaceHolder={"Department"}
                            />

                            <DynamicDropDownInput 
                                key={DDKey}
                                State={JobPositionField_State}
                                URL={'http://localhost:3333/master-data/get-job-position'}
                                Data={DataPkg} 
                                DDDataUpdater={DataUpdater}
                                Title={"Job Position"} 
                                ValueTag={"job_position"} 
                                Icon={faUserGear} 
                                PlaceHolder={"Job Position"}
                                FilterData={FilterData}
                                Dependent={true}
                            />

                            <EmpDropDownStaticInputField 
                                State={TypeOfEmployment_State} 
                                Data={DataPkg} 
                                OnChangeUpdate={updateInput} 
                                Title={"Types of Employment"} 
                                ValueTag={"employment_type"} 
                                PlaceHolder={"Select Types of Employment.."} 
                                Icon={faUser} 
                                list={["Permanent", "Contract"]} 
                            />

                            {
                                DataPkg.employment_type !== undefined ?
                                DataPkg.employment_type === "Contract" ? 
                                    <EmpTextInput 
                                    State={ContractField_State}
                                    Data={DataPkg} 
                                    OnChangeUpdate={updateInput} 
                                    Title={"Contractual period"} 
                                    ValueTag={"employment_contract_period"} 
                                    ValueType='number'
                                    Icon={faSignature} 
                                    PlaceHolder={"Contractual period In Days"}
                                /> : '' : ''
                            }

                            

                            <EmpTextInput 
                                State={EmpSDField_State}
                                Data={DataPkg} 
                                OnChangeUpdate={updateInput} 
                                Title={"Employment Start Date"} 
                                ValueTag={"employment_start_date"} 
                                ValueType='date'
                                Icon={faSignature} 
                                PlaceHolder={"Employment Start Date"}
                            /> 

                            <DynamicDropDownInput 
                                State={ShiftField_State}
                                URL={'http://localhost:3333/master-data/get-shift'}
                                Data={DataPkg} 
                                DDDataUpdater={DataUpdater}
                                // OnChangeUpdate={updateInput} 
                                Title={"Shift"} 
                                ValueTag={"emp_shift"} 
                                Icon={faPersonWalkingArrowLoopLeft} 
                                PlaceHolder={"Shift"}
                            />

                        </div>
                        
                        <div className={`${classes.emp_section_wrapper}`}>

                            <EmpTextInput 
                                State={WeeklyPayField_State}
                                Data={DataPkg} 
                                OnChangeUpdate={updateInput} 
                                Title={"Weekly Pay"} 
                                ValueTag={"weekly_pay"} 
                                Icon={faDollarSign} 
                                PlaceHolder={"Weekly Pay"}
                            /> 
                            <EmpTextInput 
                                State={MonthlyPayField_State}
                                Data={DataPkg} 
                                OnChangeUpdate={updateInput} 
                                Title={"Monthly Salary"} 
                                ValueTag={"monthly_salary"} 
                                Icon={faDollarSign} 
                                PlaceHolder={"Monthly Salary"}
                            />
                            <EmpTextInput 
                                State={MaxCreditField_State}
                                Data={DataPkg} 
                                OnChangeUpdate={updateInput} 
                                Title={"Max Credit Amount"} 
                                Tip={"The Maximume ammount of money you willing to credit for employee in percent"} 
                                ValueTag={"max_credit_amount"} 
                                Icon={faDollarSign} 
                                PlaceHolder={"Max Credit Amount"} 
                            />


                            <EmpTextInput 
                                State={PtoField_State}
                                Data={DataPkg} 
                                OnChangeUpdate={updateInput} 
                                Title={"PTO"} 
                                Tip={"Payed Time Off"} 
                                ValueTag={"pto"} 
                                Icon={faPersonHiking} 
                                PlaceHolder={"PTO"}
                            /> 
                            <DynamicDropDownInput 
                                State={BankField_State}
                                URL={'http://localhost:3333/master-data/get-bank'}
                                Data={DataPkg} 
                                DDDataUpdater={DataUpdater}
                                // OnChangeUpdate={updateInput} 
                                Title={"Bank"} 
                                ValueTag={"bank"} 
                                Icon={faBank} 
                                PlaceHolder={"Bank"}
                            />

                            <EmpTextInput 
                                State={AccountNumberField_State}
                                Data={DataPkg} 
                                OnChangeUpdate={updateInput} 
                                Title={"Account Number"}  
                                ValueTag={"account_number"} 
                                Icon={faFileLines} 
                                PlaceHolder={"Bank Account Number"} 
                            />

                        </div>
                        

                    </div>

                </div>
        </div>
    )
})

export default CompanyProfileCollector;