import { faPen, faPenNib, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ORIDataTableStyle.css'

export default function ORITableActionButton({ID, HandleEditCallback, HandleRemoveCallback}) 
{
    return (
        <div className="ori-table-action-button-wrapper">
            <div className="edit-button-wrapper">
                <button onClick={() => {HandleEditCallback(ID)}}><FontAwesomeIcon icon={faPenNib} /></button>
            </div>
            <div className="remove-button-wrapper">
                <button onClick={() => {HandleRemoveCallback(ID)}}><FontAwesomeIcon icon={faTrash} /></button>
            </div>
        </div>
    );
}