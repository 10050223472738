import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import classes from './CreateEmployee.module.css'
import PersonalInfoCollector from './Component/PersonalInfoCollector/PersonalInfoCollector';
import PicAndDocCollector from './Component/PicAndDocCollector/PicAndDocCollector';
import CompanyProfileCollector from './Component/CompanyProfileCollector/CompanyProfileCollector';
import FinalizeCollector from './Component/FinializeCollector/FinalizeCollector';
import { useRef, useState } from 'react';
import axios from 'axios';
import NotificationPopUp from '../NotificationPopUp/NotificationPopUp';

export default function CreateEmployee({CKey, Open, branchID, OpenController, HandleNoteficationComplete, HandleNoteficationNotQuit}) 
{
    // Ref
    const MainEmployeeFormRef = useRef();
    const FirstLevelInputCollectRef = useRef();
    const SecondeLevelInputCollectorRef = useRef();
    const ThirdLevelInputCollectorRef = useRef();
    const [Registering, SetRegistring] = useState(false);


    const totalTabCount = 4;
    const [ActiveTab, SetActiveTab] = useState(1);

    const [employeeData, SetEmployeeData] = useState([
        // {full_name: ''},
        // {phone: ''},
        // {email: ''},
        // {date: ''},
        // {age: ''},
        // {gender: ''},
        // {address: ''},
        // {emerg_full_name: ''},
        // {emerg_phone: ''},
        // {education_status: ''},
        // {field_of_study: ''},
        // {institution: ''},
        // {nationality: ''},

        // {employee_picture: ''},
        // {employee_certification: ''},
        // {employee_kebele_id: ''},
        // {employee_guarantee_kbl_id: ''},
        // {employee_guarantee_doc: ''},

        // {department: ''},
        // {job_title: ''},
        // {job_position: ''},
        // {employment_start_date: ''},
        // {employment_end_date: ''},
        // {emp_shift: ''},
        // {weekly_pay: ''},
        // {monthly_salary: ''},
        // {max_credit_amount: ''},
        // {pto: ''},
        // {bank: ''},
        // {account_number: ''}


    ]);

    function HandleEmployeeDataUpdate(e) 
    {
        SetEmployeeData(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    }

    function ApproveNextTabOrder() 
    {
        // Check all required field

        if(ActiveTab === 1) 
        {
            console.log(employeeData);
            if(!FirstLevelInputCollectRef.current.PerformLocalInputGuard()) 
                return false;
        }

        if(ActiveTab === 2) 
        {
            if(!SecondeLevelInputCollectorRef.current.PerformLocalInputGuard()) 
            {
                return false;
            }
        } 
        if(ActiveTab === 3) 
        {
            if(!ThirdLevelInputCollectorRef.current.PerformLocalInputGuard()) 
            {
                return false;
            }
        } 
    

        return true;
    }
    function voidCaller() 
    {

    }
    function HandleNextTabClick(e) 
    {
        e.preventDefault();
        if(!ApproveNextTabOrder()) 
        {
            return;
        }
        if(ActiveTab === totalTabCount)
        {
            MainEmployeeFormRef.current.reset();
            //Ignite backend process
            SetRegistring(true);
            setTimeout(()=>{
                const fd = new FormData();
                
                fd.append("full_name", employeeData.full_name);
                fd.append("phone", employeeData.phone);
                fd.append("email", employeeData.email);
                fd.append("date", employeeData.date);
                fd.append("age", employeeData.age);
                fd.append("gender", employeeData.gender);
                fd.append("address", employeeData.address);
                fd.append("emerg_full_name", employeeData.emerg_full_name);
                fd.append("emerg_phone", employeeData.emerg_phone);
                fd.append("education_status", employeeData.education_status);
                fd.append("field_of_study", employeeData.field_of_study);
                fd.append("institution", employeeData.institution);
                fd.append("nationality", employeeData.nationality);
                fd.append("employee_work_space", employeeData.employee_work_space);

                fd.append("employee_picture", employeeData.employee_picture);
                fd.append("employee_certification", employeeData.employee_certification);
                fd.append("employee_kebele_id", employeeData.employee_kebele_id);
                fd.append("employee_guarantee_kbl_id", employeeData.employee_guarantee_kbl_id);
                fd.append("employee_guarantee_doc", employeeData.employee_guarantee_doc);

                fd.append("department", employeeData.department);
                fd.append("job_position", employeeData.job_position);
                fd.append("employment_start_date", employeeData.employment_start_date);
                fd.append("employment_contract_period", employeeData.employment_contract_period);
                fd.append("employment_type", employeeData.employment_type);
                fd.append("emp_shift", employeeData.emp_shift);
                fd.append("weekly_pay", employeeData.weekly_pay);
                fd.append("monthly_salary", employeeData.monthly_salary);
                fd.append("max_credit_amount", employeeData.max_credit_amount);
                fd.append("pto", employeeData.pto);
                fd.append("bank", employeeData.bank);
                fd.append("account_number", employeeData.account_number);
                fd.append("branch_id", branchID);
                
                axios.post('http://localhost:3333/employee/create/', fd, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    })
                .then(res => {
                    SetRegistring(false);
                    // window.location.reload();
                    if(res.data.MyStateCode === 1) 
                    {
                        HandleNoteficationComplete(res.data.Msg, res.data.MyStateCode, CKey);
                    } else //if(res.data.MyState === -1 || res.data.MyState === 0) 
                    {
                        HandleNoteficationNotQuit(res.data.Msg, res.data.MyStateCode, CKey);
                    } 
                    
                })
                .catch(err => {
                    if(err.data === undefined) 
                    {
                        HandleNoteficationNotQuit("Internal Server Error!", -1, CKey);
                    } else 
                    {
                        HandleNoteficationNotQuit(err.data.Msg, err.data.MyStateCode, CKey);
                    }
                    SetRegistring(false);
                })
            }, 2000)

            return;
        }
        SetActiveTab(ActiveTab + 1);
    }
    function HandlePrevTabClick() 
    {
        if(ActiveTab === 1)
        {
            return;
        }
        SetActiveTab(ActiveTab - 1);
    }
    return (
        <div className={`${Open ? classes.create_employee_main_wrapper : classes.create_employee_main_wrapper_hide}`}>

            <div className={`${Open ? classes.create_employee_inner_wrapper : classes.create_employee_inner_wrapper_hide}`}>
                 <div className={`${classes.create_employee_header}`}>
                    <div className={`${classes.emp_header_ch}`}>
                        <h2>Register <span className={`${classes.win_title}`}>New Employee</span></h2>
                    </div>
                    <div className={`${classes.emp_header_ch}`}>
                        <button onClick={() => {OpenController(false)}}><FontAwesomeIcon icon={faXmarkCircle}></FontAwesomeIcon></button>
                    </div>
                 </div>

                 <div className={`${classes.emp_crt_body_wrapper}`}>

                    <form ref={MainEmployeeFormRef} encType="multipart/form-data" onSubmit={HandleNextTabClick} action="POST">

                        {ActiveTab === 1 && 
                        <PersonalInfoCollector
                            ref={FirstLevelInputCollectRef}
                            DataPkg={employeeData}
                            DataPkgUpdate={SetEmployeeData}
                            updateInput={HandleEmployeeDataUpdate}
                         />}


                        {ActiveTab === 2 && 
                        <CompanyProfileCollector
                            ref={SecondeLevelInputCollectorRef}
                            DataPkg={employeeData}
                            DataUpdater={SetEmployeeData}
                            updateInput={HandleEmployeeDataUpdate}
                        />}
                        {ActiveTab === 3 && 
                        <PicAndDocCollector
                            ref={ThirdLevelInputCollectorRef}
                            DataPkg={employeeData}
                            DataUpdater={SetEmployeeData}
                            updateInput={HandleEmployeeDataUpdate}
                        />}
                        
                        {ActiveTab === 4 && 
                        <FinalizeCollector
                            DataPkg={employeeData}
                            DataUpdater={SetEmployeeData}
                            updateInput={HandleEmployeeDataUpdate}
                        />}
                            
                        <div className={`${classes.emp_reg_tab_controller}`}>
                            <button disabled={(ActiveTab === 1) || Registering} onClick={() => {HandlePrevTabClick()}} className={`${ (ActiveTab === 1) || Registering ? classes.emp_button_disable : ''}`} type='button'>Previous</button>
                            <button disabled={Registering}  className={`${ Registering ? classes.emp_button_disable : ''}`} onClick={(e) => {ActiveTab !== totalTabCount ? HandleNextTabClick(e) : voidCaller()}} type={ActiveTab === totalTabCount ? 'submit' : 'button'}>{ActiveTab === totalTabCount ? Registering ? <FontAwesomeIcon icon={faSpinner} spin/> : "Register" : "Next"}</button>
                        </div>
                       
                    </form>

                 </div>
            </div>

        </div>
    );
}