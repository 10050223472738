import { useState } from 'react';
import classes from './ManageDistribution.module.css'
import {FetchCKORderGroupForShipping} from '../../../SharedComp/API/CK/AP_CenteraKitchenOrder'
import { useEffect } from 'react';
import DistributionGroup from './Component/DistributionGroup/DistributionGroup';
import { useAuth } from '../../../AuthProvider';

export default function ManageDistribution() 
{

    const auth = useAuth();
    const [OrderGroupList, SetOrderGroupList] = useState([]);

    const FetchGroupList = async() =>
    {
        try 
        {
            const fd = new FormData();

            fd.append("processor_workshop_id", auth.activeWorkShop);
            

            const main_res = await FetchCKORderGroupForShipping(fd, 'token');
            SetOrderGroupList(main_res);
        } catch(err) 
        {

        }
    }

    useEffect(()=>{
        FetchGroupList();
    }, [auth.activeWorkShop])



    return (
        <div className={`${classes.manage_distribution_master_wrapper}`}>

                

                <div className={`${classes.shiping_group_list_wrapper}`}>

                    {
                        OrderGroupList.map((item, idx)=>(

                            <DistributionGroup Data={item} key={idx}/>
                            
                        ))
                    }

                </div>


        </div>
    )
}