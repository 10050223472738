import { useEffect, useState } from 'react'
import classes from './XChefProductionStackGroup.module.css'

import {QueryDispatchedOrderForChef, QueryChefsProductionStack} from '../../../../../SharedComp/API/Kicthen/API_FE_Kicthen'
import {CreateChefNotifMsg} from '../../../../../SharedComp/API/Message/API_FE_Message'

import { useToast } from '../../../../../ToastContext'
import { useAuth } from '../../../../../AuthProvider';

import UIButton from '../../../../Components/UIButton/UIButton'
import { CheckCircle, Spinner, Warning, WarningDiamond } from '@phosphor-icons/react';


import BarLoader from '../../../../../SharedComp/BarLoader/BarLoader';

import TimeAgo from '../../../../../SharedComp/TimeElapsed/TimeAgo';
import { useDAControl } from '../../../../../DAControlContext'

export default function XChefProductionStackGroup({TargetData}) 
{

    const notify = useToast();
    const auth = useAuth();
    const DAControl = useDAControl(); 


    const [RawDispatchedOrderList, SetRawDispatchedOrderList] = useState([]);
    const [RawProductionStackList, SetRawProductionStackList] = useState([]);

    const QueryCustomDispatchedOrder = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("employee_id", TargetData.EmployeeID);
            fd.append("workspace_id", TargetData.BranchID);

            const main_res = await QueryDispatchedOrderForChef(fd, 'token');
            SetRawDispatchedOrderList(main_res);

        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }
    const QueryCustomProductionStack = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("user_account_id", TargetData.UserAccountID);
            fd.append("branch_id", TargetData.BranchID);
            fd.append("choice_date", new Date());

            const main_res = await QueryChefsProductionStack(fd, 'token');
            SetRawProductionStackList(main_res);

        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }


    useEffect(()=>{

        QueryCustomDispatchedOrder();
        QueryCustomProductionStack();


    }, [])


    const HandleNotifyMsg = async(item)=>
    {
        notify.Close(10);
        notify.ShowLoad("Sending Auto msg....", 10);
        setTimeout(async ()=>
        {
            try 
            {

                const fd = new FormData();
                fd.append("send_full_name", auth.user.FirstName + ' ' + auth.user?.LastName);
                fd.append("sender_id", auth.user.UserAccountID);
                fd.append("receiver_id", TargetData.UserAccountID);
                fd.append("target_item_name", item.CKP_ItemName);
                fd.append("target_item_quantity", item.CKDPC_OrderedQuantity);

                const main_res = await CreateChefNotifMsg(fd, 'token');
                notify.ShowSuccessUpdate(main_res.Msg, 10);
                

            }catch(err) 
            {
                notify.ShowErrorUpdate("Falied to send message!", 10)
            }
        }, 1500)
    }


    return (
        <div className={`${classes.x_chef_production_stack_group_wrapper}`}>
                <div className={`${classes.x_chef_production_header}`}>
                    <h2>{TargetData.FirstName} {TargetData.LastName} #Task View</h2>
                </div>

                <div className={`${classes.x_chef_task_body_wrapper}`}>
                    
                    <div className={`${classes.chef_profile_outer_wrapper}`}>
                        <div className={`${classes.chef_profile}`}>
                            <img src="https://cdn.pixabay.com/photo/2015/03/04/22/35/avatar-659651_640.png" alt="" />

                            <div className={`${classes.owner_name_and_stuff}`}>
                                <li>{TargetData.FirstName} {TargetData.LastName}</li>
                                <li>{TargetData.RoleNameLazy}</li>
                            </div>
                        </div>

                        <div className={`${classes.active_task_bundle_wrapper}`}>

                            <div className={`${classes.data_view_mini_header}`}>
                                <h3>Dispatched </h3>
                            </div>
                            <div className={`${classes.dispatched_production_order}`}>
                                {
                                    RawDispatchedOrderList.map((item, idx)=>(
                                        <li key={idx}>
                                            <span>{item.CKP_ItemName}</span>
                                                <span>{item.CKDPC_OrderedQuantity}</span>
                                                <span className={`${item.CKDPC_ProductionStatus === "Processed" ? classes.state_proccessed : classes.state_unppro } `}>
                                                {item.CKDPC_ProductionStatus === "Processed" ? <CheckCircle weight='bold'/> : <Spinner weight='bold'/> }  {item.CKDPC_ProductionStatus === "Pending" ?  "Waiting Action" : "Stock Requested"}
                                                </span>
                                            <span>{item.CKDPC_ProductionStatus === "Processed" ? '-' : <TimeAgo date={item.CKDPC_DispatcheDateTime}/>}</span>
                                            <span className={`${classes.action_buttons_wrapper} `}>
                                                <UIButton onClick={()=>{HandleNotifyMsg(item)}} Icon={<WarningDiamond weight='bold' />} label={"Alert"} Variant={item.CKDPC_ProductionStatus !== "Processed" ? "Danger" : "Disabled"}/>
                                            </span>
                                        </li>
                                    ))
                                }
                            </div>

                            <div className={`${classes.data_view_mini_header}`}>
                                <h3>Production Queue </h3>
                            </div>

                            <div className={`${classes.dispatched_production_order}`}>
                                {
                                    RawProductionStackList.map((item, idx)=>(
                                        <li key={idx}>
                                            <span>{item.CKP_ItemName}</span>
                                            <span>{item.CKPRBUF_Status}</span>
                                            <span>
                                                {
                                                     item.CKPRBUF_Status === "Processed" && <span>Took <span className={`${classes.minute_value}`}>{(((new Date(item.CKPRBUF_EndTime) - new Date(item.CKPRBUF_StartTime)) / 1000) / 60).toFixed(1) }</span>  min.</span>
                                                }  
                                                {
                                                     item.CKPRBUF_Status === "Pending" && "Waiting to Chef Begin"
                                                }
                                                {
                                                    item.CKPRBUF_Status === "Started" && <TimeAgo date={item.CKPRBUF_StartTime} show_suffix={true} />
                                                }
                                                {
                                                    item.CKPRBUF_Status === "Uninitialized" && "Waiting Stock Action"
                                                }
                                                {
                                                    item.CKPRBUF_Status === "Started"   &&
                                                    <BarLoader Percentage={((((new Date() - new Date(item.CKPRBUF_StartTime)) / 1000) / 60).toFixed(2) / (parseFloat(item.KV_PreparationTime))) * 100} Text={""}/> 
                                                }
                                            </span>
                                            {/* <span><TimeAgo date={item.CKPRBUF_StartTime}/></span> */}
                                            {/* <span>{item.CKPRBUF_Duration} {(((new Date() - new Date(item.CKPRBUF_StartTime)) / 1000) / 60).toFixed(2)} </span> */}
                                            {item.CKPRBUF_Status === "Pending" || item.CKPRBUF_Status === "Uninitialized" &&    <span className={`${classes.action_buttons_wrapper} `}>
                                                 <UIButton 
                                                    Icon={<Warning weight='bold' />} 
                                                    label={"Terminate"} 
                                                    Variant={item.CKPRBUF_Status !== "Processed" ? "Danger" : "Disabled"}
                                                    onClick={()=>
                                                    {
                                                        DAControl.Trigger(`Terminate Pending Order`,
                                                                            `You are about terminate chef's pendning order, this is possible becouse the assigned chef didn't request any
                                                                            stock yet. If you do this! The order considered rejected... `,
                                                                            true,
                                                                            7,
                                                                            (data)=>{
                                                                                if(data) 
                                                                                {

                                                                                }
                                                                            }
                                                                        )
                                                    }}
                                                 />
                                            </span>}
                                        </li>
                                    ))
                                }
                            </div>

                        </div>
                    </div>

                </div>
        </div>
    )
}