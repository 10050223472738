import { faLayerGroup, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import classes from './HROperationDataCreator.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRef, useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useEffect } from 'react';


function AllowanceInputDataHandler(HandleClose, Registring, SetRegistring, FormRef, NotificationTrigger) 
{
    function HandleLocalSubmit(e) 
    {
        SetRegistring(true);
        e.preventDefault();
        setTimeout(()=>{
            const fd = new FormData();
            
            console.log(e.target.allowance_type.value);
            fd.append("allowance_type", e.target.allowance_type.value);
            fd.append("allowance_detail", e.target.allowance_detail.value);
            
            axios.post('http://localhost:3333/master-data/add-allowance-type/', fd, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                })
            .then(res => {
                SetRegistring(false);
                // window.location.reload();
                if(res.data.MyStateCode === 1) 
                {
                    NotificationTrigger(res.data);
                    HandleClose();
                } else //if(res.data.MyState === -1 || res.data.MyState === 0) 
                {
                    NotificationTrigger(res.data);
                    HandleClose();
                } 
                
            })
            .catch(err => {
                if(err.data === undefined) 
                {
                    NotificationTrigger("Internal Server Error!", -1);
                    HandleClose();
                } else 
                {
                    NotificationTrigger(err.data.Msg, err.data.MyStateCode);
                    HandleClose();
                }
                SetRegistring(false);
            })
        }, 2000)
    }

    return (
        <form onSubmit={HandleLocalSubmit} ref={FormRef} action="POST">
            <div className={`${classes.main_form_wrapper}`}>      
                    <div className={`${classes.form_input_wrapper}`}>

                        <div className={`${classes.hr_input_wrapper}`}>
                            <div className={`${classes.hr_input_icon}`}>
                                <FontAwesomeIcon icon={faLayerGroup}/>
                            </div>
                            <div className={`${classes.hr_input_data}`}>
                                <input name='allowance_type' type="text" placeholder='Allowance Type' autoComplete='off' required />
                            </div>
                        </div>


                        <div className={`${classes.hr_input_wrapper}`}>
                            <div className={`${classes.hr_input_icon}`}>
                                <FontAwesomeIcon icon={faLayerGroup}/>
                            </div>
                            <div className={`${classes.hr_input_data}`}>
                                <input name='allowance_detail' type="text" placeholder='Detail' autoComplete='off' />
                            </div>
                        </div>

                        <div className={`${classes.action_button_wrapper}`}>
                            <button disabled={Registring} className={`${classes.action_button_save} ${Registring ? classes.is_registring : '' }`}>{Registring ? <FontAwesomeIcon icon={faSpinner} spin/> : "Save"}</button>
                            <button disabled={Registring} onClick={HandleClose} type='button' className={`${classes.action_button_disc} ${Registring ? classes.is_registring : '' } `}>Discard</button>
                        </div>

                    </div>

                </div>
        </form>
    )
}

function PenalityInputHandler(HandleClose, Registring, SetRegistring, FormRef, NotificationTrigger) 
{
    function HandleLocalSubmit(e) 
    {
        SetRegistring(true);
        e.preventDefault();
        setTimeout(()=>{
            const fd = new FormData();
            
            fd.append("penality_name", e.target.penality_name.value);
            fd.append("Penality_description", e.target.Penality_description.value);
            
            axios.post('http://localhost:3333/master-data/add-penality/', fd, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                })
            .then(res => {
                SetRegistring(false);
                // window.location.reload();
                if(res.data.MyStateCode === 1) 
                {
                    NotificationTrigger(res.data);
                    HandleClose();
                } else //if(res.data.MyState === -1 || res.data.MyState === 0) 
                {
                    NotificationTrigger(res.data);
                    HandleClose();
                } 
                
            })
            .catch(err => {
                if(err.data === undefined) 
                {
                    NotificationTrigger("Internal Server Error!", -1);
                    HandleClose();
                } else 
                {
                    NotificationTrigger(err.data.Msg, err.data.MyStateCode);
                    HandleClose();
                }
                SetRegistring(false);
            })
        }, 2000)
    }

    return (
        <form onSubmit={HandleLocalSubmit} ref={FormRef} action="POST">
            <div className={`${classes.main_form_wrapper}`}>      
                    <div className={`${classes.form_input_wrapper}`}>

                        <div className={`${classes.hr_input_wrapper}`}>
                            <div className={`${classes.hr_input_icon}`}>
                                <FontAwesomeIcon icon={faLayerGroup}/>
                            </div>
                            <div className={`${classes.hr_input_data}`}>
                                <input name='penality_name' type="text" placeholder='Penality Type' autoComplete='off' required />
                            </div>
                        </div>


                        <div className={`${classes.hr_input_wrapper}`}>
                            <div className={`${classes.hr_input_icon}`}>
                                <FontAwesomeIcon icon={faLayerGroup}/>
                            </div>
                            <div className={`${classes.hr_input_data}`}>
                                <input name='Penality_description' type="text" placeholder='Detail' autoComplete='off' />
                            </div>
                        </div>

                        <div className={`${classes.action_button_wrapper}`}>
                            <button disabled={Registring} className={`${classes.action_button_save} ${Registring ? classes.is_registring : '' }`}>{Registring ? <FontAwesomeIcon icon={faSpinner} spin/> : "Save"}</button>
                            <button disabled={Registring} onClick={HandleClose} type='button' className={`${classes.action_button_disc} ${Registring ? classes.is_registring : '' } `}>Discard</button>
                        </div>

                    </div>

                </div>
        </form>
    )
}
function PtoInputHandler(HandleClose, Registring, SetRegistring, FormRef, NotificationTrigger) 
{
    function HandleLocalSubmit(e) 
    {
        SetRegistring(true);
        e.preventDefault();
        setTimeout(()=>{
            const fd = new FormData();
            
            fd.append("Pto_type_name", e.target.Pto_type_name.value);
            fd.append("Pto_avail_day", e.target.Pto_avail_day.value);
            fd.append("pto_description", e.target.pto_description.value);
            
            axios.post('http://localhost:3333/master-data/add-pto/', fd, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                })
            .then(res => {
                SetRegistring(false);
                // window.location.reload();
                if(res.data.MyStateCode === 1) 
                {
                    NotificationTrigger(res.data);
                    HandleClose();
                } else //if(res.data.MyState === -1 || res.data.MyState === 0) 
                {
                    NotificationTrigger(res.data);
                    HandleClose();
                } 
                
            })
            .catch(err => {
                if(err.data === undefined) 
                {
                    NotificationTrigger("Internal Server Error!", -1);
                    HandleClose();
                } else 
                {
                    NotificationTrigger(err.data.Msg, err.data.MyStateCode);
                    HandleClose();
                }
                SetRegistring(false);
            })
        }, 2000)
    }

    return (
        <form onSubmit={HandleLocalSubmit} ref={FormRef} action="POST">
            <div className={`${classes.main_form_wrapper}`}>      
                    <div className={`${classes.form_input_wrapper}`}>

                        <div className={`${classes.hr_input_wrapper}`}>
                            <div className={`${classes.hr_input_icon}`}>
                                <FontAwesomeIcon icon={faLayerGroup}/>
                            </div>
                            <div className={`${classes.hr_input_data}`}>
                                <input name='Pto_type_name' type="text" placeholder='PTO Type Name eg. Sick / Wedding' autoComplete='off' required />
                            </div>
                        </div>

                        <div className={`${classes.hr_input_wrapper}`}>
                            <div className={`${classes.hr_input_icon}`}>
                                <FontAwesomeIcon icon={faLayerGroup}/>
                            </div>
                            <div className={`${classes.hr_input_data}`}>
                                <input name='Pto_avail_day' type="text" placeholder='Number of allowed day' autoComplete='off' required />
                            </div>
                        </div>


                        <div className={`${classes.hr_input_wrapper}`}>
                            <div className={`${classes.hr_input_icon}`}>
                                <FontAwesomeIcon icon={faLayerGroup}/>
                            </div>
                            <div className={`${classes.hr_input_data}`}>
                                <input name='pto_description' type="text" placeholder='Detail' autoComplete='off' />
                            </div>
                        </div>

                        <div className={`${classes.action_button_wrapper}`}>
                            <button disabled={Registring} className={`${classes.action_button_save} ${Registring ? classes.is_registring : '' }`}>{Registring ? <FontAwesomeIcon icon={faSpinner} spin/> : "Save"}</button>
                            <button disabled={Registring} onClick={HandleClose} type='button' className={`${classes.action_button_disc} ${Registring ? classes.is_registring : '' } `}>Discard</button>
                        </div>

                    </div>

                </div>
        </form>
    )
}
function JobPositionInputDataHandler(HandleClose, Registring, SetRegistring, FormRef, NotificationTrigger, handleSelect, optionList, selectedOptions, DepartmentValue) 
{
    function HandleLocalSubmit(e) 
    {
        SetRegistring(true);
        e.preventDefault();
        setTimeout(()=>{
            const fd = new FormData();
            
            fd.append("job_department", DepartmentValue.department);
            fd.append("job_position_name", e.target.job_position_name.value);
            fd.append("job_position_detail", e.target.job_position_detail.value);
            fd.append("job_position_rate", e.target.job_position_rate.value)
            
            axios.post('http://localhost:3333/master-data/add-job-position/', fd, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                })
            .then(res => {
                SetRegistring(false);
                // window.location.reload();
                if(res.data.MyStateCode === 1) 
                {
                    NotificationTrigger(res.data);
                    HandleClose();
                } else //if(res.data.MyState === -1 || res.data.MyState === 0) 
                {
                    NotificationTrigger(res.data);
                    HandleClose();
                } 
                
            })
            .catch(err => {
                if(err.data === undefined) 
                {
                    NotificationTrigger("Internal Server Error!", -1);
                    HandleClose();
                } else 
                {
                    NotificationTrigger(err.data);
                    HandleClose();
                }
                SetRegistring(false);
            })
        }, 2000)
    }


    const Custom_DD_Style = {
        
        control: (provided, state) => ({
            ...provided,
            width: 'auto',
            minHeight: 20,
            height: 30,
            fontWeight: 'bold',
            fontSize: 13,
            background: 'var(--background-dark)',
            border: 'none',
            borderColor: 'var(--background-light)',
            color: 'var(--primary_text_color)',
            borderRadius: 1,
            borderColor: 'none', 
            boxShadow: 'none', 
            '&:hover': {
                borderColor: 'none', 
                boxShadow: 'none', 
            },
            }),
            option: (provided) => ({
            ...provided,
            backgroundColor: 'var(--primary-color)', 
            color: 'var(--secondary-color)',
            }),
            option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? 'var(--body-background)' : 'var(--primary-color)', 
            color: state.isFocused ? 'var(--primary-color)' : 'var(--secondary-color)',
            transition: 'background-color 0.2s ease-in-out',
            cursor: 'pointer',
        }),
            menu: (provided) => ({
            ...provided,
            zIndex: 9999,
            backgroundColor: 'var(--primary-color)',
            color: 'var(--text_light_white)',
            fontSize: 13,
            borderRadius: 1,
            fontWeight: 500,
            }),
            singleValue: styles => ({
                ...styles,
                color: 'var(--text_light_white)', // Adjust this line for selected text color
              }),
        }

    return (
        <form onSubmit={HandleLocalSubmit} ref={FormRef} action="POST">
            <div className={`${classes.main_form_wrapper}`}>      
                    <div className={`${classes.form_input_wrapper}`}>

                    <div className={`${classes.hr_input_wrapper}`}>
                            <div className={`${classes.hr_input_icon}`}>
                                <FontAwesomeIcon icon={faLayerGroup}/>
                            </div>
                            <div className={`${classes.hr_input_data}`}>
                                <Select
                                    styles={Custom_DD_Style}
                                    onChange={handleSelect}
                                    name={"department"}
                                    options={optionList}
                                    placeholder={"Department"}
                                    value={selectedOptions}
                                    isSearchable={true}
                                    required ={true}
                                />
                            </div>
                        </div>

                        <div className={`${classes.hr_input_wrapper}`}>
                            <div className={`${classes.hr_input_icon}`}>
                                <FontAwesomeIcon icon={faLayerGroup}/>
                            </div>
                            <div className={`${classes.hr_input_data}`}>
                                <input name='job_position_name' type="text" placeholder='Job position name' autoComplete='off' required />
                            </div>
                        </div>

                        <div className={`${classes.hr_input_wrapper}`}>
                            <div className={`${classes.hr_input_icon}`}>
                                <FontAwesomeIcon icon={faLayerGroup}/>
                            </div>
                            <div className={`${classes.hr_input_data}`}>
                                <input name='job_position_rate' type="number" min={0} max={100} placeholder='Position rating 0 - 100' autoComplete='off' />
                            </div>
                        </div>

                        <div className={`${classes.hr_input_wrapper}`}>
                            <div className={`${classes.hr_input_icon}`}>
                                <FontAwesomeIcon icon={faLayerGroup}/>
                            </div>
                            <div className={`${classes.hr_input_data}`}>
                                <input name='job_position_detail' type="text" placeholder='Detail' autoComplete='off' />
                            </div>
                        </div>

                        <div className={`${classes.action_button_wrapper}`}>
                            <button disabled={Registring} className={`${classes.action_button_save} ${Registring ? classes.is_registring : '' }`}>{Registring ? <FontAwesomeIcon icon={faSpinner} spin/> : "Save"}</button>
                            <button disabled={Registring} onClick={HandleClose} type='button' className={`${classes.action_button_disc} ${Registring ? classes.is_registring : '' } `}>Discard</button>
                        </div>

                    </div>

                </div>
        </form>
    )
}

export default function HROperationDataCreator({StateController, ActiveState, MDType, NotificationTrigger}) 
{
    const FormRef = useRef();
    const [Registring, SetRegistring] = useState(false);

    const [selectedOptions, setSelectedOptions] = useState();
    const [optionList, setOptionList] = useState([]);
    const [DepartmentValue, SetDepartmentValue] = useState();

    function handleSelect(data) 
    {
        SetDepartmentValue(prev => ({
            ...prev,
            ["department"]: data.value
        }))
    }

    useEffect(() => 
    {
        QueryOption();
    }, [])
    function PrepList(data) {
        const newOptionList = [];
      
        for (var i = 0; i < data.length; i++) {
          
          let object = data[i];
      
          let value_key = null;
          let value_lable = null;
  
         // console.log(object);
          for (const key in object) {
  
            const active = String(key);
            if (active.toLowerCase().includes('name')) {
  
              const val = object[key];
              value_lable = val;
  
            } else if (active.toLowerCase().includes('id')) {
  
              const val = object[key];
              value_key = val;
  
            }
  
            
  
            if(value_lable !== null && value_key !== null) 
            {
              newOptionList.push({ value: value_key, label: value_lable  });
              break;
            }
  
          }
  
          
        }
      
        setOptionList(newOptionList);
      }
      function QueryOption() 
    {
        const URL = 'http://localhost:3333/master-data/get-department/';
        fetch(URL)
        .then(res => res.json())
        .then((data) => PrepList(data));
    }

    function HandleClose() 
    {
        FormRef.current.reset();
        StateController(false);
    }


    return (
        <div className={`${classes.hr_operation_data_creator_main_wrapper}`}>

            <div className={`${classes.hr_operation_creator_card}`}>

                <div className={`${classes.hr_operation_creator_card_header}`}>
                    <li>Registering {MDType}</li>
                    <li className={`${classes.close_icon}`}>
                        <button onClick={HandleClose}><FontAwesomeIcon icon={faTimesCircle}/></button>
                    </li>
                </div>

                <div className={`${classes.hr_creator_body}`}>

                    { MDType === "Allownace" && AllowanceInputDataHandler(HandleClose, Registring, SetRegistring, FormRef, NotificationTrigger)} 
                    { MDType === "Penality" && PenalityInputHandler(HandleClose, Registring, SetRegistring, FormRef, NotificationTrigger)} 
                    { MDType === "Pto" && PtoInputHandler(HandleClose, Registring, SetRegistring, FormRef, NotificationTrigger)} 
                    { MDType === "JobPosition" && JobPositionInputDataHandler(HandleClose, Registring, SetRegistring, FormRef, NotificationTrigger, handleSelect, optionList, selectedOptions, DepartmentValue)}

                </div>
            
            </div>

        </div>
    )
}