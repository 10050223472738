import classes from './PreOrder.module.css'
import UIButton from '../../Components/UIButton/UIButton'
import { GearSix, MoneyWavy, SpinnerGap, UserCircle } from '@phosphor-icons/react'
import { useState } from 'react'
import OrderProductCard from './Components/OrderProductCard/OrderProductCard'

import {QueryMasterProdCatValidToPreorder} from '../../../SharedComp/API/DeskView/API_FE_DeskView'
import { useAuth } from '../../../AuthProvider'
import { useToast } from '../../../ToastContext'
import { useEffect } from 'react'

import ReceiveOrder from './Components/ReceiveOrder/ReceiveOrder'

export default function PreOrder() 
{

    const [ActiveTask, SetActiveTask] = useState(0);
    const auth = useAuth();
    const notify = useToast();
    
    const HandleSwitchTask = async(idx)=> { SetActiveTask(idx); }


    

    return (
        <div className={`${classes.desk_view_manage_main_wrapper}`}>

            <div className={`${classes.desk_view_manage_header_wrapper}`}>

                <div className={`${classes.desk_view_manage_header_inner_wrapper}`}>

                    <UIButton Variant={ActiveTask === 0 ? "Primary" : "Secondary"} label={"Receive Order"} onClick={()=>{HandleSwitchTask(0)}} Icon={<UserCircle/>} />
                    <UIButton Variant={ActiveTask === 1 ? "Primary" : "Secondary"} label={"Active Order"} onClick={()=>{HandleSwitchTask(1)}} Icon={<GearSix/>} />
                    <UIButton Variant={ActiveTask === 2 ? "Primary" : "Secondary"} label={"Pending Payment"} onClick={()=>{HandleSwitchTask(2)}} Icon={<SpinnerGap/>} />
                    <UIButton Variant={ActiveTask === 3 ? "Primary" : "Secondary"} label={"Collect Cash"} onClick={()=>{HandleSwitchTask(3)}} Icon={<MoneyWavy/>} />

                </div>

            </div>

            <div className={`${classes.desk_view_manager_master_list_view_wrapper}`}>

                <div className={`${classes.desk_view_manager_inner_list_view_wrapper}`}>

                    {
                        ActiveTask === 0 && <ReceiveOrder/>
                        
                    }

                </div>

            </div>



        </div>
    )
}