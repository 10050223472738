import { useEffect, useState } from 'react';


import Login from './pages/Login/Login';
import Dashboard from                   './pages/Dashboard/Dashboard'
import UserManagment from               './pages/USerManagment/UserManagment'
import InventoryMasterDataEntry from    './pages/InventoryMasterData/InventoryMasterDataEntry';
import ProductionMasterDataEntry from   './pages/ProductionMasterData/ProductionMasterDataEntry';
import WarehouseItem from               './pages/WarehouseItem/WarehouseItem';
import YamMixType from                  './pages/YamMix/YamMixType';
import CompanyBranch from               './pages/CompanyBranch/CompanyBranch';
import CompanyOperationMasterData from  './pages/CompanyOperationMasterData/CompanyOperationMasterData';
import MaterialToolsMasterData from     './pages/MaterialToolMasterData/MaterialToolsMasterData';
import AssetToolsMasterData from        './pages/AssetToolMasterData/AssetToolsMasterData';
import ProductionOrder from             './pages/ProductionOrder/ProductionOrder';
import StockCard from                   './pages/StockCard/StockCard';
import StockOut from                    './pages/StockOut/StockOut'
import StockManager from                './pages/StockManager/StockManager';
import EmployeeManager from             './pages/Employee/EmployeeManager';
import HROperationData from             './pages/HROperationData/HROperationData';
import InventoryIdentifier from         './pages/Inventory/InventoryIdentifier/InventoryIdentifier';
import NonEdibleGoods from              './pages/Inventory/NonEdibleGoods/NonEdibleGoods';
import InventoryMasterData from         './pages/Inventory/EdibleGoods/InventoryMasterData';
import ProductionMix from               './pages/Production/ProductionMix/ProductionMix';
import PreOrder from                    './pages/FrontDesk/PreOrder/PreOrder';
import CashCollectionMaster from        './pages/FrontDeskManagment/CashCollection/CashCollectionMaster';
import Merchandise from                 './pages/Production/Merchandise/Merchandise';
import DomesticProduction from          './pages/Production/DomesticProduction/DomesticProduction';
import ProductionStack from             './pages/ProductionStack/ProductionStack';
import CashCollectionArchive from       './pages/Archive/CashCollectionArchive/CashCollectionArchive';
import LocalShopArchive from            './pages/Archive/LocalShopArchive/LocalShopArchive';
import Vendors from                     './pages/Archive/Vendors/Vendors';
import CKProduction from                './pages/Production/CKProduction/CKProduction';
import PittyCashBranchLevel from        './pages/PittyCashManagment/PittyCashBranch/PittyCashBranch';
import PittyCashFinanceLevel from       './pages/PittyCashManagment/PittyCashFinance/PittyCashFinance';
import RoleManagment from               './pages/AccountManagment/RolesManagment/RoleManagment';
import Warehouse from                   './pages/StockManager/Pages/Warehouse/Warehouse'
import AuditTrails from                 './pages/StockManager/Pages/AuditTrails/AuditTrails';
import TrackRequest from                './pages/StockManager/Pages/TrackRequest/TrackRequest';
import TransferStock from               './pages/StockManager/Pages/TransferStock/TransferStock';
import Layout from                       './Layout/Layout'
import Products from                    './pages/Production/Products/Products';
import UnknownRoute from                './pages/404/UnknownRoute';
import WhatsGPS from                    './pages/DeviceIntegration/Vehicle/WhatsGPS'
import KitchenVariants from             './pages/Production/KitchenVariants/KitchenVariants';
import OfflinePage from                 './pages/Offline/Offline';

import KitchenSupplyOrder from          './pages/ManageOrders/KitchenSupplyOrder/KitchenSupplyOrder';
import LocalOrders from                 './pages/ManageOrders/LocalOrder/LocalOrders';
import YAMOnlineOrders from             './pages/ManageOrders/YAMOnlineOrders/YAMOnlineOrders';
import ProductionControlCenter from     './pages/Kitchen/ProductControlCenter/ProductionControlCenter';
import AwaitingDispatch from            './pages/Kitchen/AwaitingDispatch/AwaitingDispatch';


import ChefsDashboard from              './pages/Chefs/ChefsDashboard/ChefsDashboard';
import ChefsPerformance from            './pages/Chefs/ChefsPerformance/ChefsPerformance';
import ChefsStockRequest from './pages/Kitchen/ChefsStockRequest/ChefsStockRequest';

import ChefLineup from './pages/Kitchen/ChefLineup/ChefLineup';

import OnProductionLine from './pages/Kitchen/OnProductionLine/OnProductionLine';
import ManageDistribution from './pages/Kitchen/ManageDistribution/ManageDistribution';

import DistributionArchive from './pages/Kitchen/DistributionArchive/DistributionArchive';

import classes from './App.module.css'

import AuthProvider from './AuthProvider';
import { ToastProvider } from './ToastContext';
import { GlobalNotificationProvider } from './GlobalNotification';
import PrivateRoute from './ProtectedRoute';
import { DAControlProvider } from './DAControlContext';

// Router
import { BrowserRouter, Routes, Route  } from 'react-router-dom';


function App() {


  

  return (

    <BrowserRouter>
    <GlobalNotificationProvider>
      <ToastProvider>
        <DAControlProvider>
          <AuthProvider>
            <Routes>
              <Route path='/' element={<Login />} />
              <Route exact path='/login' element={<Login />} />
              <Route exact path='/offline' element={<OfflinePage />} />
                  <Route element={<PrivateRoute />} >
                    <Route path='/dashboard_master'                         element={<Layout> <Dashboard />                         </Layout>} />
                    <Route path='/user-account'                             element={<Layout> <UserManagment />                     </Layout>} />
                    <Route path='/inventory-master-data-entry'              element={<Layout> <InventoryMasterData />               </Layout>} />
                    <Route path='/inventory-non-edible-master-data-entry'   element={<Layout> <NonEdibleGoods />                    </Layout>} />
                    <Route path='/production-master-data-entry'             element={<Layout> <DomesticProduction />                </Layout>} />
                    <Route path='/warehouse-master-data-entry'              element={<Layout> <WarehouseItem />                     </Layout>} />
                    <Route path='/yam-mix-master-data-entry'                element={<Layout> <ProductionMix />                     </Layout>} />
                    <Route path='/company-branch-manager'                   element={<Layout> <CompanyBranch />                     </Layout>} />
                    <Route path='/operation-master-data'                    element={<Layout> <CompanyOperationMasterData />        </Layout>} />
                    <Route path='/material-tool-master-data'                element={<Layout> <MaterialToolsMasterData />           </Layout>} />
                    <Route path='/asset-tool-master-data'                   element={<Layout> <AssetToolsMasterData />              </Layout>} />
                    <Route path='/production-order'                         element={<Layout> <ProductionOrder />                   </Layout>} />
                    <Route path='/stock-card'                               element={<Layout> <StockCard />                         </Layout>} />
                    <Route path='/stock-out'                                element={<Layout> <StockOut />                          </Layout>} />
                    <Route path='/stock-in'                                 element={<Layout> <StockManager />                      </Layout>} />
                    <Route path='/employee'                                 element={<Layout> <EmployeeManager/>                    </Layout>} />
                    <Route path='/hr-employee-manager'                      element={<Layout> <HROperationData />                   </Layout>} />
                    <Route path='/inventory-identifier'                     element={<Layout> <InventoryIdentifier />               </Layout>} />
                    <Route path='/inventory-merchandise'                    element={<Layout> <Merchandise />                       </Layout>} />
                    <Route path='/prod-stack'                               element={<Layout> <ProductionStack />                   </Layout>} />
                    <Route path='/pre-order'                                element={<Layout> <PreOrder />                          </Layout>} />
                    <Route path='/cash-collection-master'                   element={<Layout> <CashCollectionMaster />              </Layout>} />
                    <Route path='/local-shop-archive'                       element={<Layout> <LocalShopArchive />                  </Layout>} />
                    <Route path='/cash-collection-archive'                  element={<Layout> <CashCollectionArchive />             </Layout>} />
                    <Route path='/vendors'                                  element={<Layout> <Vendors />                           </Layout>} />
                    <Route path='/ck-production'                            element={<Layout> <CKProduction />                      </Layout>} />
                    <Route path='/pitty-cash-branch-level'                  element={<Layout> <PittyCashBranchLevel />              </Layout>} />
                    <Route path='/pitty-cash-finance-level'                 element={<Layout> <PittyCashFinanceLevel />             </Layout>} />
                    <Route path='/user-role'                                element={<Layout> <RoleManagment />                     </Layout>} />
                    <Route path='/kitchen-variants-master-data-entry'       element={<Layout> <KitchenVariants />                   </Layout>} />

                    <Route path='/stock-warehouse'                          element={<Layout> <Warehouse />                         </Layout>} />
                    <Route path='/stock-track-request'                      element={<Layout> <TrackRequest />                      </Layout>} />
                    <Route path='/stock-trasnfer-stock'                     element={<Layout> <TransferStock />                     </Layout>} />
                    <Route path='/stock-audit-trails'                       element={<Layout> <AuditTrails />                       </Layout>} />
                    <Route path='/master-product-line'                      element={<Layout> <Products/>                           </Layout>}/>

                    {/* Manage Orders */}
                    <Route path='/kitchen-supply-order'                      element={<Layout> <KitchenSupplyOrder/>                 </Layout>}/>
                    <Route path='/yam-online-order'                          element={<Layout> <YAMOnlineOrders/>                    </Layout>}/>
                    <Route path='/local-front-order'                         element={<Layout> <LocalOrders/>                        </Layout>}/>

                    {/* Kitchen */}
                    <Route path='/production-control-center'                  element={<Layout> <ProductionControlCenter/>              </Layout>}/>
                    <Route path='/production-awaiting-dispatch'               element={<Layout> <AwaitingDispatch/>                     </Layout>}/>
                    <Route path='/chefs-stock-request'                        element={<Layout> <ChefsStockRequest/>                    </Layout>}/>
                    <Route path='/on-production-line'                         element={<Layout> <OnProductionLine/>                     </Layout>}/>
                    <Route path='/manage-workspace-order-distribution'        element={<Layout> <ManageDistribution/>                   </Layout>}/>
                    <Route path='/chef-lineup'                                element={<Layout> <ChefLineup/>                           </Layout>}/>
                    <Route path='/distribution-archive'                       element={<Layout> <DistributionArchive/>                  </Layout>}/>

                    {/* Chefs */}
                    {/* ChefsStockRequest */}
                    <Route path='/chefs_dashboard'                           element={<Layout> <ChefsDashboard/>                     </Layout>}/>
                    <Route path='/chefs_performance'                         element={<Layout> <ChefsPerformance/>                   </Layout>}/>



                    <Route path='/whats-gps-vehicle-integration'             element={<Layout> <WhatsGPS/>                           </Layout>}/>
                  </Route>
                  <Route path='*'                                         element={<UnknownRoute />} />
            </Routes>
          </AuthProvider>
        </DAControlProvider>
      </ToastProvider>
    </GlobalNotificationProvider>
    </BrowserRouter>

  );
}

export default App;
