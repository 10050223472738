import data_win from './DataCreator.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLayerGroup, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { Tooltip } from 'react-tooltip'
export default function MasterDataRegInput({input, tip, forceInput}) 
{
    return (
        <div className={`${data_win.form_input_with_icon} ${forceInput ? data_win.form_input_with_icon_force_input : ''}`} {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': tip})}>
            <Tooltip className={`${data_win.tool_quick}`} id="offical_doc" place="top" />
            <div className={`${data_win.form_input_child_icon_wrapper}`}><span className={`${data_win.form_input_icon}`}><FontAwesomeIcon icon={faLayerGroup}></FontAwesomeIcon></span></div>
            <div className={`${data_win.form_input_child_wrapper}`}>
                {input}
            </div>
        </div>
    )
}