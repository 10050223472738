import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import classes from './EmployeeMiniCardMaster.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Et, Us } from 'react-flags-select';

export default function EmployeeMiniCardMaster({EmpData, EmpViewController}) 
{
    const [imageSrc, setImageSrc] = useState(null);



    // useEffect(() => 
    // {
    //     fetch('http://localhost:3333/employee/Res/EmployeeDir/', {
    //         method: 'POST',
    //         headers: {
    //           'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify({ Name: EmpData.FullName, Pic: EmpData.EmployeePhoto  }),
    //       })//BranchData.BranchID
    //         .then(res => res.json())
    //         .then((data) => SetBranchEmployee(data));
    // }, [])

    // useEffect(() => {
    //   const fetchData = async () => {
    //     try {
    //       const response = await fetch('http://localhost:3333/employee/Res/EmployeeDir/', {
    //         method: 'POST',
    //         headers: {
    //           'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify({ Name: EmpData.FullName, Pic: EmpData.EmployeePhoto  }),
    //       });
          
    //       if (!response.ok) {
    //         throw new Error('Network response was not ok');
    //       }

    //       console.log(response);
    //       const blob = await response.blob();
    //       const imageUrl = URL.createObjectURL(blob);
    //       setImageSrc(imageUrl);
    //     } catch (error) {
    //       console.error('Error fetching image:', error);
    //     }
    //   };
  
    //   fetchData();
    // }, []);
    return (
        <div onClick={() => {EmpViewController(true, EmpData)}} className={`${classes.employee_master_card_main_wrapper}`}>
            <div className={`${classes.employee_master_card_header}`}>
                <div className={`${classes.employee_master_card_header_inner}`}> 
                    <img className={`${classes.employee_master_card_header_image}`} src="https://cdn.pixabay.com/photo/2015/03/04/22/35/avatar-659651_640.png" alt="" />
                    {/* "https://cdn.pixabay.com/photo/2015/08/05/04/02/people-875597_640.jpg" */} 
                </div>
                <div className={`${classes.employee_master_card_header_inner}`}>
                    <li className={`${classes.employee_name_in_master_Card}`}>{ EmpData.FullName}  <sub className={`${classes.employee_workspace}`}>[{EmpData.Workspace}]</sub></li>
                    <li><FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon> {EmpData.Email}</li>
                    <li><FontAwesomeIcon icon={faPhone}></FontAwesomeIcon> {EmpData.PhoneNumber}</li>
                </div> 
            </div>
        </div>
    );
}