import classes from './LocalOrders.module.css'


export default function LocalOrders() 
{


    return (
        <div>
            <h1>Local Order</h1>
        </div>
    )
}