import { faPenNib, faPlus, faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons'
import classes from './ProductionMix.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'

import NotificationPopUp from '../../../SharedComp/NotificationPopUp/NotificationPopUp'
// Mix data

import MixData from './Component/MixData/MixData'
import MixDataEdit from './Component/MixDataEdit/MixDataEdit'

import axios from 'axios';
import { useDAControl } from '../../../DAControlContext'
import { useToast } from '../../../ToastContext'
import {PurgeYamMixID} from '../../../api'
import { getBaseURL } from '../../../SharedComp/BaseUrlConfig'


export default function ProductionMix() 
{ 
    const [ReloadQuery, SetReloadQuery] = useState(false);
    const [OpenMixData, SetOpenMixData] = useState(false);
    const [OpenMixDataEdit, SetOpenMixDataEdit] = useState(false);
    const [openNoti, SetOpenNoti] = useState(false);
    const [MsgPkg, SetMsgPkg] = useState({});
    const [DBMixData, SetDBMixData] = useState([]);
    const [MixEditIdx, SetMixEditIdx] = useState();

    const DAControl = useDAControl();
    const notify = useToast();




    function HandleOpenMixData() 
    {
        SetOpenMixData(true);
    }

    function HandleMixEdit(id) 
    {
        SetMixEditIdx(id);
        SetOpenMixDataEdit(true);
    }

    function MsgTrigger(res)  
    {
        SetMsgPkg({
            Key: Math.random() * 1000,
            Msg: res.data.Msg,
            State:  res.data.MyStateCode
        })
        SetOpenNoti(true);
    }

    useEffect(()=>{

        fetch(getBaseURL() + 'master-data/get-mix')
        .then(res => res.json())
        .then((data) => SetDBMixData(data));

    }, [OpenMixData, OpenMixDataEdit, ReloadQuery])

    return (
        <div className={`${classes.production_mix_main_wrapper}`}>

            {openNoti && <NotificationPopUp key={MsgPkg.Key} msg={MsgPkg.Msg} state={MsgPkg.State}/>}
            {OpenMixData && <MixData OpenController={SetOpenMixData} MsgTrigger={MsgTrigger}/> }
            {OpenMixDataEdit && <MixDataEdit EditIdx={MixEditIdx} OpenController={SetOpenMixDataEdit} MsgTrigger={MsgTrigger}/> }

            <div className={`${classes.mix_box_wrapper}`}>
                    <div className={`${classes.production_main_list_upper}`}>
                    
                    <div className={`${classes.head_prod}`}>
                        <h3>Production Mix</h3>
                        <button onClick={()=>{HandleOpenMixData()}} className={`${classes.mix_top_button}`}><FontAwesomeIcon icon={faPlus}/></button>
                    </div>

                    <div className={`${classes.production_main_list}`}>
                        <div className={`${classes.mix_list_wrapper}`}>

                            {
                                DBMixData.map((item, index)=>(
                                    <li key={index}> 
                                        <span className={`${classes.mix_name}`}>{item.MixName} </span> 
                                        <span className={`${classes.mix_ing_count}`}> {item.IngredientsCount} Ingredients </span> 
                                        <span className={`${classes.mix_action}`}>
                                            <button onClick={()=>{HandleMixEdit(item.MID)}}><FontAwesomeIcon icon={faPenNib}/></button>
                                            <button onClick={()=>{DAControl.Trigger("Purge Product Recipe", 
                                                `You are about to purge the Production Mix '${item.MixName}'. This action will trigger severe consequences:
                                                Any active Product Item That are linked with this Recipe will be Hidden!This action is IRREVERSIBLE!`, 
                                                item.MID,
                                                10,
                                                async (data)=>{
                                                    if(!data)
                                                        return;

                                                        notify.ShowLoad(`Purging Item ${item.MixName}.....`, 10);

                                                        setTimeout(async()=>{

                                                            try 
                                                            {
                                                                const res = await PurgeYamMixID(data, 'token');
                                                                notify.ShowSuccessUpdate(res.Msg, 10);
                                                                SetReloadQuery(!ReloadQuery);
                                                            }catch(err) 
                                                            {
                                                                notify.ShowErrorUpdate(err.message, 10);
                                                            }

                                                        }, 500)


                                                })}}><FontAwesomeIcon icon={faTrash}/></button>
                                        </span> 
                                    </li>
                                ))
                            }
                        
                        </div>
                    </div>

                </div>
            </div>

            
        </div>
    )
}