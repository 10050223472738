import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircleCheck, faCircleNotch, faSpinner, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import classes from './EmpCredit.module.css'
import CreditAction from './Component/CreditAction/CreditAction';
import { useEffect, useState } from 'react';
import CleanList from './Component/CleanList/CleanList';
import { Tooltip } from 'react-tooltip';
import { getBaseURL } from '../../../../../SharedComp/BaseUrlConfig';

export default function EmpCredit({EmpData, MsgTrigger}) 
{
    const [creditHandler, SetCreditHandle] = useState(false);
    const [creditOperation, SetCreditOperation] = useState('');

    const [employeeCredit, SetEmployeeCredit] = useState([]);
    const [employeeCreditHist, SetEmployeeCreditHist] = useState([]);

    const [employeeCreditLength, SetEmployeeCreditLength] = useState(1);
    const [employeeCreditHistLength, SetEmployeeCreditHistLength] = useState(1);
    const [empCredit, SetEmpCredit] = useState(0);
    const [creditHandleKey, SetCreditHandleKey] = useState(1);


    function HandleCreditCollectClick(id) 
    {
        SetCreditHandleKey(Math.random() * 100);
        SetCreditHandle(true); 
        SetCreditOperation("Collect")
        SetEmpCredit(id);
    }

    useEffect(() => 
    {
        fetch(getBaseURL() +  'employee/query_employee_credit/', { 
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ EmpID: EmpData.EmployeeID }),
          })
            .then(res => res.json())
            .then((data) => {SetEmployeeCredit(data); SetEmployeeCreditLength(data.length)});


            fetch(getBaseURL() +  'employee/query_employee_credit_hist/', { 
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ EmpID: EmpData.EmployeeID }),
          })
            .then(res => res.json())
            .then((data) => {SetEmployeeCreditHist(data); SetEmployeeCreditHistLength(data.length)});



    }, [creditHandler])

 
    return (
        <div className={`${classes.emp_credit_main_wrapper}`}>
            <Tooltip className={`${classes.tool_quick}`} id="offical_doc" place="top" />
            <CreditAction MsgTrigger={MsgTrigger} creditID={empCredit} EmpData={EmpData} Open={creditHandler} OpenController={SetCreditHandle} Operation={creditOperation}/>

            <div className={`${classes.emp_action_adder}`}>
                <button onClick={() => {SetCreditHandle(true); SetCreditOperation("Issue")}}>Credit Cash</button>
            </div>
            
            <div className={`${classes.amp_credit_info}`}>
                    {/* Unpayed Credit */}
                    <div className={`${classes.emp_credit_inner_wrapper}`}>
                        
                        <div className={`${classes.unpayed_credit_list_wrapper}`}>

                        {employeeCreditLength > 0 ? employeeCredit.map((item, index) => (
                            <li {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': item.CreditRemark})}
                             key={index} onClick={() => {HandleCreditCollectClick(item.WSEMPCID)}}> 
                             
                             <span>
                                 {new Date(item.CreditApprovedDate).toLocaleString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                             </span>
                            
                            <span className={`${classes.unpayed_credit_amount}`}>
                                {
                                    (item.CreditAmount).toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'ETB',
                                    })
                                }
                            </span> 

                            </li>
                        )) : <CleanList/>}

                        </div>
                    </div>

                    {/* History */}
                    <div className={`${classes.emp_credit_inner_wrapper}`}>

                        <div className={`${classes.payed_credit_list_wrapper}`}>


                        {employeeCreditHistLength > 0 ? employeeCreditHist.map((item, index) => (
                            <li key={index}>{new Date(item.CreditApprovedDate).toLocaleString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })} <span className={`${classes.payed_credit_amount}`}>{item.CreditAmount}</span> <span className={`${classes.payed_credit_amount_remark}`}><FontAwesomeIcon icon={faCircleCheck}></FontAwesomeIcon></span></li>
                            
                        )) : <CleanList/>}
                        
                        </div>

                    </div>
            </div>

        </div>
    );
}

