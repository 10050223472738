import classes from './OrderProductCard.module.css'



export default function OrderProductCard({TargetData, isGroup = false}) 
{


    return (
        <div className={`${classes.order_product_card_main_wrapper}`}>
            <div className={`${classes.order_product_card_content_wrapper}`}>
                <h2>{TargetData.ProdCatName}</h2>
                <li>{isGroup ? "Group" : "ETB 3800.00"}</li>
            </div>
        </div>
    )
}