import { getBaseURL } from "../../BaseUrlConfig";
import axios from 'axios'

function GetRouteConfig(token)
{
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
}

export const CreateKVType = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'kv/create-kv-type', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateKVType = async(data, token)=>
    {
        try  
        {
            const response = await axios.post(getBaseURL() + 'kv/update-kv-type', data, GetRouteConfig(token));
            const res = await response.data;
            return res;
        }catch(err) 
        {
            throw err;
        }
    }
export const FetchKVType = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'kv/fetch-kv-type', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeKVType = async(_id, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'kv/purge-kv-type', {ID: _id}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchKVTypeByID = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'kv/fetch-kv-type-id', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchKVIngByTypeID = async(data, token)=>
    {
        try  
        {
            const response = await axios.post(getBaseURL() + 'kv/fetch-kv-ing-by-type-id', data, GetRouteConfig(token));
            const res = await response.data;
            return res;
        }catch(err) 
        {
            throw err;
        }
    }

