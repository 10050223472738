import classes from './PrintProductionLogView.module.css'
import data_win from '../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useReactToPrint } from 'react-to-print'
import { useEffect, useRef } from 'react'
import { useState } from 'react'
import { useAuth } from '../../../../AuthProvider'
import { useToast } from '../../../../ToastContext'

import PaperHeader from '../../../../SharedComp/PaperHeader/PaperHeader'
import PaperHash from '../../../../SharedComp/PaperHash/PaperHash'


const formatter_currency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'ETB', 
    minimumFractionDigits: 2, 
    maximumFractionDigits: 2
});
const formatter = new Intl.NumberFormat('en-US', { 
    minimumFractionDigits: 0, 
    maximumFractionDigits: 0
})
const formatter_Frack = new Intl.NumberFormat('en-US', { 
    minimumFractionDigits: 2, 
    maximumFractionDigits: 2
})


export default function PrintProductionLogView({DataList, TargetDate, Close_CALLBACK})
{

    const auth = useAuth();
    const notify = useToast();
    const print_ref = useRef();
    const [ProcessedList, SetProcessedList] = useState([]);

    const [SummeryPrice, SetSummeryPrice] = useState(0)
    const [SummeryQuantity, SetSummeryQuantity] = useState(0)
    const [SummeryResource, SetSummeryResource] = useState(0)

    const PrintDoc = useReactToPrint({
        documentTitle: auth.activeWorkShopName + "_Production Log" + "_" + new Date().toDateString(),
        content: () => print_ref.current,
        onAfterPrint: ()=>{
            
        }
    });

    const SumUpPriceQuantity = async()=> 
    {
        let __price = 0;
        let __quantity = 0;
        let __resource = 0;

        for(let i = 0; i < DataList.length; i++) 
        {
            __quantity += parseInt(DataList[i].SCM_Quantity);
            __price += DataList[i].FProdUnitPrice * DataList[i].SCM_Quantity;
            __resource += DataList[i].SCM_Quantity * DataList[i].FProdGram;
        }

        SetSummeryQuantity(__quantity);
        SetSummeryPrice(__price);
        SetSummeryResource(__resource);
    }

    
    const GroupItems = async()=> 
    {
        SetProcessedList([]);
        const _data_buffer = new Map();
        for (let i = 0; i < DataList.length; i++) {
            const currentItem = {...DataList[i]};

            if(_data_buffer.has(currentItem.FProdName)) 
            {
                const _exist =_data_buffer.get(currentItem.FProdName);
                _exist.SCM_Quantity += currentItem.SCM_Quantity;
                // _exist.FProdGram += currentItem.FProdGram;
            } else 
            {
                _data_buffer.set(currentItem.FProdName, {...currentItem});
            }

        }

        SetProcessedList(Array.from(_data_buffer.values()));
    }
    const SortByName = async()=> 
    {
        const sortedData = [...ProcessedList].sort((a, b) => a.FProdName.localeCompare(b.FProdName));
        SetProcessedList(sortedData); 
    }


    useEffect(() => {
        if (DataList.length > 0) {
            GroupItems();
            SumUpPriceQuantity();
            // SortByName();
        }

    }, [DataList]);

    


    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>
            <div className={`${data_win.data_main} ${classes.data_main}`}>

                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        {auth.activeWorkShopName} <span className={`${data_win.data_create_name}`}>Production Log</span>
                    </h3>
                    <button onClick={()=>{Close_CALLBACK(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
                </div>

                <div className={`${classes.data_preview_wrapper}`}>

                <div className={`${classes.inner_preview_data_wrapper}`}>
                    <div ref={print_ref} className={`${classes.paper_wrapper_dash}`}>
                        <PaperHeader/>
                        <PaperHash/>
                        <div className={`${classes.meta_data_wrapper}`}>
                            <h2>[#] {auth.activeWorkShopName} </h2>
                            <h2>[#] Production Log </h2>
                        </div>

                        <div className={`${classes.transcation_owner_wrapper}`}>
                            <div className={`${classes.transcation_owner}`}>
                                <div className={`${classes.transcation_recive}`}>
                                    <h2>Processed By</h2>
                                    <li>{auth.employeeData.FullName}</li>
                                    <li>[Day - Night] - Shift</li>
                                </div>
 
                            </div>
                        </div>

                        <div className={`${classes.entry_detail}`}>
                            <li><span className={`${classes.entry_deatil_tag}`}>Production Date</span> <span className={`${classes.entry_deatil_val}`}>{new Date(TargetDate).toDateString()}</span></li>
                            <li><span className={`${classes.entry_deatil_tag}`}>Date Of Print</span> <span className={`${classes.entry_deatil_val}`}>{new Date().toDateString()}</span></li>
                        </div>  
                        
                        
                        <div className={`${classes.main_paper_data_wrapper}`}>

                            <div className={`${classes.paper_list_wrapper}`}>

                                        <li className={`${classes.paper_list_wrapper_header_tit}`}>
                                            <span>Name</span>
                                            <span className={`${classes.list_item_alignt_right}`}>Production Count</span>
                                            <span className={`${classes.list_item_alignt_right}`}>Usage</span>
                                            <span className={`${classes.list_item_alignt_right}`}>Sum Price</span>
                                        </li>

                                {
                                    ProcessedList.map((item, idx)=>(
                                        <li key={idx}>
                                            <span><FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon> {item.FProdName}</span>
                                            <span className={`${classes.list_item_alignt_right}`}>{formatter.format(item.SCM_Quantity)} Pcs</span>
                                            <span className={`${classes.list_item_alignt_right}`}>{formatter_Frack.format(item.FProdGram * item.SCM_Quantity)} Kg</span>
                                            <span className={`${classes.list_item_alignt_right}`}>{formatter_currency.format(item.SCM_Quantity * item.FProdUnitPrice)}</span>
                                        </li>
                                    ))
                                }
                               
                            </div>

                            <div className={`${classes.paper_list_wrapper_summery}`}>
                                <li className={`${classes.paper_list_wrapper_header_bot}`}>
                                    <span>Grand Total</span>
                                    <span className={`${classes.list_item_alignt_right}`}>{formatter.format(SummeryQuantity)} Pcs</span>
                                    <span className={`${classes.list_item_alignt_right}`}>{formatter_Frack.format(SummeryResource)} kg</span>
                                    <span className={`${classes.list_item_alignt_right}`}>{formatter_currency.format(SummeryPrice)}</span>
                                </li>
                            </div>
                            
                        </div>

                        <div className={`${classes.special_footer_local_wrapper}`}>

                            <div className={`${classes.footer_user_wrap}`}>
                                <h2>Production Manager</h2>
                                <li className={`${classes.name_manual}`}>Full Name: __________________</li>
                                <li className={`${classes.print_date}`}>Date:________________________</li>
                                <li className={`${classes.sign_manual}`}>Signature: ______________________</li>
                            </div>

                        </div>

                        {/* <PaperFooter Name={auth.employeeData.FullName} Role={auth.employeeData.DepName} Phone={auth.employeeData.PhoneNumber}/> */}
                        <div className={`${classes.final_note_po_stock_out}`}>
                            <li>Invalid if not signed.</li>
                        </div>

                    </div>
                </div>



                </div>


                <div className={`${classes.action_button_wrapper}`}>

                    <button onClick={(e)=>{PrintDoc()}} className={`${classes.button_print}`}>Print</button>
                    <button onClick={()=>{Close_CALLBACK(false)}} className={`${classes.button_dismiss}`}>Close</button>

                </div>

            </div>
        </div>
    )
}