import classes from './loginPageStyle.module.css'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeLowVision, faLock, faSpinner, faTriangleExclamation, faUser } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import logo from '../../asset/images/favicon.png'
import { getBaseURL } from '../../SharedComp/BaseUrlConfig';

import { Howl } from 'howler';
import error_log from '../../asset/audio/error-login.mp3'
import { useAuth } from '../../AuthProvider';
import { ToastWrapper } from '../../SharedComp/ToastWrapper/ToastWrapper';
export default function Login() 
{

    const [log_values, setLogValues] = useState({
        username: '',
        password: ''
    })

    const [showKey, SetShowKey] = useState(false);
    const auth = useAuth();

    const handleFieldInput = (e) => 
    {
        setLogValues(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    const handleLogInSubmit = async (event) => 
    {
        event.preventDefault();
        auth.loginAction(log_values, new Date().getSeconds());
    }
    return (
        <div className={`${classes.login_main_wrapper}`}>




            {/* <div className={`${auth.loading ? classes.msg_load : classes.msg_good } ${!ShowMsg ? classes.msg_load_hide : ''}`}>
                <div className={`${auth.loading ? classes.msg_icon_load : classes.msg_icon}`}>
                    <FontAwesomeIcon icon={auth.loading ? faSpinner : faTriangleExclamation} spin={auth.loading}></FontAwesomeIcon>
                </div>
                <div className={`${classes.msg_text}`}>
                    {auth.loading ? "Validating..." : auth.serverRes}
                </div>
            </div> */}
                
             


            <div className={`${classes.form_wrapper} ${auth.loading ? classes.form_wrapper_loading : ''}`}>
                <div className={`${classes.company_info}`}>  
                        <div className={`${classes.comp_name}`}>
                            <div className={`${classes.head_stick_logo}`}>
                                <img src={logo} alt="" />
                            </div>
                            <div className={`${classes.head_stick_logo}`}>
                                <h2>JAK AND SONS</h2>
                            </div>
                        </div>
                </div>
                <form onSubmit={handleLogInSubmit} method="post">

                    <div className={`${classes.input_field_wrapper}`}>
                        <div className={`${classes.input_icon}`}>
                            <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>
                        </div>
                        <div className={`${classes.input_value}`}>
                            <input  onChange={(e)=>{handleFieldInput(e)}} name='username' type="text" placeholder='Username' autoComplete='off' required />
                        </div>
                    </div>

                    <div className={`${classes.input_field_wrapper_key}`}>
                        <div className={`${classes.left_side_input_wrapper}`}>
                            <div className={`${classes.input_icon}`}>
                                <FontAwesomeIcon icon={faLock}></FontAwesomeIcon>
                            </div>
                            <div className={`${classes.input_value}`}>
                                <input onChange={(e)=>{handleFieldInput(e)}} name='password' type={showKey ? 'text' : 'password'} placeholder='********' required />
                    
                            </div>
                        </div>

                        <div className={`${classes.show_pass_key}`}>
                            <span onClick={(e)=>{SetShowKey(!showKey)}}>
                                <FontAwesomeIcon icon={showKey ? faEyeLowVision : faEye}></FontAwesomeIcon>
                            </span>
                        </div>
                    </div>

                    <div className={`${classes.action_buttons_wrapper}`}>
                        <div className={`${classes.forget_pass}`}>
                            <a href="#">Forget Password?</a>
                        </div>
                        <div className={`${classes.action_button_wrapper}`}>
                            <button disabled={auth.loading}>{auth.loading ? "Verifying..." : 'sign in'} </button>
                        </div>
                    </div>

                </form>
            </div>

                        
        </div>
        
    ); 
}