import './ORIDataTableStyle.css'
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faSearch, faSort } from '@fortawesome/free-solid-svg-icons';
import ORITableActionButton from './ORITableActionButtons';

export default function ORIDataTable(
  {columns,
   user_data, 
   Edit_CALLBACK_Inner,
   Purge_CALLBACK_Inner}) 
{
  const itemsPerPage = 5; // Number of items per page
  const [currentPage, setCurrentPage] = useState(1);
    
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;


    const [sortColumn, setSortColumn] = useState();
  const [sortOrder, setSortOrder] = useState('asc');

  const handleSort = (column) => {
    
    setFilterTerm('');
    setCurrentPage(1);

    if (column === sortColumn) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  const sortedData = user_data.slice().sort((a, b) => {
    if (sortColumn) {
        let valueA = a[sortColumn];
        let valueB = b[sortColumn];

        if(!isNaN(valueA))
        {
            return 0;
        }

        // Handle undefined values
        if (valueA === undefined) valueA = '';
        if (valueB === undefined) valueB = '';

        // Compare the values
        const comparison = valueA.localeCompare(valueB);
        return sortOrder === 'asc' ? comparison : -comparison;
      }
    return 0; // No sorting column selected
  });

  
  
  function paginate(pageNumber)  
  {
    const max_page = Math.ceil(user_data.length / itemsPerPage);
    if(pageNumber > 0 && pageNumber <=  max_page)
    {
        setCurrentPage(pageNumber);
    }
  }

  const [filterTerm, setFilterTerm] = useState('');

  const filteredData = user_data.filter((item) => {
    // Filter the data based on the filterTerm and the "name" property
    const name = toString(Object.values(item)[1]);
    // convert into string to fix coversion issue
    return name.toLowerCase().includes(filterTerm.toLowerCase());
  });
  
  let currentItems;

  if(filterTerm !== '') 
  {
    currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  } else if(sortedData !== null) 
  {
    currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);
  } else 
  {
    currentItems = user_data.slice(indexOfFirstItem, indexOfLastItem);
  }
   
  
  return (
        <div className="ori-data-table">

            <div className="table-search">
                <div className='icon_wrapper'>
                  <FontAwesomeIcon icon={faFilter}></FontAwesomeIcon>
                </div>
                <input type="text"
                 placeholder='Filter by name'
                 value={filterTerm}
                 onChange={(e) => setFilterTerm(e.target.value)}
                 /> 
            </div>

            <table>

                <tr>
                    {
                        columns.map(
                            (col) => (
                                <th onClick={() => handleSort(col.key)}>{col.key} <i className="table-header-icon"><FontAwesomeIcon icon={faSort}></FontAwesomeIcon></i> </th>
                            )
                        )
                    }
                </tr>
                <br />

                {currentItems.map((data) => (
                    <tr key={data.id}>
                            
                            {Object.keys(data).map((key, index) => (
                            <td key={key}>
                              {index === Object.keys(data).length - 1 ? <ORITableActionButton ID={data.ID} HandleEditCallback={Edit_CALLBACK_Inner} HandleRemoveCallback={Purge_CALLBACK_Inner} /> : data[key]  }
                              </td>
                            ))}
                    </tr>
                ))}

            </table>


            


<div className='ori-pagination-wrapper'>
  {user_data.length > itemsPerPage && (
    <ul className="pagination">
      <li>
        <button onClick={() => paginate(currentPage - 1)}>{"Previous"}</button>
      </li>
      {Array.from({ length: Math.ceil(user_data.length / itemsPerPage) }).map((_, index) => {
        const pageNumber = index + 1;
        const totalPages = Math.ceil(user_data.length / itemsPerPage);

        // Add ellipsis for a large number of pages
        if (
          (pageNumber > 3 && pageNumber < totalPages) &&
          (pageNumber < currentPage || pageNumber > currentPage)
        ) {
          if (index === itemsPerPage) {
            return (
              <li key={index}>
                <button>...</button>
              </li>
            );
          }
          return null;
        }

        return (
          <li key={index}>
            <button className={`${currentPage === pageNumber ? 'active-page-number' : ''}`} onClick={() => paginate(pageNumber)}>{pageNumber}</button>
          </li>
        );
      })}
      <li>
        <button onClick={() => paginate(currentPage + 1)}>{"Next"}</button>
      </li>
    </ul>
  )}
</div>

        </div>
    );
}