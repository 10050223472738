import { useState } from 'react'
import classes from './BarLoader.module.css'


export default function BarLoader({Percentage = 20, Text})
{

    const [PercentageClamp, SetPercentageClamp] = useState(Percentage > 100 ? 100 : Percentage);


    return (
        <div className={`${classes.bar_loader_main_wrapper}`}>

            <div className={`${classes.bar_loader_min_wrapper}`}>
                <div style={{width: `${PercentageClamp}%`}} className={`${classes.bar_loader_bar_wrapper}`}>

                </div>
            </div>

            <span className={`${classes.bar_text}`}> {PercentageClamp.toFixed(1)}% {Text}</span>

        </div>
    )
}