import classes from './StockRequestGroup.module.css'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { useAuth } from '../../../../../AuthProvider'
import { useDAControl } from '../../../../../DAControlContext'
import { useToast } from '../../../../../ToastContext'
import { Tooltip } from 'react-tooltip'
import data_win from '../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import TimeElapsed from '../../../../../SharedComp/TimeElapsed/TimeElapsed'

import UIButton from '../../../../Components/UIButton/UIButton'
import { CheckCircle, TrashSimple } from '@phosphor-icons/react'

import {QueryChefsStockRequestItem, AcceptChefsStockRequestItem} from '../../../../../SharedComp/API/CK/AP_CenteralKicthenChef'
import { useEffect, useState } from 'react'

export default function StockRequestGroup({TargetData, CloseCallback})
{

    const auth = useAuth();
    const DAControl = useDAControl();
    const notify = useToast();

    const [SubProductRequestList, SetSubProductRequestList] = useState([]);


    const QueryRequestItems = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("req_group_hash", TargetData.CKCSR_RequestGroupHash);
            fd.append("dispatch_hash", TargetData.CKCSR_DispatcheHash);
            const main_res = await QueryChefsStockRequestItem(fd, 'token');
            SetSubProductRequestList(main_res);
        }catch(err) 
        {
            notify.ShowError("Falied to fetch Product List: " + err.message);
        }
    }

    const HandleAcceptStockRequest = async()=>
    {
        try 
        {

            const fd = new FormData();
            fd.append("package_list", JSON.stringify(SubProductRequestList));
            fd.append("handler_id", auth.user.UserAccountID);
            fd.append("handler_workshop", auth.activeWorkShop);
            fd.append("req_hash", TargetData.CKCSR_RequestGroupHash);
            fd.append("dispatch_hash", TargetData.CKCSR_DispatcheHash);
            fd.append("req_owner", TargetData.CKCSR_ReqOwner);
            
            fd.append("is_recipe", TargetData.CKCSR_FromRecipe);
            fd.append("track_back_hash", TargetData.CKCSR_RequestGroupHash);
            fd.append("prod_order_hash", TargetData.CKCSR_ProdOrderHash);
            fd.append("auto_trigger", TargetData.CKCSR_RecipeAutoTrigger);

            const main_res = await AcceptChefsStockRequestItem(fd, 'token');
            notify.ShowSuccess(main_res.Msg);
            CloseCallback();
        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }

    useEffect(()=>{
        QueryRequestItems();
    }, [])


    return (
        <div className={`${classes.main_order_group_wrapper}`}>
            <Tooltip className={`${data_win.tool_quick}`} id="offical_doc" place="top" />
            <div className={`${classes.main_order_group_header_wrapper}`}>
                <h3>Stock Request <sub>{<TimeElapsed Time={TargetData.CKCSR_ReqTime} />}</sub></h3>

                <div className={`${classes.right_size_header_action_wrapper}`}>
                    <UIButton Icon={<TrashSimple weight='bold' />} label={"Reject"} Variant='Danger'/>
                    <UIButton onClick={()=>{HandleAcceptStockRequest()}} Icon={<CheckCircle weight='bold' />} label={"Accpet"} />
                </div>
            </div>

            <div className={`${classes.request_body_wrapper}`}>

                <div className={`${classes.order_info_wrapper}`}>

                    <div className={`${classes.order_owner_wrapper}`}>

                        <img src="https://cdn.pixabay.com/photo/2015/03/04/22/35/avatar-659651_640.png" alt="" />

                        <div className={`${classes.owner_name_and_stuff}`}>
                            <li>{TargetData.FirstName} {TargetData.LastName}</li>
                            <li>{TargetData.RoleNameLazy}</li>
                        </div>

                    </div>
                    <div className={`${classes.order_prod_list_wrapper}`}>

                        {
                            SubProductRequestList.map((item, idx)=>(
                                    <li key={item.IVItemName}>
                                        <span>{item.IVItemName}</span>
                                        <span>{item.CKCSRI_Amount} {item.UomDescription}</span>
                                    </li>
                            ))
                        }
                        
                        
                    </div>


                </div>

            </div>

        </div>
    )
}