import classes from './ProcessTRequest.module.css'
import data_win from '../../../../SharedComp/CommonStyle/DataCreator.module.css'
import MasterDataRegInput from '../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useRef, useState } from 'react'
import ProcessTransferRequestPackageInput from './ProcessRequestPackageInput/ProcessTransferRequestPackageInput'

import Select from 'react-select'
import {Custom_DD_Style, PrepareDropDownList} from '../../../../SharedComp/Utility'

import {FetchBranchExcludedCurrent, ProcessStockRequest, FetchTransferRequestPayload} from '../../../../api'
import { useAuth } from '../../../../AuthProvider'
import { useToast } from '../../../../ToastContext'
import { useDAControl } from '../../../../DAControlContext'

export default function ProcessTRequest({OpenModal, TargetRequest}) 
{

    const [Loading, SetLoading] = useState(false);

    const da_control = useDAControl();
    const auth = useAuth();
    const notify = useToast();
    const scrollRef = useRef(null);
    // Transfere Detail
    const [InventoryItemType, SetInventoryItemType] = useState(null);
    const [InventoryItemStore, SetInventoryItemStore] = useState(null);
    const [InventoryItemQuantity, SetInventoryItemQuantity] = useState(null);
    const [InventoryItemBrand, SetInventoryItemBrand] = useState(null);
    const [InventoryItemRequestHash, SetInventoryItemRequestHash] = useState(null);
    const [InventoryTransferApprovalState, SetInventoryTransferApprovalState] = useState(null);
    const [LockToSourceBranch, SetLockToSourceBranch] = useState(true);

    // Filter Branch List
    const [ValidBranchList, SetValidBranchList] = useState([]);
    const [SelectedBranch, SetSelectedBranch] = useState();
    const [ExpectedDate, SetExpectedDate] = useState();

    const [RequestPayloadList, SetRequestPayloadList] = useState([]);

    const FetchRequestPayload = async()=>{
        try 
        {
            const res = await FetchTransferRequestPayload(TargetRequest.IVSTR_RequestPayloadID, 'token');
            SetRequestPayloadList(res);

            SetInventoryItemType(null);
            SetInventoryItemStore(null);
            SetInventoryItemQuantity(null);
            SetInventoryItemBrand(null);
            SetInventoryItemRequestHash(null);
            SetInventoryTransferApprovalState(null);
            const existing = [];
            for(let i = 0; i < res.length; i++) 
            {
                const unique_idx = getRandomID();

                HandleIvTransferApprovalState(false, unique_idx);
                HandleIvnItemBrand(res[i].IVSTRP_Brand ? res[i].IVSTRP_Brand : '-', unique_idx);
                HandleItemQuantitySelection(res[i].IVSTRP_Quantity, unique_idx);
                HandleIvItemSelection({value: res[i].IvtID, label: res[i].IVItemName}, unique_idx);
                HandleIvItemRequestHash(res[i].IVSTRP_SingularItemRequestID, unique_idx);
                existing.push(
                    <ProcessTransferRequestPackageInput 
                    I_Quantity={res[i].IVSTRP_Quantity}
                    I_Brand={res[i].IVSTRP_Brand ? res[i].IVSTRP_Brand : '-'}
                    I_Inv_Item={{value: res[i].IvtID, label: res[i].IVItemName}}
                        HandleInvItemSelect={HandleIvItemSelection} 
                        HandleInvItemStore={HandleIvItemStore}
                        HandlInvItemQuantity={HandleItemQuantitySelection} 
                        HandleIvnItemBrand={HandleIvnItemBrand}
                        HandleTransferState={HandleIvTransferApprovalState}
                        idx={unique_idx}/>
                );
            }
                SetStockItem(existing);



        }catch(err) 
        {
            notify.ShowError("Faild To Fetch REquest Payload!");
        }
    }



    const FetchValidBranchList = async() =>
    {
        try 
        {
            const res = await FetchBranchExcludedCurrent(auth.activeWorkShop, 'token');
            PrepareDropDownList(res, SetValidBranchList);
        }catch(err) 
        {
            notify.ShowError("Falied to Fetch valid Workspace");
        }
    }

    function CheckValidTransfereCount() 
    {
        let data_count = 0;
        let valid_count = 0;
        for (const key in InventoryTransferApprovalState)  
        {
            data_count++;
            if(InventoryTransferApprovalState[key])
            {
                valid_count++;
            }
        }
        return valid_count;
    }

    const [StockItem, SetStockItem] = useState([]);
    function HandleIvItemSelection(data, idx) 
    {
        SetInventoryItemType(prev => ({
            ...prev,
            [idx]: data
        }))
    }
    function HandleIvItemRequestHash(data, idx) 
    {
        SetInventoryItemRequestHash(prev => ({
            ...prev,
            [idx]: data
        }))
    }
    function HandleIvItemStore(data, idx) 
    {
        SetInventoryItemStore(prev => ({
            ...prev,
            [idx]: data
        }))
    }
    function HandleItemQuantitySelection(data, idx) 
    {
        SetInventoryItemQuantity(prev => ({
            ...prev,
            [idx]: data
        }))
    }
    function HandleIvTransferApprovalState(data, idx) 
    {
        SetInventoryTransferApprovalState(prev => ({
            ...prev,
            [idx]: data
        }))
    }
    function HandleIvnItemBrand(data, idx) 
    {
        SetInventoryItemBrand(prev => ({
            ...prev,
            [idx]: data
        }))
    }

    function getRandomID() 
    {
        return Math.floor(Math.random() * 100000000);
    }

    function CloseModal() 
    {
        OpenModal();
    }
    const HandleSubmition = async(e, declined = false)=> 
    {
        if(!e)
        {
            return;
        }
        notify.ShowLoad("Processing Request...", 10);
        SetLoading(true);
        try 
        {
        
            const fd = new FormData();
            fd.append("is_declined", declined);
            fd.append("receiver_user", auth.employeeData.EmployeeID);
            fd.append("receiver_workspace", auth.activeWorkShop);
            fd.append("lock_to_source_branch", LockToSourceBranch);
            fd.append("request_payload_ID", TargetRequest.IVSTR_RequestPayloadID);
            fd.append("request_item_hash", JSON.stringify(InventoryItemRequestHash));
            fd.append("request_payload_IVItem", JSON.stringify(InventoryItemType));
            fd.append("request_payload_IVItemQuantity", JSON.stringify(InventoryItemQuantity));
            fd.append("request_payload_IVItemBrand", JSON.stringify(InventoryItemBrand));
            fd.append("request_payload_store", JSON.stringify(InventoryItemStore));
            fd.append("request_payload_approval_state", JSON.stringify(InventoryTransferApprovalState));

            const res = await ProcessStockRequest(fd, 'token');
            notify.ShowSuccessUpdate(res.Msg, 10);
            SetLoading(false);
            CloseModal();
        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
            SetLoading(false);
        }
    }
    function HandleDecline(val) 
    {
        if(!val) 
        {
            return;
        }
        HandleSubmition("sup", true);
    }
    const scrollToBottom = () => {
        scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
      };
    useEffect(()=>{
        FetchRequestPayload();

    }, [])
    
    useEffect(()=>{
        scrollToBottom();
    }, [StockItem])

    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

            <div className={`${data_win.data_main} ${classes.data_main}`} >
                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        Processing <span className={`${data_win.data_create_name}`}>Stock Request</span>
                    </h3>
                    <button onClick={()=>{CloseModal()}}>
                        <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                    </button>
                </div>

                <div className={`${data_win.data_body}`}>
                        
                

                            <form onSubmit={(e)=>{
                                e.preventDefault();
                                const valid_count = CheckValidTransfereCount();
                                if(valid_count > 0) 
                                {
                                    da_control.Trigger(
                                        'Authorize Stock Transfer',
                                        `You are about to perform a stock transfer. This action will impact your current stock balance. Please note, you won't be able to view this dialog again after proceeding.`,
                                        e, 
                                        2,
                                        HandleSubmition)
                                } else 
                                {
                                    notify.ShowError("At least one valid stock is required to perform this action!")
                                }
                              
                            }} method='POST'>
                                <div className={`${classes.form_wrapper}`}> 
                                        
                                        <div className={`${classes.input_list_main_wrapper}`}>
                                            <div  className={`${classes.input_list_wrapper}`}>
                                                
                                                    <div className={`${classes.input_header_single_wrapper_st_req_header}`}>
                                                        <h2>Warehouse Stock Transfer #{TargetRequest.IVSTRID}</h2>
                                                    </div>
                                                    
                                                    <div onClick={()=>{SetLockToSourceBranch(!LockToSourceBranch)}} className={`${classes.input_header_single_wrapper_st_req}`}>
                                                        <span><input checked={LockToSourceBranch} type="checkbox" onChange={(e)=>{SetLockToSourceBranch(e.target.checked)}} /></span>
                                                        <span>Lock to Source Branch</span>
                                                    </div>

                                            </div>
                                        </div>

                                        <div className={`${classes.po_mat_list_wrapper}`}>
                                            <div className={`${classes.po_mat_list}`}>
                                                    {StockItem.map((item) => ( 
                                                        <div 
                                                            className={`${classes.input_fild_shild}`} 
                                                            key={item.props.idx}>{item}
                                                            <div ref={scrollRef}/>
                                                        </div>
                                                    ))}
                                            </div>
                                       </div>                        
                                            
                                </div>
                                <div className={`${data_win.form_action_button_wrapper}`}>
                                    <button 
                                        className={`${data_win.form_action_button_save}`}>
                                            {Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Authorize'}
                                    </button>
                                    <button 
                                        type='button' 
                                        className={`${data_win.form_action_button_dismiss}`}
                                        onClick={()=>{
                                            da_control.Trigger(
                                                'Decline Stock Transfer', // header
                                                `You are about to decline this stock transfer request. This action is irreversible, and the sender will need to resubmit the request if necessary.`, // agreement
                                                true, 
                                                7,
                                                HandleDecline)
                                        }}
                                        >{Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Decline'}</button>
                                    <button onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                                </div>
                            </form>




                </div>
            </div>

        </div>
    )
}