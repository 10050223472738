import { useEffect, useRef } from 'react'
import classes from './QRCoreReader.module.css'
import QrReader from 'react-qr-scanner'

export default function QRCodeReader({onQRRead, OnError, Open = false, OpenController}) 
{

    const timeoutRef = useRef(null);

    useEffect(()=>{

        if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            OpenController(false);
          }, 60 * 1000);

        return () => {
            if (timeoutRef.current) {
              clearTimeout(timeoutRef.current);
            }
          };

    }, [])
    return (
        <div className={`${classes.qr_code_reader_main_wrapper}`}>
                
                <div className={`${classes.code_scan_wrapper}`}>
                    <div className={`${classes.qr_code_reader}`}>
                        {Open && <QrReader
                            onScan={onQRRead}
                            onError={OnError}
                            style={{ width: '100%' }}
                        />}

                        <div className={`${classes.qr_action}`}>
                            <li>Searching...</li>
                            <button onClick={()=>{OpenController(false)}} type='button'>Dismiss</button>
                        </div>
                    </div>
                </div>
        </div>
    )
}