import classes from './PrintTransferRequest.module.css'
import data_win from '../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightLong, faCheckCircle, faTimesCircle, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'

import PaperHeader from '../../../../SharedComp/PaperHeader/PaperHeader'
import PaperHash from '../../../../SharedComp/PaperHash/PaperHash'
import PaperFooter from '../../../../SharedComp/PaperFooter/PaperFooter'

import QRCode from 'react-qr-code'

import { useReactToPrint } from 'react-to-print'
import { useEffect, useRef, useState } from 'react'
import {FetchStockTransferRequestByID, FetchTransferRequestPayload, FetchRequestSenderUser, FetchRequestHandlerUser} from '../../../../api'
import { useAuth } from '../../../../AuthProvider'
import { useToast } from '../../../../ToastContext'

export default function PrintTransferRequest({OpenModal, TargetID}) 
{

    const auth = useAuth();
    const notify = useToast();
    const print_ref = useRef();
    const PrintDoc = useReactToPrint({
        documentTitle: auth.activeWorkShopName + "_Requisition_Order_" + "_" + new Date().toDateString(),
        content: () => print_ref.current,
        onAfterPrint: ()=>{
            
        }
    })

    const [Loading, SetLoading] = useState(true);
    const [TargetRequest, SetTargetRequest] = useState([]);
    const [RequestPayload, SetRequestPayload] = useState([]);
    const formatter = new Intl.NumberFormat('en-US');

    const [RequestSender, SetRequestSender] = useState();
    const [RequestReciver, SetRequestReciver] = useState();


    const FetchRequestPayload = async()=>
    {
        try 
        {
            const res = await FetchTransferRequestPayload(TargetID, auth.token);
            SetRequestPayload(res);
            SetLoading(false);
        }catch(err) 
        {

        }
    }

    const FetchTagetRequest = async()=>{
        try 
        {
            FetchRequestPayload();
            const res = await FetchStockTransferRequestByID(TargetID, auth.token);

            const __sender = await FetchRequestSenderUser(res[0].IVSTR_SenderUserID, auth.token);
            SetRequestSender(__sender[0]);
            const __hander = await FetchRequestHandlerUser(res[0].IVSTR_RequestHandledBy, auth.token);
            SetRequestReciver(__hander[0]);
            SetTargetRequest(res[0]);
        }catch(err) 
        {
            notify.ShowError("Falied to Fetch Invoice!")
        } 
    }
    // useEffect(()=>{
    //     if(InvoiceList.length > 0)
    //     {
    //         SetLoading(false);
    //     }
    // }, [InvoiceList])

    useEffect(()=>{
        SetLoading(true);
        FetchTagetRequest();
    }, [])

    useEffect(()=>{

    }, [TargetRequest])


    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>
        <div className={`${data_win.data_main}`}>
          <div className={`${data_win.data_main_header}`}>
                <h3>
                    Stock <span className={`${data_win.data_create_name}`}> Requisition Order</span>
                </h3>
                <button onClick={()=>{OpenModal(false)}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

            <div className={`${data_win.data_body} ${classes.data_body}`}>

            {Loading === false ? <div className={`${classes.inner_preview_data_wrapper}`}>
                    <div ref={print_ref} className={`${classes.paper_wrapper_dash}`}>
                        <PaperHeader/>
                        <PaperHash/>
                        <div className={`${classes.meta_data_wrapper}`}>
                            <h2>//{TargetRequest.RecipientBranchName} <FontAwesomeIcon icon={faArrowRightLong}></FontAwesomeIcon> //{TargetRequest.SenderBranchName}{TargetRequest.IVSTR_LockState ? '' : '...'}</h2>
                            <h2>Stock Requisition</h2>
                        </div>

                        <div className={`${classes.transcation_owner_wrapper}`}>
                            <div className={`${classes.transcation_owner}`}>

                                <div className={`${classes.transcation_init}`}>
                                    <h2>Requisition Intialized By</h2>
                                    <li>{RequestSender?.FirstName} {RequestSender?.LastName}</li>
                                    <li>{RequestSender?.Name}</li>
                                    <li>{RequestSender?.BranchName} Branch</li>
                                </div>
                                <div className={`${classes.transcation_recive}`}>
                                    <h2>Requisition Handled By</h2>
                                    <li>{RequestReciver?.FirstName} {RequestReciver?.LastName}</li>
                                    <li>{RequestReciver?.Name}</li>
                                    <li>{RequestReciver?.BranchName} Branch</li>
                                </div>

                            </div>
                        </div>

                        <div className={`${classes.entry_detail}`}>
                            <li><span className={`${classes.entry_deatil_tag}`}>Transaction ID</span> <span className={`${classes.entry_deatil_val}`}>{TargetRequest.IVSTR_RequestPayloadID}</span></li>
                            <li><span className={`${classes.entry_deatil_tag}`}>Locked To Source</span> <span className={`${classes.entry_deatil_val}`}>{TargetRequest.IVSTR_LockState ? 'True' : 'false'}</span></li>
                        </div>  
                        
                        
                        <div className={`${classes.main_paper_data_wrapper}`}>
                            <div className={`${classes.paper_list_wrapper}`}>

                            <li className={`${classes.fake_tabke_header}`}>
                                <span>
                                   Name
                                </span>
                                <span>
                                    Amount
                                </span>
                                <span>
                                    Brand
                                </span>
                                <span>
                                    Wherehouse
                                </span>
                                <span>
                                    Action
                                </span>
                            </li>


                                {
                                    RequestPayload.map((item, idx)=>(
                                        <li className={`${item.IVSTRP_TR_Value === 'Transferred' ? '' : classes.ban_view}`} key={idx}>
                                            <span>
                                            {item.IVItemName}
                                            </span>
                                            <span>
                                              {formatter.format(item.IVSTRP_Quantity)} KG
                                            </span>
                                            <span>
                                            {item.IVSTRP_Brand === ''? '-' : item.IVSTRP_Brand}
                                            </span>
                                            <span>
                                                {item.WHName ? item.WHName : '-'}
                                            </span>
                                            <span className={`${item.IVSTRP_TR_Value === 'Transferred' ? classes.action_wrapper_text_good : classes.action_wrapper_text_bad}`}>
                                               <span>{item.IVSTRP_TR_Value}</span> 
                                               <span className={`${item.IVSTRP_TR_Value === 'Transferred'? classes.check_valid : classes.check_invalid}`}><FontAwesomeIcon icon={item.IVSTRP_TR_Value === 'Transferred'? faCheckCircle : faXmarkCircle}></FontAwesomeIcon></span>
                                            </span>
                                        </li>
                                    ))  
                                }
                                 

                            </div>
                            
                        </div>

                        <div className={`${classes.entry_detail_code}`}>
                            <div className={`${classes.scan_data}`}>
                               {TargetRequest.IVSTR_ReadBackHash && <QRCode
                                    size={128}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    fgColor={'var(--background-dark)'}
                                    value={TargetRequest.IVSTR_ReadBackHash}
                                    viewBox={`0 0 256 256`}
                                    /> }
                            </div>
                            <div className={`${classes.scan_data_connect}`}>

                            </div>
                            <div className={`${classes.scan_data_text}`}>
                                <li>KEY: {TargetRequest.IVSTR_ReadBackHash}</li>
                            </div>
                        </div>

                        <div className={`${classes.special_footer_local_wrapper}`}>

                            <div className={`${classes.footer_user_wrap}`}>
                                <h2>Printed By</h2>
                                <li className={`${classes.name_manual}`}>{auth.employeeData.FullName}</li>
                                <li className={`${classes.print_date}`}>{new Date().toDateString()}</li>
                                <li className={`${classes.sign_manual}`}>Sign: __________________</li>
                            </div>
                            <div className={`${classes.footer_user_wrap}`}>
                                <h2>Driver</h2>
                                <li className={`${classes.name_manual}`}>Full Name: ______________</li>
                                <li className={`${classes.sign_manual}`}>Sign: __________________</li>
                            </div>
                            <div className={`${classes.footer_user_wrap}`}>
                                <h2>Received By</h2>
                                <li className={`${classes.name_manual}`}>Full Name: ______________</li>
                                <li className={`${classes.sign_manual}`}>Sign: __________________</li>
                            </div>

                        </div>

                        {/* <PaperFooter Name={auth.employeeData.FullName} Role={auth.employeeData.DepName} Phone={auth.employeeData.PhoneNumber}/> */}

                    </div>
                </div> : 'Loading'}
                    

            </div> 

                 <div className={`${classes.form_action_button_wrapper}`}>
                {/* <button className={`${classes.form_action_button_approve}`}>Request Approval</button> */}
                {/* <button className={`${classes.form_action_button_req_dismiss}`}>Request Dismissal </button> */}
                <button onClick={()=>{PrintDoc()}} className={`${classes.form_action_button_print}`}>Print</button>
                <button  onClick={()=>{OpenModal(false)}} className={`${classes.form_action_button_dismiss}`} type='button'>Close</button>
            </div> 
        </div>

    </div>
    )
}