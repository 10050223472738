import classes from './BranchView.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { getBaseURL } from '../../../../SharedComp/BaseUrlConfig';
import axios from 'axios';
import { Custom_DD_Style } from '../../../../SharedComp/Utility'
import MasterDataRegInput from '../../../../SharedComp/CommonStyle/MasterDataRegInput';
import data_win from '../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { faCirclePlus, faPlusCircle, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useRef } from 'react';
import Select from 'react-select'
import NotificationPopUp from '../../../../SharedComp/NotificationPopUp/NotificationPopUp';

import CreateWarehouse from './Component/CreateWarehouse/CreateWarehouse';
import BranchCard from '../Card/BranchCard';
import { Warehouse } from '@phosphor-icons/react';

export default function BranchView({Close_CALLBACK, BranchData}) 
{
    const [InitStore, SetInitStore] = useState(false); 

    const [StoreList, SetStoreList] = useState([]);


      // Notifiction
      const [MsgPkg, SetMsgPkg] = useState({});
      const [OpenMyNoti, SetOpenMyNoti] = useState(false);
      const [RefeshList, SetRefeshList] = useState(false);



      function MsgCallback(res) 
      {
            SetInitStore(false);
          LocalMsgTrigger(res.data.Msg, res.data.MyStateCode);
      }
      function LocalMsgTrigger(msg, stat) 
      {
          if(stat === 1) 
          {
              SetRefeshList(!RefeshList);
          }
          SetMsgPkg({Key : Math.random() * 10000, Msg: msg, State: stat})
          SetOpenMyNoti(true);
      }


      useEffect(()=>{
        fetch(getBaseURL() + 'warehouse/get-branch-store', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({BranchID: BranchData.BranchID })
        })
        .then((data) => data.json())
        .then(res => SetStoreList(res));

      }, [])

    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

        {InitStore && <CreateWarehouse Close_CALLBACK={SetInitStore} BrancID={BranchData.BranchID} Res_Callback={MsgCallback}/>}


        <div className={`${data_win.data_main}`}>
        {OpenMyNoti && <NotificationPopUp key={MsgPkg.Key} msg={MsgPkg.Msg} state={MsgPkg.State} />}

            <div className={`${data_win.data_main_header}`}>
                <h3>
                    JAK <span className={`${data_win.data_create_name}`}>Establishment {BranchData.BranchName}</span>
                </h3>
                <button onClick={()=>{Close_CALLBACK(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
            </div>

            <div className={`${classes.data_body}`}>

                <div className={`${classes.head_wrapper}`}>

                    <div className={`${classes.warehouse_wrapper}`}>
                        <div className={`${classes.warehouse_list_wrapper}`}>

                            {
                                StoreList.map((item, idx)=>(
                                    <li key={idx}>  
                                        <span className={`${classes.warehouse_name}`}> <span className={`${classes.icon_wrap}`}><Warehouse fontSize={16} weight='bold'/></span> {item.WHName}</span>
                                        <span className={`${item.IsShared ? classes.warehouse_type_shared : classes.warehouse_type}`}>{item.IsShared ? 'Shared' : 'Local'}</span>
                                        {/* <span className={`${classes.warehouse_buget}`}>$345,911.00</span> */}
                                    </li>
                                ))
                            }
                            <li onClick={()=>{SetInitStore(true)}} className={`${classes.warehouse_reg_wrap}`} style={{
                                fontSize: '1.0em',
                                textAlign: 'center',
                                display: 'flex',
                                justifyContent: 'center'
                            }} >  
                                <FontAwesomeIcon icon={faCirclePlus}></FontAwesomeIcon>
                            </li>
                        </div>
                    </div>


                    <div className={`${classes.supervisor_wrapper}`}>
                        <div className={`${classes.supervisor_wrapper_inner}`}>
                            <div className={`${classes.super_img}`}>
                                <img src="https://media.istockphoto.com/id/505653911/photo/hes-the-modern-man.jpg?s=1024x1024&w=is&k=20&c=nGq7tl57gTm61Tddb-SVim8tVoyWxc4cl4tWlGs-lYw=" alt="" />
                            </div>
                            <div className={`${classes.super_detail}`}>
                                <li>
                                    <span className={`${classes.super_name}`}>Yohannes Girma</span>
                                </li>  
                                <li>
                                    <span className={`${classes.super_phone}`}>+251991202044</span>
                                </li>
                            </div>
                        </div>
                        <div className={`${classes.super_select_action_wrapper}`}>
                            <button>Change</button>
                        </div>
                    </div>

                    <div className={`${classes.pitty_cash_ana}`}>

                        <div className={`${classes.info_text}`}>
                            <h2>Pitty Cash</h2>
                        </div>

                        <div className={`${classes.loader_wrapper}`}>

                            <div className={`${classes.figuers}`}>
                                <li> 
                                    <span className={`${classes.figuers_name}`}>Budget</span>
                                    <span className={`${classes.figuers_cash}`}>ETB 17,000.00</span> 
                                </li>
                                <li> 
                                    <span className={`${classes.figuers_name}`}>Utilized</span>
                                    <span className={`${classes.figuers_cash}`}>ETB 8,000.00</span> 
                                </li>
                            </div>

                            <div className={`${classes.outer_load}`}>
                                <div className={`${classes.inner_load}`}>

                                </div>
                            </div>

                        </div>

                    </div>

                </div>


            </div>

        </div>

    </div>
    );
}