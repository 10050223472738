import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import classes from './EmpAllowance.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';

import AllowanceAction from './Component/AllowanceAction/AllowanceAction';
import { useEffect, useState } from 'react';

import CleanList from '../Credit/Component/CleanList/CleanList'
import DecisionAssurance from '../../../../../SharedComp/DecisionAssurance/DecisionAssurance';

import axios from 'axios';
import { getBaseURL } from '../../../../../SharedComp/BaseUrlConfig';

export default function EmpAllowance({EmpData, MsgTrigger}) 
{
    const [AllownaceActionController, SetAllownaceActionController] = useState(false);

    const [EmployeeAllowance, SetEmployeeAllowance] = useState([]);
    const [EmpAllowanceLength, SetEmpAllowanceLength] = useState(0);
    const [daState, SetDSAState] = useState(false);

    const [agreement, SetAgreement] = useState();
    const [reqCallbackData, SetReqCallbackData] = useState();
    const [justDeleted, SetJustDeleted] = useState(false);
    const [ActionName, setActionName] = useState(null);


    useEffect(() => 
    {
        fetch(getBaseURL() + 'employee/query_employee_allowance/', { 
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ EmpID: EmpData.EmployeeID }),
          })
            .then(res => res.json())
            .then((data) => {SetEmployeeAllowance(data); SetEmpAllowanceLength(data.length)});

    }, [AllownaceActionController, justDeleted])

    function InitiateRequest(id, name) 
    {
        setActionName("Remove Allowance")
        SetAgreement(`About to wave goodbye to (${name}). Confirm this farewell to your data?`);
        SetReqCallbackData(id);
        SetDSAState(true);
    }
    function HandleDAResponse(id) 
    {
        if(id === null) 
        {
            return;
        }
        
        axios.delete(getBaseURL() + 'employee/purg_employee_allowance/', {
            headers: {
                'Content-Type': 'application/json',
            },
            data: { AllowID: id },
        })
        .then(response => {
            MsgTrigger(response);
            SetJustDeleted(!justDeleted);
        })
        .catch(error => console.error('Error purging employee allowance:', error));



    }


    return (
        <div className={`${classes.emp_allowance_main_wrapper}`}>

            {daState && <DecisionAssurance ActionName={ActionName}  CallbackData={reqCallbackData} Msg={agreement} openController={SetDSAState} HandleResponse={HandleDAResponse}/>}

            <div className={`${classes.empl_allowance_local_nav}`}>
                <button onClick={() => {SetAllownaceActionController(true)}}>New Allowance</button>
            </div>
            <AllowanceAction EmpData={EmpData} Open={AllownaceActionController} ActionController={SetAllownaceActionController} MsgTrigger={MsgTrigger} />
            <div className={`${classes.emp_allowance_inner_wrapper}`}>

                <div className={`${classes.emp_allowance_inner_list}`}>
                    {EmployeeAllowance.map((item, index) => (
                                <li key={index}>
                                    {item.TypeName} 

                                    <span onClick={()=>{InitiateRequest(item.AEMPALID, item.TypeName)}} className={`${classes.remove_allowance}`}>
                                        <FontAwesomeIcon icon={faTrash}/>
                                    </span>


                                <span className={`${classes.allow_amount}`}> 
                                            {(item.AllowanceAmount).toLocaleString('en-US', {style: 'currency', currency: 'ETB'})} 

                                            <span className={`${classes.allow_amount_remark}`}>
                                                <FontAwesomeIcon icon={faDollarSign}/>
                                            </span>
                                </span> 

                                

                                </li>
                            ))}
                </div>
                    {EmpAllowanceLength <= 0 ? <CleanList Msg={"No Allowance"}/> : ''}    
            </div>

        </div>
    );
}