import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import classes from './StockAction.module.css'
import ReactSignatureCanvas from 'react-signature-canvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react';
import { useRef } from 'react';
import axios from 'axios'
import NotificationPopUp from '../../../../SharedComp/NotificationPopUp/NotificationPopUp';

import { io } from 'socket.io-client';
import { getBaseURL } from '../../../../SharedComp/BaseUrlConfig';

const socket = io.connect(getBaseURL());
export default function StockAction({PoData, OpenController}) 
{
    const SigRef = useRef();
    const [MixData, SetMixData] = useState([]);
    const [HasSigned, SetHasSigned] = useState(true);
    const [SigActionMsg, SetSigActionMsg] = useState('');
    const [SignInAction, SetSignInAction] = useState(false);
    const [SignLock, SetSignLock] = useState(false);

    const [registring, SetRegistring] = useState(false);
    const [MsgMyPkg, SetMsgMyPkg] = useState({});
    const [OpenMyNoti, SetOpenMyNoti] = useState(false);

    function ClearSig() 
    {
        SetSignLock(false);
        SetSigActionMsg('')
        SigRef.current.clear();
        SigRef.current.on();
    }
    function TrimSig() 
    {
        if(SignLock) 
        {
            SigRef.current.on();
            SetSignLock(false);
        } else 
        {
            SigRef.current.off();
            SetSignLock(true);
        }
        // SetUsedSig(SigRef.current.getTrimmedCanvas());
    }
    function MsgMyTrigger(res) 
    {
        SetMsgMyPkg({Key : Math.random() * 1000, Msg: res.data.Msg, State: res.data.MyStateCode})
        SetOpenMyNoti(true);
    }
    function HandleSigBeginAction() 
    {
        SetHasSigned(true);
        SetSignInAction(true);
        SetSigActionMsg("Tracking...")
    }
    function HandleSigEnd() 
    {
        SetSignInAction(false);
        SetSigActionMsg("[..signature Captured..]")
    }
    function CloseModal() 
    {
        OpenController(false);
    }

    function WriteSig()
    {
        if(SigRef.current.isEmpty()) 
        {
            SetHasSigned(false);
            
            
        } else 
        {
            SigRef.current.off();
            const fd = new FormData();

            fd.append("po_id", PoData.POID);
            fd.append("Sig", JSON.stringify(SigRef.current.toData()))

            axios.post(getBaseURL() + 'po/perform-stock-out', fd, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                })
            .then(res => {
                if(res.data.MyStateCode === 1) 
                {
                    MsgMyTrigger(res);
                    socket.emit("PO_STOCK_PROCESSED", null);
                    CloseModal();
                } else 
                {
                    MsgMyTrigger(res);
                }
                
                
            })
            .catch(err => {
                MsgMyTrigger(err);
            })
        }
    }

    useEffect(()=>{
        
        fetch(getBaseURL() + 'po/get-po-mix-id', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ ID: PoData.POID })
        })
        .then(response => response.json())
        .then(data => {SetMixData(data);})
        .catch(error => console.error(error));
    }, [])

    return (
        <div className={`${classes.stock_action_main_wrapper}`}>

            <div className={`${classes.stock_action_card}`}>
            {OpenMyNoti && <NotificationPopUp key={MsgMyPkg.Key} msg={MsgMyPkg.Msg} state={MsgMyPkg.State} TimeOut={30}/>}
                <div className={`${classes.stock_action_card_header}`}>
                    <h1>Stock-Out Authorization</h1>
                    <button onClick={()=>{OpenController(false)}}>
                        <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                    </button>
                </div>

                <div className={`${classes.stock_action_body_wrapper}`}>

                    <div className={`${classes.po_detail_wrapper}`}>

                        <div className={`${classes.mix_low_level_detail}`}>
                            <li>Order ID: <span className={`${classes.detail_view_value}`}>{PoData.POID}</span></li>
                            <li>Production Type <span className={`${classes.detail_view_value}`}>{PoData.ProductionTypeName}</span></li>
                            <li>Mix Name <span className={`${classes.detail_view_value}`}>{PoData.MixTypeName}</span></li>
                            <li>Batch Count <span className={`${classes.detail_view_value}`}>{PoData.Batch}</span></li>
                        </div>

                        <div className={`${classes.mix_high_level_detail}`}>

                            <div className={`${classes.mix_high_level_detail_header}`}>
                                <h4>Requested Ingredients </h4>
                            </div>

                            <div className={`${classes.ing_list}`}>

                                {MixData && MixData.map((mix_detail_item, mix_index) =>(

                                        <li className={`${classes.mix_mat_list_wrapper}`} key={mix_index}>
                                            <span className={`${classes.mix_mat_name_left}`}>
                                                {mix_detail_item.MaterialTypeName}
                                            </span> 
                                            
                                            <span  className={`${classes.mix_mat_name_right}`}>
                                            {(mix_detail_item.Quantity / PoData.Batch).toFixed(3)} X {PoData.Batch} =  <span className={`${classes.stock_out_val}`}>[ {mix_detail_item.Quantity} ]</span> {/* {!ProductionMixBatchValueFlag[idx] ? mix_detail_item.Quantity : mix_detail_item.Quantity / item.Batch }   {!ProductionMixBatchValueFlag[idx] ? '' : item.Batch > 1 ? `X (${item.Batch})` : ''}  */}
                                            </span>
                                        </li>

                                    ))
                                }
                                

                            </div>
                                

                        </div>

                    </div>


                    <div className={`${classes.stock_action_wrapper}`}>
                        <div className={`${classes.sig_info}`}>
                            <li className={`${ !HasSigned ? classes.sign_force : ''}`}>Sign here, Please!</li>
                            <li className={`${!SignInAction ? classes.sing_info : classes.sing_blip}`}>{SigActionMsg}</li>
                        </div>
                        <ReactSignatureCanvas 
                        ref={SigRef}
                            onBegin={HandleSigBeginAction}
                            onEnd={HandleSigEnd}
                            // dotSize={3.0} 
                            penColor='#ef255f'
                            canvasProps={{width: 300, height: 150, className: classes.sigCanvas}} 
                        />

                        <div className={`${classes.stock_action_sig}`}>
                                <button onClick={()=>{ClearSig()}}>Re-sign</button>
                                <button onClick={()=>{TrimSig()}}>{!SignLock ? 'Lock' : 'Unlock'}</button>

                        </div>

                        <div className={`${classes.stock_action}`}>
                            <button onClick={()=>{WriteSig()}}>Authorize</button>
                        </div>
                    </div>

                </div>

               
            </div>

        </div>
    )
}