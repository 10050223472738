import { getBaseURL } from "../../BaseUrlConfig";
import axios from 'axios'

function GetRouteConfig(token)
{
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
}


export const QueryChefsOrderDashboard = async(data, token)=> 
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'ck/chefs/query-chefs-order-dashboard', data, GetRouteConfig(token));
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err; 
    }
}
export const QueryLocalStockBuffer = async(data, token)=> 
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'ck/chefs/query-local-stock-buffers', data, GetRouteConfig(token));
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err; 
    }
}
export const QueryProductionReadyItems = async(data, token)=> 
    {
        try  
        {
            const response = await axios.post(getBaseURL() + 'ck/chefs/query-prod-ready-items', data, GetRouteConfig(token));
            const res = await response.data;
    
            return res;
        }catch(err) 
        {
            throw err; 
        }
    }
export const QueryVariantIng = async(__id, token)=> 
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'ck/chefs/query-variant-ing', {ID: __id}, GetRouteConfig(token));
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err; 
    }
}
export const CreateChefsStockRequest = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'ck/chefs/create-chefs-stock-request', data, GetRouteConfig(token));
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err; 
    }
}
export const CreateChefsStockRequestFromRecipe = async(data, token)=>
    {
        try  
        {
            const response = await axios.post(getBaseURL() + 'ck/chefs/create-chefs-stock-request-fomr-recipe-card', data, GetRouteConfig(token));
            const res = await response.data;

            if(res.MyStateCode !== 1) 
            {
                throw new Error(res.Msg);
            }
    
            return res;
        }catch(err) 
        {
            throw err; 
        }
    }

export const QueryChefsStockRequestGroup = async(data, token)=> 
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'ck/chefs/query-chefs-sr-group', data, GetRouteConfig(token));
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err; 
    }
}
export const QueryChefsStockRequestItem = async(data, token)=> 
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'ck/chefs/query-chefs-sr-prod-item', data, GetRouteConfig(token));
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err; 
    }
}
export const AcceptChefsStockRequestItem = async(data, token)=> 
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'ck/chefs/accept-chefs-sr-prod-request', data, GetRouteConfig(token));
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err; 
    }
}
export const FinishChefsItemProduction = async(data, token)=> 
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'ck/chefs/finish-chefs-prod-item', data, GetRouteConfig(token));
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err; 
    }
}
export const StartChefsItemProduction = async(data, token)=> 
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'ck/chefs/start-chefs-prod-item', data, GetRouteConfig(token));
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err; 
    }
}