import classes from './CleanList.module.css'


export default function CleanList({Msg, FontSize = 20}) 
{
    return (
        <div className={`${classes.clean_state_info}`}>
            <div className={`${classes.clean_state_info_inner}`} style={{
                fontSize: `${FontSize}px`
            }}>
        
                { Msg ? Msg : "No Credit" }

            </div>
        </div>
    );
}