import classes from './ReleaseCash.module.css'

import { Custom_DD_Style } from '../../../../../SharedComp/Utility'
import data_win from '../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MasterDataRegInput from '../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useState, useRef } from 'react'

import Select from 'react-select'
import axios from 'axios';
import { useEffect } from 'react'
import { getBaseURL } from '../../../../../SharedComp/BaseUrlConfig'
import { useAuth } from '../../../../../AuthProvider'

export default function ReleaseCash({Close_CALLBACK, Msg_Callback}) 
{

    const [Registring, SetRegistring] = useState(false);
    const auth = useAuth();

    const [MRC, SetMRC] = useState('-');
    const [TIN, SetTIN] = useState('-');
    const [Receipt, SetReceipt] = useState('-');
    const [Cat, SetCat] = useState();
    const [Amount, SetAmount] = useState();
    const [Reason, SetReason]= useState('-');




    const formRef = useRef();

    const expenseReasonOptions = [
        { value: 'Food', label: 'Food' },
        { value: 'Transportation', label: 'Transportation' },
        { value: 'Office Supplies', label: 'Office Supplies' },
        { value: 'Miscellaneous', label: 'Miscellaneous' },
        { value: 'Entertainment', label: 'Entertainment' },
        { value: 'Employee Credit', label: 'Employee Credit' },
        { value: 'One-time Employee Salary', label: 'One-time Employee Salary' }
      ];

    function HandleSubmition(e) 
    {
        e.preventDefault();

        SetRegistring(true);

        const fd = new FormData();

        fd.append("MRC", MRC);
        fd.append("TIN", TIN);
        fd.append("Receipt", Receipt);
        fd.append("Cat", Cat);
        fd.append("Amount", Amount);
        fd.append("Reason", Reason);
        fd.append("BranchID", auth.activeWorkShop);

        axios.post(getBaseURL() + 'operation-data/reg-pitty-cash-expense', fd).then((data)=>{
            SetRegistring(false);
            Msg_Callback(data);
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(()=>{

    }, [auth.activeWorkShop])

    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>


        <div className={`${data_win.data_main}`}>

            <div className={`${data_win.data_main_header}`}>
                <h3>
                    JAK <span className={`${data_win.data_create_name}`}>Release Cash</span>
                </h3>
                <button onClick={()=>{Close_CALLBACK(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
            </div>

            <div className={`${classes.data_body}`}>

                    <form onSubmit={HandleSubmition} ref={formRef} action="POST">
                            <div className={`${classes.form_wrapper}`}> 
                                    <div  className={`${classes.input_list_wrapper}`}>
                                        
                                         <div className={`${classes.input_wrap}`}>
                                            <MasterDataRegInput input={<input onChange={(e)=>{SetMRC(e.target.value)}}  name='mrc' type="text" placeholder='MRC' title='' autoComplete='off' />}/>
                                        </div>
                                        <div className={`${classes.input_wrap}`}>
                                            <MasterDataRegInput input={<input onChange={(e)=>{SetReceipt(e.target.value)}}  name='rn' type="text" placeholder='Receipt Number' title='' autoComplete='off'/>} />
                                        </div>
                                        <div className={`${classes.input_wrap}`}>
                                            <MasterDataRegInput input={<input onChange={(e)=>{SetTIN(e.target.value)}} name='stin' type="text" placeholder='TIN Number' title='' autoComplete='off' />} />
                                        </div>

                                        <div className={`${classes.input_wrap}`}>
                                            <MasterDataRegInput 
                                                input={
                                                    <Select
                                                        onChange={(data)=>{SetCat(data.value)}}
                                                        name={"dd_data"}
                                                        options={expenseReasonOptions}
                                                        placeholder={"Category..."}
                                                        isSearchable={true}
                                                        styles={Custom_DD_Style}
                                                        required ={true}
                                                    />
                                                
                                            } tip={"Select Category"}  forceInput={true}/>
                                        </div>

                                        <div className={`${classes.input_wrap}`}>
                                            <MasterDataRegInput input={<input min={0} step="any" onChange={(e)=>{SetAmount(e.target.value)}} name='expence' type="number" placeholder='Amount' title='' autoComplete='off' required />} />
                                        </div>

                                        <div className={`${classes.input_wrap}`}>
                                            <MasterDataRegInput input={<input onChange={(e)=>{SetReason(e.target.value)}}  name='reason' type="text" placeholder='Comment' autoComplete='off'  />} />
                                        </div>
                                        

                                        

                                    </div>
                            </div>
                            <div className={`${data_win.form_action_button_wrapper}`}>
                                <button disabled={Registring} className={`${data_win.form_action_button_save}`}>{Registring ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Add'}</button>
                                <button disabled={Registring}  onClick={()=>{Close_CALLBACK(false)}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                            </div>
                        </form>

            </div>

        </div>

    </div>
    )
}