import classes from './CreateMasterProduct.module.css'
import data_win from '../../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { faObjectGroup, faObjectUngroup, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FetchBranchAll, PushProductLineMasterGroup } from '../../../../../../api';
import { useAuth } from '../../../../../../AuthProvider';
import { ToastWrapper, toast } from '../../../../../../SharedComp/ToastWrapper/ToastWrapper';

import MasterDataRegInput from '../../../../../../SharedComp/CommonStyle/MasterDataRegInput';

const CreateMasterProduct = ({OpenModal}) => {


    const auth = useAuth();
    const [Loading, SetLoading] = useState(false);
    const [WorkspaceList, SetWorkspaceList] = useState([]);
    const [WorkspaceChecked, SetWorkspaceChecked] = useState(new Map());
    const [ToggelListTrue, SetToggelListTrue] = useState(false);

    const [GroupName, SetGroupName] = useState();
    const [GroupDetail, SetGroupDetail] = useState();
    const [IsLocalProduction, SetIsLocalProduction] = useState(true);
    const [IncludePreorder, SetIncludePreorder] = useState(false);

    const handleToggle = (branchName) => {
        SetWorkspaceChecked(prevState => {
          const updatedState = new Map(prevState);
          updatedState.set(branchName, !updatedState.get(branchName));
          return updatedState;
        });
      };

      const selectAll = (val) => {

        SetToggelListTrue(val);

        let updated_list = new Map();
        for(const [mp_key, mp_val] of WorkspaceChecked.entries()) 
        {
            updated_list.set(mp_key, val);
        }
        SetWorkspaceChecked(updated_list);    
      };
    const QueryWorkspace = async () => {

        try {
            const res = await FetchBranchAll(auth.token);

            // for now prepare void list
            let init_check_list = new Map();
            for(let  i = 0; i < res.length; i++) 
            {
                if(!init_check_list.has(res[i].BranchName)) 
                    init_check_list.set(res[i].BranchName, false); 
            }
            SetWorkspaceChecked(init_check_list);

            SetWorkspaceList(res);
        } catch (err) 
        {
            throw new Error(err);
        }
    }

    const HandleSubmition = async (e) =>
    {
        e.preventDefault();

        SetLoading(true);
        toast.loading('Under Your Obligation', {
            toastId: 1212
        });
        
        setTimeout(async ()=>{

            try 
            {
                const fd = new FormData();
                fd.append("group_name", GroupName);
                fd.append("workspace", JSON.stringify(Object.fromEntries(WorkspaceChecked)));
                fd.append("group_detail", GroupDetail);
                fd.append("user_id", auth.user.UserAccountID);
                fd.append("local_production", IsLocalProduction);
                fd.append("include_in_preorder", IncludePreorder);

                const res = await PushProductLineMasterGroup(fd, auth.token);

                SetLoading(false);
                toast.update(1212, {render: res.Msg, autoClose: 6000, type: 'success', isLoading: false});
            } catch(err) 
            {
                SetLoading(false);
                toast.update(1212, {render: err.message || 'An error occurred', autoClose: 6000, type: 'error', isLoading: false});
            }

        }, 3000)

    }

    useEffect(()=>{
        QueryWorkspace();
    }, [])

    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>
            <ToastWrapper/>

            <div className={`${data_win.data_main}`}>
                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        New <span className={`${data_win.data_create_name}`}>Master Product</span>
                    </h3>
                    <button onClick={()=>{OpenModal(false)}}>
                        <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                    </button>
                </div>

                <div className={`${data_win.data_body}`}>
                        
                

                            <form onSubmit={HandleSubmition} action="POST">
                                <div className={`${classes.form_wrapper}`}> 


                                <div className={`${classes.master_prod_type_config_wrapper}`}>
                                        <div className={`${classes.master_product_type_inner_config_wrapper}`}>
                                            <MasterDataRegInput input={
                                                <input onChange={(e)=>{SetGroupName(e.target.value)}} type="text" placeholder='Master poduct group name' required />
                                            }/>
                                            <MasterDataRegInput input={
                                                <textarea onChange={(e)=>{SetGroupDetail(e.target.value)}} name="detail" placeholder='Master poduct group description' required></textarea>
                                            }/>
                                            <div onClick={()=>{SetIsLocalProduction(!IsLocalProduction)}} className={`${classes.prodution_group_type}`}>
                                                <span>Produced Locally</span>
                                                <input onChange={()=>{}} checked={IsLocalProduction} type="checkbox" />
                                            </div>
                                            <div onClick={()=>{SetIncludePreorder(!IncludePreorder)}} className={`${classes.prodution_group_type}`}>
                                                <span>Include In Pre-Order</span>
                                                <input onChange={()=>{}} checked={IncludePreorder} type="checkbox" />
                                            </div>
                                        </div>

                                        <div className={`${classes.master_product_type_inner_avail_workspace_list}`}>
                                            <div className={`${classes.master_product_type_inner_avail_workspace_list_header}`}>
                                                <h1>Accessibility</h1>
                                                <button type='button' onClick={()=>{selectAll(!ToggelListTrue)}}><FontAwesomeIcon icon={ToggelListTrue ? faObjectUngroup : faObjectGroup}/></button>
                                            </div>
                                            <div className={`${classes.master_prod_avail_list}`}>
                                                {
                                                    WorkspaceList.map((item, idx)=>(
                                                        <div key={idx} onClick={() => handleToggle(item.BranchName)} className={`${classes.master_prod_avail_branch}`}>
                                                            <input 
                                                            onClick={() => handleToggle(item.BranchName)}
                                                            checked={WorkspaceChecked.get(item.BranchName)} 
                                                            name={item.BranchName} 
                                                            type="checkbox"
                                                            onChange={() => handleToggle(item.BranchName)}
                                                             />
                                                            <li>{item.BranchName}</li>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                       
                                </div>
                                <div className={`${data_win.form_action_button_wrapper}`}>
                                    <button disabled={Loading} className={`${data_win.form_action_button_save}`}>{Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Add'}</button>
                                    <button disabled={Loading}  onClick={()=>{OpenModal(false)}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                                </div>
                            </form>




                </div>
            </div>

        </div>
    )
}

export default CreateMasterProduct;