import classes from './CreateRole.module.css'
import MasterDataRegInput from '../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import data_win from '../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import { getBaseURL } from '../../../../../SharedComp/BaseUrlConfig'
import axios from 'axios'
import { Custom_DD_Style } from '../../../../../SharedComp/Utility'
import Select from 'react-select'
import { useRef } from 'react'

import { CreateNewUserAccess } from '../../../../../SharedComp/API/UserAccess/API_FE_UserAccess'
import { useToast } from '../../../../../ToastContext'


export default function CreateRole({Close_CALLBACK}) 
{

    const [Loading, SetLoading] = useState(false);
    const [RoleName, SetRoleName] = useState('');
    const [LandingPageRoute, SetLandingPageRoute] = useState();
    const [ClientPage, SetClientPage] = useState(false);
    
    const notify = useToast();

    const RefRouteLlist = useRef(new Map());

    const [RouteCount, SetRouteCount] = useState(0);
    const [ValidRouteCount, SetValidRouteCount] = useState(0);

    const [AvialRoute, SetAvialRoute] = useState([]);

    const [RoutePackage, SetRoutePackage] = useState(
        new Map([
            ['Control Panel', {
                ValidRouteGroup: false, 
                Icon: 'faChartLine',
                Config: new Map([
                    ["/dashboard_master", 
                        { HasAccess: false, Route: "/dashboard_master", Label: "Dashboard" }]
                ])
            }],
            ['Account Management', {
                ValidRouteGroup: false,
                Icon: 'faUserCheck',
                Config: new Map([
                    ["/user-account", 
                        { HasAccess: false, Route: "/user-account", Label: "User" }],
                    ["/user-role", 
                        { HasAccess: false, Route: "/user-role", Label: "Role" }],
                    ["/user-privilege", 
                        { HasAccess: false, Route: "/user-privilege", Label: "Privilege" }]
                ])
            }],


            ['Employee Manager', {
                ValidRouteGroup: false,
                Icon: 'faUsers',
                Config: new Map([
                    ["/employee", 
                        { HasAccess: false, Route: "/employee", Label: "Fixed Employee" }],
                    ["/employee-nomadic", 
                        { HasAccess: false, Route: "/employee-nomadic", Label: "Nomadic Employee" }],
                    ["/termination-archive", 
                        { HasAccess: false, Route: "/termination-archive", Label: "Termination Archive" }],
                    ["/pull-employee-report", 
                        { HasAccess: false, Route: "/pull-employee-report", Label: "Pull Report" }]
                ])
            }],


            ['Payroll', {
                ValidRouteGroup: false,
                Icon: 'faDollarSign',
                Config: new Map([
                    ["/monthly-payroll", 
                        { HasAccess: false, Route: "/monthly-payroll", Label: "Monthly Payroll" }],
                    ["/weekly-payroll", 
                        { HasAccess: false, Route: "/weekly-payroll", Label: "Weekly Payroll" }]
                ])
            }],


            ['Functions', {
                ValidRouteGroup: false,
                Icon: 'faWrench',
                Config: new Map([
                    ["/tool-request", 
                        { HasAccess: false, Route: "/tool-request", Label: "Tool Request" }],
                    ["/market-price", 
                        { HasAccess: false, Route: "/market-price", Label: "Market Price" }],
                    ["/maintenance-registry", 
                        { HasAccess: false, Route: "/maintenance-registry", Label: "Maintenance Registry" }],
                    ["/received-petty-cash", 
                        { HasAccess: false, Route: "/received-petty-cash", Label: "Received Petty Cash" }],
                    ["/issue-petty-cash", 
                        { HasAccess: false, Route: "/issue-petty-cash", Label: "Issue Petty Cash" }],
                    ["/issue-registration", 
                        { HasAccess: false, Route: "/issue-registration", Label: "Issue Registration" }],
                    ["/notification-master", 
                        { HasAccess: false, Route: "/notification-master", Label: "Notification Master" }]
                ])
            }],


            ['Device Integration', {
                ValidRouteGroup: false,
                Icon: 'faLink',
                Config: new Map([
                    ["/whats-gps-vehicle-integration", 
                        { HasAccess: false, Route: "/whats-gps-vehicle-integration", Label: "Vehicle" }]
                ])
            }],


            ['Stock Management', {
                ValidRouteGroup: false,
                Icon: 'faBoxesStacked',
                Config: new Map([
                    ["/stock-in", 
                        { HasAccess: false, Route: "/stock-in", Label: "Livestock" }],
                    ["/stock-warehouse", 
                        { HasAccess: false, Route: "/stock-warehouse", Label: "Warehouse" }],
                    ["/stock-track-request", 
                        { HasAccess: false, Route: "/stock-track-request", Label: "Track Request" }],
                    ["/stock-trasnfer-stock", 
                        { HasAccess: false, Route: "/stock-trasnfer-stock", Label: "Transfer Stock" }],
                    ["/stock-out", 
                        { HasAccess: false, Route: "/stock-out", Label: "Production Stock Request" }],
                    ["/manage-invoices", 
                        { HasAccess: false, Route: "/manage-invoices", Label: "Manage Invoice" }],
                    ["/stock-audit-trails", 
                        { HasAccess: false, Route: "/stock-audit-trails", Label: "Audit Trails" }]
                ])
            }],


            ['Pitty Cash Management', {
                ValidRouteGroup: false,
                Icon: 'faArrowDownUpAcrossLine',
                Config: new Map([
                    ["/pitty-cash-finance-level", 
                        { HasAccess: false, Route: "/pitty-cash-finance-level", Label: "Central Accounts" }],
                    ["/pitty-cash-branch-level", 
                        { HasAccess: false, Route: "/pitty-cash-branch-level", Label: "Site Cash Management" }]
                ])
            }],


            ['Production Controller', {
                ValidRouteGroup: false,
                Icon: 'faGears',
                Config: new Map([
                    ["/production-order", 
                        { HasAccess: false, Route: "/production-order", Label: "Production Order" }],
                    ["/prod-stack", 
                        { HasAccess: false, Route: "/prod-stack", Label: "Production Stack" }],
                    ["/stock-card", 
                        { HasAccess: false, Route: "/stock-card", Label: "Production Log" }]
                ])
            }],


            ['Front Desk', {
                ValidRouteGroup: false,
                Icon: 'faCircleUser',
                Config: new Map([
                    ["/cash-collection-master", 
                        { HasAccess: false, Route: "/cash-collection-master", Label: "Cash Collection" }],
                    ["/pre-order", 
                        { HasAccess: false, Route: "/pre-order", Label: "Pre-Order" }],
                    ["/ready-for-delivery", 
                        { HasAccess: false, Route: "/ready-for-delivery", Label: "Ready For Delivery" }],
                    ["/pre-order-payment-history", 
                        { HasAccess: false, Route: "/pre-order-payment-history", Label: "Pre-Order Payment History" }]
                ])
            }],


            ['Manage Orders', {
                ValidRouteGroup: false,
                Icon: 'faLayerGroup',
                Config: new Map([
                    ["/kitchen-supply-order", 
                        { HasAccess: false, Route: "/kitchen-supply-order", Label: "Kitchen Supply Order" }],
                    ["/local-front-order", 
                        { HasAccess: false, Route: "/local-front-order", Label: "Local Front Order" }]
                ])
            }],

            ['Kitchen', {
                ValidRouteGroup: false,
                Icon: 'faPizzaSlice',
                Config: new Map([
                    ["/production-control-center", 
                        { HasAccess: false, Route: "/production-control-center", Label: "Product Control Center" }],
                    ["/production-awaiting-dispatch", 
                        { HasAccess: false, Route: "/production-awaiting-dispatch", Label: "Awaiting Dispatch" }],
                    ["/chefs-stock-request", 
                        { HasAccess: false, Route: "/chefs-stock-request", Label: "Chef's Stock Request" }],
                    ["/on-production-line", 
                        { HasAccess: false, Route: "/on-production-line", Label: "Production Line" }],
                    ["/manage-workspace-order-distribution", 
                        { HasAccess: false, Route: "/manage-workspace-order-distribution", Label: "Manage Distribution" }],
                    ["/chef-lineup", 
                        { HasAccess: false, Route: "/chef-lineup", Label: "Chef Lineup" }],
                    ["/distribution-archive", 
                        { HasAccess: false, Route: "/distribution-archive", Label: "Distribution Archive" }]
                ])
            }],


            ['Chefs', {
                ValidRouteGroup: false,
                Icon: 'faUtensils',
                Config: new Map([
                    ["/chefs_dashboard", 
                        { HasAccess: false, Route: "/chefs_dashboard", Label: "Chefs Dashboard" }],
                    ["/chefs_performance", 
                        { HasAccess: false, Route: "/chefs_performance", Label: "Performance" }]
                ])
            }],


            ['Archive', {
                ValidRouteGroup: false,
                Icon: 'faBoxOpen',
                Config: new Map([
                    ["/local-shop-archive", 
                        { HasAccess: false, Route: "/local-shop-archive", Label: "Local Shop Archive" }],
                    ["/cash-collection-archive", 
                        { HasAccess: false, Route: "/cash-collection-archive", Label: "Cash Collection Archive" }],
                    ["/vendors", 
                        { HasAccess: false, Route: "/vendors", Label: "Merch Vendors Archive" }]
                ])
            }],


            ['Product Line', {
                ValidRouteGroup: false,
                Icon: 'faCogs',
                Config: new Map([
                    ["/master-product-line", 
                        { HasAccess: false, Route: "/master-product-line", Label: "Master Products Classification" }],
                    ["/production-master-data-entry", 
                        { HasAccess: false, Route: "/production-master-data-entry", Label: "Manage Core Products" }],
                    ["/inventory-merchandise", 
                        { HasAccess: false, Route: "/inventory-merchandise", Label: "Manage Merchandise" }],
                    ["/ck-production", 
                        { HasAccess: false, Route: "/ck-production", Label: "Manage Kitchen Products" }],
                    ["/yam-mix-master-data-entry", 
                        { HasAccess: false, Route: "/yam-mix-master-data-entry", Label: "Manage YAM Recipe" }],
                    ["/kitchen-variants-master-data-entry", 
                        { HasAccess: false, Route: "/kitchen-variants-master-data-entry", Label: "Manage Kitchen Variants" }]
                ])
            }],


            ['JAK-YAM', {
                ValidRouteGroup: false,
                Icon: 'faBuildingColumns',
                Config: new Map([
                    ["/company-branch-manager", 
                        { HasAccess: false, Route: "/company-branch-manager", Label: "Branch Management" }],
                    ["/operation-master-data", 
                        { HasAccess: false, Route: "/operation-master-data", Label: "Operation /MD" }],
                    ["/hr-employee-manager", 
                        { HasAccess: false, Route: "/hr-employee-manager", Label: "HR /MD" }],
                    ["/inventory-master-data-entry", 
                        { HasAccess: false, Route: "/inventory-master-data-entry", Label: "Inventory /MD" }]
                ])
            }]
 ])
    );


    const PrepRouteData = () =>
    {
        const __route = [];
        for(const [key, value] of RefRouteLlist.current.entries()) 
        {
            __route.push({value: key, label: value});
        }

        SetAvialRoute(__route);
    }

    const handleCheckboxChange = (groupName) => {
        SetRoutePackage((prevRoutePackage) => {
            const groupData = prevRoutePackage.get(groupName);
            const newValidRouteGroup = !groupData.ValidRouteGroup; // Toggle ValidRouteGroup

            if(newValidRouteGroup) 
            {
                for(const [key, value] of groupData.Config.entries()) 
                {
                    RefRouteLlist.current.set(key, value.Label);
                }

            } else 
            {
                // if it is false clear it which is a bit tricky
                for(const [key, value] of groupData.Config.entries()) 
                {
                    RefRouteLlist.current.delete(key);
                }
            }
    
            // Update the child routes' HasAccess based on the new ValidRouteGroup
            const updatedConfig = new Map([...groupData.Config].map(([route, config]) => [
                route, 
                { ...config, HasAccess: newValidRouteGroup } // Set HasAccess to match ValidRouteGroup
            ]));
    
            const updatedGroup = {
                ...groupData, 
                ValidRouteGroup: newValidRouteGroup, // Update ValidRouteGroup
                Config: updatedConfig // Update the Config with new HasAccess values
            };
    
            return new Map(prevRoutePackage).set(groupName, updatedGroup); // Update RoutePackage
        });

        PrepRouteData();
    };
    const handleChildCheckboxChange = (groupName, route) => {
        SetRoutePackage((prevRoutePackage) => {
            const groupData = prevRoutePackage.get(groupName);
            const updatedConfig = new Map(groupData.Config);
    
            // Toggle the HasAccess state of the individual child route
            const config = updatedConfig.get(route);
            updatedConfig.set(route, { ...config, HasAccess: !config.HasAccess });

            if(!config.HasAccess) 
            {
                RefRouteLlist.current.set(route, config.Label);
            }
            else 
            {
                RefRouteLlist.current.delete(route);
            }
    
            // Check if any child routes are checked to update the parent's ValidRouteGroup
            const anyChildChecked = [...updatedConfig.values()].some(child => child.HasAccess);
    
            const updatedGroup = {
                ...groupData,
                Config: updatedConfig, // Update the Config with the new child state
                ValidRouteGroup: anyChildChecked // Update parent's ValidRouteGroup
            };
    
            return new Map(prevRoutePackage).set(groupName, updatedGroup); // Update RoutePackage
        });

        PrepRouteData();
    };
    

    const HandleSubmition = async(e)=> 
    {
        e.preventDefault();
        notify.ShowLoad("Processing your request...", 10);
        SetLoading(true);

        try 
        {

            let route_count = 0;
            let valid_rout_count = 0;
            for(const [key, val] of RoutePackage.entries()) 
            {
                for(const [in_key, in_val] of val.Config.entries())
                {
                    route_count++;
                    if(in_val.HasAccess)
                    {
                        valid_rout_count++;
                    }
                }
            }



            const routePackageObject = {};

            RoutePackage.forEach((groupData, groupName) => {
                routePackageObject[groupName] = {
                    ValidRouteGroup: groupData.ValidRouteGroup,
                    Icon: groupData.Icon,
                    Config: {}
                };
    
                groupData.Config.forEach((config, route) => {
                    routePackageObject[groupName].Config[route] = {
                        HasAccess: config.HasAccess,
                        Route: config.Route,
                        Label: config.Label
                    };
                });
            });
    
            // Stringify the object
            const serializedData = JSON.stringify(routePackageObject);
    
            const fd = new FormData();
            fd.append("Name", RoleName);
            fd.append("landing_page", JSON.stringify(LandingPageRoute));
            fd.append("client_page", ClientPage);
            fd.append("access_package", serializedData);
            fd.append("access_level", parseFloat((parseFloat(valid_rout_count) / parseFloat(route_count)) * 100).toFixed(1));
            const main_res = await CreateNewUserAccess(fd, 'token');

            notify.ShowSuccessUpdate(main_res.Msg, 10);
            Close_CALLBACK(false);

        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
        }
    }

    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>


        <div className={`${data_win.data_main} ${classes.data_main}`}>

            <div className={`${data_win.data_main_header}`}>
                <h3>
                    JAK <span className={`${data_win.data_create_name}`}>Create Access Method</span>
                </h3>
                <button onClick={()=>{Close_CALLBACK(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
            </div>

            <div className={`${classes.data_body}`}>

            <form onSubmit={HandleSubmition} action="POST">

                <div className={`${classes.form_wrapper}`}>

                        <div className={`${classes.input_wrap}`}>
                            <MasterDataRegInput 
                                input={
                                    <input 
                                        onChange={(e)=>{SetRoleName(e.target.value)}} 
                                        name='role_name' 
                                        type="text" 
                                        placeholder='Role Name' 
                                        title='' 
                                        autoComplete='off' 
                                        required />} 
                                />
                        </div>

                        <div className={`${classes.input_wrap}`}>
                                <MasterDataRegInput input={
                                    <Select
                                        onChange={(data)=>{SetLandingPageRoute(data);}}
                                        name={"landing_page_route"}
                                        options={AvialRoute}
                                        placeholder={"Landing Page..."}
                                        isSearchable={true}
                                        styles={Custom_DD_Style}
                                        required ={true}
                                /> }/>
                        </div>

                        <div onClick={()=>{SetClientPage(!ClientPage)}} className={`${classes.input_wrap_check_box}`}>
                            <span>Client Access</span>

                            <input 
                                checked={ClientPage}
                                onChange={(e)=>{}} 
                                name='is_client' 
                                type="checkbox" 
                            />
                        </div>

                    </div>


                    <div className={`${classes.page_list}`}>
                        {/* <div className={`${classes.list_header}`}>
                            

                            <div className={`${classes.filter_role}`}>
                               <div className={`${classes.filter_role_input}`}>
                                    <span className={`${classes.filter_role_icon}`}><FontAwesomeIcon icon={faFilter}></FontAwesomeIcon></span>
                                    <input type="text" placeholder='Eg Branch Manager...' />
                               </div>
                            </div>

                        </div> */}

                        <div className={`${classes.pages}`}>

                            {
                                Array.from(RoutePackage.entries()).map(([group_name, group_data], idx)=>(
                                        <div key={group_name + idx} className={`${classes.page_access_tick}`}>
                                            <div onClick={()=>{handleCheckboxChange(group_name)}} className={`${classes.clickable_route_group_wrapper}`}>
                                                <div>
                                                    <li>{group_name}</li>
                                                </div>
                                                <div>
                                                    <input onChange={()=>{}} checked={RoutePackage.get(group_name).ValidRouteGroup} type="checkbox" name={group_name}  />
                                                </div>
                                            </div>

                                            <div className={`${classes.router_inner_items}`}>

                                                {
                                                    Array.from(group_data.Config.entries()).map(([route, config]) => (
                                                        <div onClick={()=>{handleChildCheckboxChange(group_name, route)}} key={config.Label} className={`${classes.inner_route}`}>
                                                            <li>
                                                                <span>{config.Label}</span>
                                                                <input onChange={()=>{}} checked={config.HasAccess} type="checkbox" name={config.Label}  />
                                                            </li>
                                                        </div>
                                                    ))
                                                }

                                            </div>
                                        </div>
                                ))
                            }
                                

                        </div>

                </div>

                <div className={`${data_win.form_action_button_wrapper}`}>
                    <button disabled={Loading} className={`${data_win.form_action_button_save}`}>{Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Add'}</button>
                    <button disabled={Loading}  onClick={()=>{Close_CALLBACK(false)}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                </div>
            </form>

            </div>

        </div>

    </div>
    )
}