import { faCircleCheck, faInfoCircle, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import classes from './NotificationPopUp.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'


export default function NotificationPopUp({msg = "Message Notification will be here!", state=1, TimeOut=10 }) 
{
    const [Active, setActive] = useState(true);

    function handleCloseNoti() 
    {
        setActive(false);
    }
    useEffect(() => {
        setTimeout(()=>{
            setActive(false);
        }, TimeOut * 1000)
    }, [])
    return (
        <div className={`${ Active ? classes.Notification_main_wrapper : classes.Notification_main_wrapper_hide}`} style={
            state === 1 ? {
                borderLeft: '5px solid var(--grren-color)'
            } : 
            state === 0 ? {
                borderLeft: '5px solid var(--color-yellow)'
            } :
            state === -1 ? {
                borderLeft: '5px solid var(--red-color)'
            } : {

            }
        }>
           

            <div className={`${classes.left_side_not_wrapper}`}>

                    <div className={`${classes.icon_section}`} style={
                        state === 1 ? {
                            color: 'var(--grren-color)'
                        } : 
                        state === 0 ? {
                            color: 'var(--color-yellow)'
                        } :
                        state === -1 ? {
                            color: 'var(--red-color)'
                        } : {

                        }
                    }>
                        <FontAwesomeIcon icon={state === 1 ? faCircleCheck : state === -1 ? faTimesCircle : faInfoCircle}/>
                    </div>

                    <div className={`${classes.msg_holder}`}>

                        <div className={`${classes.msg_header}`}>
                            {state === 1 ? "Success" : state === -1 ? "Error" : "Attention"}
                        </div>

                        <div className={`${classes.msg_body}`}>
                            {msg}
                        </div>

                    </div>
                    
            </div>

            <div className={`${classes.noti_manual_close}`}>
                <button onClick={() => {handleCloseNoti()}}><FontAwesomeIcon icon={faTimes}/></button>
            </div>

        </div>
    )
}