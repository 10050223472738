import { faFileExcel, faPenNib, faPlus, faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import classes from './Products.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { useEffect, useState } from 'react';


import CreateMasterProduct from './Component/MasterProduct/Create/CreateMasterProduct';
import EditMasterProduct from './Component/MasterProduct/Edit/EditMasterProduct';

import CreateSubProduct from './Component/SubProduct/Create/CreateSubProduct';
import EditSubProduct from './Component/SubProduct/Edit/EditSubProduct';
import { ToastWrapper } from '../../../SharedComp/ToastWrapper/ToastWrapper';
import { FetchProductLineMasterGroupAll, PurgeProductLineMasterGroup, FetchProductLineMasterSubGroupAll, PurgeProductLineMasterSubGroup } from '../../../api';

import DecisionAssurance from '../../../SharedComp/DecisionAssurance/DecisionAssurance';

import { useToast } from '../../../ToastContext';
import { useAuth } from '../../../AuthProvider';

const Products = () => {

    
    const notify = useToast();
    const auth = useAuth();
    const [RefereshView, SetRefereshView] = useState(false);
    const [OpenCreateMasterProduct, SetOpenCreateMasterProduct] = useState(false);
    const [OpenEditMasterProduct, SetOpenEditMasterProduct] = useState(false);

    const [TargetMasterProductID, SetTargetMasterProductID] = useState();
    const [TargetMasterSubProductID, SetTargetMasterSubProductID] = useState();

    const [OpenCreateSubProduct, SetOpenCreateSubProduct] = useState(false);
    const [OpenEditSubProduct, SetOpenEditSubProduct] = useState(false);

    const [MutiSelectMasterProduct, SetMutiSelectMasterProduct] = useState(false);
    const [MutiSelectSubProduct, SetMutiSelectSubProduct] = useState(false);


    const [MasterGroupList, SetMasterGroupList] = useState([]);
    const [SubGroupList, SetSubGroupList] = useState([]);

    const [IsSubGroupActive, SetIsSubGroupActive] = useState(false);


    // Decision Assurance
    const [daState, SetDaState] = useState(false);
    const [agreement, SetAgreement] = useState();
    const [reqCallbackData, SetReqCallbackData] = useState();
    const [ActionName, setActionName] = useState(null);


    function HandleEditMasterProductOpen(data) 
    {
        SetTargetMasterProductID(data);
        SetOpenEditMasterProduct(true);
    }

    function HandleEditSubProductOpen(data) 
    {
        SetOpenEditSubProduct(true);
        SetTargetMasterSubProductID(data);
    }

    function HandleSubProductGroupPurge(name, id) 
    {
        SetIsSubGroupActive(true);
        SetAgreement(`
            You are about to purge the master Sub-Group '${name}'. 
            This action will have significant consequences: 
            all child products will be removed, 
            and any active production under this subcategory will be aborted. 
            This action is IRREVERSIBLE. Are you sure you want to proceed?`);
        SetReqCallbackData(id);
        SetDaState(true);
        setActionName("Purge Master Sub-Group")
    }

    const FetchMasterGroup = async () => {
        try 
        {
            const res = await FetchProductLineMasterGroupAll(auth.token);
            SetMasterGroupList(res);

        }catch(err) 
        {

        }
    }
    const FetchMasterSubGroup = async()=>{
        try 
        {
            const res = await FetchProductLineMasterSubGroupAll(auth.token);
            SetSubGroupList(res);

        }catch(err) 
        {

        }
    }

    function InitMasterGroupRemoval(id, name) 
    {
        SetIsSubGroupActive(false);
        SetAgreement(`
            You are about to purge the master group '${name}'. 
            This action will have significant consequences: 
            all child products will be removed, 
            and any active production under this subcategory will be aborted. 
            This action is IRREVERSIBLE. Are you sure you want to proceed?`);
        SetReqCallbackData(id);
        SetDaState(true);
        setActionName("Purge Master Group")
    }

    const RemoveMasterGroup = async (id) => 
    {
        try 
        {
            const fd = new FormData();
            fd.append('original_id', id);
            const res = await PurgeProductLineMasterGroup(fd, auth.token);
            notify.ShowSuccess(res.Msg);
            SetRefereshView(!RefereshView);
        }catch(err) 
        {
            console.log(err.message);
            notify.ShowError(err.message);
        }
    }

    const RemoveMasterSubGroup = async (id) => 
    {
        try 
        {
            const fd = new FormData();
            fd.append('original_id', id);
            const res = await PurgeProductLineMasterSubGroup(fd, auth.token);
            notify.ShowSuccess(res.Msg);
            SetRefereshView(!RefereshView);
        }catch(err) 
        {
            console.log(err.message);
            notify.ShowError(err.message);
        }
    }

    useEffect(()=>{
        FetchMasterGroup();
        FetchMasterSubGroup();
    }, [OpenCreateMasterProduct, OpenEditMasterProduct, OpenCreateSubProduct, OpenEditSubProduct,RefereshView])

    return (
        <div className={`${classes.product_main_master_wrapper}`}>

            {daState && 
                    <DecisionAssurance  
                    ActionName={ActionName}
                    CallbackData={reqCallbackData} 
                    Msg={agreement} 
                    openController={SetDaState} 
                    HandleResponse={IsSubGroupActive ? RemoveMasterSubGroup : RemoveMasterGroup}
                />
            }

            {OpenCreateMasterProduct && <CreateMasterProduct  OpenModal={SetOpenCreateMasterProduct}/>}
            {OpenEditMasterProduct && <EditMasterProduct  TargetID={TargetMasterProductID}  OpenModal={SetOpenEditMasterProduct}/>}

            {OpenCreateSubProduct && <CreateSubProduct  OpenModal={SetOpenCreateSubProduct}/>}
            {OpenEditSubProduct && <EditSubProduct  TargetID={TargetMasterSubProductID}  OpenModal={SetOpenEditSubProduct}/>}

            <div className={`${classes.list_item_product_class_wrapper}`}>
                
                <div className={`${classes.list_item_product_class}`}>

                    <div className={`${classes.list_item_product_class_list_wrapper_header}`}>
                        <h2>Master <span> Product Line</span> Group</h2>
                    </div>
                    

                    <div className={`${classes.list_item_product_class_list_wrapper}`}>

                        {
                            MasterGroupList.map((item, idx)=>(
                                <li key={idx}>
                                     {MutiSelectMasterProduct && <input type="checkbox" /> }
                                     <span className={`${classes.list_item_product_class_name}`}>{item.ProdCatName}</span>
                                     <span className={`${classes.list_item_product_class_detail}`}>{item.ProdCatDescription}</span>
                                     <span onClick={()=>{InitMasterGroupRemoval(item.ProdCatID, item.ProdCatName)}} className={`${classes.list_item_product_class_purge}`}> <FontAwesomeIcon icon={faTrash}/> Purge</span>
                                     <span onClick={()=>{HandleEditMasterProductOpen(item.ProdCatID)}} className={`${classes.list_item_product_class_more}`}><FontAwesomeIcon icon={faPenNib}/>  Manage</span>
                                </li>
                            ))
                        }
                        
                    </div>

                    <div className={`${classes.action_button_wrapper_product_class}`}>
                        <button onClick={()=>{SetOpenCreateMasterProduct(true)}}><FontAwesomeIcon icon={faPlusCircle}/> New</button>
                        <button onClick={()=>{SetMutiSelectMasterProduct(!MutiSelectMasterProduct)}}><FontAwesomeIcon icon={faCopy}/></button>
                    </div>
                </div>

                <div className={`${classes.list_item_product_class}`}>

                    <div className={`${classes.list_item_product_class_list_wrapper_header}`}>
                        <h2>Master<span> Product Line</span> Sub-Group</h2>
                    </div>

                    <div className={`${classes.list_item_product_class_list_wrapper}`}>
                        {
                            SubGroupList.map((item, idx)=>(
                                <li>
                                    {MutiSelectSubProduct && <input type="checkbox" /> }
                                    <span className={`${classes.list_item_product_class_subcat_name}`}>{item.ProdSubCatName}</span>
                                    <span className={`${classes.list_item_product_class_name}`}>{item.ProdCatName}</span>
                                    <span className={`${classes.list_item_product_class_detail}`}>{item.ProdSubCatDescription}</span>
                                    <span onClick={()=>{HandleSubProductGroupPurge(item.ProdSubCatName, item.ProdSubCatID)}} className={`${classes.list_item_product_class_purge}`}> <FontAwesomeIcon icon={faTrash}/> Purge</span>
                                    <span onClick={()=>{HandleEditSubProductOpen(item.ProdSubCatID)}} className={`${classes.list_item_product_class_more}`}><FontAwesomeIcon icon={faPenNib}/> Manage</span>
                                </li>
                            ))
                        }
                            

                    </div>
                    <div className={`${classes.action_button_wrapper_product_class}`}>
                        <button onClick={()=>{SetOpenCreateSubProduct(true)}}><FontAwesomeIcon icon={faPlusCircle}/> New</button>
                        <button onClick={()=>{SetMutiSelectSubProduct(!MutiSelectSubProduct)}}><FontAwesomeIcon icon={faCopy}/></button>
                    </div>

                </div>
            </div>
            
        </div>
    )
}

export default Products;