import classes from './GifStyle.module.css'

export default function DotsGif() 
{
    return (
        <div className={`${classes.dots}`}>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}