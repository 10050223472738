import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classes from './NonEdibleGoodsData.module.css'
import data_win from './../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import {faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import MasterDataRegInput from '../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useRef, useState } from 'react'
import axios from 'axios';
import Select from 'react-select';
import { useEffect } from 'react'


export default function NonEdibleGoodsData({OpenController, MsgTrigger}) 
{
    const formRef = useRef();

    // For Dropdown selection
    const [GoodsTypeOptionList, SetGoodsTypeOptionList] = useState([]);
    const [GoodsBrandOptionList, SetGoodsBrandOptionList] = useState([]);
    const [GoodsMeasurementOptionList, SetGoodsMeasurementOptionList] = useState([]);

    // Dropdown selection
    const [Itemtype, SetItemType] = useState(null);
    const [ItemBrand, SetItemBrand] = useState(null);
    const [ItemUOM, SetItemUOM] = useState(null);

    // If the server is requested but not answerd yet..
    const [registring, SetRegistring] = useState(false);

    // Clear the form when ever the user click dismiss of close the window
    function CloseModal() 
    {
        formRef.current.reset();
        OpenController(false);
    }


    // Handle submition
    function HandleSubmition(e) 
    {
        e.preventDefault();
        SetRegistring(true);

        setTimeout(() => {

            const fd = new FormData();

            const url = 'http://localhost:3333/master-data/add-iv-item'; 


            fd.append("goods_name", e.target.goods_name.value);
            fd.append("min_stock_amount", e.target.min_stock_amount.value);
            fd.append("max_stock_amount", e.target.max_stock_amount.value);
            fd.append("item_type", Itemtype);
            fd.append("item_brand", ItemBrand);
            fd.append("item_uom", ItemUOM);
            fd.append("edible", 'No');
            
            
            
            axios.post(url, fd, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    })
                .then(res => {
                    SetRegistring(false);
                    MsgTrigger(res);
                    CloseModal();
                    
                    
                })
                .catch(err => {
                    SetRegistring(false);
                    MsgTrigger(err);
                    
                })
            }, 2000)
    }


    function PrepList(data, bucket) {
        const newOptionList = [];
      
        for (var i = 0; i < data.length; i++) {
          
          let object = data[i];
      
          let value_key = null;
          let value_lable = null;

         // console.log(object);
          for (const key in object) {

            const active = String(key);
            if (active.toLowerCase().includes('name')) {

              const val = object[key];
              value_lable = val;

            } else if (active.toLowerCase().includes('id')) {

              const val = object[key];
              value_key = val;

            }

            

            if(value_lable !== null && value_key !== null) 
            {
              newOptionList.push({ value: value_key, label: value_lable  });
              break;
            }

          }

          
        }
      
        bucket(newOptionList);
      }

      useEffect(() => 
        {
            QueryOption();
        }, [])

      function QueryOption() 
      {
        fetch('http://localhost:3333/master-data/get-item-brand')
        .then(res => res.json())
        .then((data) => PrepList(data, SetGoodsBrandOptionList));

        fetch('http://localhost:3333/master-data/get-uom')
        .then(res => res.json())
        .then((data) => PrepList(data, SetGoodsMeasurementOptionList));


        fetch('http://localhost:3333/master-data/get-iv-type', { 
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ID: "No" }),
          })
            .then(res => res.json())
            .then((data) => {PrepList(data, SetGoodsTypeOptionList); });
      } 

    const Custom_DD_Style = {
        control: (provided, state) => ({
            ...provided,
            width: 170,
            minHeight: 20,
            height: 35,
            fontWeight: 'bold',
            fontSize: 13,
            background: 'var(--background-light)',
            borderColor: 'var(--background-light)',
            color: 'var(--secondary-color)',
            borderRadius: 1,
            borderColor: 'none',
            boxShadow: 'none',
            '&:hover': {
            borderColor: 'none',
            boxShadow: 'none',
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? 'var(--body-background)' : 'var(--background-dark)', // Change background color on hover
            color:'var(--text_light_white)',
            transition: 'background-color 0.2s ease-in-out',
            cursor: 'pointer',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'var(--text_light_white)', // Set the text color for the selected option in the dropdown
          }),
          input: (provided) => ({
            ...provided,
            color: 'var(--text_light_white)', //input text color when searching
          }),
        menu: (provided) => ({
            ...provided,
            zIndex: 9999, // Increase the z-index if options are not displayed properly
            backgroundColor: 'var(--background-light)',
            fontSize: 13,
            borderRadius: 1,
            fontWeight: 500,
        }),
};



    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

            <div className={`${data_win.data_main}`}>
                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        Registring <span className={`${data_win.data_create_name}`}>Non-Edible Inventory Goods</span>
                    </h3>
                    <button onClick={()=>{CloseModal()}}>
                        <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                    </button>
                </div>

                <div className={`${data_win.data_body}`}>
                        
                

                            <form onSubmit={HandleSubmition} ref={formRef} action="POST">
                                <div className={`${classes.form_wrapper}`}> 
                                        <div  className={`${classes.input_list_wrapper}`}>
                                            <MasterDataRegInput input={<input name='goods_name' type="text" placeholder='Inventory Good Name' title='' autoComplete='off' required />} />

                                            <MasterDataRegInput 
                                            input={
                                                <Select
                                                    onChange={(data)=>{SetItemType(data.value)}}
                                                    name={"dd_data"}
                                                    options={GoodsTypeOptionList}
                                                    placeholder={"Type..."}
                                                    isSearchable={true}
                                                    styles={Custom_DD_Style}
                                                    required ={true}
                                                />
                                                
                                            } tip={"Goods Type Selection: Only Edible goods will be displayed for registration in this section."} />

                                            <MasterDataRegInput input={
                                                <Select
                                                onChange={(data)=>{SetItemBrand(data.value)}}
                                                name={"dd_data"}
                                                options={GoodsBrandOptionList}
                                                placeholder={"Brand..."}
                                                isSearchable={true}
                                                styles={Custom_DD_Style}
                                                required ={true}
                                            />
                                            } />
                                            <MasterDataRegInput input={
                                                <Select
                                                    onChange={(data)=>{SetItemUOM(data.value)}}
                                                    name={"dd_data"}
                                                    options={GoodsMeasurementOptionList}
                                                    placeholder={"UOM..."}
                                                    isSearchable={true}
                                                    styles={Custom_DD_Style}
                                                    required ={true}
                                            />
                                            } tip={"Unit of measurement"} />
                                            <MasterDataRegInput input={<input title='' name='min_stock_amount' type="text" min={1} placeholder='Min Stock' autoComplete='off' required/>} tip={"Minimum Stock Amount: Receive an email notification when the stock balance hits this threshold."} />
                                            <MasterDataRegInput input={<input title='' name='max_stock_amount' type="text" min={1} placeholder='Max Stock Balance' autoComplete='off' required/>} tip={"Maximum Stock Amount: When the stock balance matches this amount, the item is considered to have sufficient quantity."} />
                                        </div>
                                </div>
                                <div className={`${data_win.form_action_button_wrapper}`}>
                                    <button disabled={registring} className={`${data_win.form_action_button_save}`}>{registring ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Add'}</button>
                                    <button disabled={registring}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                                </div>
                            </form>




                </div>
            </div>

        </div>
    )
}