import ORIDataTable from './ORIDataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPrint, faSpinner } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useState, useEffect } from 'react';
import ReactToPrint from 'react-to-print';
import DotsGif from '../CssGif/DotsGif';

export default function DataTableWrapper(
    {
            WrapperName,
            WinName,
            ThemeColor,
            WrapperWidth,
            RegisterCallback,
            PrintCallback,
            RouteAddress,
            Filter = null,
            NewEntryFlag,
            IsMD = true,
            DBTableName,
            TableCol,
            TableData,
            Edit_CALLBACK,
            Purge_CALLBACK
         }
    ) 
{

    const [columns, setColums] = useState([]);
    const  [user_data, setUserData] = useState([]);
    const columns_temp = [];
    const user_data_temp = [];
    const [isLoading, SetIsLoading] = useState(false);

  function ExtractColumn(object) 
  {
    for(var key in object) 
    {
        var final_key = key;
        columns_temp.push({key: final_key});
    }
    columns_temp.push({key: 'Action'});
  }

  function ExtractTable(inputData)  
  {
    if(inputData.length <= 0)
    {
        return;
    }
    user_data_temp.length = 0;
    columns_temp.length = 0;
    ExtractColumn(inputData[0]);  
    
    for (var i = 0; i < inputData.length; i++) {
        let object = inputData[i];
        object.Action = '';
        user_data_temp.push(object);
    } 
     setColums(columns_temp);
     setUserData(user_data_temp);

    //  user_data_temp.length = 0;
    //  columns_temp.length = 0;
    
  }

  useEffect(() => 
  {
    SetIsLoading(true);

    if(Filter) 
    {
        fetch(RouteAddress, { 
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ID: Filter }),
        })
            .then(res => res.json())
            .then((data) => {ExtractTable(data);SetIsLoading(false); });
    } else 
    {
        fetch(RouteAddress)
        .then(res => res.json())
        .then((data) => {ExtractTable(data); SetIsLoading(false)});
    }
    
  }, [NewEntryFlag])

  function handleInternalPrint() 
  {

  }
    return (
        <div className="table-wrapper-quick" style={
            {
                width: WrapperWidth,
            }
        }>
                <div className="data-table-wrapper">

                        <div className="header-bar" style={
                            {
                                backgroundColor: ThemeColor,
                                padding: '10',
                                boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                            }
                        }>
                            <div className="header-name">
                            <h3>Archive of <span className='head-bold'>{WrapperName}</span> {IsMD && <sub className='head-bold-sub'>[MD]</sub>} </h3>
                            </div>

                            <div className="register-action-buttons">
                                <div className="add-item-button">
                                    <button onClick={()=>{RegisterCallback(WinName)}}><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon></button>
                                </div>
                                <div className="print-action">
                                    {/* <button onClick={handleInternalPrint}><FontAwesomeIcon icon={faPrint}></FontAwesomeIcon></button> */}
                                </div>
                            </div>
                        </div>

                        
                        
                        <div className="table-data">
                                <br />                            
                                { !isLoading ? <ORIDataTable
                                    Edit_CALLBACK_Inner={Edit_CALLBACK}
                                    Purge_CALLBACK_Inner={Purge_CALLBACK}
                                    columns={columns}
                                    user_data={user_data}
                                 /> :
                                 
                                 <div className='gif_wrapper'>
                                    <DotsGif/>
                                 </div>

                                 }
                        </div>

                </div>
        </div>
    );
}