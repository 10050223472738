import { useState } from 'react'
import classes from './CatDetailShow.module.css'
import { useEffect } from 'react';



export default function CatDetailShow({CatID, iRefList, iProdList, iHeader}) 
{


    const [ProdList, SetProdList] = useState(iProdList);
    const [RefList, SetRefList] = useState(iRefList);

    const [SoldItem, SetSoldItem] = useState([]);
    const [UnsoldItem, SetUnsoldItem] = useState([]);
    const [DamagedItem, SetDamagedItem] = useState([]);
    const [LocalUsedItem, SetLocalUsedItem] = useState([]);

    const [CashSold, SetCashSold] = useState(0);
    const [CashUnsold, SetCashUnsold] = useState(0);
    const [CashDamaged, SetCashDamaged] = useState(0);
    const [CashLocalUse, SetCashLocalUse] = useState(0);




    function FindInRefList(name) 
    {
        const _itm = RefList.find(it => it.Name === name);
        return _itm ? _itm : null;
    }
    function AddSoldItem(item) 
    {
        let price_tag = item.UnitPrice ? item.UnitPrice : item.ItemSellingPrice; // default selling price is for the merch item the database is registed with the name of "ItemSellingPrice" 
        SetCashSold((prev)=> (price_tag * item.Quantity) + prev); 
        SetSoldItem(prev => [...prev, item]);
    }
    function AddUnsoldItem(item) 
    {
        let price_tag = item.UnitPrice ? item.UnitPrice : item.ItemSellingPrice;
        SetCashUnsold((prev)=> (price_tag * item.Quantity) + prev); 
        SetUnsoldItem(prev => [...prev, item]);
    }

    function AddLocalUsedItem(item) 
    {
        let price_tag = item.UnitPrice ? item.UnitPrice : item.ItemSellingPrice;
        SetCashLocalUse((prev)=> (price_tag * item.Quantity) + prev); 
        SetLocalUsedItem(prev => [...prev, item]);
    }

    function AddDamagedItem(item) 
    {
        let price_tag = item.UnitPrice ? item.UnitPrice : item.ItemSellingPrice;
        SetCashDamaged((prev)=> (price_tag * item.Quantity) + prev); 
        SetDamagedItem(prev => [...prev, item]);
    }


    function ProcessData(item) 
    {

        // If we found the item in this list it means it has unsold or damage or loclusage deduction, ar all
        const my_item = FindInRefList(item.ItemName);


        if(my_item) 
        {
            if((item.Quantity - my_item.UNSOLD_ITEM) > 0)
              {
                let __sold = {...item};
                let __unsold = {...item};
                let __damage = {...item};
                let __local_use = {...item};

                if(my_item.UNSOLD_ITEM > 0)
                {
                    __sold.Quantity -= my_item.UNSOLD_ITEM;
                    __unsold.Quantity = my_item.UNSOLD_ITEM;
                    AddUnsoldItem(__unsold);
                }

                if(my_item.DAMAGED_ITEM > 0)
                {
                    __sold.Quantity -= my_item.DAMAGED_ITEM;
                    __damage.Quantity = my_item.DAMAGED_ITEM;
                    AddDamagedItem(__damage);
                }

                if(my_item.LOCAL_USED_ITEM > 0)
                {
                    __sold.Quantity -= my_item.LOCAL_USED_ITEM;
                    __local_use.Quantity =  my_item.LOCAL_USED_ITEM;
                    AddLocalUsedItem(__local_use);
                }

                AddSoldItem(__sold);

              } else 
              {
                AddUnsoldItem(item);
              }
        } else 
        {
            // The item is produced and sold with no damage with unsold item, or any other mambo jambo
            AddSoldItem(item);
        }
    }

    useEffect(()=>{


    SetSoldItem([]);
    SetUnsoldItem([]);
    SetLocalUsedItem([]);
    SetDamagedItem([]);

    SetCashSold(0);
    SetCashUnsold(0);
    SetCashDamaged(0);
    SetCashLocalUse(0);

        ProdList.map((item, idx)=>{
            //if(item.ProdGrandParentID === CatID) 
            ProcessData(item);
        })
    }, [])

    return (
            <div className={`${classes.list_item}`}>

                <div className={`${classes.cat_name_wrap}`}>
                        <h3>{iHeader}</h3>
                </div>

                <div className={`${classes.list_list_wrapper}`}>
                    <div className={`${classes.sold_item_list_wrapper}`}>
                        <div className={`${classes.header_min_sold}`}>
                            <h5><span className={`${classes.header_text}`}>Sold -</span>   <span className={`${classes.unsold_cash_tag} ${CashSold > 0 ? classes.unsold_cash_anim : '' }`}>
                                {
                                        (CashSold).toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'ETB',
                                        })
                                    } 
                                    </span> </h5> 
                        </div>

                        <div className={`${classes.raw_list} ${classes.list_sold}`}>
                            {
                                SoldItem.map((item, idx)=>(
                                    <li key={idx}><span className={`${classes.item_name}`}>{item.ItemName}</span> <span className={`${classes.sold_quantity}`}>{(item.Quantity).toLocaleString('en-US')}</span> </li>
                                ))
                            }
                            
                            
                        </div>
                    </div>
                    <div className={`${classes.unsold_item_list_wrapper}`}>
                        <div className={`${classes.header_min_unsold}`}>
                            <h5> <span className={`${classes.header_text}`}>Unsold - </span>  <span className={`${classes.unsold_cash_tag} ${CashUnsold > 0 ? classes.unsold_cash_anim : '' }`}>
                                {
                                        (CashUnsold).toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'ETB',
                                        })
                                    }
                                    </span> 
                            </h5>
                        </div>

                        <div className={`${classes.raw_list} ${classes.list_unsold}`}>
                            {
                                UnsoldItem.map((item, idx)=>(
                                    <li key={idx}><span className={`${classes.item_name}`}>{item.ItemName}</span> <span className={`${classes.sold_quantity}`}>{(item.Quantity).toLocaleString('en-US')}</span> </li>
                                ))
                            }
                        </div>
                    </div>


                    <div className={`${classes.unsold_item_list_wrapper}`}>
                        <div className={`${classes.header_min_damage}`}>
                            <h5> <span className={`${classes.header_text}`}>Damage - </span>  <span className={`${classes.unsold_cash_tag} ${CashDamaged > 0 ? classes.unsold_cash_anim : '' }`}>
                                {
                                        (CashDamaged).toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'ETB',
                                        })
                                    }
                                    </span> 
                            </h5>
                        </div>

                        <div className={`${classes.raw_list} ${classes.list_damage}`}>
                            {
                                DamagedItem.map((item, idx)=>(
                                    <li key={idx}><span className={`${classes.item_name}`}>{item.ItemName}</span> <span className={`${classes.sold_quantity}`}>{(item.Quantity).toLocaleString('en-US')}</span> </li>
                                ))
                            }
                        </div>
                    </div>




                    <div className={`${classes.unsold_item_list_wrapper}`}>
                        <div className={`${classes.header_min_local_use}`}>
                            <h5> <span className={`${classes.header_text}`}>Local Use - </span>  <span className={`${classes.unsold_cash_tag} ${CashLocalUse > 0 ? classes.unsold_cash_anim : '' }`}>
                                {
                                        (CashLocalUse).toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'ETB',
                                        })
                                    }
                                    </span> 
                            </h5>
                        </div>

                        <div className={`${classes.raw_list} ${classes.list_local_use}`}>
                            {
                                LocalUsedItem.map((item, idx)=>(
                                    <li key={idx}><span className={`${classes.item_name}`}>{item.ItemName}</span> <span className={`${classes.sold_quantity}`}>{(item.Quantity).toLocaleString('en-US')}</span> </li>
                                ))
                            }
                        </div>
                    </div>


                </div>
                
            </div>
    )
}