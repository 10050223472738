// Styles
import classes from './MixData.module.css'
import data_win from './../../../../../SharedComp/CommonStyle/DataCreator.module.css'

// Comp
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MasterDataRegInput from '../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useRef } from 'react'
import { useState } from 'react'
import { faMinus, faSpinner, faObjectGroup, faObjectUngroup, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'
import DynamicMixInputData from '../ExtraMixData/DynamicMixInputData'
import { FetchBranchAll, PushYamMix } from '../../../../../api'
import NotificationPopUp from '../../../../../SharedComp/NotificationPopUp/NotificationPopUp'
import Select from 'react-select';

//lib
import axios from 'axios';
import { useAuth } from '../../../../../AuthProvider'
import { useToast } from '../../../../../ToastContext'
export default function MixData({OpenController, MsgTrigger}) 
{

    const auth = useAuth();
    const notify = useToast();

    const formRef = useRef();
    const [registring, SetRegistring] = useState(false);

    const [WorkspaceList, SetWorkspaceList] = useState([]);
    const [WorkspaceChecked, SetWorkspaceChecked] = useState(new Map());
    const [ToggelListTrue, SetToggelListTrue] = useState(false);

    const [MixName, SetMixName] = useState();
    const [MixDetail, SetMixDetail] = useState();
    const [ComputationMethod, SetComputationMethod] = useState(true);
    const [OrderabelState, SetOrderabelState] = useState(false);
    const [MixID, SetMixID] = useState();
    const [MixIngList, SetMixIngList] = useState([<DynamicMixInputData  HandleMixQantity={HandleMixQantity} HandleMixRawMaterial={HandleMixRawMaterial} idx={getRandomID()} />]);

    const [MsgMyPkg, SetMsgMyPkg] = useState({});
    const [MixDataRawMaterial, SetMixDataRawMaterial] = useState(null);
    const [MixDataQuntity, SetMixDataQuntity] = useState(null);
    const [OpenMyNoti, SetOpenMyNoti] = useState(false);
    


        // This is to hanle indiviual select
        const handleToggle = (branch_id) => {

            if(!WorkspaceChecked.has(branch_id))
            {
                return;
            }



            SetWorkspaceChecked(prevState => {
            const updatedState = new Map(prevState);
            updatedState.set(branch_id, !updatedState.get(branch_id));
            return updatedState;
            });
        };
        // This function handle the select all stuff
        const selectAll = (val) => {

        SetToggelListTrue(val);

        let updated_list = new Map();
        for(const [mp_key, mp_val] of WorkspaceChecked.entries()) 
        {
            updated_list.set(mp_key, val);
        }
        SetWorkspaceChecked(updated_list);    
        };
        // we need to query all the branch inorder to check which one is active
        const QueryWorkspace = async () => {

            try {
                const res = await FetchBranchAll(auth.token);

                // for now prepare void list
                let init_check_list = new Map();
                for(let  i = 0; i < res.length; i++) 
                {
                    if(!init_check_list.has(res[i].BranchID)) 
                        init_check_list.set(res[i].BranchID, false); 
                }
                SetWorkspaceChecked(init_check_list);

                SetWorkspaceList(res);
            } catch (err) 
            {
                throw new Error(err);
            }
        }
        function getRandomID() 
        {
            return Math.floor(Math.random() * 100000000);
        }
        function HandleMixRawMaterial(data, idx) 
        {
            SetMixDataRawMaterial(prev => ({
                ...prev,
                [idx]: data
            }))
            
        }
        function HandleMixQantity(data, idx) 
        {
            SetMixDataQuntity(prev => ({
                ...prev,
                [idx]: data
            }))

        }
        function AddNewInputField() 
        {
            const existing = [...MixIngList];
            existing.push(<DynamicMixInputData HandleMixQantity={HandleMixQantity} HandleMixRawMaterial={HandleMixRawMaterial} idx={getRandomID()} />);
            SetMixIngList(existing);
        }
        function RemoveInputField(id_to_remove) 
        {
            if(MixIngList.length === 1)
                return;
            
            
            // Remove Raw Material
            if(MixDataRawMaterial) 
            {
                SetMixDataRawMaterial(prevState => {
                    const { [id_to_remove]: _, ...updated } = prevState;
                    return updated;
                });
            }
            


            // Remove material Quantity
            if(MixDataQuntity) 
            {
                SetMixDataQuntity(prevState => {
                    const { [id_to_remove]: _, ...updated } = prevState;
                    return updated;
                });
            }
            


            SetMixIngList(prevState => {
                const updated = prevState.filter(item => item.props.idx !== id_to_remove);
                return updated;
            });
        }
        useEffect(() => 
        {
            QueryWorkspace();
        }, [])

        function CloseModal() 
        {
            formRef.current.reset();
            OpenController(false);
        }

        const HandleSubmition = async(e)=> 
        {
            e.preventDefault();

            SetRegistring(true);
            notify.ShowLoad("Locking in Your Obligations...", 10);

            setTimeout(async ()=>{

               try 
               {
                    const fd = new FormData();

                    fd.append("mix_name", MixName);
                    fd.append("mix_remark", MixDetail);
                    fd.append('computation_method', ComputationMethod);
                    fd.append('orderable', OrderabelState);
                    fd.append("mix_raw_material", JSON.stringify(MixDataRawMaterial));
                    fd.append("mix_quantity", JSON.stringify(MixDataQuntity));
                    fd.append("Avail_workspace", JSON.stringify(Object.fromEntries(WorkspaceChecked)));
                    fd.append("user_id", auth.user.UserAccountID);

                    const res = await PushYamMix(fd, auth.token);
                    notify.ShowSuccessUpdate(res.Msg, 10);
                    SetRegistring(false);

               }catch(err) 
               {
                  notify.ShowErrorUpdate(err.message, 10);
                  SetRegistring(false);
               }

            }, 3000)
        }

        function MsgMyTrigger(res)  
        {
            SetMsgMyPkg({
                Key: Math.random() * 1000,
                Msg: res.data.Msg,
                State:  res.data.MyStateCode
            })
            SetOpenMyNoti(true);
        }

    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>
        {OpenMyNoti && <NotificationPopUp key={MsgMyPkg.Key} msg={MsgMyPkg.Msg} state={MsgMyPkg.State}/>}

            <div className={`${data_win.data_main}`}>
                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        Registring <span className={`${data_win.data_create_name}`}> Production Recipe</span>
                    </h3>
                    <button onClick={()=>{CloseModal()}}>
                        <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                    </button>
                </div>

                <div className={`${data_win.data_body}`}>
                        
                

                <form onSubmit={HandleSubmition} ref={formRef} action="POST">
                                <div className={`${classes.form_wrapper}`}> 

                                        <div  className={`${classes.input_list_wrapper}`}>
                                            <MasterDataRegInput input={<input value={MixName} onChange={(e)=>{SetMixName(e.target.value)}} name='mix_name' type="text" placeholder='Mix Name' title='' autoComplete='off' required />} />
                                            <MasterDataRegInput input={<input value={MixDetail} onChange={(e)=>{SetMixDetail(e.target.value)}} name='mix_remark' type="text" placeholder='Remark' title='' autoComplete='off' />} />
                                            <div onClick={(e)=>{SetComputationMethod(!ComputationMethod)}} className={`${classes.recipe_usage_wrapper}`}>
                                                <input checked={ComputationMethod} onClick={(e)=>{SetComputationMethod(!ComputationMethod)}} type="checkbox" />
                                                <span>Per Kilogram</span>
                                            </div>
                                            <div onClick={(e)=>{SetOrderabelState(!OrderabelState)}} className={`${classes.recipe_usage_wrapper}`}>
                                                <input checked={OrderabelState} onClick={(e)=>{SetOrderabelState(!OrderabelState)}} type="checkbox" />
                                                <span>Treat as Order</span>
                                            </div>
                                        </div>

                                        <div className={`${classes.ing_selection}`}>
                                                <div className={`${classes.mix_list_ing_wrap}`}>
                                                    {MixIngList.map((item) => (
                                                        <div className={`${classes.input_fild_shild}`} key={item.props.idx}>{item}{MixIngList.length > 1 ? <button type='button' onClick={()=>{RemoveInputField(item.props.idx);}} className={`${classes.remove_button_wrapper}`}><FontAwesomeIcon icon={faMinus}></FontAwesomeIcon></button> : <span  className={`${classes.remove_button_wrapper_fake}`}></span> }</div>
                                                    ))}
                                                </div>

                                                <div className={`${classes.master_product_type_inner_avail_workspace_list}`}>
                                                        <div className={`${classes.master_product_type_inner_avail_workspace_list_header}`}>
                                                            <h1>Accessibility</h1>
                                                            <button type='button' onClick={()=>{selectAll(!ToggelListTrue)}}><FontAwesomeIcon icon={ToggelListTrue ? faObjectUngroup : faObjectGroup}/></button>
                                                        </div>
                                                        <div className={`${classes.master_prod_avail_list}`}>
                                                            {
                                                                WorkspaceList.map((item, idx)=>(
                                                                    <div key={idx} onClick={() => handleToggle(item.BranchID)} className={`${classes.master_prod_avail_branch}`}>
                                                                        <input 
                                                                        onClick={() => handleToggle(item.BranchID)}
                                                                        checked={WorkspaceChecked.get(item.BranchID)} 
                                                                        name={item.BranchName} 
                                                                        type="checkbox"
                                                                        onChange={() => handleToggle(item.BranchID)}
                                                                        />
                                                                        <li>{item.BranchName}</li>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                 </div>
                                        </div>
                                       
                                        

                                        <div className={`${classes.input_adder}`}>
                                            <button onClick={()=>{AddNewInputField()}} type='button'>Add Ingredient</button>
                                        </div>
                                            
                                            
                                </div>
                                <div className={`${data_win.form_action_button_wrapper}`}>
                                    <button disabled={registring} className={`${data_win.form_action_button_save}`}>{registring ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Add'}</button>
                                    <button disabled={registring}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                                </div>
                            </form>



                </div>
            </div>

        </div>
    )
}