import classes from './YAMOnlineOrders.module.css'



export default function YAMOnlineOrders() 
{


    return (
        <div>
            <h1>YAM Online Orders</h1>
        </div>
    )
}