import React from 'react';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import classes from './ToastWrapper.module.css'


const ToastWrapper = ({ children }) => {
    return (
        <>
            {children}
            <ToastContainer
                position="top-right"
                autoClose={10000}
                hideProgressBar={true}
                pauseOnFocusLoss={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                stacked={false}
                draggable
                pauseOnHover
                theme="dark"
                transition={Slide}
                className={classes.toast_val}
                />
        </>
    )
}
export { ToastWrapper, toast }