let primary_color = "#2fba60";
export function SetPrimaryColor(color)  { primary_color = color; }

function lightenColor(color, percent) {
    let num = parseInt(color.slice(1), 16),
      amt = Math.round(2.55 * percent),
      R = (num >> 16) + amt,
      G = ((num >> 8) & 0x00ff) + amt,
      B = (num & 0x0000ff) + amt;
  
    return (
      "#" +
      (0x1000000 +
        (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
        (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
        (B < 255 ? (B < 1 ? 0 : B) : 255))
        .toString(16)
        .slice(1)
    );
  }

export const KeyColorSchemeBuffer = [
    {Name: "Verdant Surge",         Value: "#2fba60" },
    {Name: "Eclipse Amber",         Value: "#FCB643" },
    {Name: "Yonder Sky",            Value: "#0091FF" },
    {Name: "Obsidian Mist",         Value: "#c7c8ca" },
    {Name: "Blooming Orchard",      Value: "#25c2a0" },
    {Name: "Crimson Blaze",         Value: "#DC143C" },
];
export const MixColor = (config, primary_color) => {
    return {
      ...config,
      // For Text
      // ...(primary_color === '#c7c8ca' ? {
      //   "--primary_text_color": "#1a1a1a",
      //   "--text_light_white": "#4d4d4d"
      // } : {}),   

      "--color-theme": primary_color,
      "--main-color": primary_color,
      "--default_gradient": `linear-gradient(to right, ${lightenColor(primary_color, 10)}, ${primary_color})`
    };
  };
export const PaintColorSchemeBuffer = [
    {Name: "Eclipse", Value: {

        // For Text
      "--primary_text_color": "#e0e0e0",         
      "--text_light_white": "#b0b0b0",           
      
      // UI Shade
      "--background-dark_plus": "#000000",      
      "--background-dark_minus": "#0d0d0d",     
      "--background-dark": "#1a1a1a",          
      "--background-light": "#262626", 
    
      // Key Color
      "--color-theme": `${primary_color}`,
      "--main-color": `${primary_color}`,
      "--default_gradient": `linear-gradient(to right, ${lightenColor(primary_color, 10)}, ${primary_color})`
    }},
    {
        Name: "Shadow", Value: {
            // Text
            "--primary_text_color": "#f7f7f7",
            "--text_light_white": "#9f9fa3", 
        
            // UI Shader
            "--background-dark_plus": "#141414",
            "--background-dark_minus": "#181818",
            "--background-dark": "#252525",
            "--background-light": "#303030",
        
            // Key Color
            "--color-theme": `${primary_color}`,
            "--main-color": `${primary_color}`,
            "--default_gradient": `linear-gradient(to right, ${lightenColor(primary_color, 10)}, ${primary_color})` 
        }},
        {
            Name: "Lumos", Value: {

                // For Text
                "--primary_text_color": "#1a1a1a",     
                "--text_light_white": "#4d4d4d",        
            
                // UI Shade
                "--background-dark_plus": "#ffffff",    
                "--background-dark_minus": "#f0f0f0",    
                "--background-dark": "#e0e0e0",          
                "--background-light": "#cccccc", 
            
                // Key Color
                "--color-theme": `${primary_color}`,
                "--main-color": `${primary_color}`,
                "--default_gradient": `linear-gradient(to right, ${lightenColor(primary_color, 10)}, ${primary_color})` 
            }},
        {
            Name: "Aura", Value: {

                // For Text
                "--primary_text_color": "#000000",      
                "--text_light_white": "#141414",        
            
                // UI Shade
                "--background-dark_plus": "#b3b3b3",    
                "--background-dark_minus": "#cccccc",   
                "--background-dark": "#d9d9d9",         
                "--background-light": "#e6e6e6",        
            
                // KeyColor
                "--color-theme": `${primary_color}`,
                "--main-color": `${primary_color}`,
                "--default_gradient": `linear-gradient(to right, ${lightenColor(primary_color, 10)}, ${primary_color})` 
            }}
];
