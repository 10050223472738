
import './OperationDataCreatorStyle.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faBuilding } from '@fortawesome/free-solid-svg-icons';
import InputField from '../../SharedComp/InputField/InputField'
import { useState } from 'react';
import Draggable from "react-draggable";
import axios from 'axios';

import { GLOBAL_PopupMsgTimeout } from '../../SharedComp/Utility';

function BankInput(InputSetCallback) 
{
    return (
        <div className="bank-input-wrapper">
            <InputField
                HandleInputUpdate={InputSetCallback}   
                Name={"bank_name"} 
                icon_value={faBuilding} 
                type={'text'} 
                placeholder={"Bank Name"} />

            <InputField
                HandleInputUpdate={InputSetCallback}   
                Name={"bank_code"} 
                icon_value={faBuilding} 
                type={'text'} 
                placeholder={"Bank Code"} />

        </div>
    );
}
function DepartmentInput(InputSetCallback) 
{
    return (
        <div className="bank-input-wrapper">
            <InputField
                HandleInputUpdate={InputSetCallback}   
                Name={"department_name"} 
                icon_value={faBuilding} 
                type={'text'} 
                placeholder={"Department Name"} />

            <InputField
                HandleInputUpdate={InputSetCallback}   
                Name={"department_description"} 
                icon_value={faBuilding} 
                type={'text'} 
                placeholder={"Department Description"} />

        </div>
    );
}
function ShiftInput(InputSetCallback) 
{
    return (
        <div className="bank-input-wrapper">
            <InputField
                HandleInputUpdate={InputSetCallback}   
                Name={"shift_name"} 
                icon_value={faBuilding} 
                type={'text'} 
                placeholder={"Shift Name"} />

            <InputField
                HandleInputUpdate={InputSetCallback}   
                Name={"shift_description"} 
                icon_value={faBuilding} 
                type={'text'} 
                placeholder={"Shift Description"} />

        </div>
    );
}
function UtilityExpenseInput(InputSetCallback) 
{
    return (
        <div className="bank-input-wrapper">
            <InputField
                HandleInputUpdate={InputSetCallback}   
                Name={"expense_name"} 
                icon_value={faBuilding} 
                type={'text'} 
                placeholder={"Expense Name"} />

            <InputField
                HandleInputUpdate={InputSetCallback}   
                Name={"expense_description"} 
                icon_value={faBuilding} 
                type={'text'} 
                placeholder={"Expense Description"} />

        </div>
    );
}

function PenalityInput(InputSetCallback) 
{
    return (
        <div className="bank-input-wrapper">
            <InputField
                HandleInputUpdate={InputSetCallback}   
                Name={"penality_name"} 
                icon_value={faBuilding} 
                type={'text'} 
                placeholder={"Penality Name"} />

            <InputField
                HandleInputUpdate={InputSetCallback}   
                Name={"Penality_description"} 
                icon_value={faBuilding} 
                type={'text'} 
                placeholder={"Penality Description"} />

        </div>
    );
}


export default function OperationDataCreator({Open, setOpenCreator, win_name, setMessagePopup}) 
{

    const [activeBank, setActiveBank] = useState();
    const [activeWerehouse, setWerehouse] = useState();
    const [activeShift, setActiveShift] = useState();
    const [activeDepartment, setDepartment] = useState();
    const [activeExpenseType, setExpenseType] = useState();
    const [activePenality, setPenality] = useState();


    function handleSubmition(event) 
    {
        if(win_name === "Bank")
        {
            handleRoute(event, 'http://localhost:3333/master-data/add-bank', activeBank);

        } else if(win_name === "Shift") 
        {
            handleRoute(event, 'http://localhost:3333/master-data/add-shift', activeShift);
        }
        else if(win_name === "Department") 
        {
            handleRoute(event, 'http://localhost:3333/master-data/add-department', activeDepartment);
        }
        else if(win_name === "Utility Expense") 
        {
            handleRoute(event, 'http://localhost:3333/master-data/add-expense-type', activeExpenseType);
        }
        else if(win_name === "Penality") 
        {
            handleRoute(event, 'http://localhost:3333/master-data/add-penality', activePenality);
        }
    }
    function HandlePopupTimeout(success, open, msg) 
    {
        GLOBAL_PopupMsgTimeout(setMessagePopup, success, false, msg);
        // setTimeout(() => {
        //     setMessagePopup(success, false, msg);
        //   }, 4 * 1000);
    }
    const handleRoute = async (event, my_route, value) => 
    {
        event.preventDefault();
        
        axios.post(my_route, value)
        .then(res => {
            if(res.data.Okay) 
            {
                setMessagePopup(res.data.Okay, true, res.data.Msg);;
                HandlePopupTimeout(res.data.Okay, true, res.data.Msg);
            }else
            {
                setMessagePopup(res.data.Okay, true, res.data.Msg);;
                HandlePopupTimeout(res.data.Okay, true, res.data.Msg);
            }
        })
        .catch(err => {
            
        })
    }

    return (
        <div className="pdc_wrapper">
            
            <Draggable>
            <div className={`pdc-main-content-wrapper ${Open ? `pdc-main-content-wrapper-show` : `pdc-main-content-wrapper-hide`}`}>
                <div className="branch-creator-header">
                        <div className="bc-win-name">
                            <h1>Register Master Data of <span className='bc-strong'>{win_name}</span></h1>
                        </div>
                        <div className="bc-win-action-button">
                            <button onClick={() => {setOpenCreator(false)}}> <FontAwesomeIcon icon={faXmark}/> </button>
                        </div>
                </div>

                <form onSubmit={handleSubmition} action="#" method="post">
                        <div className="bc-body">
                            
                            { win_name === "Bank"               && BankInput(setActiveBank) }
                            { win_name === "Shift"              && ShiftInput(setActiveShift) }
                            { win_name === "Department"         && DepartmentInput(setDepartment) }
                            { win_name === "Utility Expense"    && UtilityExpenseInput(setExpenseType)}
                            { win_name === "Penality"           && PenalityInput(setPenality) }

                            <div className="bc-win-seprator">
                                <hr />
                            </div>

                    <div className="bc-submit-buttons-wrapper">
                        <div className="bc-submit-form-wrapper">
                            <div type="submit" className="bc-submit-button-wrapper">
                                <button>Create</button>
                            </div>
                            <div className="bc-discard-button-wrapper">
                                <button type="button" onClick={() => {setOpenCreator(false)}}>Discard</button>
                            </div>
                        </div>
                    </div>
                            

                        </div>
                    </form>
            </div>
        </Draggable>


        </div>
    );
}