import { faCircle, faCircleDot, faDotCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import classes from './StockView.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CircleMiniGraph from '../../../../SharedComp/CircleMiniGraph/CircleMiniGraph'

import { useEffect, useRef, useState } from 'react'
import DotsGif from '../../../../SharedComp/CssGif/DotsGif'
import ApChartMini from '../../../../SharedComp/ApexChartMiniWrapper/ApChartMini'
import {mapToObject, objectToMap} from './../../../../SharedComp/Utility'
import { io } from 'socket.io-client'
import { getBaseURL } from '../../../../SharedComp/BaseUrlConfig'
import { useAuth } from '../../../../AuthProvider'
import { useToast } from '../../../../ToastContext'

import InvoiceView from './Component/InvoiceView/InvoiceView'

import {FetchInventoryStockID, FetchInventoryInvoice, FetchPOLinkedRawMatID} from '../../../../api'

const truncateString = (str, maxLength) => {
  if (str.length > maxLength) {
      return str.substring(0, maxLength) + '.....';
  }
  return str;
}
function clampPercentage(value, max) {
  // Assuming 100 as 15%, and linearly interpolate between min and max
  const percentage = (value / max) * 100;

  // Clamp the percentage between 0 and 100
  const clampedPercentage = Math.max(0, Math.min(100, percentage));

  return clampedPercentage;
}
function GetRandomValue()
{
   return Math.floor(Math.random() * (98 - 90 + 1) + 90)
}

const socket = io.connect(getBaseURL());
export default function StockView({OpenController, TargetID, DataItemID}) 
{

  const messagesEndRef = useRef(null);
  const auth = useAuth();
  const notify = useToast();

  const [OpenInvoiceView, SetOpenInvoiceView] = useState(false);

  const [Loading, SetLoading] = useState(true);
  const [TargetData, SetTargetData] = useState(null);
  const [DataReady, SetDataReady] = useState(false);
  const [InvoiceList, SetInvoiceList] = useState([]);
  const [AnimateAmountAction, SetAnimateAmountAction] = useState(false);
  const [BranchSpecData, SetBranchSpecData] = useState();
  const [PendingAmount, SetPendingAmount] = useState(0);

  const [ShowMore, SetShowMore] = useState(false);
  const [InvoiceTargetUID, SetInvoiceTargetUID] = useState();

 

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

    const TestChartDataA = {
        "Nov 01": GetRandomValue(),
        "Nov 02": GetRandomValue(),
        "Nov 03": GetRandomValue(),
        "Nov 04": GetRandomValue(),
        "Nov 05": GetRandomValue(),
        "Nov 06": GetRandomValue(),
        "Nov 07": GetRandomValue(),
        "Nov 08": GetRandomValue(),
        "Nov 09": GetRandomValue(),
        "Nov 10": GetRandomValue(),
        "Nov 11": GetRandomValue(),
        "Nov 12": GetRandomValue(),
        "Nov 13": GetRandomValue(),
        "Nov 14": GetRandomValue(),
        "Nov 15": GetRandomValue(),
       
     };
     const TestChartDataB = {
       
        "Nov 01": GetRandomValue(),
        "Nov 02": GetRandomValue(),
        "Nov 03": GetRandomValue(),
        "Nov 04": GetRandomValue(),
        "Nov 05": GetRandomValue(),
        "Nov 06": GetRandomValue(),
        "Nov 07": GetRandomValue(),
        "Nov 08": GetRandomValue(),
        "Nov 09": GetRandomValue(),
        "Nov 10": GetRandomValue(),
        "Nov 11": GetRandomValue(),
        "Nov 12": GetRandomValue(),
        "Nov 13": GetRandomValue(),
        "Nov 14": GetRandomValue(),
        "Nov 15": GetRandomValue(),
        "Nov 16": GetRandomValue(),
        "Nov 17": GetRandomValue(),
        "Nov 18": GetRandomValue(), 
        "Nov 19": GetRandomValue(),
        "Nov 20": GetRandomValue(),
        "Nov 21": GetRandomValue(),
        "Nov 22": GetRandomValue(),
        "Nov 23": GetRandomValue(),
        "Nov 24": GetRandomValue(),
        "Nov 25": GetRandomValue(),
        "Nov 26": GetRandomValue(),
        "Nov 28": GetRandomValue(),
        "Nov 29": GetRandomValue(),
        "Nov 30": GetRandomValue(),
        "Nov 1": GetRandomValue(),
  
     };

     function ComputePendingStockAmount(data) 
     {
       var amount = 0;
       for(let  i = 0; i < data.length; i++) 
       {
          if(data[i].IsPending) 
          {
            amount += parseInt(data[i].Quantity);
          }
        }
        SetPendingAmount(amount);
     }
     const FetchInvoice = async () => {
        try 
        {
          const fd = new FormData();
          fd.append("BranchID", auth.activeWorkShop);
          fd.append("StoreID",  TargetData.StoreID);
          fd.append("ItemID", TargetData.ItemID);
          const res = await FetchInventoryInvoice(fd, 'token');
          SetInvoiceList(res); 

        }catch(err) 
        {
          notify.ShowError("Failed to Fetch Target Stock Invoice! Try Reloding");
        }
     }
     const FetchComputePendingAmount = async()=> 
     {
        try 
        {
          // TO-DO here we query all linked production order and show all 
          // request amount here/ it is not really nessary but is is kind of cool

          // And another thing: function FetchPOLinkedRawMatID is fucked!
            const res = [];//FetchPOLinkedRawMatID(DataItemID, 'token');
            ComputePendingStockAmount(res);
        }catch(err) 
        {
          notify.ShowError("Failed to Fetch Any Linked Production Order! Try Realoding");
        }
     }
     const FetchTargetStockDetail = async()=> 
      {
           try 
           {
             const res = await FetchInventoryStockID(TargetID, 'token');
             SetTargetData(res[0]);
           }catch(err) 
           {
             notify.ShowError("Failed to Fetch Target Stock Data! Try Realoding");
           }
     }

     function HandleOpneInvoiceView(id) 
     {
        SetOpenInvoiceView(true);
        SetInvoiceTargetUID(id);
     }


     useEffect(()=>{
        if(TargetData) 
        {
          const p_res = objectToMap(JSON.parse(TargetData.IVItemWorkspaceInfo)).get(auth.activeWorkShop);
          if(!p_res.get("MinBalance") || !p_res.get("MaxBalance")) 
          {
            notify.ShowWarn(`Be a good little boy and set the min and max balance, or I won't send any alert messages. And trust me, I'll keep popping up to remind you until you do. You understand me?`)
          }
          SetBranchSpecData(p_res);
          FetchInvoice();
          SetDataReady(true);
        }
     }, [TargetData])

     function HandleSocktUpdate() 
     {
        FetchTargetStockDetail(); 
        FetchComputePendingAmount();

        SetAnimateAmountAction(true);
        SetAnimateAmountAction(false);
     }

     useEffect(()=>{

        HandleSocktUpdate();

        socket.on("HANDLE_PO_PROCESS", HandleSocktUpdate);

        return () => {
            socket.off("HANDLE_PO_PROCESS", HandleSocktUpdate);
        }

     }, [socket])

     useEffect(()=>{
      scrollToBottom();
     }, [scrollToBottom])



    return (
        <div className={`${classes.stock_view_main_wrapper}`}>

          {OpenInvoiceView && <InvoiceView TargetID={InvoiceTargetUID} OpenModal={SetOpenInvoiceView}/>}
            <div className={`${classes.stock_data_wrapper_frame}`}>

                <div className={`${classes.stock_data_wrapper_header}`}>
                    <li>Stock Detail - <span className={`${classes.stock_name}`}>{TargetData?.IVItemName}</span></li>
                    <button  onClick={()=>{OpenController(false)}} type='button'><FontAwesomeIcon icon={faTimesCircle} /></button>
                </div>

                {DataReady ? <div className={`${classes.stock_detail_body}`}>

                    <div className={`${classes.stock_db_detail}`}>

                        <div className={`${classes.stock_db_detail_left}`}>
                            <div className={`${classes.stock_db_detail_right_bot_detail_header}`}>
                                  <h2>Invoices</h2>
                              </div>
                                
                              <div className={`${classes.stock_db_detail_right_bot_detail_body_wrapper}`}>
                                {PendingAmount > 0  && <div className={`${classes.pending_stock_out_wapper} ${AnimateAmountAction ? classes.animate_action_stock : ''}`}>
                                        <p> <span className={`${classes.pending_stock_out_indicator}`}><FontAwesomeIcon icon={faCircle} /></span> Waiting Action <span className={`${classes.pending_stock_out_amount}`}>{PendingAmount} {TargetData.UomName}</span></p>
                                </div>}

                                {
                                  InvoiceList.map((item, idx)=>(
                                      <li key={idx} onClick={()=>{HandleOpneInvoiceView(item.IvInvoiceUID)}}>
                                        <span>
                                          <span className={`${classes.list_pointer}`}>
                                            <FontAwesomeIcon icon={faCircleDot} />
                                            </span> <span>Invoice - {new Date(item.IvInvoiceEntryDate).toDateString()}</span>
                                        </span> <span onClick={(e)=>{navigator.clipboard.writeText(item.IvInvoiceUID)}} className={`${classes.stock_db_detail_val}`}>
                                            {truncateString(item.IvInvoiceUID, 15)}
                                        </span> 
                                      </li>
                                  ))
                                }
                              </div>

                        </div>

                        <div className={`${classes.stock_db_detail_right}`}>
                            <div className={`${classes.stock_circle_Chart_wrapper}`}>
                                <CircleMiniGraph 
                                    Color={TargetData.AvailQuantity < BranchSpecData.get("MinBalance") ? 'var(--red-color)' :'var(--main-color)'} 
                                    rad={1.3} 
                                    value={TargetData.AvailQuantity.toLocaleString('en-US')} 
                                    Text={TargetData.UomDescription}
                                    Percentage={(clampPercentage(TargetData.AvailQuantity, BranchSpecData.get("MaxBalance"))).toFixed(2)} 
                                />
                            </div>
                            <div  className={`${classes.stock_db_detail_right_bot_detail}`}>
                                    <li>
                                        <span>
                                          <span className={`${classes.list_pointer}`}>
                                              <FontAwesomeIcon icon={faCircleDot} />
                                          </span>
                                          <span>
                                              Min Balance 
                                          </span>
                                        </span>
                                        <span className={`${classes.stock_db_detail_right_bot_detail_value}`}>
                                        
                                          {(BranchSpecData.get("MinBalance") ? BranchSpecData.get("MinBalance") : 0).toLocaleString('en-US')}<span className={`${classes.stock_uom}`}>{ TargetData.UomName}

                                          </span>
                                        </span>
                                    </li>
                                    <li> <span><span className={`${classes.list_pointer}`}><FontAwesomeIcon icon={faCircleDot} /></span> <span>Max Balance</span></span> <span className={`${classes.stock_db_detail_right_bot_detail_value}`}>
                                        
                                        {(BranchSpecData.get("MaxBalance") ? BranchSpecData.get("MaxBalance") : 0).toLocaleString('en-US')} <span className={`${classes.stock_uom}`}>{TargetData.UomName}</span>
                                        </span></li>
                                    <li><span><span className={`${classes.list_pointer}`}><FontAwesomeIcon icon={faCircleDot} /></span><span>Edible State</span></span> <span className={`${classes.stock_db_detail_right_bot_detail_value}`}>{TargetData.IVItemTypeEdibleState ? "Ture" : "False"}</span></li>
                                    <li><span><span className={`${classes.list_pointer}`}><FontAwesomeIcon icon={faCircleDot} /></span><span>Wherehouse</span></span> <span className={`${classes.stock_db_detail_right_bot_detail_value}`}>{TargetData.WHName}</span></li>
                            </div>
                        </div>


                    </div>

                    <div className={`${classes.more_button_wrapper}`}>
                      <button onClick={(e)=>{SetShowMore(!ShowMore);}} type='button'>Show {ShowMore ? 'Less' : 'More'}</button>
                    </div>
                    

                    {ShowMore && <div className={`${classes.stock_chart_wrapper}`}>


                       <div className={`${classes.chart_whole_wrapper}`}>
                          <div className={`${classes.chart_head}`}>
                              <li>Stock Movment</li>
                          </div>

                          <ApChartMini 
                              Type={"area"} 
                              Data={TestChartDataA} 
                              Height={200} 
                              Tag={"Used kilogram"}/>
                        </div>

                        <div className={`${classes.chart_whole_wrapper}`}>
                          <div className={`${classes.chart_head}`}>
                              <li>Price fluctuation</li>
                          </div>
                          

                            <ApChartMini 
                              Type={"bar"} 
                              Data={TestChartDataB} 
                              Height={200} 
                              Tag={"Price Per-KG"}/>
                        </div>

                     
                      <div ref={messagesEndRef} />
                    </div>}

                </div>  : 

                <div className={`${classes.asset_loading_placeholder}`}>
                  <DotsGif/>
                </div> }

            </div>

        </div>
    )
}