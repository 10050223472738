import classes from './Merchandise.module.css'
import DataTableWrapper from '../../../SharedComp/DataTable/DataTableWrapper';

import { useState } from 'react';

import NotificationPopUp from '../../../SharedComp/NotificationPopUp/NotificationPopUp';
import MerchType from './Component/MerchType/MerchType';
import MerchData from './Component/MerchData/MerchData';

export default function Merchandise() 
{

    const [OpenMerchType, SetOpenMerchType] = useState(false);
    const [OpenMerchData, SetOpenMerchData] = useState(false);
    const [openNoti, SetOpenNoti] = useState(false);
    const [MsgPkg, SetMsgPkg] = useState({});

    const [newEntryFlag, setNewEntryFlag] = useState(false);


    function HandleOpenMerch(win_name) 
    {
        SetOpenMerchData(true);
    }
    function HandleOpenMerchType(win_name) 
    {
        SetOpenMerchType(true);
    }


    function MsgTrigger(res)  
    {
        SetMsgPkg({
            Key: Math.random() * 1000,
            Msg: res.data.Msg,
            State:  res.data.MyStateCode
        })
        SetOpenNoti(true);
    }


    return (
        <div className={`${classes.merchandise_main_warpper}`}>

            {openNoti && <NotificationPopUp key={MsgPkg.Key} msg={MsgPkg.Msg} state={MsgPkg.State}/>}

            {OpenMerchType && <MerchType OpenController={SetOpenMerchType} MsgTrigger={MsgTrigger}/>}
            {OpenMerchData && <MerchData OpenController={SetOpenMerchData} MsgTrigger={MsgTrigger}/>}


                <div className={`${classes.merch_data_wrapper}`}>
                    <DataTableWrapper
                        WrapperName={'Merchandise Products Type'}
                        ThemeColor={'var(--background-light)'}
                        WrapperWidth={'90%'}

                        WinName={'Merchandise Products Type'}
                        RouteAddress={'http://localhost:3333/master-data/get-merch-type-item'}
                        NewEntryFlag={newEntryFlag}
                        RegisterCallback={HandleOpenMerchType}
                    />
                </div>

                <div className={`${classes.merch_data_wrapper}`}>
                    <DataTableWrapper
                        WrapperName={'Purchased Products Item'}
                        ThemeColor={'var(--background-light)'}
                        WrapperWidth={'90%'}

                        WinName={'Purchased Products'}
                        RouteAddress={'http://localhost:3333/master-data/get-merch-item'}
                        NewEntryFlag={newEntryFlag}
                        RegisterCallback={HandleOpenMerch}
                    />
                </div>

        </div>
    )
}