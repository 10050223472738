import classes from './OrderStockProcessor.module.css'
import data_win from '../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import { useReactToPrint } from 'react-to-print'
import { useAuth } from '../../../../AuthProvider'
import { useDAControl } from '../../../../DAControlContext'

import { useEffect, useRef } from 'react'
import { useState } from 'react'
import { nanoid } from 'nanoid'

import {SingleProductPackageOrderTemplate} from '../../../ProductionOrder/Component/InitProductionOrder/ProductOrderUtils'

import PaperHeader from '../../../../SharedComp/PaperHeader/PaperHeader'
import PaperHash from '../../../../SharedComp/PaperHash/PaperHash'

import {FetchSavedProductionProductOrder, ApprovePoRequest} from '../../../../api'
import {FetchSavedProductionOrderConfig} from '../../../../SharedComp/API/Production/API_ProductionOrder'

import SingleOrderDataViewWrapper from './Component/SingleOrderDataViewWrapper/SingleOrderDataViewWrapper'
import GrandTotalDataViewWrapper from './Component/GrandTotalOrderDataViewWrapper/GrandTotalOrderDataViewWrapper'
import { useToast } from '../../../../ToastContext'

export default function OrderStockProcessor({Close_CALLBACK, DataRefersh, TargetData}) 
{
    const auth = useAuth();
    const DAControl = useDAControl();
    const notify = useToast();

    const [OrderedProductList, SetOrderedProductList] = useState([]);
    const [OrderOwner, SetOrderOwner] = useState();

    const DataReadBackMapBuffer = useRef(new Map());
    const [GrandTotalArray, SetGrandTotalArray] = useState([]);


    const FetchTragetSavedOrder = async()=>
    {
        const TargetHash = TargetData.PO_Hash;
        const order_prod_list  = await FetchSavedProductionProductOrder(TargetHash, 'token');
        SetOrderedProductList(order_prod_list);
    }
    const FetchOrderOwner = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("user_id", TargetData.PO_OrderedBy);
            fd.append("workspace_id", auth.activeWorkShop);
            const res = await FetchSavedProductionOrderConfig(fd, 'token');
            SetOrderOwner(res[0]);
        }catch(err) 
        {
            
        }
    }


    const print_ref = useRef();
    const PrintDoc = useReactToPrint({
        documentTitle: auth.activeWorkShopName + "_PO-Requested-Stock" + "_" + new Date().toDateString(),
        content: () => print_ref.current,
        onAfterPrint: ()=>{
            
        }
    })
    const handleApproval = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("source_branch", auth.activeWorkShop);
            fd.append("user_id", auth.user.UserAccountID);
            fd.append("data_pkg", JSON.stringify(GrandTotalArray));
            fd.append("store_id", TargetData.PO_SourceWarehouse);
            fd.append("order_hash", TargetData.PO_Hash);

            const res = await ApprovePoRequest(fd, 'token');
            
            if(res.MyStateCode !== 1)
            {
                throw new Error(res.Msg);
            }

            notify.ShowSuccess("Production Stock Request was Successfull.");
            DataRefersh();
            Close_CALLBACK(false);

        }catch(err) 
        {
            notify.ShowError(<div dangerouslySetInnerHTML={{ __html: err.message }} />);
        }
    }
    const UpdateReadbackBuffer = async (name, Data) => {
    
        if (DataReadBackMapBuffer.current.has(name)) {
            // If the name exists, update the amount
            const existingData = DataReadBackMapBuffer.current.get(name);
            
            DataReadBackMapBuffer.current.set(name, {
                ID: existingData.ID,
                Amount: existingData.Amount + Data.Amount,
                UOM: Data.UOM
            });
        } else {

            DataReadBackMapBuffer.current.set(name, Data);
        }

        SetGrandTotalArray(Array.from(DataReadBackMapBuffer.current.entries()));
    };
    
    useEffect(()=>{
        FetchTragetSavedOrder();
        FetchOrderOwner();
    }, [auth.employeeData]) 




    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>
            <div className={`${data_win.data_main} ${classes.data_main}`}>

                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        JAK <span className={`${data_win.data_create_name}`}>PO Stock Process</span>
                    </h3>
                    <button onClick={()=>{Close_CALLBACK(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
                </div>

                <div className={`${classes.data_preview_wrapper}`}>

                <div className={`${classes.inner_preview_data_wrapper}`}>
                    <div ref={print_ref} className={`${classes.paper_wrapper_dash}`}>
                        <PaperHeader/>
                        <PaperHash/>
                        <div className={`${classes.meta_data_wrapper}`}>
                            <h2>[#] {auth.activeWorkShopName} </h2>
                            <h2>[#] PO Stock Processing</h2>
                        </div>

                        <div className={`${classes.transcation_owner_wrapper}`}>
                            <div className={`${classes.transcation_owner}`}>

                                <div className={`${classes.transcation_init}`}>
                                    <h2>Requested By</h2>
                                    <li>{OrderOwner?.FirstName} {OrderOwner?.LastName}</li>
                                   
                                </div>
                                <div className={`${classes.transcation_recive}`}>
                                    <h2>Processed By</h2>
                                    <li>{auth.employeeData?.FullName}</li>
                                </div>

                            </div>
                        </div>

                        <div className={`${classes.entry_detail}`}>
                            <li><span className={`${classes.entry_deatil_tag}`}>Process ID</span> <span className={`${classes.entry_deatil_val}`}>{TargetData.PO_Hash}</span></li>
                        </div>  
                        
                        
                        <div className={`${classes.main_paper_data_wrapper}`}>

                            <div className={`${classes.paper_list_wrapper}`}>
                                {
                                    OrderedProductList.map((item, idx)=>(
                                        <SingleOrderDataViewWrapper key={idx} DataReadback_Callback={UpdateReadbackBuffer}  DataPoint={item}/>
                                    ))
                                }
                                {
                                    <GrandTotalDataViewWrapper DataList={GrandTotalArray} />
                                }
                            </div>
                            
                        </div>

                        <div className={`${classes.special_footer_local_wrapper}`}>

                            <div className={`${classes.footer_user_wrap}`}>
                                <h2>Digital Authoriziation By</h2>
                                <li className={`${classes.name_manual}`}>{auth.employeeData?.FullName}</li>
                                <li className={`${classes.print_date}`}>{new Date().toDateString()}</li>
                                <li className={`${classes.sign_manual}`}>Signature: ______________________</li>
                            </div>

                            <div className={`${classes.footer_user_wrap}`}>
                                <h2>Physical Authoriziation By</h2>
                                <li className={`${classes.name_manual}`}>Full Name: __________________</li>
                                <li className={`${classes.print_date}`}>Date:________________________</li>
                                <li className={`${classes.sign_manual}`}>Signature: ______________________</li>
                            </div>

                        </div>

                        {/* <PaperFooter Name={auth.employeeData?.FullName} Role={auth.employeeData?.DepName} Phone={auth.employeeData?.PhoneNumber}/> */}
                        <div className={`${classes.final_note_po_stock_out}`}>
                            <li>This document must have dual signatures to be considered valid.</li>
                        </div>

                    </div>
                </div>



                </div>


                <div className={`${classes.action_button_wrapper}`}>

                    <button onClick={(e)=>{PrintDoc()}} className={`${classes.button_print}`}>Print</button>
                    <button onClick={()=>{
                                        DAControl.Trigger(`Approve Stock Out`, 
                                        `This action will affect Inventory Balance. In other word the "Grand Total" Amount you see will be deducted form your store.`,
                                        true,
                                        5, (val)=>{
                                            if(val !== null) 
                                            {
                                                handleApproval();
                                            }
                                        }   
                                    );}} className={`${classes.button_approve}`}>Approve</button>
                    <button onClick={()=>{Close_CALLBACK(false)}} className={`${classes.button_dismiss}`}>Close</button>

                </div>

            </div>
        </div>
    )
}