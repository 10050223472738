import { useEffect, useRef, useState } from 'react';
import classes from './CashCollectionDetailView.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';
import { faPrint, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import LogoImage from '../../../../asset/images/Asset-1@4x.png'

import { useReactToPrint } from 'react-to-print';
import { getBaseURL } from '../../../../SharedComp/BaseUrlConfig';

export default function CashCollectionDetailView({ref_date, branch_id, SummeryData, close_callback}) 
{

    const [CollectionData, SetCollectionDate] = useState([]);
    const [showPrintLogo, SetshowPrintLogo] = useState(false);


    // Cash
    const [SoldBakeryCash, SetSoldBakeryCash] = useState(0);
    const [SoldPastryCash, SetSoldPastryCash] = useState(0);
    const [SoldMerchCash, SetSoldMerchCash] = useState(0);

    const [UnsoldBakeryCash, SetUnsoldBakeryCash] = useState(0);
    const [UnsoldPastryCash, SetUnsoldPastryCash] = useState(0);
    const [UnsoldMerchCash, SetUnsoldMerchCash] = useState(0);

    // Product
    const [SoldBakeryProd, SetSoldBakeryProd] = useState(0);
    const [SoldPastryProd, SetSoldPastryProd] = useState(0);
    const [SoldMerchProd, SetSoldMerchProd] = useState(0);

    const [UnsoldBakeryProd, SetUnsoldBakeryProd] = useState(0);
    const [UnsoldPastryProd, SetUnsoldPastryProd] = useState(0);
    const [UnsoldMerchProd, SetUnsoldMerchProd] = useState(0);


    // Damage Cash
    const [DamageBakeryCash, SetDamageBakeryCash] = useState(0);
    const [DamagePastryCash, SetDamagePastryCash] = useState(0);
    const [DamageMerchCash, SetDamageMerchCash] = useState(0);

    // Damage Prod
    const [DamageBakeryProd, SetDamageBakeryProd] = useState(0);
    const [DamagePastryProd, SetDamagePastryProd] = useState(0);
    const [DamageMerchProd, SetDamageMerchProd] = useState(0);

    // Local-Use Cash
    const [LocalUseBakeryCash, SetLocalUseBakeryCash] = useState(0);
    const [LocalUsePastryCash, SetLocalUsePastryCash] = useState(0);
    const [LocalUseMerchCash, SetLocalUseMerchCash] = useState(0);


    // Local-Use Cash
    const [LocalUseBakeryProd, SetLocalUseBakeryProd] = useState(0);
    const [LocalUsePastryProd, SetLocalUsePastryProd] = useState(0);
    const [LocalUseMerchProd, SetLocalUseMerchProd] = useState(0);



    const print_ref = useRef();
    const PrintDoc = useReactToPrint({
        content: () => print_ref.current,
        onAfterPrint: ()=>{
            
        }
    })

    function HandlePrint() 
    {
        
        PrintDoc();  
    }

    function PrepareReport() 
    {
        let sold_bakery_cash = 0;
        let unsold_bakery_cash = 0;
        let damged_bakery_cash = 0;
        let used_bakery_cash = 0;

        let sold_item_count_bakery = 0;
        let unsold_item_count_bakery = 0;
        let damaged_item_count_bakery = 0;
        let local_used_item_count_bakery = 0;


        let sold_pastry_cash = 0;
        let unsold_pastry_cash = 0;
        let damged_pastry_cash = 0;
        let used_pastry_cash = 0;

        let sold_item_count_pastry = 0;
        let unsold_item_count_pastry = 0;
        let damaged_item_count_pastry = 0;
        let local_used_item_count_pastry = 0;

        let sold_merch_cash = 0;
        let unsold_merch_cash = 0;
        let damged_merch_cash = 0;
        let used_merch_cash = 0;

        let sold_item_count_merch = 0;
        let unsold_item_count_merch = 0;
        let damaged_item_count_merch = 0;
        let local_used_item_count_merch = 0;

        for(let i = 0; i < CollectionData.length; i++) 
        {
            if(CollectionData[i].Category === "Bakery")
             {
                sold_item_count_bakery += CollectionData[i].SoldAmount;
                unsold_item_count_bakery += CollectionData[i].UnsoldAmount;
                damaged_item_count_bakery += CollectionData[i].DamagedAmount;
                local_used_item_count_bakery += CollectionData[i].UsedAmount;

                 sold_bakery_cash += CollectionData[i].SoldAmount * CollectionData[i].UnitPrice;
                 unsold_bakery_cash += CollectionData[i].UnsoldAmount * CollectionData[i].UnitPrice;
                 damged_bakery_cash += CollectionData[i].DamagedAmount * CollectionData[i].UnitPrice;
                 used_bakery_cash += CollectionData[i].UsedAmount * CollectionData[i].UnitPrice;
             }

             if(CollectionData[i].Category === "Pastry")
             {
                
                sold_item_count_pastry += CollectionData[i].SoldAmount;
                unsold_item_count_pastry += CollectionData[i].UnsoldAmount;
                damaged_item_count_pastry += CollectionData[i].DamagedAmount;
                local_used_item_count_pastry += CollectionData[i].UsedAmount;

                sold_pastry_cash += CollectionData[i].SoldAmount * CollectionData[i].UnitPrice;
                unsold_pastry_cash += CollectionData[i].UnsoldAmount * CollectionData[i].UnitPrice;
                damged_pastry_cash += CollectionData[i].DamagedAmount * CollectionData[i].UnitPrice;
                used_pastry_cash += CollectionData[i].UsedAmount * CollectionData[i].UnitPrice;
             }

             if(CollectionData[i].Category === "Merchandise")
             {
                sold_item_count_merch += CollectionData[i].SoldAmount;
                unsold_item_count_merch += CollectionData[i].UnsoldAmount;
                damaged_item_count_merch += CollectionData[i].DamagedAmount;
                local_used_item_count_merch += CollectionData[i].UsedAmount;

                sold_merch_cash += CollectionData[i].SoldAmount * CollectionData[i].UnitPrice;
                unsold_merch_cash += CollectionData[i].UnsoldAmount * CollectionData[i].UnitPrice;
                damged_merch_cash += CollectionData[i].DamagedAmount * CollectionData[i].UnitPrice;
                used_merch_cash += CollectionData[i].UsedAmount * CollectionData[i].UnitPrice;
             }
        }


        // BAKERY
        SetSoldBakeryCash(sold_bakery_cash);
        SetUnsoldBakeryCash(unsold_bakery_cash);

        SetSoldBakeryProd(sold_item_count_bakery);
        SetUnsoldBakeryProd(unsold_item_count_bakery);

        SetDamageBakeryCash(damged_bakery_cash);
        SetDamageBakeryProd(damaged_item_count_bakery);

        SetLocalUseBakeryCash(used_bakery_cash);
        SetLocalUseBakeryProd(local_used_item_count_bakery);


        // PASTRY
        SetSoldPastryCash(sold_pastry_cash);
        SetUnsoldPastryCash(unsold_pastry_cash);

        SetSoldPastryProd(sold_item_count_pastry);
        SetUnsoldPastryProd(unsold_item_count_pastry);

        SetDamagePastryCash(damged_pastry_cash);
        SetDamagePastryProd(damaged_item_count_pastry);

        SetLocalUsePastryCash(used_pastry_cash);
        SetLocalUsePastryProd(local_used_item_count_pastry);


        // MERCH
        SetSoldMerchCash(sold_merch_cash);
        SetUnsoldMerchCash(unsold_merch_cash);

        SetSoldMerchProd(sold_item_count_merch);
        SetUnsoldMerchProd(unsold_item_count_merch);

        SetDamageMerchCash(damged_merch_cash);
        SetDamageMerchProd(damaged_item_count_merch);

        SetLocalUseMerchCash(used_merch_cash);
        SetLocalUseMerchProd(local_used_item_count_merch);
    }
    useEffect(()=>{

        fetch(getBaseURL() + 'operation-data/query_cash_collection_detial_by_branch', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({RefDate: ref_date, BranchID: branch_id})
        })
        .then(res => res.json())
        .then((data)=>{
            SetCollectionDate(data);
        }).
        catch(err => {
            console.log(err)
        })

    }, [])

    useEffect(()=>{
        PrepareReport();
    }, [CollectionData])



    return (
        <div className={`${classes.cash_collection_detail_view_master_wrapper}`}>

            <div className={`${classes.cash_collection_inner_wrapper}`}>

                <div className={`${classes.header_main_wrapper}`}>
                    <h3>Report View</h3>
                    <button onClick={(e)=>{close_callback(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
                </div>

                <div className={`${classes.doc_to_view_wraper}`}>

                        <div  className={`${classes.cash_view_frame_wrapper}`}>

                            <div ref={print_ref} className={`${classes.cash_view_frame_inner_wrapper}`}>

                                <div className={`${classes.back_ground_image}`}>
                                        <div className={`${classes.back_ground_image_on_print}`} style={{backgroundImage: `url(${LogoImage})`}}>

                                        </div>
                                </div>

                                <div className={`${classes.inner_head_main_wrapper}`}>
                                    <div className={`${classes.paper_header}`}>
                                        <div className={`${classes.image_wrapper}`}>
                                            <img className={`${classes.jak_logo}`} src={LogoImage} alt="" width={160}  />
                                        </div>
                                    </div>
                                    <div className={`${classes.paper_header}`}>
                                        <h2>JAK AND SONS TRADING PLC</h2>
                                        <li>YAM BAKERY GROUP</li>
                                        <li>X BRANCH</li>
                                        <li>CASH COLLECTION</li>
                                        <li>{new Date().toDateString()}</li>
                                    </div>
                                    <div className={`${classes.paper_header}`}>
                                        <div className={`${classes.image_wrapper}`}>
                                            <img className={`${classes.yam_logo}`} src="https://yambakery.com/wp-content/uploads/elementor/thumbs/logo-wight-q7unzmwoied00tm97ojjtfi919h39u39q7u92mf1rq.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                

                            <div className={`${classes.cash_collection_detail_list_wrapper}`}>

                                <div className={`${classes.cash_inner_data_wrapper}`}>


                                    <div className={`${classes.cash_header_wrapper}`}>
                                        <div className={`${classes.cash_header}`}>
                                                <h1>Daily Cash Analysis</h1>
                                        </div>
                                    </div>
                                    

                                    <div className={`${classes.cash_analysis}`}>
                                        <div className={`${classes.sold_wrapper}`}>
                                            <li>
                                                <span className={`${classes.ground_name}`}>Total Bakey Sold</span>
                                                <span className={`${classes.ground_value}`}>
                                                    {(SoldBakeryCash).toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'ETB',
                                                    })}
                                                </span>
                                            </li>

                                            <li>
                                                <span className={`${classes.ground_name}`}>Total Pastry Sold</span>
                                                <span className={`${classes.ground_value}`}>
                                                {(SoldPastryCash).toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'ETB',
                                                    })}
                                                </span>
                                            </li>


                                            <li>
                                                <span className={`${classes.ground_name}`}>Total Merchandise Sold</span>
                                                <span className={`${classes.ground_value}`}>
                                                {(SoldMerchCash).toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'ETB',
                                                    })}
                                                </span>
                                            </li>
                                        </div>

                                        <div className={`${classes.unsold_wrapper}`}>
                                            <li>
                                                <span className={`${classes.ground_name}`}>Total Bakey Unsold</span>
                                                <span className={`${classes.ground_value}`}>
                                                {(UnsoldBakeryCash).toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'ETB',
                                                    })}
                                                </span>
                                            </li>

                                            <li>
                                                <span className={`${classes.ground_name}`}>Total Pastry Unsold</span>
                                                <span className={`${classes.ground_value}`}>
                                                {(UnsoldPastryCash).toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'ETB',
                                                    })}
                                                </span>
                                            </li>


                                            <li>
                                                <span className={`${classes.ground_name}`}>Total Merchandise Unsold</span>
                                                <span className={`${classes.ground_value}`}>
                                                {(UnsoldMerchCash).toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'ETB',
                                                    })}
                                                </span>
                                            </li>
                                        </div>
                                    </div>
                                    
                                </div>

                                <div className={`${classes.cash_product_expense_wrapper}`}>

                                    <div className={`${classes.cash_header_wrapper}`}>
                                        <div className={`${classes.cash_header}`}>
                                                <h1>Daily Product Analysis</h1>
                                        </div>
                                    </div>

                                    <div className={`${classes.cash_analysis}`}>
                                        <div className={`${classes.sold_wrapper}`}>
                                            <li>
                                                <span className={`${classes.ground_name}`}>Total Bakey Sold</span>
                                                <span className={`${classes.ground_value}`}>
                                                {(SoldBakeryProd).toLocaleString('en-US', {
                                                        style: 'decimal'
                                                    })} pcs
                                                </span>
                                            </li>

                                            <li>
                                                <span className={`${classes.ground_name}`}>Total Pastry Sold</span>
                                                <span className={`${classes.ground_value}`}>
                                                {(SoldPastryProd).toLocaleString('en-US', {
                                                        style: 'decimal'
                                                    })} pcs
                                                </span>
                                            </li>


                                            <li>
                                                <span className={`${classes.ground_name}`}>Total Merchandise Sold</span>
                                                <span className={`${classes.ground_value}`}>
                                                {(SoldMerchProd).toLocaleString('en-US', {
                                                        style: 'decimal'
                                                    })} pcs
                                                </span>
                                            </li>
                                        </div>

                                        <div className={`${classes.unsold_wrapper}`}>
                                            <li>
                                                <span className={`${classes.ground_name}`}>Total Bakey Unsold</span>
                                                <span className={`${classes.ground_value}`}>
                                                {(UnsoldBakeryProd).toLocaleString('en-US', {
                                                        style: 'decimal'
                                                    })} pcs
                                                </span>
                                            </li>

                                            <li>
                                                <span className={`${classes.ground_name}`}>Total Pastry Unsold</span>
                                                <span className={`${classes.ground_value}`}>
                                                {(UnsoldPastryProd).toLocaleString('en-US', {
                                                        style: 'decimal'
                                                    })} pcs
                                                </span>
                                            </li>


                                            <li>
                                                <span className={`${classes.ground_name}`}>Total Merchandise Unsold</span>
                                                <span className={`${classes.ground_value}`}>
                                                {(UnsoldMerchProd).toLocaleString('en-US', {
                                                        style: 'decimal'
                                                    })} pcs
                                                </span>
                                            </li>
                                        </div>
                                    </div>

                                    <div className={`${classes.cash_expense_analysis_wrapper}`}>

                                        <div className={`${classes.cash_header_wrapper}`}>
                                            <div className={`${classes.cash_header}`}>
                                                    <h1>Daily Expense</h1>
                                            </div>
                                        </div>

                                        <div className={`${classes.cash_expense_analysis}`}>
                                            <div className={`${classes.cash_analysis}`}>
                                                <li>
                                                    <span className={`${classes.ground_name}`}>Pitty Cash Expense</span>
                                                    <span className={`${classes.ground_value}`}>
                                                    {(SummeryData.PittyCashExpense).toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'ETB',
                                                    })}
                                                    </span>
                                                </li>
                                            </div>
                                        </div>
                                    

                                    </div>


                                    <div className={`${classes.damage_analysis}`}>
                                            <div className={`${classes.cash_header_wrapper}`}>
                                                <div className={`${classes.cash_header}`}>
                                                        <h1>Damage Analysis</h1>
                                                </div>
                                            </div>

                                                <div className={`${classes.cash_analysis}`}>
                                                    <div className={`${classes.cash_expense_analysis}`}>
                                                        <li>
                                                            <span className={`${classes.ground_name}`}>Bakery</span>
                                                            <span className={`${classes.ground_value}`}>
                                                            {(DamageBakeryProd).toLocaleString('en-US', {
                                                                style: 'decimal'
                                                            })} pcs
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className={`${classes.ground_name}`}>Pastry</span>
                                                            <span className={`${classes.ground_value}`}>
                                                            {(DamagePastryProd).toLocaleString('en-US', {
                                                                style: 'decimal'
                                                            })} pcs
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className={`${classes.ground_name}`}>Merchandise</span>
                                                            <span className={`${classes.ground_value}`}>
                                                            {(DamageMerchProd).toLocaleString('en-US', {
                                                                style: 'decimal'
                                                            })} pcs
                                                            </span>
                                                        </li>
                                                    </div>
                                                </div>
                                    </div>

                                    <div className={`${classes.cash_collection_eummery}`}>

                                            <div className={`${classes.cash_header_wrapper}`}>
                                                <div className={`${classes.cash_header}`}>
                                                        <h1>Summery</h1>
                                                </div>
                                            </div>

                                            <div className={`${classes.cash_summery_wrapper}`}>
                                                <div className={`${classes.cash_summery_list_wrapper}`}>
                                                    <li>
                                                        <span className={`${classes.ground_name}`}>Total Expense </span>
                                                        <span className={`${classes.ground_value}`}>
                                                        {(SummeryData.PittyCashExpense).toLocaleString('en-US', {
                                                            style: 'currency',
                                                            currency: 'ETB',
                                                        })}
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className={`${classes.ground_name}`}>Net Collection <span className={`${classes.mini_helper_note}`}>[According to production volume]</span></span>
                                                        <span className={`${classes.ground_value}`}>
                                                        {(SoldBakeryCash + SoldPastryCash + SoldMerchCash).toLocaleString('en-US', {
                                                            style: 'currency',
                                                            currency: 'ETB',
                                                        })}
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className={`${classes.ground_name}`}>Total in Safe <span className={`${classes.mini_helper_note}`}>[According to  cashier]</span></span>
                                                        <span className={`${classes.ground_value}`}>
                                                        {(SummeryData.NetCollection).toLocaleString('en-US', {
                                                            style: 'currency',
                                                            currency: 'ETB',
                                                        })}
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className={`${classes.ground_name}`}>Difference <span className={`${classes.mini_helper_note}`}>[TIS - N]</span></span>
                                                        <span className={`${classes.ground_value}`}>
                                                        {(SummeryData.NetCollection - (SoldBakeryCash + SoldPastryCash + SoldMerchCash)).toLocaleString('en-US', {
                                                            style: 'currency',
                                                            currency: 'ETB',
                                                        })}
                                                        </span>
                                                    </li>
                                                </div>
                                                
                                            </div>
                                    </div>

                                </div>

                            </div>



                                    <div className={`${classes.frame_footer}`}>
                                        <li>JAK AND SONS TRADING PLC</li>
                                        <li>www.JakERP.org</li>
                                    </div>
                            </div>

                        </div>
                    </div>

                <div className={`${classes.doc_action_button}`}>
                    <button onClick={(e)=>{HandlePrint()}}> <FontAwesomeIcon icon={faPrint}></FontAwesomeIcon> Print Report</button>
                </div>

            </div>


        </div>
    )
}