import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./AuthProvider"
import { useEffect, useState } from "react";
import OfflinePage from "./pages/Offline/Offline";

const PrivateRoute = () => {

    const { user, loading, token } = useAuth();

    if (!token) {
        console.log("Invalid User");
        return <Navigate to="/login" />;
    }

    return <Outlet />;
}

export default PrivateRoute;