import classes from './ShopItemCreateCKProd.module.css'

import data_win from '../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import {Custom_DD_Style, PrepareDropDownList} from '../../../../../SharedComp/Utility'
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import MasterDataRegInput from '../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import Select from 'react-select'
import axios from 'axios';
import { useRef, useState } from 'react'
import { useEffect } from 'react'


export default function ShopItemCreateCKProd({Close_CALLBACK, RES_CALLBACK}) 
{

    const formRef = useRef();
    const[registring, SetRegistring] = useState(false);

    const[PastryItem, SetPastryItem] = useState(null);
    const[Quantity, SetQuantity] = useState(null);
    const[StockData, SetStockDate] = useState(null);

    const[PastryItemList, SetPastryItemList] = useState([]);

    function HandleSubmition(e) 
    {
        e.preventDefault();

        SetRegistring(true);

        const fd = new FormData();

        fd.append("item_id", PastryItem.value);
        fd.append("item_name", PastryItem.label);

        fd.append("quantity", Quantity);
        fd.append("stock_date", StockData);

        const url = 'http://localhost:3333/operation-data/add-local-store-item-pastry';

        axios.post(url, fd, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            }).then(res=>{
                SetRegistring(false);
                RES_CALLBACK(res);

            }).catch(err => {
                SetRegistring(false);
                RES_CALLBACK(err);
            })
    }

    useEffect(()=>{

        fetch('http://localhost:3333/master-data/get_ck_prod_item_all')
        .then(res => res.json())
        .then((data) => PrepareDropDownList(data, SetPastryItemList));


    }, [])


    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>


            <div className={`${data_win.data_main}`}>

                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        JAK <span className={`${data_win.data_create_name}`}>Add Pastry Products</span>
                    </h3>
                    <button onClick={()=>{Close_CALLBACK(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
                </div>

                <div className={`${classes.data_body}`}>

                        <form onSubmit={HandleSubmition} ref={formRef} action="POST">
                                <div className={`${classes.form_wrapper}`}> 
                                        <div  className={`${classes.input_list_wrapper}`}>
                                            <div className={`${classes.input_wrap}`}>
                                                <MasterDataRegInput 
                                                    input={
                                                        <Select
                                                            onChange={(data)=>{SetPastryItem(data)}}
                                                            name={"dd_data"}
                                                            options={PastryItemList}
                                                            placeholder={"Pastry Item.."}
                                                            isSearchable={true}
                                                            styles={Custom_DD_Style}
                                                            required ={true}
                                                        />
                                                        
                                                    } tip={"Select Centeral Kitchen product Item"} forceInput={true} />
                                            </div>
                                            <div className={`${classes.input_wrap}`}>
                                                <MasterDataRegInput input={<input min={0} max={9999} onChange={(e)=>{SetQuantity(e.target.value)}} name='merch_qunatity' type="number" placeholder='Stock Quantity' title='' autoComplete='off' required />} tip={"የምታስገባው የእቃ ቁጥር"} forceInput={true}/>
                                            </div>

                                            <div className={`${classes.input_wrap}`}>
                                                <MasterDataRegInput input={<input onChange={(e)=>{SetStockDate(e.target.value)}} name='merch_qunatity' type="date" autoComplete='off'  />} tip={"ይሄ አማራጭ ነው፣ ዛሬን ይይዛል ምንም ካላስገቡ፣ የሌላን ቀን ግን ማስገባት ከፈለጉ.."}/>
                                            </div>
                                            

                                            

                                        </div>
                                </div>
                                <div className={`${data_win.form_action_button_wrapper}`}>
                                    <button disabled={registring} className={`${data_win.form_action_button_save}`}>{registring ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Add'}</button>
                                    <button disabled={registring}  onClick={()=>{Close_CALLBACK(false)}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                                </div>
                            </form>

                </div>

            </div>

        </div>
    )
}

