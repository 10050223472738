import classes from './CreateCKProduct.module.css'
import data_win from './../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { useEffect, useRef, useState } from 'react';

import {Custom_DD_Style, PrepareDropDownList} from '../../../../../SharedComp/Utility'

import {QueryProdTypeAll, CreateCKProduct_API_ED} from '../../../../../SharedComp/API/CK/AP_CenteraKitchen'
import {FetchKVType} from '../../../../../SharedComp/API/KV/API_kv'

import MasterDataRegInput from '../../../../../SharedComp/CommonStyle/MasterDataRegInput';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faTimesCircle, faUpload } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select';
import axios from 'axios'
import { useAuth } from '../../../../../AuthProvider';
import { useToast } from '../../../../../ToastContext';


export default function CreateCKProducts({OpenController}) 
{

    const notify = useToast();
    const formRef = useRef();
    const [registring, SetRegistring] = useState(false);


    const [previewUrl, setPreviewUrl] = useState('');
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
          SetCKProductIcon(file); // Save the file
          const url = URL.createObjectURL(file); // Create a preview URL
          setPreviewUrl(url); // Set the preview URL
        }
      };
      const fileInputRef = useRef(null);
      const handleImageClick = () => {
        // Trigger the file input click when the image box is clicked
        fileInputRef.current.click();
      };

    const [CkProductTypeList, SetCkProductTypeList] = useState([]);
    const [CK_KV_TypeLlist, SetCK_KV_TypeLlist] = useState([]);


    const [CK_KV_Choice, SetCK_KV_Choice] = useState();
    const [CKProductTypeChoice, SetCKProductTypeChoice] = useState();
    const [CKProductName, SetCKProductName] = useState();
    const [CKProductUnitPrice, SetCKProductUnitPrice] = useState();
    const [CKProductIcon, SetCKProductIcon] = useState();

    const auth = useAuth();

    function CloseModal() 
    {
        formRef.current.reset();
        OpenController(false);
    }

    const QueryCKProductType = async()=> 
    {
        try 
        {
            const res = await QueryProdTypeAll(auth.token);
            PrepareDropDownList(res, SetCkProductTypeList)
        }catch(err) 
        {
        }
    }
    const QueryKVType = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("branch_id", auth.activeWorkShop);
            const res = await FetchKVType(fd, auth.token);
            PrepareDropDownList(res, SetCK_KV_TypeLlist);
        }catch(err) 
        {

        }
    }

    useEffect(()=>{
        QueryCKProductType();
        QueryKVType();
    }, [])

    const HandleSubmition = async(e)=> 
    {
        e.preventDefault();
        SetRegistring(true);
        notify.ShowLoad("Processing Your Request...", 10);
        try 
        {
            const fd = new FormData();
            fd.append("product_type", JSON.stringify(CKProductTypeChoice));
            fd.append("product_name", CKProductName);
            fd.append("product_variance", JSON.stringify(CK_KV_Choice));
            fd.append("unit_price", CKProductUnitPrice);
            fd.append("icon", CKProductIcon);

            const res = await CreateCKProduct_API_ED(fd, 'token');
            SetRegistring(false);
            notify.ShowSuccessUpdate(res.Msg, 10);
            CloseModal();
            
        }catch(err) 
        {
            SetRegistring(false);
            notify.ShowErrorUpdate(err.message, 10);
            CloseModal();
        }
        
    }

    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

        <div className={`${data_win.data_main}`}>
            <div className={`${data_win.data_main_header}`}>
                <h3>
                    Registring <span className={`${data_win.data_create_name}`}>Centeral Kicthen Product</span>
                </h3>
                <button onClick={()=>{CloseModal()}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

            <div className={`${data_win.data_body}`}> 
                    
            

                        <form onSubmit={HandleSubmition} ref={formRef} action="POST">
                            <div className={`${classes.form_wrapper}`}> 
                                    <div  className={`${classes.input_list_wrapper}`}>

                                        <div className={`${classes.single_input}`}>
                                            <MasterDataRegInput input={
                                                    <Select
                                                        onChange={(data)=>{SetCKProductTypeChoice(data)}}
                                                        name={"dd_data"}
                                                        options={CkProductTypeList}
                                                        placeholder={"Product Type"}
                                                        isSearchable={true}
                                                        styles={Custom_DD_Style}
                                                        required ={true}
                                                />
                                                } tip={"Product Type"} forceInput={true} /> 
                                        </div>
                                        <div className={`${classes.single_input}`}>
                                            <MasterDataRegInput input={
                                                    <Select
                                                        onChange={(data)=>{SetCK_KV_Choice(data)}}
                                                        name={"dd_data"}
                                                        options={CK_KV_TypeLlist}
                                                        placeholder={"Kitchen Variance Type"}
                                                        isSearchable={true}
                                                        styles={Custom_DD_Style}
                                                        required ={true}
                                                />
                                                } tip={"Product Type"} forceInput={true} /> 
                                        </div>

                                        <div className={`${classes.single_input}`}>
                                            <MasterDataRegInput input={<input onChange={(e)=>{SetCKProductName(e.target.value)}}  name='ck_prod_name' type="text" placeholder='CK Product Name' title='' autoComplete='off' required />} forceInput={true} />
                                        </div>
                                        
                                        <div className={`${classes.single_input}`}>
                                            <MasterDataRegInput input={<input onChange={(e)=>{SetCKProductUnitPrice(e.target.value)}} name='ck_prod_unit_price' min={0} step="any" type="number" placeholder='Unit Price' title='' autoComplete='off' required />} forceInput={true} />
                                        </div>
                                        
                                        
                                        
                                      
                                    </div>

                                    <div className={`${classes.prod_preview_wrpper}`}>
                                            <div onClick={() => fileInputRef.current.click()}  className={`${classes.prod_image_input_wrapper}`}>
                                               {previewUrl  ? <img src={previewUrl} alt="Product Preview" /> : 
                                                    <div className={`${classes.click_upload_helper}`}>
                                                       <li> <FontAwesomeIcon icon={faUpload}></FontAwesomeIcon></li>
                                                       <li>Click to Upload</li>
                                                    </div> } 
                                            </div>
                                            <div style={{ 
                                                        position: 'absolute', // Ensure it's out of the flow
                                                        width: '0',
                                                        height: '0',
                                                        opacity: '0',
                                                        overflow: 'hidden',
                                                        }}
                                                    className={`${classes.single_input}`}>
                                                
                                                <MasterDataRegInput input={<input ref={fileInputRef} onChange={(e)=>{handleFileChange(e)}} name='ck_prod_icon' min={0}  type="file" accept=".jpg,.jpeg,.png" placeholder='Icon' />} />
                                            </div>
                                            
                                    </div>
                            </div>
                            <div className={`${data_win.form_action_button_wrapper}`}>
                                <button disabled={registring} className={`${data_win.form_action_button_save}`}>{registring ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Add'}</button>
                                <button disabled={registring}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                            </div>
                        </form>




            </div>
        </div>

    </div>
    )
}