import { useState, useEffect } from "react";


export default function CountdownTimer({ targetTime, CallbackFunc = null, callbackFuncData = null, CountSec })
{
    const calculateTimeRemaining = () => {
      
      // var tzoffset = (new Date()).getTimezoneOffset() * 60000;
      // var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);

      const StoredDate = new Date(targetTime);
      const storedTimeLocal = new Date(StoredDate.getTime() - (StoredDate.getTimezoneOffset() * 60000));


      const now = new Date();
      const difference = new Date(storedTimeLocal) - now;
  
      if (difference > 0) {
        const seconds = Math.floor(difference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const weeks = Math.floor(days / 7);
        const months = Math.floor(days / 30); // Approximation
        const years = Math.floor(days / 365); // Approximation

       
  
        return { years, months, weeks, days, hours: hours % 24, minutes: minutes % 60, seconds: seconds % 60 };
      } else {
          if(CallbackFunc) 
          {
            CallbackFunc(callbackFuncData);
          }
        return { years: 0, months: 0, weeks: 0, days: 0, hours: 0, minutes: 0, seconds: 0 };
      }
    };
  
    const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());
    const [LastSecCount, SetLastSecCount] = useState(false);
  
    useEffect(() => {
      const timer = setInterval(() => {
        setTimeRemaining(calculateTimeRemaining());
        // console.log(timeRemaining);
        // if(
        //   timeRemaining.seconds === 0 && 
        //   timeRemaining.minutes === 0 &&
        //   timeRemaining.hours === 0   &&
        //   timeRemaining.days === 0   &&
        //   timeRemaining.weeks === 0   &&
        //   timeRemaining.months === 0   &&
        //   timeRemaining.years === 0 
        //    ) 
        //    {
        //       if(CallbackFunc) 
        //       {
        //         CallbackFunc(callbackFuncData);
        //       }
        //    }

        //    if((timeRemaining.seconds === 0) &&  (timeRemaining.minutes <= 1) && (timeRemaining.hours === 0) && (timeRemaining.days === 0) && (timeRemaining.weeks === 0) && (timeRemaining.months === 0)   && (timeRemaining.years === 0) ) 
        //      {
        //         SetLastSecCount(true);
        //      }
      }, CountSec ? 1000 : 60 * 1000);
  
      return () => clearInterval(timer);
    }, [targetTime]);
  
    return (
      <span>
        {timeRemaining.years > 0 && <span>{timeRemaining.years} year{timeRemaining.years > 1 ? 's' : ''}</span>}
        {timeRemaining.months > 0 && <span>{timeRemaining.months} month{timeRemaining.months > 1 ? 's' : ''} </span>}
        {timeRemaining.weeks > 0 && <span>{timeRemaining.weeks} week{timeRemaining.weeks > 1 ? 's' : ''} </span>}
        {timeRemaining.days > 0 && <span>{timeRemaining.days} day{timeRemaining.days > 1 ? 's' : ''} </span>}
        {timeRemaining.hours > 0 && <span>{timeRemaining.hours} hour{timeRemaining.hours > 1 ? 's' : ''} </span>}
        {<span>{timeRemaining.minutes} minute{timeRemaining.minutes > 1 ? 's' : ''} </span>}
        {CountSec && <span>{timeRemaining.seconds} second{timeRemaining.seconds > 1 ? 's' : ''} </span>}

        
      </span>
    );
  };
