import classes from './StockOut.module.css'

import { useRef, useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import TimeElapsed from '../../SharedComp/TimeElapsed/TimeElapsed';
import { Tooltip } from 'react-tooltip';

import StockAction from './Component/StockAction/StockAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {FetchProductionOrderBranchPending} from '../../api'
import { useAuth } from '../../AuthProvider';
import OrderGroupManager from './Component/OrderGroupManager/OrderGroupManager';

import OrderStockProcessor from './Component/OrderStockProcessor/OrderStockProcessor';
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons';


export default function StockOut() 
{

    const auth = useAuth();
    const [ActiveProductionOrders, SetActiveProductionOrders] = useState([]);
    const [OpenPOStockProcessor, SetOpenPOStockProcessor] = useState(false);
    const [FocusedData, SetFocusedData] = useState();
    const [Refersh, SetRefersh] = useState(false);


    const RefershList = async()=>
    {
        SetRefersh(!Refersh);
    }
    const QueryActiveProductionOrders = async()=>{
        try 
        {
            const res = await FetchProductionOrderBranchPending(auth.activeWorkShop, 'token');
            SetActiveProductionOrders(res);
            
        }catch(err) 
        {

        }
    }

    const PoProcessCallback = async(data)=> 
    {
        SetFocusedData(data);
        SetOpenPOStockProcessor(true);
    }

    useEffect(()=>{
        SetActiveProductionOrders([]);
        QueryActiveProductionOrders();

    }, [Refersh]);
    
    return (
        <div className={`${classes.stock_out_processing_main_wrapper}`}>
            <Tooltip className={`${classes.tool_quick}`} id="offical_doc" place="top" />

            {OpenPOStockProcessor && <OrderStockProcessor DataRefersh={RefershList} TargetData={FocusedData} Close_CALLBACK={SetOpenPOStockProcessor}/> }

            <div className={`${classes.active_order_list}`}>
                {
                    ActiveProductionOrders.map((item, idx)=>(
                        <OrderGroupManager DataRefersh={RefershList} DataAcceptCallback={PoProcessCallback} OrderData={item}/>
                    ))
                }
                {
                    ActiveProductionOrders.length === 0 && 
                    <div className={`${classes.empty_po_stock_request}`}>
                            <li><FontAwesomeIcon icon={faBoxOpen}></FontAwesomeIcon></li>
                            <li>no Active production stock request!</li>
                    </div>
                }
            </div>

        </div>
    );
}