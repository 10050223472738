import { useRef } from 'react';
import classes from './EmployeeIDCard.module.css'
import QRCode from 'react-qr-code'; 
import { useReactToPrint } from 'react-to-print';
export default function EmpStat({EmpData}) 
{

    const IDCardRef = useRef();
    
    const handlePrint = useReactToPrint({
        content: () => IDCardRef.current,
    })


    return (
        <div className={`${classes.emp_id_card_design_main_wrapper}`}>

            <div className={`${classes.print_action_wrapper}`}>
                <button onClick={()=>{handlePrint()}}>Print</button>
            </div>
            
            <div className={`${classes.emp_id_card_design_inner_wrapper}`}>
                

                <div ref={IDCardRef}  className={`${classes.emp_id_card}`}>

                    <div className={`${classes.em_effect}`}>

                    </div>

                    <div className={`${classes.id_header}`}>
                        <img src="https://jakandsons.com/wp-content/uploads/2023/09/Asset-1@4x.png" alt="" width={110}  />
                    </div>

                    <div className={`${classes.emp_personal}`}>

                        <div className={`${classes.emp_image_wrapper}`}>

                            <div className={`${classes.img_it_self_wrapper}`}>
                                <img  src="https://cdn.pixabay.com/photo/2015/03/04/22/35/avatar-659651_640.png" alt="name stuff" />
                            </div>

                        </div>

                        

                        <div className={`${classes.emp_role_wrapper}`}>
                            <li>{EmpData.FullName}</li>
                            <li className={`${classes.emp_role_pos_name}`}>{EmpData.PositionName}</li>
                        </div>

                        <div className={`${classes.extra_emp_info}`}>
                            <li><span className={`${classes.extra_tag}`}>Emp ID</span> <span className={`${classes.extra_tag_sepa}`}>:</span> <span className={`${classes.extra_tag_value}`}>{EmpData.EmployeeID}</span> </li>
                            <li><span className={`${classes.extra_tag}`}>Phone</span> <span className={`${classes.extra_tag_sepa}`}>:</span> <span className={`${classes.extra_tag_value}`}>{EmpData.PhoneNumber}</span> </li>
                        </div>

                        <div className={`${classes.emp_qr_wrapper}`}>
                                <div className={`${classes.qr_code_wrapper}`} style={{ height: "auto", margin: "0 auto", maxWidth: 64, width: "100%" }}>
                                    <QRCode
                                        size={64}
                                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                        fgColor={'var(--background-dark)'}
                                        value={EmpData.FullName}
                                        viewBox={`0 0 512 512`}
                                        />   
                                </div>
                        </div>
                        <div className={`${classes.id_footer}`}>
                            <li>www.jakandsons.com</li>
                        </div>

                    </div>

                </div>


            </div>

            

        </div>
    )
}