import { faCheckSquare, faCircleNotch, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import classes from './LocalNotification.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'

export default function LocalNotification({Msg, State}) 
{
    const [Active, setActive] = useState(true);

    function handleCloseNoti() 
    {
        setActive(false);
    }
    useEffect(() => {

        if(State !== 0) 
        {
            setTimeout(()=>{
                setActive(false);
            }, 5 * 1000)
        }
       
    }, [])
    return (
        <div className={`${Active ? classes.local_notification : classes.local_notification_hide}`} style={{
            borderColor: State === 0 ? `var(--menu-button-hover-color)` : State === -1 ? `var(--red-color)` : `var(--color-theme)`,
            background: State === 0 ? `var(--menu-button-hover-color)` : State === -1 ? `var(--red-color)` : `var(--color-theme)`
        }} >

                <div className={`${classes.local_wrapper_not}`}>
                    <div className={`${classes.local_icon_section}`} style={{
                        color: State === 0 ? `var(--menu-button-hover-color)` : State === -1 ? `var(--red-color)` : `var(--color-theme)`
                    }}>
                        <FontAwesomeIcon icon={State === 0 ? faCircleNotch : State === -1 ? faTriangleExclamation : faCheckSquare } spin={State === 0}></FontAwesomeIcon>
                    </div>

                    <div className={`${classes.local_msg_seaction}`}>

                        {State === 0 ? `Working....` :  Msg }
                        
                    </div>
                </div>

            </div>
    )
}