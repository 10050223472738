import { useEffect, useState } from 'react'
import classes from './ChefLineup.module.css'


import ChefLineUpGroup from './ChefLineUpGroup/ChefLineUpGroup'

import {QueryProductionCenterWorkShop} from '../../../SharedComp/API/Kicthen/API_FE_Kicthen'

export default function ChefLineup() 
{
    const [ProductionCeneterList, SetProductionCeneterList] = useState([]);


    const FetchProductionCenterWorkShop = async()=>
    {
        try 
        {
            const main_res = await QueryProductionCenterWorkShop('token');
            SetProductionCeneterList(main_res);
        }catch(err) 
        {
            
        }
    }

    useEffect(()=>{
        FetchProductionCenterWorkShop();
    }, []);


    return (
        <div className={`${classes.chef_line_up_master_wrapper}`}>
            

            <div className={`${classes.production_space_workshop_list_wrapper}`}>
                {
                    ProductionCeneterList.map((item, idx)=>(
                        <ChefLineUpGroup TargetData={item} key={idx}/>
                    ))
                }
            </div>

        </div>
    )
}