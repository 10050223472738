import { useEffect, useState } from "react";


export default function TimeElapsed({Time}) 
{

    const [TimePassedVal, SetTimePassedVal] = useState(TimePassed(Time));

    function TimePassed(specificTime)
    {
        
        const currentTime = new Date();
        const elapsedMilliseconds = currentTime - new Date(specificTime);
        const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);

        
      
            let elapsedString;
        
            if (elapsedSeconds < 60) {

                // elapsedString = `${elapsedSeconds} second${elapsedSeconds !== 1 ? 's' : ''} ago`;
                elapsedString = "A minute ago"
            } else if (elapsedSeconds < 3600) {

                const minutes = Math.floor(elapsedSeconds / 60);
                elapsedString = `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;

            } else if (elapsedSeconds < 86400) {

                const hours = Math.floor(elapsedSeconds / 3600);
                elapsedString = `${hours} hour${hours !== 1 ? 's' : ''} ago`;

            } else if (elapsedSeconds < 604800) {

                const days = Math.floor(elapsedSeconds / 86400);
                elapsedString = `${days} day${days !== 1 ? 's' : ''} ago`;

            } else if (elapsedSeconds < 2419200) {

                const weeks = Math.floor(elapsedSeconds / 604800);
                elapsedString = `${weeks} week${weeks !== 1 ? 's' : ''} ago`;

            } else {

                const months = Math.floor(elapsedSeconds / 2419200);
                elapsedString = `${months} month${months !== 1 ? 's' : ''} ago`;

            }
        
            return elapsedString;
      };

      useEffect(() => {
        const timer = setInterval(() => {
            SetTimePassedVal(TimePassed(Time));
        }, 60 * 1000);
    
        return () => clearInterval(timer);
      }, [Time]);
      

      return (
        <span>
            {TimePassedVal}
        </span>
      )
}