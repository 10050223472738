import { getBaseURL } from "../../BaseUrlConfig";
import axios from 'axios'


function GetRouteConfig(token)
{
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
}

export const QueryProductionCenterWorkShop = async(token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'kitchen/query-production-center-workshop', {}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const AssignChefToWorkspace = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'kitchen/assign-chef-for-active-kicthen', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryAssignedChef = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'kitchen/query-assigned-chefs', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeAssignedChef = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'kitchen/purge-assigned-chefs', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}

export const QueryDispatchedOrderForChef = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'kitchen/query-dispacthed-order-for-chef', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryChefsProductionStack = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'kitchen/query-chefs-production-stack', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const ShipProducedCkProduct = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'kitchen/ship-ck-products', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}

export const QueryShippedProductGroupByReqGroup = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'kitchen//query-shipped-prod-group-by-req-group-id', data, GetRouteConfig(token));
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err;
    }
}