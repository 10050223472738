import { getBaseURL } from "../../BaseUrlConfig";
import axios from 'axios'

function GetRouteConfig(token)
{
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
}


export const QueryProdTypeAll = async(token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'ck/get_ck_prod_type_all', {}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryProdTypeID = async(__id, token)=>
    {
        try  
        {
            const response = await axios.post(getBaseURL() + 'ck/get_ck_prod_type_id', {ID: __id}, GetRouteConfig(token));
            const res = await response.data;
            return res;
        }catch(err) 
        {
            throw err;
        }
}
export const PurgeProdTypeID = async(__id, token)=>
    {
        try  
        {
            const response = await axios.post(getBaseURL() + 'ck/purge_ck_prod_type_id', {ID: __id}, GetRouteConfig(token));
            const res = await response.data;
            return res;
        }catch(err) 
        {
            throw err;
        }
}
export const QueryCKProductALL = async(token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'ck/get_ck_prod_item_all', {}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryCKProductKitchenID = async(__id, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'ck/get_ck_prod_item_kitchen_id', {ID: __id}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateCKProductAvailState = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'ck/update_ck_prod_avail_state', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryCKProductID = async(__id, token)=>
    {
        try  
        {
            const response = await axios.post(getBaseURL() + 'ck/get_ck_prod_item_id', {ID: __id}, GetRouteConfig(token));
            const res = await response.data;
            return res;
        }catch(err) 
        {
            throw err;
        }
    }
export const CreateCKProductType_API_EP = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'ck/reg_ck_prod_type', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateCKProductType_API_EP = async(data, token)=>
    {
        try  
        {
            const response = await axios.post(getBaseURL() + 'ck/update_ck_prod_type', data, GetRouteConfig(token));
            const res = await response.data;
            return res;
        }catch(err) 
        {
            throw err;
        }
}
export const CreateCKProduct_API_ED = async(data, token)=>
    {
        try  
        {
            const response = await axios.post(getBaseURL() + 'ck/reg_ck_prod', data, GetRouteConfig(token));
            const res = await response.data;
            return res;
        }catch(err) 
        {
            throw err;
        }
}
export const UpdateCKProduct_API_ED = async(data, token)=>
    {
        try  
        {
            const response = await axios.post(getBaseURL() + 'ck/update_ck_prod', data, GetRouteConfig(token));
            const res = await response.data;
            return res;
        }catch(err) 
        {
            throw err;
        }
}
export const PurgeCKProduct_API_ED = async(__id, token)=>
    {
        try  
        {
            const response = await axios.post(getBaseURL() + 'ck/purge_ck_prod_id', {ID: __id}, GetRouteConfig(token));
            const res = await response.data;
            return res;
        }catch(err) 
        {
            throw err;
        }
}

export const FetchWorkspaceProductionCenterOnly = async(token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'ck/fetch_workshop_production_center_only', {}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}