import classes from './PersonalInfoCollector.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faLocationPin, faMapLocation, faPhone, faTriangleExclamation, faUserGraduate, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope, faUser } from '@fortawesome/free-regular-svg-icons';
import EmpTextInput from '../EmpTextInput/EmpTextInput';
import EmpDropDownStaticInputField from '../EmpDropDownInput/EmpDropDownStaticInput';
import { useState } from 'react';
import EmpNationalitySelector from '../EmpNationalitySelector/EmpNationalitySelector';


import { forwardRef, useImperativeHandle } from 'react';


const PersonalInfoCollector = forwardRef(({ DataPkg, DataPkgUpdate, updateInput }, ref) => {

    const [FullNameField_State, SetFullNameField_State]             = useState(0);
    const [PhoneField_State, SetPhoneField_State]                   = useState(0);
    const [EmailField_State, SetEmailField_State]                   = useState(0);
    const [BirthDateField_State, SetBirthDateField_State]           = useState(0);
    const [AgeField_State, SetAgeField_State]                       = useState(0);
    const [GenderField_State, SetGenderField_State]                 = useState(0);
    const [AddressField_State, SetAddressField_State]               = useState(0);
    const [ECFNField_State, SetECFNField_State]                     = useState(0);
    const [ECPField_State, SetECPField_State]                       = useState(0);
    const [EducStatusField_State, SetEducStatusField_State]         = useState(0);
    const [FieldOfStudyField_State, SetFieldOfStudyField_State]     = useState(0);
    const [InstitutionField_State, SetInstitutionField_State]       = useState(0);
    const [EmpWorkSpaceField_State, SetEmpWorkSpaceField_State]       = useState(0);
    const [NationalityField_State, SetNationalityField_State]       = useState(0);


    function PerformLocalInputGuard() 
    {
        let ret_value = true;
        if(DataPkg["full_name"] === undefined || DataPkg["full_name"] === '') 
        {
            SetFullNameField_State(-1);
            ret_value = false;
        } else 
        {
            SetFullNameField_State(1);
        }
        if(DataPkg["phone"] === undefined || DataPkg["phone"] === '') 
        {
            SetPhoneField_State(-1);
            ret_value = false;
        }
        else 
        {
            SetPhoneField_State(1);
        }
        if(DataPkg["email"] === undefined || DataPkg["email"] === '') 
        {
            SetEmailField_State(-2);
        }
        else 
        {
            SetEmailField_State(1);
        }
        if(DataPkg["date"] === undefined || DataPkg["date"] === '') 
        {
            SetBirthDateField_State(-2);
        }
        else 
        {
            SetBirthDateField_State(1);
        }
        if(DataPkg["age"] === undefined || DataPkg["age"] === '') 
        {
            SetAgeField_State(-1);
            ret_value = false;
        }
        else 
        {
            SetAgeField_State(1);
        }
        if(DataPkg["gender"] === undefined || DataPkg["gender"] === '') 
        {
            SetGenderField_State(-1);
            ret_value = false;
        }
        else 
        {
            SetGenderField_State(1);
        }
        if(DataPkg["address"] === undefined || DataPkg["address"] === '') 
        {
            SetAddressField_State(-1);
            ret_value = false;
        }
        else 
        {
            SetAddressField_State(1);
        }
        if(DataPkg["emerg_full_name"] === undefined || DataPkg["emerg_full_name"] === '') 
        {
            SetECFNField_State(-1);
            ret_value = false;
        }
        else 
        {
            SetECFNField_State(1);
        }
        if(DataPkg["emerg_phone"] === undefined || DataPkg["emerg_phone"] === '') 
        {
            SetECPField_State(-1);
            ret_value = false;
        }
        else 
        {
            SetECPField_State(1);
        }
        if(DataPkg["education_status"] === undefined || DataPkg["education_status"] === '') 
        {
            SetEducStatusField_State(-1);
            ret_value = false;
        }
        else 
        {
            SetEducStatusField_State(1);
        }
        if(DataPkg["field_of_study"] === undefined || DataPkg["field_of_study"] === '') 
        {
            SetFieldOfStudyField_State(-2);
        }
        else 
        {
            SetFieldOfStudyField_State(1);
        }
        if(DataPkg["institution"] === undefined || DataPkg["institution"] === '') 
        {
            SetInstitutionField_State(-2);
        }
        else 
        {
            SetInstitutionField_State(1);
        }

        if(DataPkg["employee_work_space"] === undefined || DataPkg["employee_work_space"] === '') 
        {
            SetEmpWorkSpaceField_State(-1);
        }
        else 
        {
            SetEmpWorkSpaceField_State(1);
        }

        if(DataPkg["nationality"] === undefined || DataPkg["nationality"] === '') 
        {
            SetNationalityField_State(-1);
        }
        else 
        {
            SetNationalityField_State(1);
        }

        return ret_value;
    }

    useImperativeHandle(ref, () => ({
        PerformLocalInputGuard,
    }))

    return (
        <div className={`${classes.emp_personal_info_wrapper}`}>
            <div  className={`${classes.emp_personal_info_header}`}>
                    <h2>Personal Information </h2>
            </div>
            <div className={`${classes.emp_section_manager}`}>
                    <div className={`${classes.emp_input_section_wrapper}`}>                        

                            <EmpTextInput State={FullNameField_State} Data={DataPkg} OnChangeUpdate={updateInput} Title={"Full Name"} Tip={"Eg. Shambel Abebe Bikila"} ValueTag={"full_name"} Icon={faUser} PlaceHolder={"Full Name"}/>
                            
                            <EmpTextInput State={PhoneField_State} Data={DataPkg} OnChangeUpdate={updateInput} Title={"Phone Number"} ValueTag={"phone"} Icon={faPhone} PlaceHolder={"Phone"}/>
                            <EmpTextInput State={EmailField_State} Data={DataPkg} OnChangeUpdate={updateInput} Title={"Email"} ValueType='email' ValueTag={"email"} Icon={faEnvelope} PlaceHolder={"Email"} ForceInput={false}/>

                            <EmpTextInput State={BirthDateField_State} Data={DataPkg} OnChangeUpdate={updateInput} Title={"Birth Date"} ValueType='date' ValueTag={"date"} Icon={faUser} PlaceHolder={"Date"} ForceInput={false}/>
                            <EmpTextInput State={AgeField_State} Data={DataPkg} OnChangeUpdate={updateInput} Title={"Age"} ValueType='number' ValueTag={"age"} Icon={faUser} PlaceHolder={"Age"}/>

                            <EmpDropDownStaticInputField State={GenderField_State} Data={DataPkg} OnChangeUpdate={updateInput} Title={"Gender"} ValueTag={"gender"} PlaceHolder={"Select Gender..."} Icon={faUser} list={["Male", "Female"]} />

                            <EmpDropDownStaticInputField State={EmpWorkSpaceField_State} Data={DataPkg} OnChangeUpdate={updateInput} Title={"Employee Work Space"} ValueTag={"employee_work_space"} PlaceHolder={"Select Employee Workspace..."} Icon={faUser} list={["Office", "Workshope"]} />
                        </div>

                        <div className={`${classes.emp_input_section_wrapper}`}>
                            <EmpTextInput State={AddressField_State} Data={DataPkg} OnChangeUpdate={updateInput} Title={"Address"} ValueTag={"address"} Icon={faLocationPin} PlaceHolder={"Address"}/>
                            
                            <EmpTextInput State={ECFNField_State} Data={DataPkg} OnChangeUpdate={updateInput} Title={"Emergency Contact Full Name"} ValueTag={"emerg_full_name"} Icon={faUser} PlaceHolder={"Emergency Contact Full Name"}/>
                            <EmpTextInput State={ECPField_State} Data={DataPkg} OnChangeUpdate={updateInput} Title={"Emergency Contact Phone"} ValueTag={"emerg_phone"} Icon={faPhone} PlaceHolder={"Emergency Contact Phone"}/>

                            
                            {/* <EmpTextInput State={EducStatusField_State} Data={DataPkg} OnChangeUpdate={updateInput} Title={"Education Status"} ValueTag={"education_status"} Icon={faUserGraduate} PlaceHolder={"Education Status"}/> */}
                            <EmpDropDownStaticInputField State={EducStatusField_State} Data={DataPkg} OnChangeUpdate={updateInput} Title={"Education Status"} ValueTag={"education_status"} PlaceHolder={"Select Education Status..."} Icon={faUserGraduate} list={["Under 10", "Just 10", "10+3", "Certificate", "Diploma","Degree", "Masters", "PHD",  "Other"]} />

                            <EmpTextInput State={FieldOfStudyField_State} Data={DataPkg} OnChangeUpdate={updateInput} Title={"Field of Study"} ValueTag={"field_of_study"} Icon={faUserGraduate} PlaceHolder={"Field of study"} ForceInput={false} />

                            <EmpTextInput State={InstitutionField_State} Data={DataPkg} OnChangeUpdate={updateInput} Title={"Institution"} ValueTag={"institution"} Icon={faBuilding} PlaceHolder={"Institution"} ForceInput={false}/>
                            
                            <EmpNationalitySelector State={NationalityField_State} DataUpdate={DataPkgUpdate} Data={DataPkg} OnChangeUpdate={updateInput} Title={"Nationality"} ValueTag={"nationality"} PlaceHolder={"Select Nationality..."} Icon={faMapLocation}/>
                        </div>

                </div>
        </div> 
    )
});


export default PersonalInfoCollector;