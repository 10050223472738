import { faAnglesDown, faAnglesUp, faCircleInfo, faFilter, faGavel, faHandDots, faSquarePollVertical } from '@fortawesome/free-solid-svg-icons'
import classes from './CashCollectionMaster.module.css'
import CC_ProductItem from './Component/ProdItem/CC_ProductItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import axios from 'axios'


import ProductMerchItem from './Component/ProductMerchItem/ProductMerchItem'
import ProductPastryItem from './Component/ProductPastryItem/ProductPastryItem'
import CollectionPreviewReport from './Component/CollectionPreviewReport/CollectionPreviewReport'
import NotificationPopUp from '../../../SharedComp/NotificationPopUp/NotificationPopUp'
import { Tooltip } from 'react-tooltip';

import { ProductItem } from './ProductItem'
import ReadbackBufferPreview from './Component/ReadbackBufferPreview/ReadbackBufferPreview'

import { getBaseURL } from '../../../SharedComp/BaseUrlConfig'

export default function CashCollectionMaster()
{

    // Nakery
    const [TodayProduction_Unprocessed, SetTodayProduction__Unprocessed] = useState([]);
    const [TodayProduction, SetTodayProduction] = useState([]);
    const [FilteredProcductionItem, SetFilteredProcductionItem] = useState([]);

    // Pastry
    const [PasteryProducts_Unprocessed, SetPasteryProducts_Unprocessed] = useState([]);
    const [PastryProducts, SetPastryProduct] = useState([]);
    const [FilteredPastryProducts, SetFilteredPastryProducts] = useState([]);


    // Merchnedise
    const [MerchProducs_Unprocessed, SetMerchProducs_Unprocessed] = useState([]);
    const [MerchProducts, SetMerchProducts] = useState([]);
    const [FiltredMerchProducts, SetFiltredMerchProducts] = useState([]);

    // Readback buffer is when the card input or when the user is add unsold or damage item this buffer start to populate
    // and this reaback buffer will be sent to the backend assuming this item are gone be processed
    // for each catagory we need seprate buffer Pastry, Merchndise and Bakery
    const [Bakery_ReadbackBuffer, SetBakery_ReadbackBuffer] = useState([]);
    const [Pastry_ReadbackBuffer, SetPastry_ReadbackBuffer] = useState([]);
    const [Merch_ReadbackBuffer, SetMerch_ReadbackBuffer] = useState([]);


    const [InfoBoxOpen, SetInfoBoxOpen] = useState(false);
    //                                          ALL    BAK    PAST  MERCH
    const [ActiveTab, SetActiveTab] = useState([true, false, false, false]);
    const [TabString, SetTabString] = useState("");

    const [DailyCollectedAmount, SetDailyCollectedAmount] = useState(0);
    const [PittyCashExpense, SetPittyCashExpense] = useState(0);
    const [InitialAmount, SetInitialAmount] = useState(0);

    const [MsgPkg, SetMsgPkg] = useState({});
    const [OpenMyNoti, SetOpenMyNoti] = useState(false);
    const [OpenPreview, SetOpenPreview] = useState(false);

    const [ProductItems, SetProductItems] = useState([]);
    const [RefreshPage, SetRefreshPage] = useState(false);

    function HandleTabSwicth(idx) 
    {
        let active_tab = [];
        for(let i = 0; i < 4; i++) 
            active_tab.push(idx === i);
        SetActiveTab(active_tab);
        let DataAccumulation = [];
        if(idx === 0) // ALL
        {
            SetTabString("");
            SetFilteredProcductionItem(TodayProduction)
            return;
        } else if (idx === 1) // BAKERY
        {
            SetTabString("Production");
            for (let i = 0; i < TodayProduction.length; i++) {

                if (TodayProduction[i].ProdGrandParentID === 9) {
                    DataAccumulation.push({ ...TodayProduction[i]});
                }
            }
        }else if (idx === 2)  // PASTRY
        {
            SetTabString("Pastry");
            for (let i = 0; i < TodayProduction.length; i++) {
    
                if (TodayProduction[i].ProdGrandParentID === 10) {
                    DataAccumulation.push({ ...TodayProduction[i]});
                }
            }
        }else if (idx === 3) // MERCHANDISE
        {
            SetTabString("Merchandise");
            for (let i = 0; i < TodayProduction.length; i++) {
    
                if (TodayProduction[i].ProdGrandParentID === 12) {
                    DataAccumulation.push({ ...TodayProduction[i]});
                } 
            }
        }



        DataAccumulation.sort(SortByName);
        SetFilteredProcductionItem(DataAccumulation);

    }
    function SortByName(a, b) 
    {
        return a.ItemName.localeCompare(b.ItemName);
    }
    function Bakery_PreProcess() 
    {
        // Collect All Similar Item
        let DataAccumulation = [];
        for (let i = 0; i < TodayProduction_Unprocessed.length; i++) {

            const existingItem = DataAccumulation.find((item) => { return item.ItemName === TodayProduction_Unprocessed[i].ItemName});
            
            let item = TodayProduction_Unprocessed[i];
            item.DamegedQuantity = 0;
            item.FoodExpense = 0;
            item.Unsold = 0;

            if (!existingItem) {
                DataAccumulation.push({ ...TodayProduction_Unprocessed[i]});
            } else 
            {
                existingItem.Quantity = parseInt(existingItem.Quantity) + parseInt(TodayProduction_Unprocessed[i].Quantity);
            }
        }
        
        DataAccumulation.sort(SortByName);
        SetTodayProduction(DataAccumulation);
        SetFilteredProcductionItem(DataAccumulation);
    }

    function Pastry_Preprocess() 
    {
        let DataAccumulation = [];
        for (let i = 0; i < PasteryProducts_Unprocessed.length; i++) 
        {
            let item = PasteryProducts_Unprocessed[i];
            item.DamegedQuantity = 0;
            item.FoodExpense = 0;
            item.Unsold = 0;

            DataAccumulation.push({ ...PasteryProducts_Unprocessed[i]});
        }
        
        DataAccumulation.sort(SortByName);
        SetPastryProduct(DataAccumulation);
        SetFilteredPastryProducts(DataAccumulation);
    }
    function Merch_Preprocess()
    {
        let DataAccumulation = [];
        for (let i = 0; i < MerchProducs_Unprocessed.length; i++) 
        {
            let item = MerchProducs_Unprocessed[i];
            item.DamegedQuantity = 0;
            item.FoodExpense = 0;
            item.Unsold = 0;

            DataAccumulation.push({ ...MerchProducs_Unprocessed[i]});
        }
        
        DataAccumulation.sort(SortByName);
        SetMerchProducts(DataAccumulation);
        SetFiltredMerchProducts(DataAccumulation);
    }

    async function SimplifyData() {

        let simplified = await Promise.all(FilteredProcductionItem.map(async (item) => {
          let _temp_obj = new ProductItem();
          _temp_obj.SetItemName(item.ProdName);
          _temp_obj.SetQuantity(item.Quantity);
          _temp_obj.SetParentID(item.ProdGrandParentID);
          _temp_obj.SetProductID(item.ItemID);
          _temp_obj.SetUnitPrice(item.UnitPrice);
          return _temp_obj; 
        }));

        SetProductItems(simplified);
      }

    useEffect(()=>{
    }, [ProductItems]) 
    
    useEffect(()=>{
        SimplifyData();
    }, [FilteredProcductionItem])


    function BakeryReadbackBufferUpdateCallback(name, obj) 
    {
        // get if nay registered item before if any? // This might be empty
        const currentState = [...Bakery_ReadbackBuffer];
        

        // check if the item exist
        const index = currentState.findIndex((item) => {
            return item.Name === name;
        });
        


        if (index !== -1) {
            // If the item exists, update it
            currentState[index] = { ...currentState[index], ...obj[0] };
        } else {
            // If the item doesn't exist, add it
            currentState.push(obj[0]);
        }

        SetBakery_ReadbackBuffer(currentState);
    }
    function PastryReadbackBufferUpdateCallback(name, obj) 
    {
        // get pastry item if registered/ query current state
        const currentState = [...Pastry_ReadbackBuffer];

        // follow the prev step
        const index = currentState.findIndex((item) => {
            return item.Name === name;
        });
        


        if (index !== -1) {
            // If the item exists, update it
            currentState[index] = { ...currentState[index], ...obj[0] };
        } else {
            // If the item doesn't exist, add it
            currentState.push(obj[0]);
        }

        SetPastry_ReadbackBuffer(currentState);
    }
    function MerchReadbackBufferUpdateCallback(name, obj) 
    {
        // get pastry item if registered/ query current state
        const currentState = [...Merch_ReadbackBuffer];

        // follow the prev step
        const index = currentState.findIndex((item) => {
            return item.Name === name;
        });
        


        if (index !== -1) {
            // If the item exists, update it
            currentState[index] = { ...currentState[index], ...obj[0] };
        } else {
            // If the item doesn't exist, add it
            currentState.push(obj[0]);
        }

        SetMerch_ReadbackBuffer(currentState);
    }

    function ToggleInfoBox() 
    {
        if(InfoBoxOpen)
            SetInfoBoxOpen(false);
        else 
            SetInfoBoxOpen(true);
    }
    function FilterByName(e) 
    {
        let DataAccumulation = [];
        for (let i = 0; i < TodayProduction.length; i++) {

            if(TodayProduction[i].ItemName.toLowerCase().includes(e.target.value.toLowerCase()))
                DataAccumulation.push({ ...TodayProduction[i]});
        }
        DataAccumulation.sort(SortByName);
        SetFilteredProcductionItem(DataAccumulation);

        DataAccumulation = [];
        for (let i = 0; i < MerchProducts.length; i++) {

            if(MerchProducts[i].ItemName.toLowerCase().includes(e.target.value.toLowerCase()))
                DataAccumulation.push({ ...MerchProducts[i]});
        }
        DataAccumulation.sort(SortByName);
        SetFiltredMerchProducts(DataAccumulation);
        
        DataAccumulation = [];
        for (let i = 0; i < PastryProducts.length; i++) {

            if(PastryProducts[i].ItemName.toLowerCase().includes(e.target.value.toLowerCase()))
                DataAccumulation.push({ ...PastryProducts[i]});
        }
        DataAccumulation.sort(SortByName);
        SetFilteredPastryProducts(DataAccumulation);
    }

    function FetchToDateProduction() 
    {
        fetch(getBaseURL() + 'operation-data/get-stock-card-master')
        .then(response => response.json())
        .then(data => SetTodayProduction__Unprocessed(data))
        .catch(error => console.error(error));

        fetch(getBaseURL() + 'operation-data/query-local-store-pastry-item-all')
        .then(response => response.json())
        .then(data => SetPasteryProducts_Unprocessed(data))
        .catch(error => console.error(error));

        fetch(getBaseURL() + 'operation-data/query-local-shop-merch-all')
        .then(response => response.json())
        .then(data => SetMerchProducs_Unprocessed(data))
        .catch(error => console.error(error));
    }

    useEffect(() => {
        FetchToDateProduction();
        SetActiveTab([true, false, false, false]);
    }, [RefreshPage])

    useEffect(()=>{
        Bakery_PreProcess();
        Pastry_Preprocess();
        Merch_Preprocess();
    }, [TodayProduction_Unprocessed])


    function QuickInputCheck() 
    {
        if(DailyCollectedAmount <= 0) 
        {
            LocalMsgTrigger("<DAILY COLLECTED AMOUNT> Cannot be [0], [Empty], or [Less that 0]. Is that clear BOY?", 0);
            return false;
        }

        if(PittyCashExpense < 0) 
        {
            LocalMsgTrigger("<PITTY CASH AMOUNT> Cannot be [0], [Empty], or [Less that 0].", 0);
            return false;
        }

        if(PittyCashExpense > DailyCollectedAmount) 
        {
            LocalMsgTrigger("<PITTY CASH EXPENSE> Cannot Exceed <DAILY COLLECTED AMOUNT>", 0);
            return false;
        }

        if(InitialAmount < 0) 
        {
            LocalMsgTrigger("<INITAIL AMOUNT> Cannot be [Less that 0].", 0);
            return false;
        }

        return true;
    }

    function OnReportPreview() 
    {
        if(!QuickInputCheck()) 
        {
            return;
        }
        
       // console.log(ReadbackBuffer);
        SetOpenPreview(true);
    }

    function LocalMsgTrigger(msg, stat) 
    {
        SetMsgPkg({Key : Math.random() * 10000, Msg: msg, State: stat})
        SetOpenMyNoti(true);
    }

    function CashCollectionFinalReturn(res) 
    {
        SetMsgPkg({Key : Math.random() * 10000, Msg: res.data.Msg, State: res.data.MyStateCode})
        SetOpenMyNoti(true);
    }

    function SubmitCashCollection() 
    {
        if(!QuickInputCheck()) 
        {
            return;
        }
        
        const fd = new FormData();

        // BAKERY PRODUCT
        fd.append("Bakery_FilterBuffer", JSON.stringify(Bakery_ReadbackBuffer));
        fd.append("Bakery_MainBuffer", JSON.stringify(TodayProduction));

        // PASTRY PRODUCT
        fd.append("Pastry_FilterBuffer", JSON.stringify(PastryProducts));
        fd.append("PastryBuffer", JSON.stringify(Pastry_ReadbackBuffer));

        // MERCHNDISE PRODUCT
        fd.append("MerchBuffer_FilterBuffer", JSON.stringify(MerchProducts));
        fd.append("MerchBuffer", JSON.stringify(Merch_ReadbackBuffer));


        fd.append("CashOnHand", DailyCollectedAmount);
        fd.append("PittyCashExpense", PittyCashExpense);
        fd.append("InitialAmount", InitialAmount);

        const url = getBaseURL() + 'operation-data/collect-cash';

        axios.post(url, fd, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then(res => {
            CashCollectionFinalReturn(res);
            SetRefreshPage(!RefreshPage);
        }).catch(err => {
            console.log(err);
        })

    }

    function getTimeInFormat() {
        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
      }






    return (
        <div className={`${classes.main_page_wrapper}`}>

        {/* <div className={`${classes.lock_collection}`}>
            <div className={`${classes.info_wrapper}`}>
                <li>Collection Start Soon</li>
                <li>{getTimeInFormat()}</li>
            </div>
        </div> */}

       {OpenPreview && 
            <CollectionPreviewReport 
                CLOSE_CLBK={SetOpenPreview} 
                DailyCollection={DailyCollectedAmount} 
                PittyExpense={PittyCashExpense}

                ToDateProd_Bakery={TodayProduction}
                ToDateProd_Pastry={PastryProducts}
                ToDateProd_Merch={MerchProducts}

                Bakery_ReadBackBuffer={Bakery_ReadbackBuffer} 
                Pastry_ReadbackBuffer={Pastry_ReadbackBuffer}
                Merch_ReadBackBuffer={Merch_ReadbackBuffer}
            />
       } 

        <Tooltip className={`${classes.tool_quick}`} id="offical_doc" place="top" />
            {OpenMyNoti && <NotificationPopUp key={MsgPkg.Key} msg={MsgPkg.Msg} state={MsgPkg.State} />}
            <div className={`${classes.head_wrapper}`}>
                {/* <h1>Cash Collection</h1> */}
            </div>


            <div className={`${classes.body_wrapper}`}>
                    <div className={`${classes.item_wrapper}`}>

                        <div className={`${classes.product_switch_menu_wrapper}`}>
                            <div className={`${classes.item_catagrody}`}>
                                <li onClick={()=>{HandleTabSwicth(0)}} className={`${ActiveTab[0] ? classes.active_tab : ''}`}>All</li>
                                <li onClick={()=>{HandleTabSwicth(1)}} className={`${ActiveTab[1] ? classes.active_tab : ''}`}>Bakery</li>
                                <li onClick={()=>{HandleTabSwicth(2)}} className={`${ActiveTab[2] ? classes.active_tab : ''}`}>Pastry</li>
                                <li onClick={()=>{HandleTabSwicth(3)}} className={`${ActiveTab[3] ? classes.active_tab : ''}`}>Merchandise</li>
                            </div>
                            

                            <div className={`${classes.filter_page_wrapper}`}>
                                <div className={`${classes.filter_input}`}>
                                    <input onChange={FilterByName} type="text" placeholder='Filter by Name' />
                                    <span className={`${classes.filter_icon}`}><FontAwesomeIcon icon={faFilter}></FontAwesomeIcon></span>
                                </div>
                            </div>
                        </div>

                            <div className={`${InfoBoxOpen ? classes.cash_collection_report_back : classes.cash_collection_report_back_hide} ${classes.cash_collection_report}`}>
                                <div className={`${classes.collection_header}`}>
                                    <h2>Collection Box</h2>
                                    <span onClick={ToggleInfoBox} className={`${classes.info_box_action}`}>
                                        <FontAwesomeIcon icon={InfoBoxOpen ? faAnglesDown : faAnglesUp }></FontAwesomeIcon>
                                    </span>
                                </div>

                                <div className={`${classes.cash_related_wrapper}`}>
                                    <div className={`${classes.cash_related}`}>
                                        
                                        <input onChange={(e)=>{SetDailyCollectedAmount(parseInt(e.target.value))}} type="number" min={0} placeholder='Daily Collected Amount' />
                                        <input onChange={(e)=>{SetPittyCashExpense(parseInt(e.target.value))}} type="number" min={0} placeholder='Pitty Cash Expense. If Any?' />
                                        <input onChange={(e)=>{SetInitialAmount(parseInt(e.target.value))}} type="number" min={0} placeholder='Initial Amount. If Any?' />

                                    </div>
                                </div>

                                <div className={`${classes.collection_list}`}>
                                    { Bakery_ReadbackBuffer.map((item, index) => (
                                        
                                        <ReadbackBufferPreview key={index} BufferData={item}/>

                                    )) }

                                    { Pastry_ReadbackBuffer.map((item, index) => (
                                        
                                        <ReadbackBufferPreview key={index} BufferData={item}/>

                                    )) }

                                    { Merch_ReadbackBuffer.map((item, index) => (
                                        
                                        <ReadbackBufferPreview key={index} BufferData={item}/>

                                    )) }
                                </div>
                                        
                                <div className={`${InfoBoxOpen ? classes.action_collection_box_wrapper : classes.action_collection_box_wrapper_hide}`}>
                                    <div className={`${classes.action_collection_box}`}>
                                            <button onClick={OnReportPreview} className={`${classes.preview_collection}`}><span className={`${classes.icon_wrap}`}><FontAwesomeIcon icon={faSquarePollVertical}></FontAwesomeIcon></span> Preview Report</button>
                                            <button onClick={SubmitCashCollection} className={`${classes.finish_collection}`}><span className={`${classes.icon_wrap}`}><FontAwesomeIcon icon={faGavel}></FontAwesomeIcon></span> Finish Collection</button>
                                    </div>
                                </div>

                            </div>

                            <div className={`${classes.item_list_parent}`}>
                                    <div className={`${classes.item_list_wrapper}`}>

                                            <div className={`${classes.item_list}`}>

                                            {TodayProduction.map((item, index) => {

                                                const isInTodayProduction = FilteredProcductionItem.some(todayItem => todayItem.ItemName === item.ItemName);
                                                
                                                // Render CC_ProductItem only if it's found in TodayProduction
                                                if (isInTodayProduction) {
                                                    return (
                                                        <CC_ProductItem
                                                            key={index}
                                                            Data={item}
                                                            DATA_BUFFER_UPDATE_CALLACBK={BakeryReadbackBufferUpdateCallback}
                                                        />
                                                    );
                                                } 
                                            })}

                                            { 
                                            (TabString === "" || TabString === "Pastry") &&
                                                PastryProducts.map((item, idx) => {

                                                        const __exist = FilteredPastryProducts.some(pp => pp.ItemName === item.ItemName);

                                                        if(__exist)
                                                         {
                                                            return (
                                                                <ProductPastryItem
                                                                key={idx}
                                                                Data={item}
                                                                DATA_BUFFER_UPDATE_CALLACBK={PastryReadbackBufferUpdateCallback}
                                                                />
                                                            )
                                                         }
                                                })
                                            }

                                            {
                                                (TabString === "" || TabString === "Merchandise") &&
                                                MerchProducts.map((item, idx) => {
                                                    const __exist = FiltredMerchProducts.some(mp => mp.ItemName === item.ItemName);
                                                    if(__exist) 
                                                    {
                                                        return (
                                                            <ProductMerchItem
                                                            key={idx}
                                                            Data={item}
                                                            DATA_BUFFER_UPDATE_CALLACBK={MerchReadbackBufferUpdateCallback}
                                                            />
                                                        )
                                                    }
                                                    })
                                            }

                                            </div>

                                            <div className={`${FilteredProcductionItem.length === 0 && FiltredMerchProducts.length === 0 && FilteredPastryProducts.length === 0? classes.empty_reminder_show : classes.empty_reminder_hide } ${classes.empty_reminder}`}>
                                                        <h1>NO {TabString} DATA</h1>
                                            </div>
                                    </div>
                            </div>

                    </div>
            </div>

        </div>
    )
}