import classes from './ChefsProductionStackCard.module.css'
import TimeElapsed from '../../../../../SharedComp/TimeElapsed/TimeElapsed'
import { useEffect, useState } from 'react'
import { formatDistanceToNow } from 'date-fns';

import TimeAgo from '../../../../../SharedComp/TimeElapsed/TimeAgo';

import {FinishChefsItemProduction, StartChefsItemProduction} from '../../../../../SharedComp/API/CK/AP_CenteralKicthenChef'
import { useToast } from '../../../../../ToastContext';


export default function ChefsProductionStackCard({TargetData, DataRef}) 
{
    const [IsStarted, SetIsStarted] = useState(TargetData?.CKPRBUF_Status === "Started");
    const notify = useToast();


    useEffect(()=>{
        SetIsStarted(TargetData?.CKPRBUF_Status === "Started");
    }, [TargetData])


    const HandleFinishProduct = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("workspace_id", TargetData.CKPRBUF_WorkshopID);
            fd.append("chef_id", TargetData.CKPRBUF_ChefID);
            fd.append("group_hash", TargetData.CKPRBUF_SeprateByRequestGroupHash);
            fd.append("prod_id", TargetData.CKPRBUF_CkProdID);
            
            const main_res = await FinishChefsItemProduction(fd, 'token');
            notify.ShowSuccess(main_res.Msg);
            DataRef();
        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }
    const HandleStartProduct = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("workspace_id", TargetData.CKPRBUF_WorkshopID);
            fd.append("chef_id", TargetData.CKPRBUF_ChefID);
            fd.append("group_hash", TargetData.CKPRBUF_SeprateByRequestGroupHash);
            fd.append("prod_id", TargetData.CKPRBUF_CkProdID);
            
            const main_res = await StartChefsItemProduction(fd, 'token');
            notify.ShowSuccess(main_res.Msg);
            DataRef();
        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }


    return (
        <div className={`${classes.chefs_production_stack_card} ${IsStarted ? classes.chefs_production_stack_card_started : ''}`}>

            
            <div className={`${classes.chef_prod_name}`}>
                <h3>{TargetData.CKP_ItemName}</h3>
                <li>{TargetData.KV_TypeName}</li>
            </div>

            <div className={`${classes.prod_detail}`}>
                <li className={`${classes.expectation_tag}`}>Expected</li>
                <li><span className={`${classes.expenct_amount}`}>{TargetData.CKPRBUF_Quantity} Sheet,</span> <span className={`${classes.expenct_amount}`}>{TargetData.KV_SliceCount * TargetData.CKPRBUF_Quantity} Slice</span> </li>
            </div>

            <div className={`${classes.card_right_data}`}>
                {/* <li>Sheet Count: {TargetData.CKPRBUF_Quantity}</li> */}
                <li>Time: {IsStarted ?  <TimeAgo date={TargetData.CKPRBUF_StartTime}/> : "NOT STARTED YET"}</li>
            </div>

            
            <div className={`${classes.action_button}`}>
                <button onClick={()=>{IsStarted ? HandleFinishProduct() : HandleStartProduct()}} className={`${IsStarted ? classes.button_action_started : ''}`}>{IsStarted ? "Finish" : "Get started"}</button>
               {IsStarted && <button onClick={()=>{IsStarted ? HandleFinishProduct() : HandleStartProduct()}} className={`${classes.button_action_report_damage}`}>Report Damage</button>} 
            </div>
        </div>
    )
}