import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import classes from './CreditAction.module.css'
import { useRef, useState } from 'react';
import axios from 'axios';
import React from 'react';

import MasterDataRegInput from '../../../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import UIButton from '../../../../../../Components/UIButton/UIButton';
import { CheckCircle } from '@phosphor-icons/react';


export default function CreditAction({Open, OpenController, Operation, EmpData, creditID, MsgTrigger}) 
{

    const [creditData, SetCreditData] = useState();
    const [registring, SetRegistring] = useState(false);
    const CreditFormRef = useRef();

    
    function HandleInputChange(e) 
    {
        SetCreditData(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    function HandleCreditSubmit(event) 
    {
        SetRegistring(true); 
        event.preventDefault();
        
        setTimeout(() => {

            const fd = new FormData();

            const isCollecting =  Operation === "Collect";
            const url = Operation === "Collect" ? 'http://localhost:3333/employee/create_employee_credit_hist/' : 'http://localhost:3333/employee/create_employee_credit/'; 

            if(isCollecting) 
            {
                fd.append("credit_id", creditID);
            }
            fd.append("credit_amount", creditData.credit_amount);
            fd.append("employee_id", EmpData.EmployeeID);
            fd.append("credit_remark", creditData.credit_remark);
            fd.append("credit_date", creditData.credit_date);
            fd.append("EmployeeID", EmpData.EmployeeID);
            
            
            
            axios.post(url, fd, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    })
                .then(res => {
                    CreditFormRef.current.reset();
                    SetRegistring(false);
                    MsgTrigger(res);
                    OpenController(false);
                    
                    
                })
                .catch(err => {
                    CreditFormRef.current.reset();
                    SetRegistring(false);
                    MsgTrigger(err);
                    OpenController(false);
                    
                })
            }, 2000)


        
    }
    return (
        <div className={`${Open ? classes.main_block_back : ''}`}>
            <div className={`${Open ? classes.credit_action_main_wrapper : classes.credit_action_main_wrapper_hide}`}>
                        
                        <div className={`${classes.cred_win_header}`}>
                            <li>Credit - {Operation}</li>
                            <button onClick={() => {OpenController(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
                        </div> 

                            <form ref={CreditFormRef} onSubmit={HandleCreditSubmit} action="POST">
                                    <div  className={`${classes.credit_inner_input_wrapper}`}>
                                            <div className={`${classes.credit_input_main}`}>
                                                <MasterDataRegInput
                                                    input={<input min={1} onChange={HandleInputChange} name='credit_amount' type="number" placeholder='Amount' required/>}
                                                />
                                            </div>
                                            <div className={`${classes.credit_input_main}`}>
                                                <MasterDataRegInput
                                                    input={<input onChange={HandleInputChange} name='credit_remark' type="text" autoComplete='off' placeholder='Remark' />}
                                                />
                                                
                                            </div>
                                            <div className={`${classes.credit_input_main}`}>
                                                <MasterDataRegInput
                                                    input={<input onChange={HandleInputChange} name='credit_date' type="date"/>}
                                                />
                                            
                                            </div>

                                            <div className={`${classes.credit_input_submit_action_button}`}>
                                                <UIButton Variant={registring ? "Disabled" : "Secondary"} onClick={()=>{}} Icon={<CheckCircle/>} label={registring ? "Processing..." : Operation} />
                                            </div>
                                    </div>
                            </form>

                    </div>
        </div>
        
    );
}