import { useEffect, useState } from 'react'
import classes from './RecipeCard.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TimeElapsed from '../../../../../SharedComp/TimeElapsed/TimeElapsed'
import { faChevronCircleDown, faChevronCircleUp, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'


import {QueryVariantIng, CreateChefsStockRequestFromRecipe} from '../../../../../SharedComp/API/CK/AP_CenteralKicthenChef'

import { useAuth } from '../../../../../AuthProvider'
import { useToast } from '../../../../../ToastContext' 
const currency_format = (val)=> 
{
    return val.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}
export default function RecipeCard({TargetData, DataRef})
{

    const [IngList, SetIngList] = useState([]);
    const [InvalidIngMap, SetInvalidIngMap] = useState(new Map());
    const [ScanStockBuffer, SetScanStockBuffer] = useState(false);
    const [AutoStartAfterAuth, SetAutoStartAfterAuth] = useState(true);
    const [ShowDetailCardInfo, SetShowDetailCardInfo] = useState(false);
    const [ActiveSheetCount, SetActiveSheetCount] = useState(1);

    const auth = useAuth();
    const notify = useToast();


    const QueryIng = async()=>
    {
        try 
        {
            const main_res = await QueryVariantIng(TargetData.CKP_ItemVarianceID);
            SetIngList(main_res);

        }catch(err) 
        {

        }
    }
    const HandleIncreaseActveSheet = ()=>
    {
        if(ActiveSheetCount < TargetData.CKDPC_RemainQuantity) 
        {
            SetActiveSheetCount(ActiveSheetCount + 1);
        }
    }
    const HandleDecreaseActveSheet = ()=>
    {
        if(ActiveSheetCount > 1) 
        {
            SetActiveSheetCount(ActiveSheetCount - 1);
        }
    }

    const HandleStockRequest = async()=>
    {
        try 
        {

            const fd = new FormData();
            fd.append("Ing_list", JSON.stringify(IngList));
            fd.append("Rejected_Ing_List", JSON.stringify(Array.from(InvalidIngMap.entries())));
            fd.append("scan_local_buffer", ScanStockBuffer);
            fd.append("auto_order", AutoStartAfterAuth);
            fd.append("sheet_count", ActiveSheetCount);
            fd.append("handler_id", auth.user.UserAccountID);
            fd.append("handler_workshop", auth.activeWorkShop);
            fd.append("order_prod_hash", TargetData.CKDPC_OrderProdHash);
            fd.append("prod_ck_id", TargetData.CKDPC_ProdCKID); 
            fd.append("dispatch_hash", TargetData.CKDPC_DispatchHash); 
            fd.append("active_order_group_id", TargetData.CKDPC_ActiveOrderGroupID);
            fd.append("distination_workspace", TargetData.CKDPC_DestinationWorkspaceID);

            const main_res = await CreateChefsStockRequestFromRecipe(fd, 'token');

            notify.ShowSuccess(main_res.Msg);
            DataRef();

        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }

    

    const HandleToggelInvalidation = (item)=>
    {
        const _exist  = InvalidIngMap;
        if(_exist.has(item.IVItemName)) 
        {
            _exist.delete(item.IVItemName);
        } else {
            _exist.set(item.IVItemName, item)
        }

        SetInvalidIngMap(new Map(_exist));
    }

    const IsInvalid = (__key)=>
    {
        return InvalidIngMap.has(__key);
    }

    useEffect(()=>{
        QueryIng();
    }, [])

    return (
        <div className={`${classes.recipe_card_main_wrapper}`}>

            <div className={`${classes.recipe_card_header}`}>
                <h2><sub>Recipe Card</sub> {TargetData.CKP_ItemName} </h2>
            </div>

            <div className={`${classes.recipe_card_ing_list}`}>

                {
                    IngList.map((item, idx)=>
                    (
                        <li onClick={()=>{HandleToggelInvalidation(item)}} key={idx} className={`${IsInvalid(item.IVItemName) ? classes.ban_stock_out : ''}`}>
                            <span className={`${classes.recipe_card_tag}`}>{item.IVItemName}</span>
                            <span className={`${classes.recipe_card_val}`}>
                                {
                                   item.UomDescription === "KG" ?  currency_format((item.KV_InvQuantity * 1000) * ActiveSheetCount) 
                                    : currency_format(item.KV_InvQuantity * ActiveSheetCount)
                                } {item.UomDescription === "KG" ? "Gram" : item.UomDescription} </span>
                        </li>
                    ))
                }
                    
            </div>

            <div className={`${classes.crad_action_wrapper}`}>
                <div className={`${classes.show_beh}`}>
                    <button onClick={()=>{SetShowDetailCardInfo(!ShowDetailCardInfo)}}>Show {ShowDetailCardInfo ? "Less" : "More"}</button>
                </div>

                <div className={`${ShowDetailCardInfo ? classes.sheet_count : classes.sheet_count_hide}`}>
                    <li onClick={()=>{SetAutoStartAfterAuth(!AutoStartAfterAuth)}} className={`${classes.scan_local_buffer}`}><span>Auto Start When Ready</span> <span className={`${classes.sheet_tag_input}`}> <input onChange={()=>{}} checked={AutoStartAfterAuth} type="checkbox" /> </span></li>
                    <li onClick={()=>{SetScanStockBuffer(!ScanStockBuffer)}} className={`${classes.scan_local_buffer}`}><span>Scan Stock Buffer</span> <span className={`${classes.sheet_tag_input}`}> <input onChange={()=>{}} checked={ScanStockBuffer} type="checkbox" /> </span></li>
                    <li><span>Sheet Count</span> <span className={`${classes.sheet_tag_val}`}>{TargetData.CKDPC_RemainQuantity} Pcs</span></li>
                    <li><span>Dispatcher</span> <span className={`${classes.sheet_tag_val}`}>{TargetData.FirstName} {TargetData.LastName}</span></li>
                    <li><span>Time</span> <span className={`${classes.sheet_tag_val}`}><TimeElapsed Time={TargetData.CKDPC_DispatcheDateTime}/></span></li>
                </div>
                <div className={`${classes.card_action_button}`}>
                    <li> 
                            <span onClick={()=>{HandleIncreaseActveSheet()}} className={`${classes.sheet_amount_manip}`}><FontAwesomeIcon icon={faChevronCircleUp}/></span> 
                            <span className={`${classes.sheet_amount_active}`}>{ActiveSheetCount}</span> 
                            <span onClick={()=>{HandleDecreaseActveSheet()}} className={`${classes.sheet_amount_manip}`}><FontAwesomeIcon icon={faChevronCircleDown}/></span></li>
                    {/* <button><FontAwesomeIcon icon={faCubes}/> Process All</button> */}
                    <button onClick={()=>{HandleStockRequest()}}><FontAwesomeIcon icon={faQuestionCircle}/> Request Stock</button>
                </div>
            </div>

        </div>
    )
}