import classes from './ProductionOrder.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useEffect, useState } from 'react';

import axios from 'axios';
import { io } from 'socket.io-client';
import { faArrowRightLong, faCheckCircle, faCheckDouble, faCheckToSlot, faChevronDown, faChevronUp, faCircleStop, faCloud, faCopy, faFolder, faHeartCircleBolt, faPersonChalkboard, faQrcode, faTrash, faWaveSquare } from '@fortawesome/free-solid-svg-icons';

import CountdownTimer from '../../SharedComp/CountDown/CountDownTimer';
import TimeElapsed from '../../SharedComp/TimeElapsed/TimeElapsed';
import CreatePO from './Component/CreatePO/CreatePO';
import QRCodeReader from '../../SharedComp/QRCodeReader/QRCodeReader';

import NotificationPopUp from '../../SharedComp/NotificationPopUp/NotificationPopUp';
import CreateOrderShadow from './Component/CreateOrderShadow/CreateOrderShadow';
import DecisionAssurance from '../../SharedComp/DecisionAssurance/DecisionAssurance';

import { Tooltip } from 'react-tooltip';
import { getBaseURL } from '../../SharedComp/BaseUrlConfig';

import ProductShelfCard from './Component/ProductShelfCard/ProductShelfCard';
import OrderSavedCard from './Component/OrderSavedCard/OrderSavedCard';


import InitProductionOrder from './Component/InitProductionOrder/InitProductionOrder';
import DirectProductionOrder from './Component/DirectProductionOrder/DirectProductionOrder';

import {FetchProductByBranchOrderable, FetchYamMixOrderableBranch} from '../../api';
import {FetchSavedProductionOrderConfig} from '../../SharedComp/API/Production/API_ProductionOrder'

import { useAuth } from '../../AuthProvider';
import { useToast } from '../../ToastContext';
import { faSquare } from '@fortawesome/free-regular-svg-icons';




// const socket = io.connect("http://localhost:3333");

export default function() 
{

    const auth = useAuth();
    const notify = useToast();
    const [Test, SetTest] = useState([1, 2, 3, 4]);




    // New Start
    const [BaseValidProductList, SetBaseValidProductList] = useState([]);
    const [BaseValidMixList, SetBaseValidMixList] = useState([]);

    const [ValidProductList, SetValidProductList] = useState([]);
    const [ValidMixList, SetValidMixList] = useState([]);
    const [BatchProcess, SetBatchProcess] = useState(false);

    const [OrderTarget, SetOrderTarget] = useState([]);
    const [OrderTargetMix, SetOrderTargetMix] = useState([]);
    const [SavedOrderList, SetSavedOrderList] = useState([]);

    const[OpenCreateOrder, SetOpenCreateOrder] = useState(false);
    const[OpenDirectOrder, SetOpenDirectOrder] = useState(false);
    const[TargetOrderHash, SetTargetOrderHash] = useState();

    const [ShowSaved, SetShowSaved] = useState(false);
    const [RefreshData, SetRefreshData] = useState(false);
    // New End
    

    // New Function Start

    const FetchSavedPO = async() => 
    {
        try 
        {
            const fd = new FormData();
            fd.append('workspace_id', auth.activeWorkShop);
            fd.append('user_id', auth.user.UserAccountID);
            const res = await FetchSavedProductionOrderConfig(fd, auth.token);
            SetSavedOrderList(res);

        }catch(err) 
        {
            console.log(err);
        }
    } 

    const FetchProduct = async() => 
    {
        try 
        {
            const fd = new FormData();
            fd.append('workspace_id', auth.activeWorkShop);
            const res = await FetchProductByBranchOrderable(fd, auth.token);
            SetValidProductList(res);
            SetBaseValidProductList(res);

        }catch(err) 
        {
            console.log(err);
        }
    }
    const FetchMix = async()=> 
    {
        try 
        {
            const res = await FetchYamMixOrderableBranch(auth.activeWorkShop, auth.token);
            if(res.length > 0)
            {
                SetValidMixList(res);
                SetBaseValidMixList(res);
            }
        }catch(err) 
        {
            console.log(err);
        }
    }
    const FetchData = async()=> 
    {
        try 
        {
            FetchProduct();
            FetchMix();
            FetchSavedPO();
        }catch(err) 
        {
            console.log(err);
        }
    }
    function HandleCreateModalOpen(data) 
    {
        const existing = [];
        existing.push({ ID: 0, Data: data });
        SetOrderTarget(existing);
        SetOpenCreateOrder(true);
    }
    function HandleCreateModelOpenSelect(data, idx) 
    {
        const existing = Array.isArray(OrderTarget) ? [...OrderTarget] : [];
        const itemIndex = existing.findIndex(item => item.ID === idx);

        if (itemIndex > -1) {
            existing.splice(itemIndex, 1);
            SetOrderTarget(existing)
            return false;
        } else {
            existing.push({ ID: idx, Data: data });
            SetOrderTarget(existing)
            return true;
        }

        ;
    }
    function HandleCreateModalOpen_MIX(data) 
    {
        const existing = [];
        existing.push({ ID: 0, Data: data });
        SetOrderTargetMix(existing);
        SetOpenCreateOrder(true);
    }
    function HandleCreateModelOpenSelect_MIX(data, idx) 
    {
        const existing = Array.isArray(OrderTargetMix) ? [...OrderTargetMix] : [];
        const itemIndex = existing.findIndex(item => item.ID === idx);

        if (itemIndex > -1) {
            existing.splice(itemIndex, 1);
            SetOrderTargetMix(existing);
            return false;
        } else {
            existing.push({ ID: idx, Data: data });
            SetOrderTargetMix(existing);
            return true;
        }
    }
    function IsSelected(search_key, is_mix) 
    {
        const iter = is_mix ? OrderTargetMix : OrderTarget;
        for(let i = 0; i < iter.length; i++) 
        {
            const _my_key = is_mix ? iter[i].Data.MixName : iter[i].Data.FProdName;
            if(_my_key.toLowerCase() === search_key.toLowerCase())
            {
                return true;
            }
        }
        return false;
    }
    function PrepMultiSelect() 
    {
        SetOrderTargetMix([]);
        SetOrderTarget([]);
        SetBatchProcess(!BatchProcess);
    }
    useEffect(()=>{
        FetchData();
    }, [RefreshData]);

    function CloseClean() 
    {
        PrepMultiSelect();
        SetOpenCreateOrder(false); 
        SetOpenDirectOrder(false);
        SetRefreshData(!RefreshData);
    }

    const FilterView = async(filter_value)=>
    {
        SetValidProductList([]);
        SetValidMixList([]);
        const prod_main_exist = [];
        for(let i = 0; i < BaseValidProductList.length; i++) 
        {
            if(BaseValidProductList[i].FProdName.toLowerCase().includes(filter_value.toLowerCase()))  
            {
                prod_main_exist.push(BaseValidProductList[i])
            }
        }
        const prod_mix_exist = [];
        for(let i = 0; i < BaseValidMixList.length; i++) 
        {
            if(BaseValidMixList[i].MixName.toLowerCase().includes(filter_value.toLowerCase()))  
            {
                prod_mix_exist.push(BaseValidMixList[i]);
            }
        }

        SetValidProductList(prod_main_exist);
        SetValidMixList(prod_mix_exist);
    }

    const HandleDirectOrder = async(order_pkg)=>{
        SetTargetOrderHash(order_pkg);
        SetOpenDirectOrder(true);
    }
    return ( 

        <div className={`${classes.po_main_wrapper}`}>
            <Tooltip className={`${classes.tool_quick}`} id="offical_doc" place="top" />

            {OpenCreateOrder && <InitProductionOrder DataMix={OrderTargetMix} Data={OrderTarget} CloseCleanCALLBACK={CloseClean} CloseModal={SetOpenCreateOrder}/>}
            {OpenDirectOrder && <DirectProductionOrder Target={TargetOrderHash} CloseCleanCALLBACK={CloseClean} CloseModal={SetOpenDirectOrder}/>}

            <div className={`${classes.list_wrapper_with_action_but}`}>
                <div className={`${classes.action_button_wrapper_po}`}>
                    <div className={`${classes.order_button_group_left}`}>
                        {(OrderTarget.length > 0 || OrderTargetMix.length > 0) && BatchProcess && <button className={`${classes.process_batch}`} onClick={()=>{SetOpenCreateOrder(true);}}><FontAwesomeIcon icon={faCheckCircle}/> Process</button>}
                      {!ShowSaved &&  <button onClick={()=>{PrepMultiSelect()}}><FontAwesomeIcon icon={BatchProcess ? faCircleStop : faCheckToSlot}/> {BatchProcess ? 'Void' : 'Select'}</button>} 
                    </div>
                    <div className={`${classes.order_button_group_right}`}>
                        <button {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': "View Requested Orders!"})} onClick={()=>{}}><FontAwesomeIcon icon={faPersonChalkboard}/> {"My Orders"}</button>
                        <button onClick={()=>{SetShowSaved(!ShowSaved)}}><FontAwesomeIcon icon={!ShowSaved ? faFolder : faWaveSquare}/> {ShowSaved ? " Real" : " Saved"}</button>
                        <div className={`${classes.prod_order_filter_input_wrapper}`}>
                            <input onChange={(e)=>{FilterView(e.target.value)}} type="text" placeholder='Search Name..' />
                        </div>
                    </div>
                    
                </div>
                {!ShowSaved && <div className={`${classes.list_wrapper}`}>
                    {
                        ValidProductList.map((item, idx)=>(
                            <ProductShelfCard 
                                key={item.FProdName} 
                                IsMix={false} 
                                ID={idx} 
                                Selected_CALLBACK={IsSelected(item.FProdName, false)} 
                                BatchMode={BatchProcess} 
                                OnClick={BatchProcess ? HandleCreateModelOpenSelect : HandleCreateModalOpen} 
                                Data={item} /> 
                        )) 
                    }
                    {
                        ValidMixList.map((item, idx)=>(
                                <ProductShelfCard 
                                    key={item.MixName}
                                    IsMix={true} 
                                    ID={idx} 
                                    Selected_CALLBACK={IsSelected(item.MixName, true)} 
                                    BatchMode={BatchProcess} 
                                    OnClick={BatchProcess ? HandleCreateModelOpenSelect_MIX : HandleCreateModalOpen_MIX}  
                                    Data={item} /> 
                        ))
                    }
                </div>  }
               {ShowSaved &&  <div className={`${classes.view_saved_list_item}`}>

                    {
                        SavedOrderList.map((item,idx)=>(
                            <OrderSavedCard key={idx} Handle_CALLBACK={HandleDirectOrder} Data={item} />
                        ))
                    }

                    {
                        SavedOrderList.length === 0 &&
                        <div className={`${classes.empty_saved_list}`}>
                            <li className={`${classes.empty_icon}`}><FontAwesomeIcon icon={faCloud}></FontAwesomeIcon></li>
                            <li>You don't have any saved or shared Order config.</li>
                        </div>
                    }

                </div>}
            </div>

        </div>

    );
}