import classes from './GifStyle.module.css'


export default function HillCubeLoaderGif()
{
    return (
        <div className={`${classes.loader}`}>
            <div className={`${classes.box}`}></div>
            <div className={`${classes.hill}`}></div>
        </div>
    )
}