import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faArrowRight, faArrowRightLong, faArrowRotateBack, faCircleArrowDown, faClockRotateLeft, faDollarSign, faPenNib, faSackDollar, faShieldHalved, faTrash, faTurnDown, faTurnUp, faUserPen, faUserShield } from '@fortawesome/free-solid-svg-icons';
import classes from './EmpPenality.module.css'

import PenalityAction from './Component/PenalityAction/PenalityAction';
import DecisionAssurance from '../../../../../SharedComp/DecisionAssurance/DecisionAssurance';

import { useEffect, useState } from 'react';

import { Tooltip } from 'react-tooltip';
import axios from 'axios';
import UIButton from '../../../../Components/UIButton/UIButton';
import { Money, UserCircleGear } from '@phosphor-icons/react';
import { getBaseURL } from '../../../../../SharedComp/BaseUrlConfig';

 
export default function EmpPenality({MsgTrigger, EmpData, ReqRefresh}) 
{
    const [OpenPenalityAction, SetOpenPenalityAction] = useState(false);
    const [actionType, SetActionType] = useState(null);

    const [EmpPenality, SetEmpPenlity] = useState([]);
    const [EmpPenalityLength, SetEmpPenalityLength] = useState();

    const [DemotionHist, SetDemotionHist] = useState([]);
    const [DemotionHistLength, SetDemotionHistLength] = useState();

    // Decision Assurance
    const [daState, SetDSAState] = useState(false);
    const [agreement, SetAgreement] = useState();
    const [reqCallbackData, SetReqCallbackData] = useState();
    const [justDeleted, SetJustDeleted] = useState(false);
    const [ActionName, setActionName] = useState(null);
 
    // data for update
    const [CashPenalityData, SetCashPenalityData] = useState(null);

    // Penality Filter
    const [FilterCashPenality, setFilterCashPenality] = useState(true);
    const [cashPenalityTip, setCashPenalityTip] = useState("Currently inspecting.... Cash Related Penality");
    const [FilterOtherPenality, setFilterOtherPenality] = useState(false);
    const [otherPenalityTip, setOtherPenalityTip] = useState("Click to see non-cash penality only.");

    useEffect(() => {
            if(FilterCashPenality) 
            {
                ToggelFilterCashOnly(); 
            } else if(FilterOtherPenality) 
            {
                ToggelFilterOtherOnly();
            }

            fetch(getBaseURL() + 'employee/query_employee_demotion_penality/', { 
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ EmpID: EmpData.EmployeeID }),
          })
            .then(res => res.json())
            .then((data) => {SetDemotionHist(data); SetDemotionHistLength(data.length); ReqRefresh()});

    }, [OpenPenalityAction, justDeleted])

    function ToggelFilterCashOnly() 
    {
        setFilterOtherPenality(false);
        setFilterCashPenality(true);

        setCashPenalityTip("Currently inspecting... Cash Related Penality");
        setOtherPenalityTip("Click to see non-cash penality only.");
        SetEmpPenlity([]);
        fetch(getBaseURL() + 'employee/query_employee_cash_penality/', { 
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ EmpID: EmpData.EmployeeID }),
          })
            .then(res => res.json())
            .then((data) => {SetEmpPenlity(data); SetEmpPenalityLength(data.length)});
    }
    function ToggelFilterOtherOnly() 
    {
        setFilterCashPenality(false);
        setFilterOtherPenality(true);

        setOtherPenalityTip("Currently inspecting... Non-Cash Related Penality");
        setCashPenalityTip("Click to see cash related penality only.");
        SetEmpPenlity([]);
        fetch(getBaseURL() + 'employee/query_employee_other_penality/', { 
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ EmpID: EmpData.EmployeeID }),
          })
            .then(res => res.json())
            .then((data) => {SetEmpPenlity(data); SetEmpPenalityLength(data.length)});
    }

    function InitiateRequestRemoval(id, name) 
    {
        SetAgreement(`You're about to delete the penality record for ${name}. Confirm this action?`);
        SetReqCallbackData(id);
        SetDSAState(true);
        setActionName("Delete Penality")
    }
    function InitiateRequestRemovalOtherPenality(id, name) 
    {
        SetAgreement(`You're about to pardon employee from ## ${name} ## penality. Are you sure bra?`);
        SetReqCallbackData(id);
        SetDSAState(true);
        setActionName("Pardon Penality")
    }
    function InitiateRequestReversal(id, name) 
    {
        SetAgreement(`You're about to reverse the penality record for ${name}. Remember, this will be stored as history of the employee. Confirm this action?`);
        SetReqCallbackData(id);
        SetDSAState(true);
        setActionName("Penality Reversal")
    }
    function InitiateRequestRemote(id, name) 
    {
        SetAgreement(`You're about to reverse the demotion of ## ${name} ## This will discard any actions taken below the ## ${name} ## Rating. Confirm this action?`);
        SetReqCallbackData(id);
        SetDSAState(true);
        setActionName("Demotion Reversal")
    }
    function HandleDAResponse(id) 
    {
        if(id === null) 
        {
            return;
        }

        if(ActionName === "Delete Penality") 
        {

            axios.delete(getBaseURL() + 'employee/purg_employee_cash_penality/', {
            headers: {
                'Content-Type': 'application/json',
            },
            data: { ID: id },
            })
            .then(response => {
                MsgTrigger(response);
                SetJustDeleted(!justDeleted);
            })
            .catch(error => console.error('Error purging employee penality:', error));

            return;
        } else if(ActionName === "Demotion Reversal") 
        {
            axios.delete(getBaseURL() + 'employee/reverse_demotion_employee_penality/', {
            headers: {
                'Content-Type': 'application/json',
            },
            data: { ID: id },
            })
            .then(response => {
                MsgTrigger(response);
                SetJustDeleted(!justDeleted);
            })
            .catch(error => console.error('Error reversing employee demotion penality:', error));

            return;
        } else if(ActionName === "Pardon Penality") 
        {
            axios.delete(getBaseURL() + 'employee/remove_other_employee_penality/', {
            headers: {
                'Content-Type': 'application/json',
            },
            data: { EmpID: id },
            })
            .then(response => {
                MsgTrigger(response);
                SetJustDeleted(!justDeleted);
            })
            .catch(error => console.error('Error pardoning employee penality:', error));

            return;
        }
        
        

    }

    function OnUpdatePenality(data) 
    {
        SetOpenPenalityAction(true); 
        SetActionType("Update Cash Penality");
        SetCashPenalityData(data);
    }

    return (
        <div className={`${classes.emp_penality_main_wrapper}`}>
            {/* <Tooltip className={`${classes.tool_quick}`} id="offical_doc" place="top" /> */}

            {daState && 
                <DecisionAssurance  
                    ActionName={ActionName}
                    CallbackData={reqCallbackData} 
                    Msg={agreement} 
                    openController={SetDSAState} 
                    HandleResponse={HandleDAResponse}
                />
            }

            {OpenPenalityAction && <PenalityAction CashPenalityData={CashPenalityData} MsgTrigger={MsgTrigger} EmpData={EmpData} Open={OpenPenalityAction} OpenController={SetOpenPenalityAction} ActionType={actionType} />}

            <div className={`${classes.emp_local_penality_nav}`}>
                <button onClick={() => {SetOpenPenalityAction(true); SetActionType("Demote");}} >Demote</button>
                <button onClick={() => {SetOpenPenalityAction(true); SetActionType("Cash Penality");}}>Cash Penality</button>
                <button onClick={() => {SetOpenPenalityAction(true); SetActionType("Other Penality");}} >Other Penality</button>
            </div>

            <div className={`${classes.emp_penality_inner_wrapper}`}>

                <div className={`${classes.emp_penality_inner_content}`}>
                    <div className={`${classes.emp_penality_inner_content_wrapper}`}>
                        <div className={`${classes.emp_cash_penality_history}`}>

                            <div className={`${classes.emp_penality_demotion_header}`}>
                                <div>
                                    <h2>Penality History</h2>
                                </div>
                                <div className={`${classes.emp_penality_demotion_header_action}`}>
                                    <UIButton Icon={<Money weight='bold'/>} Variant={FilterCashPenality ? "Primary" : "Secondary"} onClick={() => {ToggelFilterCashOnly()}} label={FilterCashPenality ? "Cash Related" : "Show Cash Related"}/>
                                    <UIButton Icon={<UserCircleGear weight='bold'/>} Variant={!FilterCashPenality ? "Primary" : "Secondary"} onClick={() => {ToggelFilterOtherOnly()}} label={!FilterCashPenality ? "Other Penality" : "Show Other Penality"}/>
                                </div>
                            </div>

                            <div className={`${classes.emp_penality_list}`}>
                                { FilterCashPenality && 
                                    EmpPenality.map((item, index) => (

                                        <li {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': item.Detail})}
                                        key={index}>

                                            <span>
                                            {new Date(item.Date).toLocaleString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                                            </span>

                                            <span className={`${classes.emp_penality_cash_amount}`}>
                                               {
                                                (item.Amount).toLocaleString('en-US', {
                                                    style: 'currency',
                                                    currency: 'ETB',
                                                    })

                                                    
                                               }
                                            </span> 
                                            
                                            <span className={`${classes.penality_history_icon}`}>
                                                <button onClick={() => {OnUpdatePenality(item)}} {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': 'Edit Amount / Increase-Decrease'})} className={`${classes.penality_action_remote}`}> <FontAwesomeIcon icon={faPenNib}></FontAwesomeIcon></button>
                                                {/* <button onClick={() => {InitiateRequestReversal(item.EMPCPID, (item.Amount).toLocaleString('en-US', { style: 'currency', currency: 'ETB'}))}} {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': 'Remove Penality! Off the book.'})} {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': 'Reverse Penality! This action will be recorded as a history.'})} className={`${classes.penality_action_remote}`}> <FontAwesomeIcon icon={faArrowRotateBack}></FontAwesomeIcon></button> */}
                                                <button onClick={() => {InitiateRequestRemoval(item.EMPCPID, (item.Amount).toLocaleString('en-US', { style: 'currency', currency: 'ETB'}))}} {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': 'Remove Penality! Off the book.'})} className={`${classes.penality_action_remote}`}> <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></button>

                                            </span></li>
                                    ))
                                }

                                { FilterOtherPenality && 
                                    EmpPenality.map((item, index) => (

                                        <li {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': item.Detail})}
                                        key={index}>

                                            <span>
                                            {new Date(item.Date).toLocaleString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                                            </span>

                                            <span className={`${classes.emp_penality_type_other}`}>
                                               {item.PenalityType}
                                            </span> 
                                            
                                            <span className={`${classes.penality_history_icon}`}>

                                                <button onClick={() => {InitiateRequestRemovalOtherPenality(item.EMPOPID, item.PenalityType)}} {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': 'Pardon this Penality.'})} className={`${classes.penality_action_remote}`}> <FontAwesomeIcon icon={faUserShield}></FontAwesomeIcon></button>

                                            </span></li>
                                    ))
                                }
                            </div>

                        </div>
                    </div>
                </div> 

                <div className={`${classes.emp_penality_inner_content}`}>

                   <div className={`${classes.emp_penality_inner_content_wrapper}`}>

                        <div className={`${classes.emp_penality_demotion_history}`}>
                            <div className={`${classes.emp_penality_demotion_header}`}>
                                <h2>Demotion History</h2>
                            </div>

                            <div className={`${classes.emp_penality_list}`}>
                                {
                                    DemotionHist.map((item, index) => (
                                        <li {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': item.Reason})} key={index}>
                                            
                                            {new Date(item.Date).toLocaleString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}

                                            <span className={`${classes.emp_penality_demotion}`}>

                                                    <span>
                                                        {item.FromJob}

                                                    </span>
                                                    <span className={`${classes.emp_demotion_pointer}`}>
                                                        <FontAwesomeIcon icon={faTurnDown}></FontAwesomeIcon>
                                                    </span> 

                                                    <span>
                                                    {item.FromJob}

                                                    </span>

                                            </span> 

                                            <span className={`${classes.penality_history_icon}`}>
                                                <button onClick={() => {InitiateRequestRemote(item.EMPDSID, item.FromJob)}} {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': 'Reverse demotion'})} className={`${classes.penality_action_remote}`}><FontAwesomeIcon icon={faTurnUp}></FontAwesomeIcon></button>
                                            </span>
                                            
                                        </li>
                                        
                                    ))
                                }
                            </div>
                        </div>
                   </div>

                </div>

            </div>

        </div>
    );
}