import { useState } from 'react'
import classes from './ArchiveBranchGroup.module.css'
import { useEffect } from 'react';
import { useToast } from '../../../../ToastContext';

import {FetchBranchPreorderHistory} from '../../../../SharedComp/API/CK/AP_CenteraKitchenOrder'
import {QueryShippingReadyProducts} from '../../../../SharedComp/API/CK/AP_CenteraKitchenOrder'

import { Files, Folders, StackMinus } from '@phosphor-icons/react';
import TimeAgo from '../../../../SharedComp/TimeElapsed/TimeAgo';
import UIButton from '../../../Components/UIButton/UIButton';

import ShippingDialog from '../../ManageDistribution/Component/ShippingDialog/ShippingDialog';


export default function ArchiveBranchGroup({TargetData}) 
{

    const [GData, SetGData] = useState();
    const [DistorDataList, SetDistorDataList] = useState([]);
    const [SubProductList, SetSubProductList] = useState([]);
    const [RefreshView, SetRefreshView] = useState(false);
    const [OpenShippingDialog, SetOpenShippingDialog] = useState(false);

    const notify = useToast();

    const FetchDistributionData = async()=>
    {
        try 
        {

            const fd = new FormData();
            fd.append("processor_workshop_id", TargetData.BranchID);

            // Here Fetch resolved Production order
            const main_res = await FetchBranchPreorderHistory(fd, 'token');
            SetDistorDataList(main_res);

        }catch(err) 
        {
            console.log("Falied to Fetch Distribution Data: " + err.message);
            notify.ShowError(err.message);

        }
    }


    useEffect(()=>{

        FetchDistributionData();

    }, [])


    const FetchShipReadyProducts = async(Data) =>
    {
        try 
        {

            const fd = new FormData();
            fd.append("source_workspace_id", Data.CKAOG_SourceWorkspace);
            fd.append("destination_workspace_id", Data.CKAOG_DestinationWorkspace);
            fd.append("group_id", Data.CKAOGID);
            fd.append("targte_date", new Date());

            const main_res = await QueryShippingReadyProducts(fd, 'token'); 
            SetSubProductList(main_res);
        }catch(err) 
        {

        }
    }

    const HandleShowPreviewDoc = async(data)=>
    {
        SetGData(data);
        const fetch_res = await FetchShipReadyProducts(data);
        SetOpenShippingDialog(true);
    }


    return (
        <div className={`${classes.archive_branch_group_main_wrapper}`}>

            {OpenShippingDialog && <ShippingDialog Preview={true} UpdateViewToggel={RefreshView} UpdateView={SetRefreshView} DataList={SubProductList} GroupData={GData} Close_CALLBACK={SetOpenShippingDialog}/> }

            <div className={`${classes.archive_branch_group_header}`}>
                <h3> <span className={`${classes.distro_icon_header}`}><Folders weight='fill'/></span> {TargetData.BranchName} <span className={`${classes.distro_common_text}`}>Archive</span></h3>
            </div>

            <div className={`${classes.data_list_wrapper}`}>

                {
                    DistorDataList.map((item, idx)=>(
                        <li key={item.CKAOG_Hash + idx}>
                            <span>{new Date(item.CKAOG_OrderDateTime).toDateString()}</span>
                            <span><TimeAgo date={item.CKAOG_OrderDateTime} show_suffix={true} /></span>
                            <span className={`${classes.remain_time_to_purge}`}>{90 - (((((new Date() - new Date(new Date(item.CKAOG_OrderDateTime))) / 1000) / 60) / 60) / 24).toFixed(0)} Days Remain</span>
                            <span><UIButton onClick={()=>{HandleShowPreviewDoc(item)}} Variant='Secondary' label={"View Doc"} Icon={<Files weight='bold'/>}/></span>
                        </li>
                    ))
                }
                {
                    DistorDataList.length === 0 && 
                    <div className={`${classes.empty_his}`}>
                        <div className={`${classes.empty_his_icon}`}>
                            <StackMinus weight='bold'/>
                        </div>
                        <div className={`${classes.empty_hist_text}`}>
                            <h2>no Distribution History</h2>
                        </div>
                    </div>
                }
            </div>  

        </div>
    )
}