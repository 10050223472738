import { useEffect, useState } from 'react'
import classes from './KitchenVariants.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faPlusCircle, faTimesCircle, faTrash } from '@fortawesome/free-solid-svg-icons';

import CreateKitchenVar from './Component/CreateKitchenVar/CreateKitchenVar';
import EditKitchenVariants from './Component/EditKitchenVar/EditKitchenVariants';

import {FetchKVType, PurgeKVType} from '../../../SharedComp/API/KV/API_kv'
import { useAuth } from '../../../AuthProvider';
import { useDAControl } from '../../../DAControlContext'
import { useToast } from '../../../ToastContext'

export default function KitchenVariants() 
{

    const [OpenCreateKitchenVar, SetOpenCreateKitchenVar] = useState(false);
    const [EditKitchenVar, SetEditKitchenVar] = useState(false);
    const [KVDataArray, SetKVDataArray] = useState([]);
    const [EditVarIdx, SetEditVarIdx] = useState();
    const [ReloadQuery, SetReloadQuery] = useState(false);

    const auth =  useAuth();
    const DAControl = useDAControl();
    const notify = useToast();



    const HandleEditVar = async(__idx)=>
    {
        SetEditVarIdx(__idx);
        SetEditKitchenVar(true);
    }

    const FetchKVTypeFront = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("branch_id", auth.activeWorkShop);
            const res = await FetchKVType(fd, auth.token);
            SetKVDataArray(res);
        }catch(err) 
        {

        }
    }

    useEffect(()=>{
        FetchKVTypeFront();

    }, [OpenCreateKitchenVar, auth.activeWorkShop, EditKitchenVar, ReloadQuery]);


    return (
        <div className={`${classes.kitchen_var_main_wrapper}`}>

            {OpenCreateKitchenVar && <CreateKitchenVar OpenController={SetOpenCreateKitchenVar}/>}
            {EditKitchenVar && <EditKitchenVariants EditIdx={EditVarIdx} OpenController={SetEditKitchenVar} />}

            <div className={`${classes.kitchen_var_bundle_outer}`}>

                <div className={`${classes.kitchen_var_bundle_wrapper}`}>

                    <div className={`${classes.kitchen_var_head_wrapper}`}>
                        <h1>Kitchen Variance</h1>
                    </div>

                    <div className={`${classes.kitchn_var_list_body}`}>
                        {
                        KVDataArray.map((item, idx)=>(
                            <li>
                                <span>{item.KV_TypeName}</span>
                                <span>{item.KV_SliceCount}</span>
                                <span>{item.KV_IsSolo ? "Solo" : "Multi Pcs"}</span>
                                <span className={`${classes.action_button_wrapper}`}>
                                    <button className={`${classes.purge_kv}`}
                                    onClick={()=>{DAControl.Trigger("Purge Kitchen Variance", 
                                        `You are about to purge the Kitchen Variance '${item.KV_TypeName}'. This action will trigger severe consequences:
                                        Any active Product Item That are linked with this Recipe will be Hidden!This action is IRREVERSIBLE! Make sure no product is linked with this Variance.`, 
                                        item.KVTID,
                                        10,
                                        async (data)=>{
                                            if(!data)
                                                return;

                                                notify.ShowLoad(`Purging Variance ${item.KV_TypeName}.....`, 10);

                                                setTimeout(async()=>{

                                                    try 
                                                    {
                                                        const res = await PurgeKVType(data, 'token');
                                                        notify.ShowSuccessUpdate(res.Msg, 10);
                                                        SetReloadQuery(!ReloadQuery);
                                                    }catch(err) 
                                                    {
                                                        notify.ShowErrorUpdate(err.message, 10);
                                                    }

                                                }, 500)


                                        })}}
                                    >
                                        <span>
                                            <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                                        </span>
                                        <span>
                                            Purge
                                        </span>
                                    </button>
                                    <button onClick={()=>{HandleEditVar(item.KVTID)}} className={`${classes.manage_kv}`}>
                                        <span>
                                            <FontAwesomeIcon icon={faPen}></FontAwesomeIcon>
                                        </span>
                                        <span>
                                            Manage
                                        </span>
                                    </button>
                                </span>
                            </li>
                        ))
                        }
                    </div>

                    <div className={`${classes.kitchen_var_footer_wrapper}`}>

                        <div className={`${classes.kicthen_var_footer_action_button_wrapper}`}>
                            <button onClick={()=>{SetOpenCreateKitchenVar(true)}}><span><FontAwesomeIcon icon={faPlusCircle}></FontAwesomeIcon></span>New</button>
                        </div>

                    </div>

                </div>
            </div>

        </div>
    )
}