import classes from './ProductionControlCenter.module.css'
import DataViewTableProxy from '../../../SharedComp/DataViewTableProxy/DataViewTableProxy'
import { QueryCKProductKitchenID, UpdateCKProductAvailState} from '../../../SharedComp/API/CK/AP_CenteraKitchen'
import { useAuth } from '../../../AuthProvider'
import { useToast } from '../../../ToastContext';
import { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useDAControl } from '../../../DAControlContext';
import { useEffect } from 'react';
import { useRef } from 'react';



const currency_format = (val)=> 
{
    return val.toLocaleString('en-US', {
        style: 'currency',
        currency: 'ETB',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}


export default function ProductionControlCenter() 
{

    const auth = useAuth();
    const notify = useToast();
    const DAControl = useDAControl();

    const [LocalProductList, SetLocalProductList] = useState([]);
    const [OpenCKProduct, SetOpenCKProduct] = useState(false);

    const [ReloadView, SetReloadView] = useState(false);

    const ToastHeader = useRef(new Map());

    const QueryProductItemList = async() =>
    {
        try 
        {
            const res = await QueryCKProductKitchenID(auth.activeWorkShop, auth.token);
            SetLocalProductList(res);
        }catch(err) 
        {

        }
    }

    const GetToastID = (__id) =>
    {
        const _rand = parseInt(Math.ceil((Math.random() * 1000)));
        ToastHeader.current.set(__id, _rand);
        return _rand;
    }
    const FlushToastID = (__id) =>
    {
        const _flush_id = parseInt(ToastHeader.current.get(__id));
        ToastHeader.current.delete(__id);
        return _flush_id;
    }

    const UpdateAvailabilityStatus = async(_id, _value, _name)=>
    {
        notify.ShowLoad("Processing your request....", GetToastID(_id));
        setTimeout(async()=>{

            try 
            {
                const fd = new FormData();
                fd.append("target_id", _id);
                fd.append("target_value", _value);
                fd.append("target_name", _name);

                const main_res = await UpdateCKProductAvailState(fd, auth.token);

                notify.ShowSuccessUpdate(main_res.Msg, FlushToastID(_id));
                SetReloadView(!ReloadView);

            }catch(err) 
            {
                notify.ShowErrorUpdate(err.message, FlushToastID(_id));
            }

        }, 1500)
    }

    useEffect(()=>{
        QueryProductItemList();
    }, [auth.activeWorkShop, ReloadView])

    return (
        <div>
            

            <div className={`${classes.data_table_wrapper}`}>

            <DataViewTableProxy ShowNewButton={false} OpenDataCreate={SetOpenCKProduct} DataArray={
                <div>
                    {
                        LocalProductList.map((item, idx)=>(
                            <li key={idx}>
                                <span>{item.CKP_ItemName}</span>
                                <span><span className={`${classes.cash_view_value}`}>{currency_format(item.CKP_UnitPrice)}</span></span>
                                <span style={{textAlign: 'right'}} className={`${classes.action_button_wrapper}`}>
                                    <button onClick={()=>{UpdateAvailabilityStatus(item.CKPID, !(item.CKP_OrderableState === 1), item.CKP_ItemName)}} className={`${item.CKP_OrderableState ? classes.active_prod : classes.inactive_prod}`}>
                                        <span><FontAwesomeIcon icon={item.CKP_OrderableState ? faCheckCircle : faBan}></FontAwesomeIcon></span>
                                        <span> {item.CKP_OrderableState ? 'Active' : 'Inactive'}</span>
                                    </button>
                                </span>
                            </li>
                        ))
                    }
                </div>
            }
                HeaderName={
                    <div>
                        <h2>{auth.activeWorkShopName} <b>Kitchen Products</b> List</h2>
                    </div>
                }
             />

            </div>
        </div>
    )
}