import { getBaseURL } from "./SharedComp/BaseUrlConfig";
import axios from 'axios'

function GetRouteConfig(token)
{
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
}

export const FetchAuthUserData = async (UserID, token) => {
    try 
    {
        console.log("Called");
        const response = await fetch(getBaseURL() + 'query_usr_omni', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ id: UserID })
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const userData = await response.json();
        return userData;
    } catch (error) {
        console.error('Failed to fetch user data:', error);
        throw error;
    }
}
export const FetchBranchAll = async (token) => {
    try 
    {
        const response = await fetch(getBaseURL() + 'master-data/get-company-branch', {
            method: 'GET'
        });

        const res = await response.json();

        return res;

    }catch(err) 
    {
        throw new Error(err);
    }
}

// //////////////////////////////////// MASTER PRODUCT GROUP

export const FetchProductLineMasterGroup = async (id, token)=>
    {
        try 
        {
            const response = await fetch(getBaseURL() + 'product-line/fetch-prod-cat', {
                method: 'POST',
                headers: {
                   'Content-Type': 'application/json',
                },
                body: JSON.stringify({ID: id})
            });
            const res = await response.json();
            return res;
    
        }catch(err) 
        {
            throw err;
        }
}
export const FetchProductLineMasterGroupAll = async (token)=>
{
    try 
    {
        const response = await fetch(getBaseURL() + 'product-line/fetch-prod-cat-all', {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
            }
        });
        const res = await response.json();
        return res;

    }catch(err) 
    {
        throw err;
    }
}
export const PushProductLineMasterGroup = async (data, token) => {
    try 
    {
        const response = await axios.post(getBaseURL() + 'product-line/add-prod-cat', data);
        const res = await response.data;

        if(res.MyStateCode === -1 || res.MyStateCode === 0)
        {
            throw new Error(res.Msg);
        } else 
        {
            return res;
        }
        
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateProductLineMasterGroup = async (data, token) => {
    try 
    {
        const response = await axios.post(getBaseURL() + 'product-line/update-prod-cat', data);
        const res = await response.data;

        if(res.MyStateCode === -1 || res.MyStateCode === 0)
        {
            throw new Error(res.Msg);
        } else 
        {
            return res;
        }
        
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeProductLineMasterGroup = async (data, token) => {
    try 
    {
        const response = await axios.post(getBaseURL() + 'product-line/purge-prod-cat', data);
        const res = await response.data;

        if(res.MyStateCode === -1 || res.MyStateCode === 0)
        {
            throw new Error(res.Msg);
        } else 
        {
            return res;
        }
        
    }catch(err) 
    {
        throw err;
    }
}

// //////////////////////////////////// MASTER SUB-PRODUCT GROUP

export const FetchProductLineMasterSubGroup = async (id, token)=>
    {
        try 
        {
            const response = await fetch(getBaseURL() + 'product-line/fetch-sub-prod-cat', {
                method: 'POST',
                headers: {
                   'Content-Type': 'application/json',
                },
                body: JSON.stringify({ID: id})
            });
            const res = await response.json();
            return res;
    
        }catch(err) 
        {
            throw err;
        }
}
export const FetchProductLineMasterSubGroupAll = async (token)=>
{
    try 
    {
        const response = await fetch(getBaseURL() + 'product-line/query-prod-sub-cat-all', {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
            }
        });
        const res = await response.json();
        return res;

    }catch(err) 
    {
        throw err;
    }
}
export const PushProductLineMasterSubGroup = async (data, token) => {
    try 
    {
        const response = await axios.post(getBaseURL() + 'product-line/add-prod-sub-cat', data);
        const res = await response.data;

        if(res.MyStateCode === -1 || res.MyStateCode === 0)
        {
            throw new Error(res.Msg);
        } else 
        {
            return res;
        }
        
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateProductLineMasterSubGroup = async (data, token) => {
    try 
    {
        const response = await axios.post(getBaseURL() + 'product-line/update-sub-prod-cat', data);
        const res = await response.data;

        if(res.MyStateCode === -1 || res.MyStateCode === 0)
        {
            throw new Error(res.Msg);
        } else 
        {
            return res;
        }
        
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeProductLineMasterSubGroup = async (data, token) => {
    try 
    {
        const response = await axios.post(getBaseURL() + 'product-line/purge-sub-prod-cat', data);
        const res = await response.data;

        if(res.MyStateCode === -1 || res.MyStateCode === 0)
        {
            throw new Error(res.Msg);
        } else 
        {
            return res;
        }
        
    }catch(err) 
    {
        throw err;
    }
}

// ///////////////////////////////////////// Domestic Product 

export const PushDomesticProduct = async(data, token) => {
    try 
    {
        const response = await axios.post(getBaseURL() + 'product-line/add-prod-final', data, GetRouteConfig(token));
        const res = await response.data;

        if(res.MyStateCode === -1 || res.MyStateCode === 0)
        {
            throw new Error(res.Msg);
        } else 
        {
            return res;
        }
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateDomesticProduct = async(data, token) => {
    try 
    {
        const response = await axios.post(getBaseURL() + 'product-line/update-prod-final', data, GetRouteConfig(token));
        const res = await response.data;

        if(res.MyStateCode === -1 || res.MyStateCode === 0)
        {
            throw new Error(res.Msg);
        } else 
        {
            return res;
        }
    }catch(err) 
    {
        throw err;
    }
}

export const FetchDomestickProductAll = async(token)=>{
    try 
    {
        const response = await axios.post(getBaseURL() + 'product-line/fetch-final-prod-line', {}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchDomestickProductID = async(id, token)=>{
    try 
    {
        const response = await axios.post(getBaseURL() + 'product-line/fetch-final-prod-line-id', {ID: id}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeDomesticProduct = async(id, token) => {
    try 
    {
        const response = await axios.post(getBaseURL() + 'product-line/purge-final-prod-line-id', {ID: id}, GetRouteConfig(token));
        const res = await response.data;

        if(res.MyStateCode === -1 || res.MyStateCode === 0)
        {
            throw new Error(res.Msg);
        } else 
        {
            return res;
        }
    }catch(err) 
    {
        throw err;
    }
}

// //////////////////////////// YAM-MIX
export const PushYamMix = async(data, token) => {
    try 
    {
        const response = await axios.post(getBaseURL() + 'master-data/add-yam-mix', data, GetRouteConfig(token));
        const res = await response.data;

        if(res.MyStateCode === -1 || res.MyStateCode === 0)
        {
            throw new Error(res.Msg);
        } else 
        {
            return res;
        }
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateYamMix = async(data, token) => {
    try 
    {
        const response = await axios.post(getBaseURL() + 'master-data/update-yam-mix', data, GetRouteConfig(token));
        const res = await response.data;

        if(res.MyStateCode === -1 || res.MyStateCode === 0)
        {
            throw new Error(res.Msg);
        } else 
        {
            return res;
        }
    }catch(err) 
    {
        throw err;
    }
}
export const FetchYamMixID = async(id, token) => {
    try 
    {
        const response = await axios.post(getBaseURL() + 'master-data/get-mix-id', {ID: id}, GetRouteConfig(token));
        const res = await response.data;

        if(res.MyStateCode === -1 || res.MyStateCode === 0)
        {
            throw new Error(res.Msg);
        } else 
        {
            return res;
        }
    }catch(err) 
    {
        throw err;
    }
}
export const FetchYamMixAll = async (token) =>{
    try 
    {
        const response = await axios.get(getBaseURL() + 'master-data/get-mix', {}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchYamMixOrderableBranch = async (id, token) =>{
    try 
    { 
        const response = await axios.post(getBaseURL() + 'master-data/get-mix-orderable', {ID: id}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}

export const FetchYamMixIngID = async(id, token) => {
    try 
    {
        const response = await axios.post(getBaseURL() + 'master-data/get-mix-ing-id', {ID: id}, GetRouteConfig(token));
        const res = await response.data;

        if(res.MyStateCode === -1 || res.MyStateCode === 0)
        {
            throw new Error(res.Msg);
        } else 
        {
            return res;
        }
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeYamMixID = async(id, token) => {
    try 
    {
        const response = await axios.post(getBaseURL() + 'master-data/purg_prod_mix', {ID: id}, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1)
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}

// ////////////////////////////////// INVENTORY ITEM GROUP
export const FetchIVGroupAll = async(token)=>{
    try 
    {
        const response = await axios.post(getBaseURL() + 'master-data/get-iv-type', {}, GetRouteConfig(token));
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchIVGroupEdibleState = async(state, token)=>{
    try 
    {
        const response = await axios.post(getBaseURL() + 'master-data/get-iv-type-id', {IS_EDIBLE: state}, GetRouteConfig(token));
        const res = await response.data;

        if(res.MyStateCode !== 1)
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchIVGroupID = async(id, token)=>{
    try 
    {
        const response = await axios.post(getBaseURL() + 'master-data/get-iv-type-id', {ID: id}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PushIVGroup = async(data, token)=>{
    try 
    {
        const response = await axios.post(getBaseURL() + 'master-data/add-iv-type', data, GetRouteConfig(token));
        const res = await response.data;

        if(res.MyStateCode !== 1)
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateIVGroupID = async(data, token)=>{
    try 
    {
        const response = await axios.post(getBaseURL() + 'master-data/update-iv-type', data, GetRouteConfig(token));
        const res = await response.data;

        if(res.MyStateCode !== 1)
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeIVGroupID = async(id, token)=>{
    try 
    {
        const response = await axios.post(getBaseURL() + 'master-data/purge-iv-type-id', {ID: id}, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1)
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}

// //////////////////////////////////// INVENTORY ITEM
export const  FetchIVItemAll = async(token)=>{
    try 
    {
        const response = await axios.post(getBaseURL() + 'master-data/get-iv-item', {}, GetRouteConfig(token));
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err;
    }

}
export const  FetchIVItemID = async(id, token)=>{
    try 
    {
        const response = await axios.post(getBaseURL() + 'master-data/get-iv-item-id', {ID: id}, GetRouteConfig(token));
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const  FetchIVItemEdibaleState = async(state, token)=>{
}
export const  UpdateFetchIVItemID = async(data, token)=>{
    try 
    {
        const response = await axios.post(getBaseURL() + 'master-data/update-iv-item-id', data, GetRouteConfig(token));
        const res = await response.data;

        if(res.MyStateCode !== 1)
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const  PurgeFetchIVItemID = async(id, token)=>{
    try 
    {
        const response = await axios.post(getBaseURL() + 'master-data/purge-iv-item-id', {ID: id}, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1)
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PushIVItem = async(data, token)=>{
    try 
    {
        const response = await axios.post(getBaseURL() + 'master-data/add-iv-item', data, GetRouteConfig(token));
        const res = await response.data;

        if(res.MyStateCode !== 1)
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}

// ////////////////////////////////////// INVENTORY STOCK
export const FetchInventoryStockID = async(id, token)=>{
    try 
    {
        const response = await axios.post(getBaseURL() + 'inventory/get-inv-stock-id', {ID: id}, GetRouteConfig(token));
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchInventoryStockBranchID = async(branch_id, token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'inventory/get-inv-stock-branch', {ID: branch_id}, GetRouteConfig(token));
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PushInventoryStock = async(data, token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'inventory/add-inv-stock', data, GetRouteConfig(token));
        const res = await response.data;

        if(res.MyStateCode !== 1)
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
// ////////////////////////////////////// STOCK INVOICE

export const FetchInventoryInvoice = async(data, token) =>{
    try 
    {
        const response = await axios.post(getBaseURL() + 'inventory/query-invoice-read', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchInventoryInvoiceUID = async(uid, token) =>{
    try 
    {
        const response = await axios.post(getBaseURL() + 'inventory/query-invoice-read-uid', {UID: uid}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
// ////////////////////////////////////////////// PO
export const FetchPOLinkedRawMatID = async(id, token)=>{
    try 
    {
        const response = await axios.post(getBaseURL() + 'po/get-po-mix-mat-id', id, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}

// ///////////////////////////////////////// Warehouse
export const FetchWarehouseItem = async(data, token)=>{
    try 
    { 
        const response = await axios.post(getBaseURL() + 'warehouse/get-inv-stock-branch-warehouse', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchBranchWarehouse = async(branch_id, token)=>{
    try 
    { 
        const response = await axios.post(getBaseURL() + 'warehouse/get-branch-warehouse', {ID: branch_id}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}

// ////////////////////////////////////////// BRANCH
export const FetchBranchID = async(id, token) =>
{

}
export const FetchBranchExcludedCurrent = async(id, token) =>
{
    try 
    { 
        const response = await axios.post(getBaseURL() + 'workspace/get-workspace-exc', {ID: id}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}

// ///////////////////////////////////////// Stock Request
export const CreateStockTransferRequest = async(data, token)=>
{
    try 
    { 
        const response = await axios.post(getBaseURL() + 'stock-transfer/create-transfer-request', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1)
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchTransferRequestDispatched = async(id, token)=>{
    try 
    {
        const response = await axios.post(getBaseURL() + 'stock-transfer/fetch-dispatched-request', {ID: id}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchTransferRequestIncoming = async(id, token)=>{
    try 
    {
        const response = await axios.post(getBaseURL() + 'stock-transfer/fetch-incoming-request', {ID: id}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchTransferRequestDispatchedDetail = async(id, token)=>{
    try 
    {
        const response = await axios.post(getBaseURL() + 'stock-transfer/fetch-dispatched-request-detail', {ID: id}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchTransferRequestPayload = async(id, token)=>
{ 
    try 
    {
        const response = await axios.post(getBaseURL() + 'stock-transfer/fetch-request-payload', {ID: id}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const CheckEligibleBalanceState = async(data, token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'stock-transfer/check-balance-eligible', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const ProcessStockRequest = async(data, token)=>{
    try 
    {
        const response = await axios.post(getBaseURL() + 'stock-transfer/process-stock-tr-request', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const ProcessRequestReceiveTransfer = async(data, token)=>{
    try 
    {
        const response = await axios.post(getBaseURL() + 'stock-transfer/process-request-receive-transfer', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchStockTransferRequestByID = async(id, token)=>{
    try 
    {
        const response = await axios.post(getBaseURL() + 'stock-transfer/fetch-stock-transfer-id', {ID: id}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchStockTransferRequestReadBackHash = async(id, token)=>{
    try 
    {
        const response = await axios.post(getBaseURL() + 'stock-transfer/fetch-stock-transfer-read-back-hash', {ID: id}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchRequestSenderUser = async(id, token) =>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'stock-transfer/fetch-request-sender-user-id', {ID: id}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchRequestHandlerUser = async(id, token) =>
{
        try 
        {
            const response = await axios.post(getBaseURL() + 'stock-transfer/fetch-request-handler-user-id', {ID: id}, GetRouteConfig(token));
            const res = await response.data;
            return res;
        }catch(err) 
        {
            throw err;
        }
}


// Product
export const FetchProductByBranch = async(data, token) =>
{
    try 
        {
            const response = await axios.post(getBaseURL() + 'product-line/fetch-final-prod-line-branch-id', data, GetRouteConfig(token));
            const res = await response.data;
            return res;
        }catch(err) 
        {
            throw err;
        }
}
export const FetchProductByBranchOrderable = async(data, token) =>
    {
        try 
            {
                const response = await axios.post(getBaseURL() + 'product-line/fetch-final-prod-line-branch-id-orderable', data, GetRouteConfig(token));
                const res = await response.data;
                return res;
            }catch(err) 
            {
                throw err;
            }
}

// Production Order
export const FetchMixIngredientJoined = async(id, token)=>{
    try 
    {
        const response = await axios.post(getBaseURL() + 'po/fetch-ingredient-joind', {MixID: id}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err)
    {
        throw err;
    }
}
export const CreateProductionOrder = async(data, token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'po/process-po-reg', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchSavedProductionProductOrder = async(hash_id, token)=>{
    try 
    {
        const response = await axios.post(getBaseURL() + 'po/fetch-ordered-product-item', {ID: hash_id}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchFinalProductSaved = async(id, token)=>{
    try 
    {
        const response = await axios.post(getBaseURL() + 'po/fetch-saved-final-prod', {ID: id}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchFinalProductByRecipe = async(id, token)=>{
    try 
    {
        const response = await axios.post(getBaseURL() + 'po/fetch-final-prod-by-recipe', {ID: id}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchMixSaved = async(id, token)=>{
    try 
    {
        const response = await axios.post(getBaseURL() + 'po/fetch-saved-mix', {ID: id}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchProductionOrderReqMaterial = async(id, token)=>{
    try 
    {
        const response = await axios.post(getBaseURL() + 'po/fetch-prod-order-mat', {ID: id}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
// Pendning
export const FetchProductionOrderBranchPending = async(branch_id, token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'po/fetch-production-order-branch-pending', {ID: branch_id}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchProductionOrderBranchStockedOut = async(branch_id, token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'po/fetch-production-order-branch-stocked-out', {ID: branch_id}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const ApprovePoRequest = async(data, token)=>{
    try 
    {
        const response = await axios.post(getBaseURL() + 'po/approve-po-request', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const RejectPoRequest = async(data, token)=>{
    try 
    {
        const response = await axios.post(getBaseURL() + 'po/reject-po-request', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}

export const ProcessProductedPoStack = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'production-log/porcess-producted-po-stack', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        { 
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const RefreshProdcutionOrder = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'production-log/refresh-production-order', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}

