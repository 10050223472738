import classes from './EmpTextInput.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk, faCircleCheck, faCircleExclamation, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'react-tooltip';

export default function EmpTextInput({OnChangeUpdate, Data, Title="", ValueType="text", ValueTag, Icon, PlaceHolder, State, Tip, ForceInput = true}) 
{
    return (
        <div className={`${classes.emp_text_input_main_wrapper}`}>
            <Tooltip className={`${classes.tool_quick}`} id="input_field_tip" place="top" />
            <div className={`${classes.emp_input_title}`}>
                <li>{Title} <span className={`${classes.emp_input_force_notifyer}`}>{ForceInput ? <sup><FontAwesomeIcon icon={faAsterisk}></FontAwesomeIcon></sup> : ''}</span></li>
            </div>
                <div className={`${classes.emp_input_line_wrapper} ${State === -1 ? classes.emp_input_line_wrapper_error_border : State === 1 ? classes.emp_input_line_wrapper_suc_border : '' }`}>

                    <div className={`${classes.input_left_side_wrapper}`}>
                        <div className={`${classes.emp_input_left_icon_wrapper}`}>
                            <span className={`${classes.emp_side_icon}`}><FontAwesomeIcon icon={Icon}></FontAwesomeIcon></span>
                        </div>

                        <div
                        {...(Tip ? {'data-tooltip-id': 'input_field_tip', 'data-tooltip-content': Tip} : {})}
                        className={`${classes.emp_create_input_wrapp}`}>
                            <input 
                            type={ValueType} 
                            name={ValueTag}
                            placeholder={PlaceHolder}
                            autoComplete='on'
                            value={Data[ValueTag]}
                            onChange={OnChangeUpdate}
                            required={ForceInput}
                            min="1960-01-01" max="2013-12-31"
                            title=""
                            />
                        </div>
                    </div>

                    <div className={`${classes.emp_icon_right_side_notify}`}>
                        <span className={`${classes.emp_side_icon_noti} ${State === 0 ? classes.emp_icon_right_side_notify_friendly : State === -1 ? classes.emp_icon_right_side_notify_error : State === 1 ? classes.emp_icon_right_side_notify_good : State === -2 ?classes.emp_icon_right_side_notify_whatever : ''}`}>
                                {State !== 0 && <FontAwesomeIcon icon={State === -1 ? faCircleExclamation : State === -2 ? faExclamation : faCircleCheck}></FontAwesomeIcon> }
                            </span>
                    </div>
                </div>
        </div>
    )
}