import classes from './ViewMappedItem.module.css'
import data_win from '../../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { useRef, useState } from 'react';
import { useAuth } from '../../../../../../AuthProvider';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import {FetchWarehouseItem} from '../../../../../../api'
import { useEffect } from 'react';

const ViewMappedItem = ({CloseModal, TargetData}) => {

    const formRef = useRef();
    const auth = useAuth();

    const [Loading, SetLoading] = useState(false);
    const [PrintFiltered, SetPrintFiltered] = useState(false);

    const [WarehouseItemList, SetWarehouseItemList] = useState([]);
    const [InventoryItemViewList, SetInventoryItemViewList] = useState([]);

    const formatter = new Intl.NumberFormat('en-US');
    const FetchLinkedItem = async()=>{
        try 
        {
            const fd = new FormData();
            fd.append("BranchID", TargetData.BranchID);
            fd.append("StoreID", TargetData.WhID);
            const res = await FetchWarehouseItem(fd);
            SetWarehouseItemList(res);
            SetInventoryItemViewList(res);

        }catch(err) 
        {
            
        }
    }

    useEffect(()=>{
        FetchLinkedItem();
    }, [])

    const FilterIvItemByName = async(text)=>{

        const temp_list = [];
        for(let i = 0; i < WarehouseItemList.length; i++) 
        {
            const check = WarehouseItemList[i].IVItemName.toLowerCase();
            if(check.includes(text.toLowerCase()))
                temp_list.push(WarehouseItemList[i])
        }

        SetInventoryItemViewList(temp_list);
    }
    const select_process = async(filter_key)=>{

        const temp_list = [];
        if(filter_key === 'all') 
        {
            SetInventoryItemViewList(WarehouseItemList)
        }else if(filter_key === 'edible')
        {
            for(let i = 0; i < WarehouseItemList.length; i++) 
            {
                if(WarehouseItemList[i].IVItemTypeEdibleState === 1)
                    temp_list.push(WarehouseItemList[i])
            }
            SetInventoryItemViewList(temp_list)
        } else if(filter_key === 'raw') 
        {
            for(let i = 0; i < WarehouseItemList.length; i++) 
            {
                if(WarehouseItemList[i].IVItemTypeEdibleState === 0)
                    temp_list.push(WarehouseItemList[i])
            }
            SetInventoryItemViewList(temp_list)
        }else if(filter_key === 'hasParent') 
        {
            for(let i = 0; i < WarehouseItemList.length; i++) 
            {
                if(WarehouseItemList[i].IVItemFollowParent === 1)
                    temp_list.push(WarehouseItemList[i])
            }
            SetInventoryItemViewList(temp_list)
        }else if(filter_key === 'noParent') 
        {
            for(let i = 0; i < WarehouseItemList.length; i++) 
            {
                if(WarehouseItemList[i].IVItemFollowParent === 0)
                    temp_list.push(WarehouseItemList[i])
            }
            SetInventoryItemViewList(temp_list)
        }else if(filter_key === 'todayStock') 
        {
            for(let i = 0; i < WarehouseItemList.length; i++) 
            {
                if(new Date(WarehouseItemList[i].LastStockInDate).getDate() === new Date().getDate())
                    temp_list.push(WarehouseItemList[i])
            }
            SetInventoryItemViewList(temp_list)
        }
    }



    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

            <div className={`${data_win.data_main} ${classes.data_main}`} >
                <div className={`${data_win.data_main_header}`}>
                    <h3>
                    Warehouse <span className={`${data_win.data_create_name}`}>Stock Guard [{TargetData.BranchName}]</span>
                    </h3>
                    <button onClick={()=>{CloseModal()}}>
                        <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                    </button>
                </div>

                <div className={`${data_win.data_body} ${classes.data_body}`}>

                    <div className={`${classes.header_wrapper_list}`}>
                        <div className={`${classes.filter_input_wrapper}`}>
                            <input onChange={(e)=>{FilterIvItemByName(e.target.value)}} type="text" placeholder='Filter..' />
                        </div>
                        <div className={`${classes.action_button_wrapper_product_class_filter}`}>
                            <select onChange={(e)=>{select_process(e.target.value)}}  id="filterCat" name="filterCat">
                                <option value="all">All Items</option>
                                <option  value="edible">Edible Only</option>
                                <option value="raw">Raw Material Only</option>
                                <option value="hasParent">With Parent Link</option>
                                <option value="noParent">With No Parent Link</option>
                                <option value="todayStock">Today Stocked In</option>
                            </select>
                        </div>
                        <div onClick={(e)=>{SetPrintFiltered(!PrintFiltered)}} className={`${classes.helper_tick}`}>
                            <input checked={PrintFiltered} onChange={(e)=>{SetPrintFiltered(!PrintFiltered)}} type="checkbox" />
                            <span>Stick with Filtered On Print</span>
                        </div>
                    </div>

                    <div className={`${classes.filter_list_wrapper}`}>

                        <div className={`${classes.filter_paper_list_wrapper}`}>
                            <div className={`${classes.filter_inner_list_wrapper}`}>
                                {
                                    InventoryItemViewList.map((item, idx)=>(
                                        <li key={idx}>
                                            <span className={`${classes.filter_item_list}`}>{idx + 1}. {item.IVItemName}</span>
                                            <span className={`${classes.filter_item_amount}`}>[APX] {formatter.format(item.AvailQuantity)} {item.UomDescription}</span>
                                            <span className={`${classes.filter_item_price_unit}`}>[APX] {formatter.format(item.CurrentCapital / item.AvailQuantity)} ETB</span>
                                            <span className={`${classes.filter_item_price_total}`}>[APX] {formatter.format(item.CurrentCapital)} ETB</span>
                                        </li>
                                    ))
                                }
                                {
                                    InventoryItemViewList.length === 0 && <div className={`${classes.empty_not}`}>
                                            there are no item, in ({TargetData.BranchName} - {TargetData.WHName}), I Think!
                                    </div>      
                                }
                                    
                                    

                            </div>
                        </div>

                    </div>
                        
                
                    <div className={`${data_win.form_action_button_wrapper} ${classes.form_action_button_wrapper}`}>
                        {/* <button className={`${classes.form_action_button_save}`}>Print</button> */}
                        <button  onClick={()=>{CloseModal()}} className={`${classes.form_action_button_dismiss}`} type='button'>Dismiss</button>
                    </div>




                </div>
            </div>

        </div>
    )
}

export default ViewMappedItem;