import classes from './EmpBenefit.module.css'


export default function EmpBenefit() 
{
    return (
        <div className={`${classes.emp_benefit_main_wrapper}`}>
            <h1>EmpBenefit</h1>
        </div>
    );
}