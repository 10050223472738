import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import classes from './VenderCard.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function VendorCard({ID, ItemName, Tin, Purge_CALLBACK}) 
{
    return (
        <div className={`${classes.vender_card_main_wrapper}`}>
            <div className={`${classes.vender_card_d_wrapper}`}>
                <li className={`${classes.v_name}`}>{ItemName}</li>
                <li className={`${classes.v_tin}`}>TIN: {Tin}</li>
            </div>

            <div className={`${classes.vendor_card_action_button_wrapper}`}>
                {/* <button> <FontAwesomeIcon icon={faPen}></FontAwesomeIcon> </button> */}
                <button onClick={()=>{Purge_CALLBACK(ID, ItemName)}}> <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon> </button>
            </div>
        </div>
    )
}