import React, { useState } from "react";
import { createContext, useContext } from "react";

import DecisionAssurance from "./SharedComp/DecisionAssurance/DecisionAssurance";

const DAControlContext = createContext();

export const useDAControl = () => useContext(DAControlContext);

export const DAControlProvider = ({children}) => {

    const [WinName, SetWinName] = useState();
    const [Agreement,SetAgreement] = useState();
    const [CallbackData, SetCallbackData] = useState();
    const [Fallback, SetFallback] = useState();
    const [Degree, SetDegree] = useState(0);
    const [OpenDAControl, SetOpenDAControl] = useState(false);

    function CloseDeal(data) 
    {
        SetWinName(null);
        SetAgreement(null);
        SetCallbackData(null);
        SetFallback(null);
        SetOpenDAControl(false);
    }

    function Trigger(win_name, agreement, callback_data, degree, callback_func) 
    {
        SetDegree(degree);
        SetWinName(win_name);
        SetAgreement(agreement);
        SetCallbackData(callback_data);
         SetFallback(()=>callback_func);
        SetOpenDAControl(true);
    }

    return (
        <DAControlContext.Provider value={{
            Trigger
        }}>
            {OpenDAControl && <DecisionAssurance  
                    ActionName={WinName}
                    CallbackData={CallbackData} 
                    Msg={Agreement} 
                    Level={Degree}
                    openController={CloseDeal} 
                    HandleResponse={Fallback}
            /> }
            {children}
        </DAControlContext.Provider>
    )

}