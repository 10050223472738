import './InventoryDataCreatorStyle.css'

import { faXmark, faBuilding, faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputField from '../../../../SharedComp/InputField/InputField'
import { useState } from 'react';
import axios from 'axios';

import { GLOBAL_PopupMsgTimeout } from '../../../../SharedComp/Utility';
import DynamicDropDownInputField from '../../../../SharedComp/InputField/DynamicDropdownInputField';


function BrandInput(InputSetCallback) 
{
    return (
        <div className="input-wrapper">
            <InputField
                HandleInputUpdate={InputSetCallback}  
                Name={"brand_name"} 
                icon_value={faBuilding} 
                type={'text'} 
                placeholder={"Brand Name"} />

            <InputField
                HandleInputUpdate={InputSetCallback}  
                Name={"brand_desc"} 
                icon_value={faBuilding} 
                type={'text'} 
                placeholder={"Brand Description"} />

        </div>
    );
}
function UOMInput(InputSetCallback) 
{
    return (
        <div className="input-wrapper">
            <InputField
                HandleInputUpdate={InputSetCallback}   
                Name={"uom_name"} 
                icon_value={faBuilding} 
                type={'text'} 
                placeholder={"Unit of Measurement"} />

            <InputField
                HandleInputUpdate={InputSetCallback}   
                Name={"uom_description"} 
                icon_value={faBuilding} 
                type={'text'} 
                placeholder={"UOM Description"} />

        </div>
    );
}
function IVTypeInput(InputSetCallback) 
{
    return (
        <div className="input-wrapper">
            <InputField
                HandleInputUpdate={InputSetCallback}   
                Name={"iv_type_name"} 
                icon_value={faBuilding} 
                type={'text'} 
                placeholder={"Inventory Type Name"} />

            <InputField
                HandleInputUpdate={InputSetCallback}   
                Name={"iv_type_description"} 
                icon_value={faBuilding} 
                type={'text'} 
                placeholder={"Inventory Type Description"} />

        </div>
    );
}
function IVItemInput(InputSetCallback) 
{
    return (
        <div className="input-wrapper">
            <InputField
                HandleInputUpdate={InputSetCallback}   
                Name={"iv_item_name"} 
                icon_value={faLayerGroup} 
                type={'text'} 
                placeholder={" Inventory Item Name"} />

            <DynamicDropDownInputField
                OptionURL={'http://localhost:3333/master-data/get-iv-type'}
                HandleInputUpdate={InputSetCallback}   
                Name={"iv_type_name"} 
                icon_value={faLayerGroup} 
                type={'text'} 
                placeholder={" Inventory Type Name"} />

            <DynamicDropDownInputField
                OptionURL={'http://localhost:3333/master-data/get-item-brand'}
                HandleInputUpdate={InputSetCallback}   
                Name={"iv_brand_name"} 
                icon_value={faLayerGroup} 
                type={'text'} 
                placeholder={" Inventory Brand Name"} />


            <DynamicDropDownInputField
                OptionURL={'http://localhost:3333/master-data/get-uom'} 
                HandleInputUpdate={InputSetCallback}   
                Name={"iv_uom"} 
                icon_value={faLayerGroup} 
                type={'text'} 
                placeholder={"Item Measurement"} />

            <InputField
                HandleInputUpdate={InputSetCallback}   
                Name={"iv_min_stock"} 
                icon_value={faLayerGroup} 
                type={'number'} 
                Step={"0.00000000001"} 
                placeholder={"Min Stock Balance"} />

            <InputField
                HandleInputUpdate={InputSetCallback}   
                Name={"iv_max_stock"} 
                icon_value={faLayerGroup} 
                type={'number'} 
                Step={"0.00000000001"}  
                placeholder={"Max Stock Balance"} />


        </div>
    );
}


export default function InventoryDataCreator({Open, setOpenCreator, win_name, setMessagePopup}) 
{
    const [brand,   setBrand]       = useState();
    const [uom,     setUOM]         = useState();
    const [IVtype,  setIVtype]      = useState();
    const [IVItem,  setIVItem]      = useState();


    function handleSubmition(event) 
    {
        if(win_name === "Item Brand")
        {
            handleRoute(event, 'http://localhost:3333/master-data/add-item-brand', brand);

        } else if(win_name === "Unit of Measurement") 
        {
            handleRoute(event, 'http://localhost:3333/master-data/add-uom', uom);
        }
        else if(win_name === "Inventory Type") 
        {
            handleRoute(event, 'http://localhost:3333/master-data/add-iv-type', IVtype);
        }
        else if(win_name === "Inventory Item") 
        {
            handleRoute(event, 'http://localhost:3333/master-data/add-iv-item', IVItem);
        }
    }

    function HandlePopupTimeout(success, open, msg) 
    {
        GLOBAL_PopupMsgTimeout(setMessagePopup, success, false, msg);
    }
    const handleRoute = async (event, my_route, value) => 
    {
        event.preventDefault();
        
        axios.post(my_route, value)
        .then(res => {
            if(res.data.Okay) 
            {
                setMessagePopup(res.data.Okay, true, res.data.Msg);;
                HandlePopupTimeout(res.data.Okay, true, res.data.Msg);
            }else
            {
                setMessagePopup(res.data.Okay, true, res.data.Msg);;
                HandlePopupTimeout(res.data.Okay, true, res.data.Msg);
            }
        })
        .catch(err => {
            
        })
    }


    return (
        <div className={`iv-data-creator-main-wrapper ${!Open ? `iv-data-creator-main-wrapper-hide` : ``}`}>

            <div className={`pdc-main-content-wrapper ${Open ? `pdc-main-content-wrapper-show` : `pdc-main-content-wrapper-hide`}`}>
                    <div className="branch-creator-header">
                            <div className="bc-win-name">
                                <h1>Register Master Data of <span className='bc-strong'>{win_name}</span></h1>
                            </div>
                            <div className="bc-win-action-button">
                                <button onClick={() => {setOpenCreator(false)}}> <FontAwesomeIcon icon={faXmark}/> </button>
                            </div>
                    </div>

                    <form onSubmit={handleSubmition} action="#" method="post">
                            <div className="bc-body">
                                
                                { win_name === "Item Brand"             && BrandInput(setBrand)}
                                { win_name === "Unit of Measurement"    && UOMInput(setUOM)}
                                { win_name === "Inventory Type"         && IVTypeInput(setIVtype)}
                                { win_name === "Inventory Item"         && IVItemInput(setIVItem)}

                                <div className="bc-win-seprator">
                                    <hr />
                                </div>

                        <div className="bc-submit-buttons-wrapper">
                            <div className="bc-submit-form-wrapper">
                                <div type="submit" className="bc-submit-button-wrapper">
                                    <button>Create</button>
                                </div>
                                <div className="bc-discard-button-wrapper">
                                    <button type="button" onClick={() => {setOpenCreator(false)}}>Discard</button>
                                </div>
                            </div>
                        </div>
                                

                            </div>
                        </form>
                </div>

        </div>
    );
}