import { BoxArrowDown, XCircle } from '@phosphor-icons/react'
import classes from './RightMessagePanal.module.css'

import MessageCard from './MessageCard/MessageCard'

export default function RightMessagePanal({IsOpen, Close_CALLBACK, MessageList})
{



    return (
        <div   className={`${IsOpen ? classes.right_msg_panal_wrapper : classes.right_msg_panal_wrapper_hide}`}>

            <div className={`${classes.right_msg_header}`}>
                <h3>Notifications</h3>
                <button onClick={()=>{Close_CALLBACK(false)}}><XCircle weight='bold'/></button>
            </div>

            <div className={`${classes.msg_body_list_wrapper}`}>
                {
                    MessageList.map((item, idx)=>(
                        <MessageCard MsgPkg={item} key={idx} />
                    ))
                } 
                {
                    MessageList.length === 0 &&
                    
                    <div className={`${classes.empty_msg_bucket}`}>
                            <li>You have no new message!</li>
                    </div>

                }
            </div>

        </div>
    )
}