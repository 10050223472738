import classes from './NavLinkButtonStyle.module.css'
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import './activeMark.css'
export default function NavLinkButton({Name, Route, Icon}) 
{
    return (
        
        <li className={`${classes.single_main_item} link_wrap`}>
                <NavLink className={`${classes.single_main_item_inner}`} to={Route}>

                   <span className={`${classes.link_tag_wrapper}`}>
                        <span className={`${classes.link_tag_ico_wrapper}`}>
                            <FontAwesomeIcon icon={Icon}/>
                        </span>

                        <span className={`${classes.link_tag_name_wrapper}`}>
                             {Name}
                        </span>

                   </span>

                    {/* <span className={`${classes.lock_state}`}>
                        <FontAwesomeIcon icon={faLock}/>
                    </span> */}
                </NavLink>
        </li>

    );
}