import classes from './EmpAttendy.module.css'
import Calendar from 'react-calendar';
import './calander.css'
import { useState } from 'react';
import * as XLSX from 'xlsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';



export default function EmpAttendy() 
{
  const [lockAttendaceEdit, SetLockAttendaceEdit] = useState(true);
  const [selectedAttendyFile, SetSelectedAttendyFile] = useState();
  const [employeePresentDates, setEmployeePresentDates] = useState(['2023-11-19', '2023-11-20']);
  const [empPTO, setEmpPTO] = useState(['2023-11-01', '2023-11-02', '2023-11-03']);

  const tileClassName = ({ date, view }) => {
    if(view === "month") 
    {
        const dateString = date.toISOString().split('T')[0];
        if(empPTO.includes(dateString)) 
        {
            return 'pto_day'
        } else 
        {
          return employeePresentDates.includes(dateString) ? 'present_day' : 'absent_day';
        }
    }
  };

  const handleClickDay = date => {
    if(lockAttendaceEdit) 
    {
      return;
    }
    const dateString = date.toISOString().split('T')[0];

    setEmployeePresentDates(prevDates =>
      prevDates.includes(dateString)
        ? prevDates.filter(d => d !== dateString)
        : [...prevDates, dateString]
    );
  };

  const ProcessFile = (e) => {
    e.preventDefault();
    SetSelectedAttendyFile(null);
    let present_day = [];
    //console.log(e.target.attendy_file.files);
    const file = e.target.attendy_file.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      

      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      const formattedData = jsonData.map((row) => ({
        name: row[0],
        date: row[1]
      }));

      present_day = formattedData.map((obj) => (
        obj.date
      ));
      setEmployeePresentDates(present_day);
    };
    reader.readAsArrayBuffer(file);
  };

  function handleAttendyFileInputChnage(e) 
  {
    const file = e.target.files[0];
    if (file) {
      SetSelectedAttendyFile(file.name);
    }
  }
    return (
        <div className={`${classes.emp_attendy_main_wrapper}`}>
           <div className={`${classes.emp_attendy_calander_wrapper}`}>
            <div className={`${classes.calander_lock_wrapper}`}>
              <button className={`${lockAttendaceEdit ? classes.lock_cal_button : classes.unlock_cal_button}`} onClick={() => {SetLockAttendaceEdit(!lockAttendaceEdit)}}>{lockAttendaceEdit ? <FontAwesomeIcon icon={faLock}></FontAwesomeIcon> : <FontAwesomeIcon icon={faLockOpen}></FontAwesomeIcon>}</button>
            </div>
              <Calendar
                    calendarType='gregory'      
                    tileClassName={tileClassName}
                    onClickDay={handleClickDay}
                    defaultValue={new Date()}
                    maxDate={new Date()}
                    minDate={new Date(2023, 8, 1)}
                />
           </div>

           <div className={`${classes.emp_attendy_importer}`}>
              <form onSubmit={ProcessFile}>
                <label className={`${classes.xls_attendy_file_locator}`} for="xls_attendy_file">{selectedAttendyFile ? selectedAttendyFile : 'Locate attendance file'}</label>
                <input 
                type="file" 
                name='attendy_file' 
                accept=".xlsx, .xls" 
                id='xls_attendy_file' 
                className={`${classes.xls_attendy_file}`}
                onChange={handleAttendyFileInputChnage}
                 required />
                <button  
                disabled={(selectedAttendyFile && !lockAttendaceEdit) ? false : true}
                className={`${(selectedAttendyFile && !lockAttendaceEdit) ? classes.attendy_load_button_active : classes.attendy_load_button_inactive}`}>
                  Process
                  </button>
              </form>
           </div>

        </div>
    );
}