import classes from './Layout.module.css'
import NavBar from "../SharedComp/Navigation/TopMenu/Navbar";
import MainMenu from "../SharedComp/Navigation/SideMenu/MainMenu";
import { useAuth } from '../AuthProvider';
import { useEffect } from 'react';


const Layout = ({ children }) => {

    const auth = useAuth();

    useEffect(()=>{
        
    }, [auth.user])

    return (
        <div>
            <div className={`${classes.top_nav_bar}`}>

                <NavBar />

            </div>
            <div className={`${classes.elment_wrapper}`}>
                <div className={`${classes.element_x_menu}`}>
                  {auth.user?.UARI_IsClient === 0 &&  <MainMenu />} 
                </div>
                <div className={`${classes.element_x_route}`}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Layout;