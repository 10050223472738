import { getBaseURL } from "../../BaseUrlConfig";
import axios from 'axios'

function GetRouteConfig(token)
{
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
}

export const QueryEmployeeByID = async()=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'employee/query_employee_id');
        const res = await response.data;
        return res;

    }catch(err) 
    {
        throw err;
    }
} 