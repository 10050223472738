import classes from './EditMasterProduct.module.css'
import data_win from '../../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { faObjectGroup, faObjectUngroup, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FetchBranchAll, FetchProductLineMasterGroup, UpdateProductLineMasterGroup } from '../../../../../../api';
import { useToast } from '../../../../../../ToastContext';
import { useAuth } from '../../../../../../AuthProvider';

import MasterDataRegInput from '../../../../../../SharedComp/CommonStyle/MasterDataRegInput';

const EditMasterProduct = ({OpenModal, TargetID}) => {


    const notify = useToast();
    const auth = useAuth();

    const [FetchingData, SetFetchingData] = useState(false);
    const [Loading, SetLoading] = useState(false);
    const [WorkspaceList, SetWorkspaceList] = useState([]);
    const [WorkspaceChecked, SetWorkspaceChecked] = useState(new Map());
    const [ToggelListTrue, SetToggelListTrue] = useState(false);

    const [TargetData, SetTargetData] = useState();

    const [Sname, SetSname] = useState();
    const [Sdesciption, SetSdesciption] = useState();

    const [IsLocalProduction, SetIsLocalProduction] = useState(true);
    const [IncludePreorder, SetIncludePreorder] = useState(false);

    const handleToggle = (branchName) => {
        SetWorkspaceChecked(prevState => {
          const updatedState = new Map(prevState);
          updatedState.set(branchName, !updatedState.get(branchName));
          return updatedState;
        });
      };

    const selectAll = (val) => {

    SetToggelListTrue(val);

    let updated_list = new Map();
    for(const [mp_key, mp_val] of WorkspaceChecked.entries()) 
    {
        updated_list.set(mp_key, val);
    }
    SetWorkspaceChecked(updated_list);    
    };
    const QueryWorkspace = async () => {

        try {
            const res = await FetchBranchAll(auth.token);

            // for now prepare void list
            let init_check_list = new Map();
            for(let  i = 0; i < res.length; i++) 
            {
                if(!init_check_list.has(res[i].BranchName)) 
                    init_check_list.set(res[i].BranchName, false); 
            }
            SetWorkspaceChecked(init_check_list);

            SetWorkspaceList(res);
        } catch (err) 
        {
            throw new Error(err);
        }
    }

    const QueryTargetData = async () => {

        try 
        {
            const res = await FetchProductLineMasterGroup(TargetID, auth.token);
            SetTargetData(res);
            SetSdesciption(res.ProdCatDescription);
            SetSname(res.ProdCatName);

            SetIsLocalProduction(res.ProdCatLocalProduction);
            SetIncludePreorder(res.ProdCatCanPreorder);

            const crt_data =  new Map(Object.entries(JSON.parse(res.ProdCatAvailBranch)));
            let updated_list = new Map();
            for(let  i = 0; i < WorkspaceList.length; i++) 
            {
                const _workspace_name = WorkspaceList[i].BranchName;
                if(crt_data.has(_workspace_name))
                {
                    updated_list.set(_workspace_name, crt_data.get(_workspace_name));
                } else 
                {
                    updated_list.set(_workspace_name, false);
                }
            }
            SetWorkspaceChecked(updated_list); 
            SetFetchingData(false);

        } catch(err) 
        {
            SetFetchingData(false);
        }

    }

    const HandleSubmition = async (e) => 
    {
        e.preventDefault();

        SetLoading(true);
        notify.ShowLoad('Locking in Your Obligations...', 10);
        
        setTimeout(async ()=>{

            try 
            {
                const fd = new FormData();
                fd.append("group_name", Sname);
                fd.append("workspace", JSON.stringify(Object.fromEntries(WorkspaceChecked)));
                fd.append("group_detail", Sdesciption);
                fd.append("local_production", IsLocalProduction);
                fd.append("include_in_preorder", IncludePreorder);
                fd.append("original_id", TargetData.ProdCatID);


                const res = await UpdateProductLineMasterGroup(fd, auth.token);

                SetLoading(false);
                notify.ShowSuccessUpdate(res.Msg, 10);
                OpenModal(false);
            } catch(err) 
            {
                SetLoading(false);
                notify.ShowErrorUpdate(err.message, 10);
            }

        }, 3000)
    }
    
    useEffect(()=>{
        QueryTargetData();
    }, [WorkspaceList])

    useEffect(()=>{
        SetFetchingData(true);
        QueryWorkspace();
    }, [])

    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>
            <div className={`${data_win.data_main} ${classes.data_main}`}>
                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        Edit <span className={`${data_win.data_create_name}`}>Master Product</span>
                    </h3>
                    <button onClick={()=>{OpenModal(false)}}>
                        <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                    </button>
                </div>

                <div className={`${data_win.data_body}`}>
                        
                

                            <form onSubmit={HandleSubmition} action="POST">
                                <div className={`${classes.form_wrapper}`}> 

                                    <div className={`${classes.master_prod_type_config_wrapper}`}>
                                        <div className={`${classes.master_product_type_inner_config_wrapper}`}>
                                            
                                            <MasterDataRegInput input={
                                                <input onChange={(e)=>{SetSname(e.target.value)}} type="text" value={Sname} />
                                            }/>
                                            <MasterDataRegInput input={
                                                <textarea onChange={(e)=>{SetSdesciption(e.target.value)}} name="detail" value={Sdesciption}></textarea>
                                            }/>

                                            <div onClick={()=>{SetIsLocalProduction(!IsLocalProduction)}} className={`${classes.prodution_group_type}`}>
                                                <span>Produced Locally</span>
                                                <input onChange={()=>{}} checked={IsLocalProduction} type="checkbox" />
                                            </div>
                                            <div onClick={()=>{SetIncludePreorder(!IncludePreorder)}} className={`${classes.prodution_group_type}`}>
                                                <span>Include In Pre-Order</span>
                                                <input onChange={()=>{}} checked={IncludePreorder} type="checkbox" />
                                            </div>
                                        </div>

                                        <div className={`${classes.master_product_type_inner_avail_workspace_list}`}>
                                            <div className={`${classes.master_product_type_inner_avail_workspace_list_header}`}>
                                                <h1>Accessibility</h1>
                                                <button type='button' onClick={()=>{selectAll(!ToggelListTrue)}}><FontAwesomeIcon icon={ToggelListTrue ? faObjectUngroup : faObjectGroup}/></button>
                                            </div>
                                            <div className={`${classes.master_prod_avail_list}`}>
                                                {
                                                    WorkspaceList.map((item, idx)=>(
                                                        <div key={idx} onClick={() => handleToggle(item.BranchName)} className={`${classes.master_prod_avail_branch}`}>
                                                            <input
                                                            onClick={() => handleToggle(item.BranchName)} 
                                                            checked={WorkspaceChecked.get(item.BranchName)} 
                                                            name={item.BranchName} 
                                                            type="checkbox"
                                                            onChange={() => handleToggle(item.BranchName)}
                                                             />
                                                            <li>{item.BranchName}</li>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>



                                       
                                </div>
                                <div className={`${data_win.form_action_button_wrapper}`}>
                                    <button disabled={Loading} className={`${data_win.form_action_button_save}`}>{Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Update'}</button>
                                    <button disabled={Loading}  onClick={()=>{OpenModal(false)}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                                </div>
                            </form>




                </div>
            </div>

        </div>
    )
}

export default EditMasterProduct;