import { faArrowUp, faPenNib, faSackDollar, faSortUp, faSpinner, faTurnDown, faTurnUp } from '@fortawesome/free-solid-svg-icons'
import classes from './JobPay.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useEffect } from 'react';
import { Tooltip } from 'react-tooltip';
import axios from 'axios';
import { useRef } from 'react';
import { getBaseURL } from '../../../../../../../SharedComp/BaseUrlConfig';


function formatDuration(days) {
    const years = Math.floor(days / 365);
    const months = Math.floor((days % 365) / 30);
    const remainingDays = days % 30;

    const final_year =  ` ${years} year${years !== 1 ? 's' : ''} `;
    const final_month = ` ${months} month${months !== 1 ? 's' : ''} `;
    const final_day =  ` ${remainingDays} day${remainingDays !== 1 ? 's' : ''} `
  
    var result = '';
    if(years > 0) 
    {
        result += final_year;
    }
    if(months > 0) 
    {
        result += final_month;
    }
    if(remainingDays > 0) 
    {
        result += final_day;
    }
  
    return result;
}

export default function JobPay({EmpData, MsgTrigger, ReqRefresh}) 
{
    const formRef = useRef();
    const [enableEdit, SetEnableEdit] = useState(false);
    const [OpenSalaryState, SetOpenSalaryState] = useState(false);
    const [ButtonOnAction, setButtonOnAction] = useState(false);
    const [RaisePercent, SetRaisePercent] = useState(0.0);

    function HandleSalaryRaisOpen() 
    {
        SetOpenSalaryState(!OpenSalaryState);
    }
    function HandleSalaryClose() 
    {
        // setTimeout(()=>{
        //     SetOpenSalaryState(false);
        // }, 2 * 5000)
    }
    function CalcServiceDay(day) 
    {
        const today = new Date();
        const emp_start_day = new Date(day);
        const timeDifference = today.getTime() - emp_start_day.getTime();
        return formatDuration(Math.floor(timeDifference / (1000 * 60 * 60 * 24)));
    }

    function handleEmployeeSubmition(event) 
    {

        setButtonOnAction(true);
        event.preventDefault();

        setTimeout(() =>{

            const fd = new FormData();

            fd.append('raise_amount', event.target.raise_amount.value);
            fd.append('current_amount', EmpData.CurrentSalary);
            fd.append('employee_profile_id', EmpData.EmpCompanyProfileID)
            fd.append('EmpID', EmpData.EmployeeID);

            axios.post(getBaseURL() + 'employee/raise_employee_salary', fd, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                }).then(res => {
                    formRef.current.reset();
                    setButtonOnAction(false);
                    MsgTrigger(res);
                    SetOpenSalaryState(false);
                    ReqRefresh();
                })
                .catch(err => {
                    formRef.current.reset();
                    setButtonOnAction(false);
                    SetOpenSalaryState(false);
                    MsgTrigger(err);
                })

        }, 2000)
    }

    useEffect(() =>{
        SetRaisePercent(((EmpData.CurrentSalary / EmpData.Salary) - 1.0).toFixed(3));
    }, [ButtonOnAction])

    return (
        <div className={`${classes.job_and_pay_main_wrapper}`}>
            <Tooltip className={`${classes.tool_quick}`} id="offical_doc" place="top" />
            <div className={`${classes.job_and_pay_inner_wrapper}`}>
                
               <li>Department of <span className={`${classes.job_and_pay_value}`}>{EmpData.DepName}</span></li>
               <li>Position <span className={`${classes.job_and_pay_value}`}>{EmpData.PositionName}</span></li>
               <li>In Service For <span className={`${classes.job_and_pay_value}`}>{CalcServiceDay(EmpData.EmploymentStartDate)}</span></li>

            </div>

            <div className={`${classes.job_and_pay_inner_wrapper}`}>
                
                        <li>Starting Salary  
                            <span className={`${classes.job_and_pay_value}`}>
                                {
                                    (EmpData.Salary).toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'ETB',
                                    })
                                }
                            </span>
                        </li>

                        <li>Current Salary 
                            <span className={`${classes.job_and_pay_value}`}>
                                {
                                    (EmpData.CurrentSalary).toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'ETB',
                                    })
                                }
                            </span>
                        </li>

                    <li>Average Raise In Percentage 
                        <span className={`${classes.job_and_pay_value} ${classes.job_and_pay_value_prc}`}>
                            {((EmpData.CurrentSalary / EmpData.Salary) - 1.0).toFixed(3) > 0.01 ? <span className={`${classes.up_indicate}`}><FontAwesomeIcon icon={faArrowUp}/></span> : ''}{(((EmpData.CurrentSalary / EmpData.Salary) - 1.0) * 100).toFixed(3)}%
                        </span>
                    </li>

                    <div className={`${classes.salary_action_wrapper}`}>
                        <button onClick={()=>{HandleSalaryRaisOpen()}} {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': "Raise Employee Salary"})}><span className={`${classes.up_main}`}><FontAwesomeIcon icon={faTurnUp}></FontAwesomeIcon><FontAwesomeIcon icon={faSackDollar}></FontAwesomeIcon></span></button>
                        {/* <button {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': "I don't know the word for it but it is like take the money from employee for some reason!"})}><span className={`${classes.down_main}`}><FontAwesomeIcon icon={faTurnDown}></FontAwesomeIcon><FontAwesomeIcon icon={faSackDollar}></FontAwesomeIcon></span></button> */}

                        <div onMouseLeave={HandleSalaryClose()} className={`${OpenSalaryState ? classes.salary_action_form_wrapper : classes.salary_action_form_wrapper_hide}`}>
                            <form ref={formRef} onSubmit={handleEmployeeSubmition} action="POST">
                                <div className={`${classes.salary_action_form_input}`}>
                                    <input name='raise_amount' type="number" min={1} placeholder='By amount'  />
                                </div>
                                <div className={`${classes.salary_action_form_submit}`}>
                                    <button className={`${ButtonOnAction ? classes.button_on_action : ''}`} disabled={ButtonOnAction}>{ButtonOnAction ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Raise'}</button>
                                </div>
                            </form>
                        </div>
                    </div>
 
             </div>


        </div>
    )
}