import classes from './ShopItemCreate.module.css'
import data_win from '../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import {Custom_DD_Style, PrepareDropDownList} from '../../../../../SharedComp/Utility'
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import MasterDataRegInput from '../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import Select from 'react-select'
import axios from 'axios';
import { useRef, useState } from 'react'
import { useEffect } from 'react'

export default function ShopeItemCreator({Close_CALLBACK, RES_CALLBACK}) 
{
    const formRef = useRef();
    const[registring, SetRegistring] = useState(false);

    const[MechType, SetMerchType] = useState(null);
    const[VendorType, SetVendorType] = useState(null);
    const[Quantity, SetQuantity] = useState(null);
    const[StockData, SetStockDate] = useState(null);

    const[MerchList, SetMerchList] = useState([]);
    const[VendorList, SetVendorList] = useState([]);

    function HandleSubmition(e) 
    {
        e.preventDefault();

        SetRegistring(true);

        const fd = new FormData();

        fd.append("merch_id", MechType.value);
        fd.append("merch_name", MechType.label);

        fd.append("vendor_id", VendorType.value);
        fd.append("vendor_name", VendorType.label);

        fd.append("quantity", Quantity);
        fd.append("stock_date", StockData);

        const url = 'http://localhost:3333/operation-data/add-local-store-item';

        axios.post(url, fd, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            }).then(res=>{
                SetRegistring(false);
                RES_CALLBACK(res);

            }).catch(err => {
                SetRegistring(false);
                RES_CALLBACK(err);
            })
    }

    useEffect(()=>{

        fetch('http://localhost:3333/master-data/get-merch-item')
        .then(res => res.json())
        .then((data) => PrepareDropDownList(data, SetMerchList));

        fetch('http://localhost:3333/operation-data/get-vendor')
        .then(res => res.json())
        .then((data) => PrepareDropDownList(data, SetVendorList));

    }, [])
    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>


            <div className={`${data_win.data_main}`}>

                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        JAK <span className={`${data_win.data_create_name}`}>Add Shop Item</span>
                    </h3>
                    <button onClick={()=>{Close_CALLBACK(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
                </div>

                <div className={`${classes.data_body}`}>

                        <form onSubmit={HandleSubmition} ref={formRef} action="POST">
                                <div className={`${classes.form_wrapper}`}> 
                                        <div  className={`${classes.input_list_wrapper}`}>
                                            <div className={`${classes.input_wrap}`}>
                                                <MasterDataRegInput 
                                                    input={
                                                        <Select
                                                            onChange={(data)=>{SetMerchType(data)}}
                                                            name={"dd_data"}
                                                            options={MerchList}
                                                            placeholder={"Select Merch.."}
                                                            isSearchable={true}
                                                            styles={Custom_DD_Style}
                                                            required ={true}
                                                        />
                                                        
                                                    } tip={"Select Merchandise"} />
                                            </div>
                                            <div className={`${classes.input_wrap}`}>
                                                        <MasterDataRegInput 
                                                            input={
                                                                <Select
                                                                    onChange={(data)=>{SetVendorType(data)}}
                                                                    name={"dd_data"}
                                                                    options={VendorList}
                                                                    placeholder={"Vendor..."}
                                                                    isSearchable={true}
                                                                    styles={Custom_DD_Style}
                                                                    required ={true}
                                                                />
                                                            
                                                        } tip={"Select Vendor"} />
                                            </div>
                                            <div className={`${classes.input_wrap}`}>
                                                <MasterDataRegInput input={<input min={0} max={9999} onChange={(e)=>{SetQuantity(e.target.value)}} name='merch_qunatity' type="number" placeholder='Stock Quantity' title='' autoComplete='off' required />} tip={"Merchandise Quantity you are currently stocking! If You Need Tip For This I Guess!"}/>
                                            </div>

                                            <div className={`${classes.input_wrap}`}>
                                                <MasterDataRegInput input={<input onChange={(e)=>{SetStockDate(e.target.value)}} name='merch_qunatity' type="date" autoComplete='off'  />} tip={"Optional, Default is today! But if You Miss Registration.."}/>
                                            </div>
                                            

                                            

                                        </div>
                                </div>
                                <div className={`${data_win.form_action_button_wrapper}`}>
                                    <button disabled={registring} className={`${data_win.form_action_button_save}`}>{registring ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Add'}</button>
                                    <button disabled={registring}  onClick={()=>{Close_CALLBACK(false)}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                                </div>
                            </form>

                </div>

            </div>

        </div>
    )
}