import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import classes from './MiniNotification.module.css'
import data_win from '../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { Tooltip } from 'react-tooltip';
export default function MiniNotification({DataList, ClearCallback}) 
{

    const [IsMsgBoxHovered, SetIsMsgBoxHovered] = useState(false);

    return (
        <div 
        onMouseOver={() => SetIsMsgBoxHovered(true)}
        onMouseOut={() => SetIsMsgBoxHovered(false)}
        className={`${classes.mini_noti_main_warpper} ${IsMsgBoxHovered ? classes.mini_noti_wrapper_no_anim : classes.mini_noti_wrapper_anim}`}
        style={{
            
        }}
        >
             <Tooltip className={`${data_win.tool_quick}`} id="offical_doc" place="top" />
            <div className={`${classes.min_noti_header}`}>
                <h3> <FontAwesomeIcon icon={faEnvelope}> </FontAwesomeIcon> Quick Notice <span className={`${classes.helper_note}`}>BASED ON LAST CASH COLLECTION</span> </h3>
            </div>
            <div className={`${classes.mini_msg_outer_wrapper}`}>
                
                <div className={`${classes.msg_wrapper}`}>
                    {
                        DataList.map((item, index)=>(
                            <li key={index}>
                                <span className={`${classes.msg_icon}`}><FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon></span>
                                <span className={`${classes.msg_cont}`}>Merchandise item ' <span className={`${classes.msg_item_core}`}>{item.Msg}</span> ' is out of stock! Please consider reordering.</span>
                            </li>
                        ))
                    }
                
                </div>

                <div className={`${classes.msg_action_buttons}`}>
                    <button {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': "Read this message carefully before you click, as you won't see it again! Keep it until you're ready to proceed at least, But you can click it if you want this is not a law or anything!"})} onClick={()=>{ClearCallback()}}>Sure</button>
                </div>
            </div>

        </div>
    )
}