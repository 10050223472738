import './CompanyOperationMasterDataStyle.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircleCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import DataTableWrapper from '../../SharedComp/DataTable/DataTableWrapper';
import OperationDataCreator from './OperationDataCreator';
import MiniPopup from '../../SharedComp/MiniPopup/MiniPopup'
import { useState } from 'react';

export default function CompanyOperationMasterData() 
{
    const [openCreateWin, setOpenCreateWin] = useState(false);
    const [currentWinName, setCurrentWinName] = useState('');
    const [showAddPopup, setShowAddpopup] = useState(false);
    const [backendResponse, setBackendResponse] = useState('');
    const [popupMsgTheme, setPopupMsgTheme] = useState(['', ''])
    const [newEntryFlag, setNewEntryFlag] = useState(false);
    
    function handleCreateWindowOpen(win_name) 
    {
        setCurrentWinName(win_name);
        setOpenCreateWin(!openCreateWin);
    }

    function setMessagePopup(success, open_popup, response_msg) 
    {
        if(success) 
        {
            setPopupMsgTheme([
                '#1a936f',
                '#114b5f',
                '#e4dcef',
                faCircleCheck
            ]);
            setNewEntryFlag(!newEntryFlag);
        } else 
        {
            setPopupMsgTheme([
                '#880e4f', // main color
                '#ad1457', // secondary color
                '#e4dcef', // text color
                faXmark
            ]);
        }
        setShowAddpopup(open_popup);
        setBackendResponse(response_msg);
        if(success)
        {
            setCurrentWinName('');
            setOpenCreateWin(false);
        }
    }

    return (
        <div className="compoany-operation-master-data-wrapper">

            

            <OperationDataCreator 
                Open={openCreateWin} 
                setOpenCreator={setOpenCreateWin} 
                win_name={currentWinName}
                setMessagePopup={setMessagePopup}
            />

            <MiniPopup
                open={showAddPopup}
                msg={backendResponse}
                Theme={popupMsgTheme}
            />


            

            <div className="main-data-entry-page-wrapper">
 
 {/* working with active bank */}
                    <DataTableWrapper
                        WrapperName={'Active Bank'}
                        WinName={'Bank'}
                        ThemeColor={'var(--background-light)'}
                        WrapperWidth={'90%'}
                        RouteAddress={'http://localhost:3333/master-data/get-bank'}
                        RegisterCallback={handleCreateWindowOpen}
                        NewEntryFlag={newEntryFlag}
                    />

                    <DataTableWrapper
                        WrapperName={'Active Department'}
                        ThemeColor={'var(--background-light)'}
                        WrapperWidth={'90%'}
                        WinName={'Department'}
                        RouteAddress={'http://localhost:3333/master-data/get-department'}
                        RegisterCallback={handleCreateWindowOpen}
                        NewEntryFlag={newEntryFlag}
                    />

                    <DataTableWrapper
                        WrapperName={'Shift'}
                        ThemeColor={'var(--background-light)'}
                        WrapperWidth={'90%'}
                        WinName={'Shift'}
                        RouteAddress={'http://localhost:3333/master-data/get-shift'}
                        RegisterCallback={handleCreateWindowOpen}
                        NewEntryFlag={newEntryFlag}
                    />

                    <DataTableWrapper
                        WrapperName={'Utility Expense Type'}
                        ThemeColor={'var(--background-light)'}
                        WrapperWidth={'90%'}
                        WinName={'Utility Expense'}
                        RouteAddress={'http://localhost:3333/master-data/get-expense-type'}
                        RegisterCallback={handleCreateWindowOpen}
                        NewEntryFlag={newEntryFlag}
                    />
                </div>

        </div>
    );
}