import classes from './ChefsPerformance.module.css'


export default function ChefsPerformance() 
{



    return (
        <div className={`${classes.chefs_performance_main_wrapper}`}>
            <h1>Chefs Performance</h1>
        </div>
    )
}