import { faCalendarAlt, faCheckCircle, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import classes from './CashCollectionArchive.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useEffect } from 'react';
import { getBaseURL } from '../../../SharedComp/BaseUrlConfig';

import CashCollectionDetailView from './Component/CashCollectionDetailView';


const getCurrentNthWeekdayOfMonth = (currentDate) => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const dayOfMonth = currentDate.getDate();
    const weekday = currentDate.getDay(); // Day of the week (0 - Sunday, 1 - Monday, ..., 6 - Saturday)
  
    // Find the nth occurrence of the weekday in the month
    let nth = 1;
    for (let day = 1; day <= dayOfMonth; day++) {
      const tempDate = new Date(year, month, day);
      if (tempDate.getDay() === weekday) {
        if (day === dayOfMonth) {
          return nth+1;
        }
        nth++;
      }
    }
  
    return null; // If the current day is not found (should not happen if currentDate is correct)
  };

export default function CashCollectionArchive() 
{

    const [CollectionList, SetCollectionList] = useState([]);
    const [OpenDetailView, SetOpenDetailView] = useState(false);

    const [FilterRefDate, SetFilterRefDate] = useState(null);
    const [FilterRefBranchID, SetFilterRefBranchID] = useState(null);
    const [TheOneSummery, SetTheOneSummery] = useState(null);
    
    const [DateIterLastMonth, SetDateIterLastMonth] = useState([]);
    const [DateIterThisWeek, SetDateIterThisWeek] = useState([]);

    const currentDate = new Date();


    useEffect(()=>{

        fetch(getBaseURL() + 'operation-data/query_cash_collection_by_branch', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({BranchID: 1, RefDate: currentDate})
        })
        .then(res => res.json())
        .then((data)=>{
            SetCollectionList(data);
        }).
        catch(err => {
            console.log(err)
        })

        

        // fill this week
        let day_accum = [];
        let _nth_day_today = getCurrentNthWeekdayOfMonth(currentDate);
        for(let  i = 0; i <= _nth_day_today; i++) 
        {
            let _active_date = new Date();
            _active_date.setDate(currentDate.getDate() - (_nth_day_today - i));
            day_accum.push(_active_date);
        }

        SetDateIterThisWeek(day_accum);

        const offseted_day = new Date();
        offseted_day.setDate(currentDate.getDate() - _nth_day_today);
        day_accum = [];
        let _back_day = 30;
        for(let  i = 0; i < _back_day; i++) 
        {
            let _active_date = new Date();
            _active_date.setDate(offseted_day.getDate() - (_back_day - i));
            day_accum.push(_active_date);
        }
        SetDateIterLastMonth(day_accum);



    }, []);

    function PrepareDetailView(ref_date, branch_id) 
    {
        SetFilterRefDate(ref_date);
        SetFilterRefBranchID(branch_id);
        SetOpenDetailView(true);
    }

    return (
        <div className={`${classes.cash_collection_archive_main_wrapper}`}>

            {OpenDetailView && <CashCollectionDetailView ref_date={FilterRefDate} branch_id={FilterRefBranchID} SummeryData={TheOneSummery} close_callback={SetOpenDetailView}/> }

            <div className={`${classes.cash_collection_list_wrapper}`}>

                
                <div className={`${classes.cash_collection_date_list_wrapper}`}>
                        <div className={`${classes.this_week_cash_collection_list_wrapper}`}>

                            <div className={`${classes.header_week}`}>
                                <h2><FontAwesomeIcon icon={faCalendarAlt}></FontAwesomeIcon> Last Month <span className={`${classes.header_helper_note}`}>Execluding this week</span> </h2>
                            </div>

                            <div className={`${classes.inner_list_wrapper}`}>
                            
                            {
                                    DateIterLastMonth.map((day, idx)=>{
                                        
                                        let _good  = false;
                                        let _item = CollectionList.find((item, idx)=>{return new Date(item.Date).toDateString() == day.toDateString()});
                                        if(_item)
                                        {
                                            _good = true;
                                        }
                                            return <li key={idx} onClick={(e)=>{
                                                if(_good) 
                                                {
                                                    PrepareDetailView(_item.Date, _item.BranchID); 
                                                    SetTheOneSummery(_item)
                                                }
                                            }}> <span className={`${_good ? classes.day_report_text_good : classes.day_report_text_bad}`}>{day.toDateString()}</span> <span className={`${_good ? classes.day_report_icon_good : classes.day_report_icon_bad}`}><FontAwesomeIcon icon={_good ? faCheckCircle : faTriangleExclamation}></FontAwesomeIcon></span></li>
                                        })
                                }
                            </div>

                            
                        </div>

                        <div className={`${classes.last_week_cash_collection_list_wrapper}`}>

                            <div className={`${classes.header_week}`}>
                                <h2> <FontAwesomeIcon icon={faCalendarAlt}></FontAwesomeIcon> This Week</h2>
                            </div>

                            <div className={`${classes.inner_list_wrapper}`}>
                                
                                {
                                    DateIterThisWeek.map((day, idx)=>{
                                        
                                        let _good  = false;
                                        let _item = CollectionList.find((item, idx)=>{return new Date(item.Date).toDateString() == day.toDateString()});
                                        if(_item)
                                        {
                                            _good = true;
                                        }
                                            return <li key={idx} onClick={(e)=>{
                                                if(_good) 
                                                {
                                                    PrepareDetailView(_item.Date, _item.BranchID); 
                                                    SetTheOneSummery(_item)
                                                }
                                            }}> <span className={`${_good ? classes.day_report_text_good : classes.day_report_text_bad}`}>{day.toDateString()}</span> <span className={`${_good ? classes.day_report_icon_good : classes.day_report_icon_bad}`}><FontAwesomeIcon icon={_good ? faCheckCircle : faTriangleExclamation}></FontAwesomeIcon></span></li>
                                        })
                                }
                                {/* {
                                    CollectionList.map((item, index)=>(
                                        <li onClick={(e)=>{PrepareDetailView(item.Date, item.BranchID); SetTheOneSummery(item)}} key={index}> <span className={`${classes.day_report_text_good}`}>{new Date(item.Date).toDateString()}</span> <span className={`${classes.day_report_icon_good}`}><FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon></span></li>
                                    ))
                                } */}
                                
                            </div>
                            
                        </div>
                </div>
            </div>

        </div>
    )
}