import classes from './DistributionGroup.module.css'

import { useState } from 'react'
import { useEffect } from 'react';
import { Tooltip } from 'react-tooltip'
import data_win from '../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import {QueryShippingReadyProducts} from '../../../../../SharedComp/API/CK/AP_CenteraKitchenOrder'


import { useToast } from '../../../../../ToastContext';

import TimeElapsed from '../../../../../SharedComp/TimeElapsed/TimeElapsed';
import CountdownTimer from '../../../../../SharedComp/CountDown/CountDownTimer';
import { useDAControl } from '../../../../../DAControlContext';

import UIButton from '../../../../Components/UIButton/UIButton';
import { Checks, Stack, Van } from '@phosphor-icons/react';

import ShippingDialog from '../ShippingDialog/ShippingDialog';


function getFormattedDate(dateValue) {
    return new Date(dateValue).toLocaleDateString('en-US', {
      weekday: 'long', 
      month: 'short',  
      day: '2-digit'
    //   year: 'numeric'  
    });
  }
function getFormattedTime(dateValue) {
    return new Date(dateValue).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true 
    });
}
function isDateInThePast(inputDate) {
    const now = new Date();
    const providedDate = new Date(inputDate);
    return now < providedDate;
}

export default function DistributionGroup({Data})
{

    const notify = useToast();
    const DAControl = useDAControl();
    const [SubProductList, SetSubProductList] = useState([]);
    const [RefreshView, SetRefreshView] = useState(false);
    const [OpenShippingDialog, SetOpenShippingDialog] = useState(false);



    const FetchShipReadyProducts = async() =>
    {
        try 
        {

            const fd = new FormData();
            fd.append("source_workspace_id", Data.CKAOG_SourceWorkspace);
            fd.append("destination_workspace_id", Data.CKAOG_DestinationWorkspace);
            fd.append("group_id", Data.CKAOGID);
            fd.append("targte_date", new Date());

            const main_res = await QueryShippingReadyProducts(fd, 'token'); 
            SetSubProductList(main_res);
        }catch(err) 
        {

        }
    }


    useEffect(()=>{

        FetchShipReadyProducts();

    }, [RefreshView])


    return (
        <div className={`${classes.order_group_main_wrapper}`}>

            {OpenShippingDialog && <ShippingDialog UpdateViewToggel={RefreshView} UpdateView={SetRefreshView} DataList={SubProductList} GroupData={Data} Close_CALLBACK={SetOpenShippingDialog}/> }

            <Tooltip className={`${data_win.tool_quick}`} id="offical_doc" place="top" />
            <div className={`${classes.order_group_main_header_wrapper}`}>
                <h2>Shipping to #{Data.BranchName}</h2>
            </div>


            <div className={`${classes.group_body_wrapper}`}>

                {
                    SubProductList.map((item, idx)=>(
                        <li key={item.CKP_ItemName}>
                            <span>{item.CKP_ItemName}</span>
                            <span className={`${classes.quantity_data_wrapper}`}>
                                <span className={`${classes.total_amount_wrapper}`}>Ordered: {item.CKAOPID_Quantity} </span>
                                <span>Produced: {item.CKSRBUF_Quantity} </span>
                            </span>
                          <span className={`${classes.action_button_wrapper_group}`}>
                                
                                    <UIButton Tip={"Response Accuracy"} Icon={<Stack/>} label={((item.CKSRBUF_Quantity / item.CKAOPID_Quantity) * 100) + '%'} />

                                    <UIButton
                                        Icon={<Checks/>}
                                        onClick={()=>{
                                            DAControl.Trigger(`Reject Order Productt`, 
                                            `You are about to reject the rest of this products which is ${item.CKAOPID_Quantity} the dispatched amount won't be affected.`,
                                            true,
                                            5, (val)=>{
                                                if(val !== null) 
                                                {

                                                }
                                            }   
                                        );}}
                                  label={"Finish"} Variant='Secondary'/>
                            </span> 
                        </li>
                    ))
                }
                {
                    SubProductList.length === 0 &&
                    <div className={`${classes.expecting_action}`}>
                        <h2>Waiting Production Delivery...</h2>
                    </div>
                }

            </div>

            <div className={`${classes.dispatch_group_footer}`}>
                    <div className={`${classes.dispatch_group_action_button_wrapper}`}>
                    </div>

                    <div className={`${classes.right_side_dispatch_group_button_wrapper}`}>
                        <UIButton onClick={()=>{SubProductList.length === 0 ? notify.ShowError("Wait until product accumulate!") : SetOpenShippingDialog(true)}} label={"Prepare Shipping"} Icon={<Van/>}/>
                    </div>
            </div>

        </div>
    )
}