import './WarehouseItemStyle.css'
import DataTableWrapper from '../../SharedComp/DataTable/DataTableWrapper'



export default function WarehouseItem() 
{
    return (
        <div className="warehouse-item-main-wrapper">

            <div className="ware-house-table-wrapper">

                
                    {/* GORO Branch Data */}
                <div className="min-table-wrapper">

                        <DataTableWrapper
                            WrapperName={'Warehouse Item  -[ Goro ]-'}
                            ThemeColor={'#EF4056'}
                            WrapperWidth={'90%'}
                        />
                </div>


                   {/* Sefera Branch Data */}
                <div className="min-table-wrapper">

                    <DataTableWrapper
                        WrapperName={'Warehouse Item -[ Sefera ]-'}
                        ThemeColor={'#482ff7'}
                        WrapperWidth={'90%'}
                    />
                </div>


                   {/* Sefera Branch Data */}
                   <div className="min-table-wrapper">

                        <DataTableWrapper
                            WrapperName={'Warehouse Item -[ Lomi Meda ]-'}
                            ThemeColor={'#2185d5'}
                            WrapperWidth={'90%'}
                        />
                    </div>



            </div>

        </div>
    );
}