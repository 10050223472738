
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import classes from './MainMenuTag.module.css'


export default function MainMenuTag({Name, Idx, IsSubMenuOpen, Icon, FUNC_ToggelSubMenu, ActiveMainMenuIdx, URL, IconOnly}) 
{

    return (
        <div className={`${classes.main_menu_tag_wrapper} ${ (ActiveMainMenuIdx == Idx || IsSubMenuOpen) ? classes.active_main_menu : ''}`}>
            <li>
             <NavLink
                onClick={() => FUNC_ToggelSubMenu(Idx)} 
                className={`${classes.main_menu} ${ (ActiveMainMenuIdx == Idx || IsSubMenuOpen) ? classes.active_menu_item : ''}`} 
                to={URL}
                >
                    
                    {
                        IconOnly ? (
                            <span><FontAwesomeIcon  className={`${classes.main_menu_left_side_icon}`} icon={Icon} /></span>
                        ) : (
                            <>
                            
                            <span className={`${classes.main_link_tag_info}`}>
                                <span className={`${classes.main_menu_left_side_icon}`}>
                                    <FontAwesomeIcon icon={Icon} />
                                </span>
                                <span className={`${classes.main_link_tag}`}> {Name}</span>
                            </span>
                            
                            <span className={`${classes.pointer_icon}`}> 
                                    <FontAwesomeIcon className={`${classes.main_menu_right_side_icon}`} icon={IsSubMenuOpen ? faAngleUp : faAngleDown}/>
                            </span>
                            </>
                        )
                    }

                   
 
                </NavLink> 
            </li>
        </div>
    );
}