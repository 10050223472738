import { faTimesRectangle, faLayerGroup, faSpinner } from '@fortawesome/free-solid-svg-icons';
import classes from './PenalityAction.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { getBaseURL } from '../../../../../../../SharedComp/BaseUrlConfig';



function HandleCashPenality(registring, formRef, HandleSubmition) 
{
    return (
        <form ref={formRef} onSubmit={HandleSubmition} action="POST">
                        <div className={`${classes.penality_input_main}`}>
                            <span className={`${classes.penality_input_main_icon}`}><FontAwesomeIcon icon={faLayerGroup}></FontAwesomeIcon></span>
                            <input min={1} name='cash_amount' type="number" placeholder='Cash Amount' required/>
                        </div>
                        <div className={`${classes.penality_input_main}`}>
                            <span className={`${classes.penality_input_main_icon}`}><FontAwesomeIcon icon={faLayerGroup}></FontAwesomeIcon></span>
                            <input  name='reason' type="text" placeholder='Reason' />
                        </div>
                        <div className={`${classes.penality_input_main}`}>
                            <span className={`${classes.penality_input_main_icon}`}><FontAwesomeIcon icon={faLayerGroup}></FontAwesomeIcon></span>
                            <input name='pin_date' type="date" />
                        </div>

                        <div className={`${classes.penality_input_submit_action_button}`}>
                            <button disabled={registring} >{registring ? <FontAwesomeIcon icon={faSpinner} spin></FontAwesomeIcon> : "Penalitise Cash"}</button>
                        </div>
            </form>
    );
}
function HandleOtherPenality(registring, formRef, HandleSubmition, handleSelect, PenalityTypeList) 
{
    const Custom_DD_Style = {
        
        control: (provided, state) => ({
            ...provided,
            width: 370,
            minHeight: 20,
            height: 35,
            fontWeight: 'bold',
            fontSize: 13,
            background: 'var(--background-dark)',
            border: 'none',
            borderColor: 'var(--background-light)',
            color: 'var(--primary_text_color)',
            borderRadius: 1,
            borderColor: 'none', 
            boxShadow: 'none', 
            '&:hover': {
                borderColor: 'none', 
                boxShadow: 'none', 
            },
            }),
            option: (provided) => ({
            ...provided,
            backgroundColor: 'var(--primary-color)', 
            color: 'var(--secondary-color)',
            }),
            option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? 'var(--body-background)' : 'var(--primary-color)', 
            color: state.isFocused ? 'var(--primary-color)' : 'var(--secondary-color)',
            transition: 'background-color 0.2s ease-in-out',
            cursor: 'pointer',
        }),
            menu: (provided) => ({
            ...provided,
            zIndex: 9999,
            backgroundColor: 'var(--primary-color)',
            color: 'var(--primary-color)',
            fontSize: 13,
            borderRadius: 1,
            fontWeight: 500,
            }),
            singleValue: styles => ({
                ...styles,
                color: 'var(--text_light_white)', // Adjust this line for selected text color
              }),
        }

    return (
        <form ref={formRef} onSubmit={HandleSubmition} action="POST">
                        <div className={`${classes.penality_input_main}`}>
                            <span className={`${classes.penality_input_main_icon}`}><FontAwesomeIcon icon={faLayerGroup}></FontAwesomeIcon></span>
                            <Select
                                styles={Custom_DD_Style}
                                onChange={handleSelect}
                                name={"penality_type"}
                                options={PenalityTypeList}
                                placeholder={"Penality Type.."}
                                // value={selectedOptions}
                                isSearchable={true}
                                required ={true}
                            />
                        </div>
                        <div className={`${classes.penality_input_main}`}>
                            <span className={`${classes.penality_input_main_icon}`}><FontAwesomeIcon icon={faLayerGroup}></FontAwesomeIcon></span>
                            {/* <input  name='reason' type="text" placeholder='Reason' /> */}
                            <textarea name="reason" cols='55'  rows="5"  placeholder='Why is this penality is passed?' required></textarea>
                        </div>
                        <div className={`${classes.penality_input_main}`}>
                            <span className={`${classes.penality_input_main_icon}`}><FontAwesomeIcon icon={faLayerGroup}></FontAwesomeIcon></span>
                            <input name='pin_date' type="date" />
                        </div>

                        <div className={`${classes.penality_input_submit_action_button}`}>
                            <button disabled={registring} >{registring ? <FontAwesomeIcon icon={faSpinner} spin></FontAwesomeIcon> : "Penalitise"}</button>
                        </div>
            </form>
    );
}
function HandleCashPenalityUpdate(registring, formRef, HandleSubmition, existData, existDataUpdater) 
{
    function HandleInputUpdate(e) 
    {
        existDataUpdater(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    }
    return (
        <form ref={formRef} onSubmit={HandleSubmition} action="POST">
                        <div className={`${classes.penality_input_main}`}>
                            <span className={`${classes.penality_input_main_icon}`}><FontAwesomeIcon icon={faLayerGroup}></FontAwesomeIcon></span>
                            <input min={1} value={existData ? existData.Amount : ''} onChange={HandleInputUpdate} name='Amount' type="number" placeholder='Cash Amount' required/>
                        </div>
                        <div className={`${classes.penality_input_main}`}>
                            <span className={`${classes.penality_input_main_icon}`}><FontAwesomeIcon icon={faLayerGroup}></FontAwesomeIcon></span>
                            <input value={existData ? existData.Detail : ''} onChange={HandleInputUpdate}  name='Detail' type="text" placeholder='Reason' required />
                        </div>
                        <div className={`${classes.penality_input_main}`}>
                            <span className={`${classes.penality_input_main_icon}`}><FontAwesomeIcon icon={faLayerGroup}></FontAwesomeIcon></span>
                            <input value={existData ? new Date(existData.Date).toLocaleDateString('en-CA') : ''} onChange={HandleInputUpdate} name='Date' type="date" required />
                        </div>

                        <div className={`${classes.penality_input_submit_action_button}`}>
                            <button disabled={registring} >{registring ? <FontAwesomeIcon icon={faSpinner} spin></FontAwesomeIcon> : "Update Penality"}</button>
                        </div>
            </form>
    );
}

function HandleDemotionPenality(
    registring, 
    formRef, 
    HandleSubmition, 

    // Current Department  List
    selectedOptions, 
    optionList, 

    // Department List
    DepSelectedOption,
    DepOptionList,

    DemotionType, 
    handleSelect, 
    EmpData, 

    //New department controller
    SwitchDepartment, 
    SetSwitchDepartment,
    
    SetCurrentDepartment
    ) 
{
    

    const Custom_DD_Style = {
        
        control: (provided, state) => ({
            ...provided,
            width: 370,
            minHeight: 20,
            height: 35,
            fontWeight: 'bold',
            fontSize: 13,
            background: 'var(--background-dark)',
            border: 'none',
            borderColor: 'var(--background-light)',
            color: 'var(--primary_text_color)',
            borderRadius: 1,
            borderColor: 'none', 
            boxShadow: 'none', 
            '&:hover': {
                borderColor: 'none', 
                boxShadow: 'none', 
            },
            }),
            option: (provided) => ({
            ...provided,
            backgroundColor: 'var(--primary-color)', 
            color: 'var(--secondary-color)',
            }),
            option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? 'var(--body-background)' : 'var(--primary-color)', 
            color: state.isFocused ? 'var(--primary-color)' : 'var(--secondary-color)',
            transition: 'background-color 0.2s ease-in-out',
            cursor: 'pointer',
        }),
            menu: (provided) => ({
            ...provided,
            zIndex: 9999,
            backgroundColor: 'var(--primary-color)',
            color: 'var(--primary-color)',
            fontSize: 13,
            borderRadius: 1,
            fontWeight: 500,
            }),
            singleValue: styles => ({
                ...styles,
                color: 'var(--text_light_white)', // Adjust this line for selected text color
              }),
        }

        function HandleCurrentDep(data) 
        {
            SetCurrentDepartment(data.value);
        }

    return (
        <form ref={formRef} onSubmit={HandleSubmition} action="POST">
                        
                        <div className={`${classes.penality_input_dic}`}>
                            <div className={`${classes.penality_input_text}`}>
                                Switch department
                            </div>
                            <div className={`${classes.penality_input_check}`}>
                                <input onChange={()=>{SetSwitchDepartment(!SwitchDepartment)}} type="checkbox" />
                            </div>
                        </div>

                        {SwitchDepartment && <div className={`${classes.penality_input_main}`}>
                            <span className={`${classes.penality_input_main_icon}`}><FontAwesomeIcon icon={faLayerGroup}></FontAwesomeIcon></span>
                            <Select
                                styles={Custom_DD_Style}
                                onChange={HandleCurrentDep}
                                name={"demotion_type"}
                                options={DepOptionList}
                                placeholder={"Switch department to..."}
                                value={DepSelectedOption}
                                isSearchable={true}
                                required ={true}
                            />
                        </div> }
                        <br />

                        <div className={`${classes.penality_input_main}`}>
                            <span className={`${classes.penality_input_main_icon}`}><FontAwesomeIcon icon={faLayerGroup}></FontAwesomeIcon></span>
                            <input readOnly  value={"From [" + EmpData.PositionName + "...]"} name='job_from' type="text" />
                        </div>
                        
                        <div className={`${classes.penality_input_main}`}>
                            <span className={`${classes.penality_input_main_icon}`}><FontAwesomeIcon icon={faLayerGroup}></FontAwesomeIcon></span>
                            <Select
                                styles={Custom_DD_Style}
                                onChange={handleSelect}
                                name={"demotion_type"}
                                options={optionList}
                                placeholder={"Demot to.."}
                                value={selectedOptions}
                                isSearchable={true}
                                required ={true}
                            />
                        </div>

                        <div className={`${classes.penality_input_main}`}>
                            <span className={`${classes.penality_input_main_icon}`}><FontAwesomeIcon icon={faLayerGroup}></FontAwesomeIcon></span>
                            <input  name='reason' type="text" placeholder='Reason' />
                        </div>


                        <div className={`${classes.penality_input_main}`}>
                            <span className={`${classes.penality_input_main_icon}`}><FontAwesomeIcon icon={faLayerGroup}></FontAwesomeIcon></span>
                            <input name='pin_date' type="date" />
                        </div>

                        <div className={`${classes.penality_input_submit_action_button}`}>
                            <button disabled={registring} >{registring ? <FontAwesomeIcon icon={faSpinner} spin></FontAwesomeIcon> : "Demot"}</button>
                        </div>
            </form>
    );
}

export default function PenalityAction({Open, OpenController, EmpData, ActionType, MsgTrigger, CashPenalityData}) 
{
    const formRef = useRef();
    const [registring, SetRegistring] = useState();

    const [selectedOptions, setSelectedOptions] = useState();
    const [optionList, setOptionList] = useState([]);

    const [DepSelectedOption, setDepSelectedOption] = useState();
    const [DepOptionList, setDepOptionList] = useState([]);

    const [DemotionType, SetDemotionType] = useState();

    const [SwitchDepartment, SetSwitchDepartment] = useState(false);

    const [currentDepartment, SetCurrentDepartment] = useState(EmpData.DepartmentID);

    const [CashPenality, SetCashPenality] = useState(null);

    // Other Penality
    const [PenalityType, SetPenalityType] = useState();
    const [PenalityTypeList, SetPenalityTypeList] = useState([]);

    function handleDemotionSelect(data) 
    {
        SetDemotionType(prev => ({
            ...prev,
            ["demotion_type"]: data.value
        }))
    }
    function handleOtherPenalityTypeSelect(data) 
    {
        SetPenalityType(prev => ({
            ...prev,
            ["penality_type"]: data.value
        }))
    }

    
    useEffect(() => 
    {
        SetCashPenality(CashPenalityData);
        QueryOption();
    }, [SwitchDepartment, currentDepartment])
    function PrepList(data, DataFill) {
        const newOptionList = [];
      
        for (var i = 0; i < data.length; i++) {
          
          let object = data[i];
      
          let value_key = null;
          let value_lable = null;
  
         // console.log(object);
          for (const key in object) {
  
            const active = String(key);
            if (active.toLowerCase().includes('name')) {
  
              const val = object[key];
              value_lable = val;
  
            } else if (active.toLowerCase().includes('id')) {
  
              const val = object[key];
              value_key = val;
  
            }
  
            
  
            if(value_lable !== null && value_key !== null) 
            {
              newOptionList.push({ value: value_key, label: value_lable  });
              break;
            }
  
          }
  
          
        }
      
        DataFill(newOptionList);
      }
      function QueryOption() 
    {
        const URL = getBaseURL() + 'master-data/get-job-position-below/';
        fetch(URL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ BottomLine: EmpData.PositionRate, DepID: currentDepartment }),
        })
        .then(res => res.json())
        .then((data) => PrepList(data, setOptionList));

        fetch(getBaseURL() + 'master-data/get-department/')
        .then(res => res.json())
        .then((data) => PrepList(data, setDepOptionList));


        fetch(getBaseURL() + 'master-data/get-penality/')
        .then(res => res.json())
        .then((data) => PrepList(data, SetPenalityTypeList));
    }

    function HandleSubmitionCash(event)
    {
        SetRegistring(true);
        event.preventDefault();

        setTimeout(() =>{

            const fd = new FormData();

            fd.append('cash_amount', event.target.cash_amount.value);
            fd.append('reason', event.target.reason.value);
            fd.append('pin_date', event.target.pin_date.value);
            fd.append('EmpID', EmpData.EmployeeID);

            axios.post(getBaseURL() + 'employee/create_employee_cash_penality', fd, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                }).then(res => {
                    formRef.current.reset();
                    SetRegistring(false);
                    MsgTrigger(res);
                    OpenController(false);
                    
                    
                })
                .catch(err => {
                    formRef.current.reset();
                    SetRegistring(false);
                    MsgTrigger(err);
                    OpenController(false);
                    
                })

        }, 2000)
    }

    function HandleSubmitionOtherPenality(event) 
    {
        SetRegistring(true);
        event.preventDefault();

        setTimeout(() =>{

            const fd = new FormData();

            fd.append('PenalityType', PenalityType.penality_type);
            fd.append('Reason', event.target.reason.value);
            fd.append('pin_date', event.target.pin_date.value);
            fd.append('EmpID', EmpData.EmployeeID);

            axios.post(getBaseURL() + 'employee/create_employee_other_penality', fd, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                }).then(res => {
                    formRef.current.reset();
                    SetRegistring(false);
                    MsgTrigger(res);
                    OpenController(false);
                    
                    
                })
                .catch(err => {
                    formRef.current.reset();
                    SetRegistring(false);
                    MsgTrigger(err);
                    OpenController(false);
                    
                })

        }, 2000)
    }

    function HandleCashPenalitySubmitionUpdate(event) 
    {
        SetRegistring(true);
        event.preventDefault();

        setTimeout(() =>{

            const fd = new FormData();

            fd.append('Amount', event.target.Amount.value);
            fd.append('Detail', event.target.Detail.value);
            fd.append('Date', event.target.Date.value);
            fd.append('EmpID', CashPenality.EmployeeID);
            fd.append('ID', CashPenality.EMPCPID)

            axios.post(getBaseURL() + 'employee/update_employee_cash_penality', fd, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                }).then(res => {
                    formRef.current.reset();
                    SetRegistring(false);
                    MsgTrigger(res);
                    OpenController(false);
                    
                    
                })
                .catch(err => {
                    formRef.current.reset();
                    SetRegistring(false);
                    MsgTrigger(err);
                    OpenController(false);
                    
                })

        }, 2000)
    }

    function HandleSubmitionDemot(event) 
    {
        SetRegistring(true);
        event.preventDefault();

        setTimeout(() =>{

            const fd = new FormData();

            fd.append('job_to', DemotionType.demotion_type);
            fd.append('dep_to', currentDepartment);
            fd.append('reason', event.target.reason.value);
            fd.append('pin_date', event.target.pin_date.value);
            fd.append('EmpID', EmpData.EmployeeID);

            axios.post(getBaseURL() + 'employee/create_employee_demot', fd, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                }).then(res => {
                    formRef.current.reset();
                    SetRegistring(false);
                    MsgTrigger(res);
                    OpenController(false);
                    
                    
                })
                .catch(err => {
                    formRef.current.reset();
                    SetRegistring(false);
                    MsgTrigger(err);
                    OpenController(false);
                    
                })

        }, 2000)
    }

    return (
        <div className={`${Open ? classes.penality_action_main_wrapper : classes.penality_action_main_wrapper_hide}`}>
            <div className={`${classes.penality_action_header}`}>
                <h2>{ActionType}</h2>
                <button onClick={() => {OpenController(false)}}><FontAwesomeIcon icon={faTimesRectangle}></FontAwesomeIcon></button>
            </div>

            <div className={`${classes.penality_action_body}`}>
                {ActionType === "Update Cash Penality" && HandleCashPenalityUpdate(registring, formRef, HandleCashPenalitySubmitionUpdate, CashPenality, SetCashPenality)}
                {ActionType === "Cash Penality" && HandleCashPenality(registring, formRef, HandleSubmitionCash)}
                {ActionType === "Other Penality" && HandleOtherPenality(registring, formRef, HandleSubmitionOtherPenality, handleOtherPenalityTypeSelect, PenalityTypeList)}
                {ActionType === "Demote" && HandleDemotionPenality(
                        registring, 
                        formRef, 
                        HandleSubmitionDemot, 
                        selectedOptions, 
                        optionList, 

                        DepSelectedOption,
                        DepOptionList,

                        DemotionType, 
                        handleDemotionSelect, 
                        EmpData, 

                        SwitchDepartment,
                        SetSwitchDepartment,

                        SetCurrentDepartment
                    )}
            </div>
        </div>
    );
}