import { faBan, faCheckDouble, faCircleStop, faCodeBranch, faCopy, faInfoCircle, faQuestionCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import classes from './OrderGroup.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRef, useState } from 'react'
import { useEffect } from 'react';
import { Tooltip } from 'react-tooltip'
import data_win from '../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import {FetchTargetOrderGroupSubProducts, RejectOrderSubProduct} from '../../../../../SharedComp/API/CK/AP_CenteraKitchenOrder'


import { useToast } from '../../../../../ToastContext';

import FilterEmployeeList from './FilterEmployeeList/FilterEmployeeList';
import TimeElapsed from '../../../../../SharedComp/TimeElapsed/TimeElapsed';
import CountdownTimer from '../../../../../SharedComp/CountDown/CountDownTimer';
import { useDAControl } from '../../../../../DAControlContext';


function getFormattedDate(dateValue) {
    return new Date(dateValue).toLocaleDateString('en-US', {
      weekday: 'long', 
      month: 'short',  
      day: '2-digit'
    //   year: 'numeric'  
    });
  }
function getFormattedTime(dateValue) {
    return new Date(dateValue).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true 
    });
}
function isDateInThePast(inputDate) {
    const now = new Date();
    const providedDate = new Date(inputDate);
    return now < providedDate;
}
export default function OrderGroup({Data, ChefsList,  MGPos}) 
{

    const notify = useToast();
    const DAControl = useDAControl();


    const [SubProductList, SetSubProductList] = useState([]);
    const [OpenFilterMenu, SetOpenFilterMenu] = useState(false);
    const [RefreshView, SetRefreshView] = useState(false);

    const [OpenOrderTimeInfo, SetOpenOrderTimeInfo] = useState(false);
    const TimeModalRef = useRef(null);

    const [ValidOrderTime, SetValidOrderTime] = useState(isDateInThePast(Data.CKAOG_ArrivalDateTime));

    const [ProcessBuffer, SetProcessBuffer] = useState(new Map());

    const [BatchOp, SetBatchOp] = useState(false);
    
    // This is where the chef menu will be apear
    const [FilterBoxPos, SetFilterBoxPos] = useState();


    const OpenFilterBox = async() =>
    {
        SetOpenFilterMenu(true);
    }
    const FetchProductList = async() =>
    {
        try 
        {
            const main_res = await FetchTargetOrderGroupSubProducts(Data.CKAOG_Hash, 'token');
            SetSubProductList(main_res);
        }catch(err) 
        {

        }
    }
    const handleMouseMove = (event) => {
        if(OpenFilterMenu) 
        {
            return;
        }
        SetFilterBoxPos({
            x: event.clientX,
            y: event.clientY,
            });
    };
    const SingleDispatch = async(data)=>
    {
        const _single = new Map();
        _single.set(data.CKP_ItemName, {DataPkg: data, Quantity: data.CKAOPID_Quantity - data.CKAOPID_DispatchedQuantity});
        SetProcessBuffer(_single);
    }
    const OminiDispatch = ()=>
    {
        const _omni = new Map();
        for(let i = 0; i < SubProductList.length; i++) 
        {
            if((SubProductList[i].CKAOPID_RejectedQuantity + SubProductList[i].CKAOPID_DispatchedQuantity) >= SubProductList[i].CKAOPID_Quantity) 
            {
                continue;
            }
            _omni.set(SubProductList[i].CKP_ItemName, {DataPkg: SubProductList[i], Quantity: SubProductList[i].CKAOPID_Quantity - SubProductList[i].CKAOPID_DispatchedQuantity});
        }
        SetProcessBuffer(_omni);
        SetOpenFilterMenu(true);
    }
    const ToggelItemInOut = async(data)=>
    {
        if(!BatchOp)
        {
            return;
        }

        if((data.CKAOPID_RejectedQuantity + data.CKAOPID_DispatchedQuantity) >= data.CKAOPID_Quantity) 
        {
            notify.ShowError(`You can't select '${data.CKP_ItemName}'! It is closed, Please have some manner.`);
            return;
        }

        const _exist = ProcessBuffer;
        if(_exist.has(data.CKP_ItemName)) 
        {
            _exist.delete(data.CKP_ItemName);
        } else 
        {
            // I know this is Stupid but it serve a puprose letter
            _exist.set(data.CKP_ItemName, {DataPkg: data, Quantity: data.CKAOPID_Quantity - data.CKAOPID_DispatchedQuantity});
        }

        SetProcessBuffer(_exist);
    }
    const CleanCloseMiniModal = async(__val)=>
    {
        SetBatchOp(false);
        SetOpenFilterMenu(false);
        SetProcessBuffer(new Map());
    }
    const IsMarked = (__key)=>
    {
        return ProcessBuffer.has(__key);
    }
    const HandleBatchOp = (__val)=>
    {
        SetBatchOp(__val);
        SetProcessBuffer(new Map());

        if(!__val)
        {
            CleanCloseMiniModal();
        }

    }

    const HandleTimeModalInfoOpen = (val)=>
    {
        if(!OpenOrderTimeInfo && TimeModalRef.current) 
        {
            TimeModalRef.current.focus();
        }
        SetOpenOrderTimeInfo(val);
    }

    

    useEffect(()=>{
        FetchProductList();
    }, [RefreshView])

    const HandleRejectOrder = async(order_hash, prod_id, rej_aount)=>
    {

        notify.ShowLoad("Processing your request.....", 10);
        
        setTimeout(async()=>
        {
            try 
            {
                const fd = new FormData();
                fd.append("order_hash", order_hash);
                fd.append("order_id", prod_id);
                fd.append("reject_amount", rej_aount);

                const main_res = await RejectOrderSubProduct(fd, 'token');
                notify.ShowSuccessUpdate(main_res.Msg, 10);
                SetRefreshView(!RefreshView);

            }catch(err) 
            {
                notify.ShowErrorUpdate(err.message, 10);
            }
        }, 1500)
    }


    return (
        <div className={`${classes.order_group_main_wrapper}`}>
            <Tooltip className={`${data_win.tool_quick}`} id="offical_doc" place="top" />
            <div className={`${classes.order_group_main_header_wrapper}`}>
                <h2>From #{Data.BranchName}</h2>
                <button onClick={()=>{HandleTimeModalInfoOpen(!OpenOrderTimeInfo);}}><FontAwesomeIcon icon={OpenOrderTimeInfo ? faTimesCircle : faInfoCircle}></FontAwesomeIcon></button>

              <div 
                ref={TimeModalRef} 
                tabIndex={0} 
                onBlur={()=>{SetOpenOrderTimeInfo(false)}} 
                className={`${OpenOrderTimeInfo ? classes.order_info_critical : classes.order_info_critical_hide}`}>
                    <li>Ordered Date: <TimeElapsed Time={Data.CKAOG_OrderDateTime}/> </li>
                    <li>Expected Date: {getFormattedDate(Data.CKAOG_ArrivalDateTime)} @ {getFormattedTime(Data.CKAOG_ArrivalDateTime)}</li>
                    <li>Available Time: <CountdownTimer targetTime={Data.CKAOG_ArrivalDateTime} CountSec={true}/></li>
                    <li>Order Status: <span className={`${ValidOrderTime ? classes.order_validity : classes.order_invalidity}`}>{ValidOrderTime ? "In Effect" : "No Longer Active"}</span></li>
                </div>
            </div>

           {OpenFilterMenu && <FilterEmployeeList ActiveOrderGroup={Data} UpdateViewCallback={SetRefreshView} UpdateView={RefreshView} OrderSourceBranchID={Data.BranchID} ItemBuffer={ProcessBuffer} Chefs={ChefsList} CloseController={CleanCloseMiniModal} Mpos={FilterBoxPos}/>} 

            <div className={`${classes.group_body_wrapper}`}>

                {
                    SubProductList.map((item, idx)=>(
                        <li key={item.CKP_ItemName} className={`${IsMarked(item.CKP_ItemName) ? classes.selected_group_prod : ''}`} onClick={()=>{ToggelItemInOut(item)}}>
                            <span>{item.CKP_ItemName}</span>
                            <span className={`${classes.quantity_data_wrapper}`}>
                                <span {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': 'Ordered Quantity'})} className={`${classes.total_amount_wrapper}`}><FontAwesomeIcon icon={faQuestionCircle}></FontAwesomeIcon> {item.CKAOPID_Quantity} </span>
                                <span {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': 'Dispatched Amount'})}><FontAwesomeIcon icon={faCodeBranch}></FontAwesomeIcon> {item.CKAOPID_DispatchedQuantity} </span>
                                <span {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': 'Rejected Amount'})} className={`${classes.rejected_amount_wrapper}`}><FontAwesomeIcon icon={faBan}></FontAwesomeIcon> {item.CKAOPID_RejectedQuantity} </span>
                            </span>
                          <span className={`${classes.action_button_wrapper_group}`}>
                                <button disabled={((item.CKAOPID_DispatchedQuantity + item.CKAOPID_RejectedQuantity) >= item.CKAOPID_Quantity)} onClick={()=>{
                                        DAControl.Trigger(`Reject Order Productt`, 
                                        `You are about to reject the rest of this products which is ${item.CKAOPID_Quantity - item.CKAOPID_DispatchedQuantity} the dispatched amount won't be affected.`,
                                        true,
                                        5, (val)=>{
                                            if(val !== null) 
                                            {
                                                HandleRejectOrder(item.CKAOPID_GroupHash, item.CKAOPID_ProductID, item.CKAOPID_Quantity - item.CKAOPID_DispatchedQuantity);
                                            }
                                        }   
                                    );}}
                                className={`${classes.reject_order_item} ${((item.CKAOPID_DispatchedQuantity + item.CKAOPID_RejectedQuantity) >= item.CKAOPID_Quantity) ? classes.button_disabled : ''}`}  >
                                    <span>
                                        <span><FontAwesomeIcon icon={faBan}></FontAwesomeIcon></span> <span>Reject</span>
                                    </span>
                                </button>
                                <button disabled={((item.CKAOPID_DispatchedQuantity + item.CKAOPID_RejectedQuantity) >= item.CKAOPID_Quantity)} className={`${((item.CKAOPID_DispatchedQuantity + item.CKAOPID_RejectedQuantity) >= item.CKAOPID_Quantity) ? classes.button_disabled : ''}`} onMouseMove={(e)=>{handleMouseMove(e)}}  onClick={()=>{SingleDispatch(item); OpenFilterBox()}}  >
                                    <span>
                                        <span><FontAwesomeIcon icon={faCodeBranch}></FontAwesomeIcon></span> <span>Dispatch</span>
                                    </span>
                                </button>
                            </span> 
                        </li>
                    ))
                }

            </div>

            <div className={`${classes.dispatch_group_footer}`}>
                    <div className={`${classes.dispatch_group_action_button_wrapper}`}>
                        <button onClick={()=>{HandleBatchOp(!BatchOp)}}><span><FontAwesomeIcon icon={BatchOp ? faCircleStop : faCopy}></FontAwesomeIcon></span> <span>{BatchOp ? "Void" : "Select"}</span></button>
                        {ProcessBuffer.size > 0 && BatchOp && <button onMouseMove={(e)=>{handleMouseMove(e)}} onClick={()=>{OpenFilterBox()}} style={{background: `var(--color-theme)`, borderColor: `var(--color-theme)`, color: `var(--primary_text_color)`}}><span><FontAwesomeIcon icon={faCheckDouble}></FontAwesomeIcon></span> <span>Dispatch Marked</span></button>}
                    </div>

                    <div className={`${classes.right_side_dispatch_group_button_wrapper}`}>
                        <button onMouseMove={(e)=>{handleMouseMove(e);}} onClick={()=>{OminiDispatch()}} style={{background: `var(--color-theme)`, borderColor: `var(--color-theme)`, color: `var(--primary_text_color)`}}><span><FontAwesomeIcon icon={faCheckDouble}></FontAwesomeIcon></span> <span>Dispatch All</span></button>
                        <button style={{background: `var(--red-color)`, borderColor: `var(--red-color)`, color: `var(--primary_text_color)`}}><span><FontAwesomeIcon icon={faBan}></FontAwesomeIcon></span> <span>Reject Order</span></button>
                    </div>
            </div>

        </div>
    )
}