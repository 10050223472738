import { useEffect } from 'react'
import classes from './SingleOrderDataViewWrapper.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react'
import { useAuth } from '../../../../../../AuthProvider';
import { useToast } from '../../../../../../ToastContext';
import {FetchFinalProductSaved, FetchMixSaved, FetchProductionOrderReqMaterial, FetchIVItemID, FetchSavedProductionProductOrder, FetchMixIngredientJoined} from '../../../../../../api'
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { useRef } from 'react';

export default function SingleOrderDataViewWrapper({DataPoint, DataReadback_Callback}) 
{

    // If the product type is mix / meaning if it is mix but acting like a product / we gone be raciest toward him / treat him differntly
    const IsMixType = DataPoint.POP_IsMix === 1;

    const [IngList, SetIngList] = useState([]);
    const [CompInventoryList, SetCompInventoryList] = useState([]);
    const [ProductDetail, SetProductDetail] = useState();
    const Loading = useRef(false);

    const auth = useAuth();
    const notify = useToast();

    const FetchProductIngList = async(ing_id)=>
    {
        try
        {
            const res = await FetchMixIngredientJoined(ing_id, 'token');
            SetIngList(res);

            // Update read back for each
            for(let i = 0; i < res.length; i++) 
            {
                await DataReadback_Callback(res[i].IVItemName, {ID: res[i].IvtID, Amount: res[i].Quantity * DataPoint.POP_Batch, UOM: res[i].UomDescription})
            }
            await FetchComplimentaryStock();
            Loading.current = false;
        }catch(err) 
        {

        }
    }
    const FetchComplimentaryStock = async()=>
    {
        try
        {
            const res = await FetchProductionOrderReqMaterial(DataPoint.POP_Hash, 'token');
            SetCompInventoryList(res);

            // Update read back for each
            for(let i = 0; i < res.length; i++) 
            {
                await DataReadback_Callback(res[i].IVItemName, {ID: res[i].IvtID, Amount: res[i].POM_IvQuantity * DataPoint.POP_Batch, UOM: res[i].UomDescription})
            }

        }catch(err) 
        {

        }
    }
    const FetchProductDetail = async()=>
    {
        Loading.current = true;
        try 
        {
            let prod_data = [];
            if(IsMixType) 
            {
                prod_data = await FetchMixSaved(DataPoint.POP_ProdID, 'token');
            } else 
            {
                prod_data = await FetchFinalProductSaved(DataPoint.POP_ProdID, 'token');
            }

            if(prod_data.length > 0) 
            {
                SetProductDetail(prod_data[0]);

                //  Now the next step to fetch the Ing list AKA YAM-MIX 
                if(IsMixType) 
                {
                    await FetchProductIngList(prod_data[0].MID);
                }else 
                {
                    await FetchProductIngList(prod_data[0].FProdRecipe);
                }
            }else 
            {
                notify.ShowError("We Couldn't Fetch the data Master! We Failed you! For Unknown Reason");
            }


            
        }catch(err) 
        {
            notify.ShowError("We Couldn't Fetch the data Master! We Failed you! " + err.message);
        }
    }

    useEffect(()=>{
        if(!Loading.current)
        {
            FetchProductDetail();
        }
    }, [])

    return (
        <div className={`${classes.single_order_data_view_master_wrapper}`}>
        {!Loading.current ? 
            <div className={`${classes.packge_data_wrapper}`}>

                <div className={`${classes.main_prod_info_wrapper}`}>
                    <li><span className={`${classes.prod_name_PO}`}>{IsMixType ? ProductDetail?.MixName :  ProductDetail?.FProdName}</span></li>
                </div>

                <div className={`${classes.ing_list_data_wrapper}`}>
                    <div className={`${classes.data_marging_wrapper}`}>
                        {
                            IngList.map((item, idx)=>(
                                <li>
                                    <span>{item.IVItemName} </span> 
                                    <span className={`${classes.right_align}`}>{DataPoint.POP_Batch} <sup>({item.Quantity}/Batch)</sup>  </span>
                                    <span className={`${classes.right_align}`}>{(DataPoint.POP_Batch * item.Quantity).toFixed(2)} {item.UomDescription}</span>
                                </li>
                            ))
                           
                        }
                        {
                             CompInventoryList.map((item, idx)=>(
                                        <li>
                                            <span>{item.IVItemName} <FontAwesomeIcon icon={faStar}></FontAwesomeIcon></span> 
                                            <span className={`${classes.right_align}`}>{DataPoint.POP_Batch} <sup>({item.POM_IvQuantity}/Batch)</sup>  </span>
                                            <span className={`${classes.right_align}`}>{(DataPoint.POP_Batch * item.POM_IvQuantity).toFixed(2)} {item.UomDescription}</span>
                                        </li>
                                ))
                        }
                    </div>
                </div>


            </div>

            :   <div>
                    Fetching....
                </div> 
        }

        </div>
    )
}