// Styles
import classes from './MerchData.module.css'
import data_win from './../../../../../SharedComp/CommonStyle/DataCreator.module.css'

// Comp
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MasterDataRegInput from '../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useRef } from 'react'
import { useState } from 'react'
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'
import Select from 'react-select';


//lib
import axios from 'axios';

export default function MerchData({OpenController, MsgTrigger}) 
{
    const formRef = useRef();
    const [registring, SetRegistring] = useState(false);

    const [GoodsMeasurementOptionList, SetGoodsMeasurementOptionList] = useState([]);
    const [MerchTypeOptionList, SetMerchTypeOptionList] = useState([]);

    // Dropdown selection
    const [MerchType, SetMerchType] = useState(null);
    const [ItemUOM, SetItemUOM] = useState(null);

    useEffect(() => 
    {
        QueryOption();
    }, [])
    function PrepList(data, bucket) {
        const newOptionList = [];
      
        for (var i = 0; i < data.length; i++) {
          
          let object = data[i];
      
          let value_key = null;
          let value_lable = null;

         // console.log(object);
          for (const key in object) {

            const active = String(key);
            if (active.toLowerCase().includes('name')) {

              const val = object[key];
              value_lable = val;

            } else if (active.toLowerCase().includes('id')) {

              const val = object[key];
              value_key = val;

            }

            

            if(value_lable !== null && value_key !== null) 
            {
              newOptionList.push({ value: value_key, label: value_lable  });
              break;
            }

          }

          
        }
      
        bucket(newOptionList);
      }
      function QueryOption() 
      {
        fetch('http://localhost:3333/master-data/get-merch-type-item')
        .then(res => res.json())
        .then((data) => PrepList(data, SetMerchTypeOptionList));

        fetch('http://localhost:3333/master-data/get-uom')
        .then(res => res.json())
        .then((data) => PrepList(data, SetGoodsMeasurementOptionList));


        // fetch('http://localhost:3333/master-data/get-iv-type', { 
        //     method: 'POST',
        //     headers: {
        //       'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify({ ID: "Yes" }),
        //   })
        //     .then(res => res.json())
        //     .then((data) => {PrepList(data, SetGoodsTypeOptionList); });
      } 

    const Custom_DD_Style = {
        control: (provided, state) => ({
            ...provided,
            width: 170,
            minHeight: 20,
            height: 35,
            fontWeight: 'bold',
            fontSize: 13,
            background: 'var(--background-light)',
            borderColor: 'var(--background-light)',
            color: 'var(--secondary-color)',
            borderRadius: 1,
            borderColor: 'none',
            boxShadow: 'none',
            '&:hover': {
            borderColor: 'none',
            boxShadow: 'none',
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? 'var(--body-background)' : 'var(--background-dark)', // Change background color on hover
            color:'var(--text_light_white)',
            transition: 'background-color 0.2s ease-in-out',
            cursor: 'pointer',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'var(--text_light_white)', // Set the text color for the selected option in the dropdown
          }),
          input: (provided) => ({
            ...provided,
            color: 'var(--text_light_white)', //input text color when searching
          }),
        menu: (provided) => ({
            ...provided,
            zIndex: 9999, // Increase the z-index if options are not displayed properly
            backgroundColor: 'var(--background-light)',
            fontSize: 13,
            borderRadius: 1,
            fontWeight: 500,
        }),
};

    function CloseModal() 
    {
        formRef.current.reset();
        OpenController(false);
    }

    function HandleSubmition(e) 
    {
        e.preventDefault();

        SetRegistring(true);

        setTimeout(()=>{

            const fd = new FormData();

            fd.append("merch_prod_name", e.target.merch_prod_name.value);
            fd.append("merch_prod_name_remark", e.target.merch_prod_name_remark.value);
            fd.append("pkg_pcs_count", e.target.pkg_pcs_count.value);
            fd.append("buying_price", e.target.buying_price.value);
            fd.append("selling_price", e.target.selling_price.value);
            fd.append("uom", ItemUOM);
            fd.append("merch_type", MerchType);

            const url = 'http://localhost:3333/master-data/add-merch-item';
            axios.post(url, fd, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                })
            .then(res => {
                SetRegistring(false);
                MsgTrigger(res);
                CloseModal();
                
                
            })
            .catch(err => {
                SetRegistring(false);
                MsgTrigger(err);
                
            })

        }, 2000)
    }

    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

            <div className={`${data_win.data_main}`}>
                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        Registring <span className={`${data_win.data_create_name}`}>Merchandise Products</span>
                    </h3>
                    <button onClick={()=>{CloseModal()}}>
                        <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                    </button>
                </div>

                <div className={`${data_win.data_body}`}>
                        
                

                            <form onSubmit={HandleSubmition} ref={formRef} action="POST">
                                <div className={`${classes.form_wrapper}`}> 
                                        <div  className={`${classes.input_list_wrapper}`}>
                                            <MasterDataRegInput input={<input name='merch_prod_name' type="text" placeholder='Purchased Product Name' title='' autoComplete='off' required />} />
                                            <MasterDataRegInput input={<input name='merch_prod_name_remark' type="text" placeholder='Remark' title='' autoComplete='off' />} />
                                            <MasterDataRegInput input={<input name='pkg_pcs_count' type="number" min={1} placeholder='Package Pieces Count' title='' required />} />
                                            <MasterDataRegInput input={<input name='buying_price' type="number" min={1} placeholder='Buying Price' title='' required />} tip={"Price by pieces, NOT by package"} />
                                            <MasterDataRegInput input={<input name='selling_price' type="number" min={1} placeholder='Selling Price' title='' required />} tip={"How match you gonne sell this merch!"} />

                                            <MasterDataRegInput input={
                                                <Select
                                                    onChange={(data)=>{SetItemUOM(data.value)}}
                                                    name={"dd_data"}
                                                    options={GoodsMeasurementOptionList}
                                                    placeholder={"UOM..."}
                                                    isSearchable={true}
                                                    styles={Custom_DD_Style}
                                                    required ={true}
                                            />
                                            } tip={"Unit of measurement"} /> 

                                            <MasterDataRegInput input={
                                                <Select
                                                    onChange={(data)=>{SetMerchType(data.value)}}
                                                    name={"dd_data"}
                                                    options={MerchTypeOptionList}
                                                    placeholder={"Merch Type.."}
                                                    isSearchable={true}
                                                    styles={Custom_DD_Style}
                                                    required ={true}
                                            />
                                            } tip={"Production category for this item."} />                     

                                        </div>
                                </div>
                                <div className={`${data_win.form_action_button_wrapper}`}>
                                    <button disabled={registring} className={`${data_win.form_action_button_save}`}>{registring ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Add'}</button>
                                    <button disabled={registring}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                                </div>
                            </form>




                </div>
            </div>

        </div>
    )
}