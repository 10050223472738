import { faBars, faBoxOpen } from '@fortawesome/free-solid-svg-icons'
import classes from './ProductionLogGroup.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useRef, useState } from 'react'
import DotsGif from '../../../../SharedComp/CssGif/DotsGif'
import HillCubeLoaderGif from '../../../../SharedComp/CssGif/HillBoxLoaderGif'


const formatter_currency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'ETB', 
    minimumFractionDigits: 2, 
    maximumFractionDigits: 2
});
const formatter = new Intl.NumberFormat('en-US', { 
    minimumFractionDigits: 0, 
    maximumFractionDigits: 0
})
export default function ProductionLogGroup({DataList, ShiftName})
{

    const messagesEndRef = useRef(null);
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      };
    const [ShowMinFilterBox, SetShowMinFilterBox] = useState(false);
    const [MainDataView, SetMainDataView] = useState([]);

    const [SumQuantity, SetSumQuantity] = useState(0);
    const [SumPrice, SetSumPrice] = useState(0);
    const [Loading, SetLoading] = useState(false);
    const [IsEmpty, SetIsEmpty] = useState(true);

    const SumUpPriceQuantity = async()=> 
    {
        let __price = 0;
        let __quantity = 0;

        for(let i = 0; i < DataList.length; i++) 
        {
            __quantity += parseInt(DataList[i].SCM_Quantity);
            __price += DataList[i].FProdUnitPrice * DataList[i].SCM_Quantity;
        }

        SetSumQuantity(__quantity);
        SetSumPrice(__price);
        SetLoading(false);
    }
    const GroupItems = async()=> 
    {
        SetMainDataView([]);
        const _data_buffer = new Map();
        for (let i = 0; i < DataList.length; i++) {
            const currentItem = {...DataList[i]};

            if(_data_buffer.has(currentItem.FProdName)) 
            {
                const _exist =_data_buffer.get(currentItem.FProdName);
                _exist.SCM_Quantity += currentItem.SCM_Quantity;
                // _exist.FProdGram += currentItem.FProdGram;
            } else 
            {
                _data_buffer.set(currentItem.FProdName, {...currentItem});
            }

        }

        SetMainDataView(Array.from(_data_buffer.values()));
    }

    const SortByName = async()=> 
    {
        const sortedData = [...MainDataView].sort((a, b) => a.FProdName.localeCompare(b.FProdName));
        SetMainDataView(sortedData); 
    }
    const sortByQuantity = () => {
        const sortedData = [...MainDataView].sort((a, b) => b.SCM_Quantity - a.SCM_Quantity);
        SetMainDataView(sortedData);
      };
   
      const generateAnimationDelay = (index) => {
        return `${index * 0.15}s`
      };
    useEffect(() => {
        SetIsEmpty(true);
        if (DataList.length > 0) {
            SetIsEmpty(false);
            SetLoading(true);
            SetMainDataView([...DataList]);
            GroupItems();
            SumUpPriceQuantity();
        } else 
        {
            SetMainDataView([]);
        }

    }, [DataList]);

    useEffect(()=>{
        scrollToBottom();
    }, [])




    return (
        <div className={`${classes.production_log_group}`}>

            <div className={`${classes.production_log_group_header}`}>
                <div className={`${classes.prodution_log_group_header_inner}`}>
                    <h2>Production Log - {ShiftName}</h2>
                </div>

                <div className={`${classes.prodution_log_group_header_inner}`}>
                    <button onClick={()=>{SetShowMinFilterBox(!ShowMinFilterBox)}}><FontAwesomeIcon icon={faBars}></FontAwesomeIcon></button>

                    <div tabindex="0" onBlur={()=>{SetShowMinFilterBox(false)}} className={`${classes.production_log_group_filter_wrapper} ${ShowMinFilterBox ? '' : classes.production_log_group_filter_wrapper_hide}`}>
                        <li onClick={()=>{GroupItems(); SetShowMinFilterBox(false)}}>Group Items</li>
                        <li onClick={()=>{SetMainDataView(DataList); SetShowMinFilterBox(false)}}>Detail Items</li>
                        <li onClick={()=>{SortByName(); SetShowMinFilterBox(false)}}>Sort A - Z</li>
                        <li onClick={()=>{sortByQuantity(); SetShowMinFilterBox(false)}}>Sort 1 - 9</li>
                    </div>

                </div>

            </div>

            <div className={`${classes.production_log_group_body}`}>

               {!Loading && <div className={`${classes.production_log_group_data_list_wrapper}`}>

                    {
                        MainDataView.map((item, idx)=>(
                            <li key={idx} style={{animationDelay: generateAnimationDelay(idx) }}>
                                <span>{item.FProdName}</span>
                                <span className={`${classes.span_p_log_right_}`}>{item.SCM_NthBatch}</span>
                                <span className={`${classes.span_p_log_right_}`}>{formatter.format(item.SCM_Quantity)} Pcs</span>
                                <span className={`${classes.span_p_log_right_}`}>{formatter.format(item.FProdGram * item.SCM_Quantity)} Kg</span>
                            </li>
                        ))
                    }
                  {!IsEmpty && <li style={{animationDelay: generateAnimationDelay(MainDataView.length) }} className={`${classes.grand_total_val}`}>
                        <span>Grand Total</span>
                        <span className={`${classes.span_p_log_right_}`}>-</span>
                        <span className={`${classes.span_p_log_right_}`}>{formatter.format(SumQuantity)} Pcs</span>
                        <span className={`${classes.span_p_log_right_}`}>{formatter_currency.format(SumPrice)}</span>
                    </li> }

                    {IsEmpty && 
                      <div className={`${classes.empty_log}`}>
                        <ul><FontAwesomeIcon icon={faBoxOpen}></FontAwesomeIcon></ul>
                        <ul>No Production Log</ul>
                      </div> }

                    {/* <div ref={messagesEndRef} /> */}
                </div> }

                {Loading && 
                    <div className={`${classes.loader_gif_wrapper}`}>
                        {/* <DotsGif/> */}
                        <HillCubeLoaderGif/>
                    </div>
                }

            </div>

        </div>
    )
}