import classes from './WhatsGPS.module.css'
import data_win from '../../../SharedComp/CommonStyle/DataCreator.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRef, useState, useEffect } from 'react'
import axios from 'axios';

import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet-routing-machine';
// import './CustomRoutingStyles.css';
import icon from 'leaflet/dist/images/marker-icon.png';

import {FetchWhatsgpsCarInfo} from '../../../SharedComp/API/GPS/API_FE_WhatsGPS'
import { faCircleInfo, faParking, faTruckMoving } from '@fortawesome/free-solid-svg-icons'

import { GlobalNotificationProvider, useGlobalNotification } from '../../../GlobalNotification';

const mapStyle = { height: "250px", width: "100%", borderRadius: '10px' };
export default function WhatsGPS()
{

    const [CurrentLoc, SetCurrentLoc] = useState(null)
    const [LeafletReady, SetLeafletReady] = useState(false);
    const [StaticTime, SetStaticTime] = useState(1);
    const [DriverID, SetDriverID] = useState();
    const [Speed, SetSpeed] = useState(0);
    const [DisplayName, SetDisplayName] = useState();
    const [LocationInfo, SetLocationInfo] = useState(null);
    const [ShowDetailLocationInfo, SetShowDetailLocationInfo] = useState(false);

    const [EstimatedDistance, SetEstimatedDistance] = useState(0);
    const [ETA, SetETA] = useState(0);

    const mapRef = useRef(null);
    const markerRef = useRef(null);

    const GeoReverseIntervalRef = useRef(null); 
    const CarInfoIntervalRef = useRef(null);
    const ETARef = useRef(null);

    const Gnotify = useGlobalNotification();


    // I think this is global option .....
    let DefaultIcon = L.icon({
        iconUrl: icon,
        iconSize: [15, 20],
        className: `${StaticTime === '5' ? classes.map_marker : ''}`
    });
    
    L.Marker.prototype.options.icon = DefaultIcon;


    

    // This function calaculate distance from Point A to Point B by making API calls
    // The <<destination>> is the Sefera Coordinate hard coded // but it will be replaced on demand
    const CalculateDistance = async()=> 
    {
        if(CurrentLoc) 
        {
            try 
            {
                const origin = `${CurrentLoc.lon},${CurrentLoc.lat}`;
                const destination = '38.734898,8.919581';
                const res = await axios.get(`http://router.project-osrm.org/route/v1/driving/${origin};${destination}?overview=full&geometries=geojson&alternatives=true`);
                const route = res.data.routes[0];
                const distance = route.distance / 1000; // Convert to kilometers
                const duration = route.duration / 60; // Convert to minutes
                SetEstimatedDistance(distance.toFixed(2));
                SetETA(duration.toFixed(2)); 
            }catch(err) 
            {
                console.log(err);
            }
        }
       
    }
    
    // This function Extract the location info like the name
    // it take the current target usuall the car coordinate and return the name of the location city and something like that
    // in human readable format 
    function ReverseLoc() 
    {
        if(CurrentLoc)
        {
            axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${CurrentLoc.lat}&lon=${CurrentLoc.lon}&format=json`)
            .then(response => {
                // Handle the response here
                SetDisplayName(response.data.display_name);
                SetLocationInfo(response.data);
            })
            .catch(error => {
                console.error(error.message);
            });
        }
    }


    // This function As the name suggest get the token and fetch car information the speed, location, status all that jazz
    const FetchCarInfo = async (gps_token, car_id) => {
        try {
            const res = await FetchWhatsgpsCarInfo(car_id, gps_token, 'my_token');

            if(res.data)
            {
                const { lat, lon } = res.data[0];
                SetCurrentLoc({ lat: lat, lon: lon });
                SetSpeed(res.data[0].speed);
                SetStaticTime(res.data[0].status);
                SetDriverID(res.data[0].carId);
            }
    
        } catch (err) {
            console.log(err);
        }
    };



    // This is is Interval which re trigger every 5 seconde 
    // which is hard coded not good I know, 
    // First the distance calculation is expensive and is not free
    // there are afew provided which they are willing to do for free with some basic limitation like only 2 call every sec
    // so I tought I get the updated distance every 5 seconde, which is not bad and ... beside how far can a car go in 5 sec i mean think about it
    useEffect(()=>{
        ETARef.current = setInterval(()=>{

            CalculateDistance();
            
        }, 5 * 1000);

        return () => {
            if (ETARef.current) {
                clearInterval(ETARef.current);
                ETARef.current = null; // Reset the ref after clearing
            }
        };
    }, [])


    // This is the other kind of loop, it get called every 15 seconde, You though the upper was bad huh,
    // It has a few reason the first one is as always not free and if it is with limitation, 
    // In order to reverse a location we have limited calls per seconde but we don't need it
    // I thought a location does not change for a at least 15 sec, meaning if the car is JEMO and on Route to Mekial and if I make 5 API call every 5 seconde I propably get the same location
    // I update the location name every 15 sec it wil be suffient, but this will be a problem if the car move fomr JEMO -> Mekial -> German in 20 seconde
    // but I bet that woudn't happen i guess....
    useEffect(()=>{
        GeoReverseIntervalRef.current = setInterval(()=>{

            if (CurrentLoc && StaticTime === '5') 
            {
                ReverseLoc();
            }
            
        }, 15 * 1000);

        return () => {
            if (GeoReverseIntervalRef.current) {
                clearInterval(GeoReverseIntervalRef.current);
                GeoReverseIntervalRef.current = null; // Reset the ref after clearing
            }
        };
    }, [])



    // This is real time car location, so basically what happening here is
    // we ask the car "whare are you?", "whare are you?", 
    // every two seconde and card replie "Am Here","Am Here","Am Here" with the coordinate
    useEffect(()=>{
        const _gps_token = localStorage.getItem('GPS-TOKEN');
        const _active_car = localStorage.getItem('GPS-ACTIVE-CAR');
        if(_gps_token && _active_car) 
            {
                CarInfoIntervalRef.current = setInterval(async () => {
                    FetchCarInfo(_gps_token, _active_car, 'my_token');
                    
                }, 2000);
            }

            return () => {
            if (CarInfoIntervalRef.current) {
                clearInterval(CarInfoIntervalRef.current);
                CarInfoIntervalRef.current = null; // Reset the ref after clearing
            }
            };
            
        }, [])



    // Now this guy depend on the car location update meaning
    // this code make sure the pin on the map is visiable when the car moves
    // I tryed to make it at center but I couldn't my bad dwag
    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.setView([CurrentLoc.lat, CurrentLoc.lon], mapRef.current.getZoom(), {
                animate: true, 
                pan: {
                    duration: 1,
                }
            });
            if (markerRef.current) {
                markerRef.current.openPopup();
            }
        }
    }, [CurrentLoc]);



    useEffect(()=>{
        ReverseLoc();
        CalculateDistance();
    }, [LeafletReady]); 

    return (
        <div className={`${classes.whats_gps_main_wrapper}`}>

            

            <div className={`${classes.driver_info}`}>

                <div className={`${classes.driver_header}`}>
                    <h3>Operator Info</h3>
                </div>
                <div className={`${classes.driver_info_flex_wrapper}`}>
                    <div className={`${classes.driver_info_text}`}>
                        <li>
                            <span className={`${classes.text_tag}`}>Name</span>
                            <span className={`${classes.text_val}`}>Driver Name</span>
                        </li>
                        <li>
                            <span className={`${classes.text_tag}`}>Age</span>
                            <span className={`${classes.text_val}`}>18</span>
                        </li>
                        <li>
                            <span className={`${classes.text_tag}`}>Phone</span>
                            <span className={`${classes.text_val}`}>+251991202044</span>
                        </li>
                        <li>
                            <span className={`${classes.text_tag}`}>Licence Plates</span>
                            <span className={`${classes.text_val}`}>ET00912</span>
                        </li>
                        <li>
                            <span className={`${classes.text_tag}`}>Driving License</span>
                            <span className={`${classes.text_val}`}>ET00912</span>
                        </li>
                    </div>
                    <div className={`${classes.driver_info_visual}`}>
                        <div className={`${classes.driver_info_pic}`}>
                            <img src="https://img.freepik.com/premium-photo/black-student-with-accomplished-expression-posing-against-white-background_118124-51373.jpg?size=626&ext=jpg&ga=GA1.1.755433169.1719650584&semt=ais_hybrid" alt="" />
                        </div>
                        <li>
                            <span className={`${classes.text_tag}`}>Working For</span>
                            <span className={`${classes.text_val}`}>2 Years</span>
                        </li>
                    </div>
                </div>
            </div>

                <div className={`${classes.known_location}`}>
                   
                   <div className={`${classes.known_location_left}`}>
                        <li className={`${classes.name_short}`}>
                            {LocationInfo ? 
                                <span className={`${classes.loc_text_main}`}><span>{LocationInfo?.address.suburb}, {LocationInfo?.address.county} - {LocationInfo?.address.state}</span> <span onClick={()=>{SetShowDetailLocationInfo(!ShowDetailLocationInfo)}} className={`${classes.more_loc_data}`}> <FontAwesomeIcon icon={faCircleInfo}></FontAwesomeIcon></span></span>
                                 : 'Location Data Unavailable'}
                                 </li>
                   </div>
                    {ShowDetailLocationInfo &&<div className={`${classes.known_location_right}`}>
                        <li>
                            <span className={`${classes.loc_tag}`}>Type</span>
                            <span  className={`${classes.loc_val}`}>{LocationInfo?.type}</span>
                        </li>
                        <li>
                            <span className={`${classes.loc_tag}`}>Class</span>
                            <span  className={`${classes.loc_val}`}>{LocationInfo?.class}</span>
                        </li>
                        <li>
                            <span className={`${classes.loc_tag}`}>Suburb</span>
                            <span  className={`${classes.loc_val}`}>{LocationInfo?.address.suburb}</span>
                        </li>
                        <li>
                            <span className={`${classes.loc_tag}`}>County</span>
                            <span  className={`${classes.loc_val}`}>{LocationInfo?.address.county}</span>
                        </li>
                        <li>
                            <span className={`${classes.loc_tag}`}>State</span>
                            <span  className={`${classes.loc_val}`}>{LocationInfo?.address.state}</span>
                        </li>
                        <li>
                            <span className={`${classes.loc_tag}`}>Country</span>
                            <span  className={`${classes.loc_val}`}>{LocationInfo?.address.country}</span>
                        </li>
                    </div>}
                </div>
                {LeafletReady &&   <div className={`${classes.info_wrapper}`}>
                    <button onClick={()=>{Gnotify.Disturb("Don't hat me", 'Nigga')}} className={`${StaticTime === '5' ? classes.state_drive : classes.state_park}`}>
                        <span>
                        <FontAwesomeIcon icon={StaticTime === '5' ? faTruckMoving : faParking}></FontAwesomeIcon>
                        </span>
                        <span>
                        {StaticTime === '5' ? 'In Motion' : 'Stationary'}
                        </span>
                    </button>
                    
                    {StaticTime === '5' && <button className={`${classes.speed_measure}`}>{Speed}km/h</button>}
                    {StaticTime === '5' && <button className={`${classes.speed_measure}`}>{EstimatedDistance} km</button>}
                    {StaticTime === '5' && <button className={`${classes.speed_measure}`}>{ETA} minutes</button>}
                </div> }

                <div className={`${classes.map_route_view_wrapper}`}>
                    {CurrentLoc && <MapContainer 
                            ref={mapRef}
                            zoomControl={false} 
                            attributionControl={false} 
                            center={CurrentLoc} 
                            zoom={14} 
                            style={mapStyle}
                            whenCreated={mapInstance => { mapRef.current = mapInstance; }}
                            whenReady={()=>{SetLeafletReady(true)}}
                            >
                                {
                                    LeafletReady && 
                                    <>
                                        <TileLayer
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                            //url='https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png'
                                            // url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
                                            attribution='JAK AND SONS TRADING PLC'
                                        />
                                    {/* <RoutingMachine ActiveCurrent={CurrentLoc} /> */}
                                    <Marker ref={markerRef} position={[CurrentLoc.lat, CurrentLoc.lon]}>
                                        <Popup>
                                            {DriverID}
                                        </Popup>
                                    </Marker>
                                        
                                    </>
                                }
                    
                    </MapContainer> }
                    <div>
                        {!LeafletReady && <div className={`${classes.view_loading}`}>Tracking Location...</div> }
                    </div>
                </div>
              

        </div>
    )
}