import classes from './Dashboard.module.css'




export default function Dashboard() 
{
    return (
        <div className={`${classes.dashboard_main_wrapper}`}>
            <h1>Dashboard Will be here soon..</h1>
        </div>
    );
}