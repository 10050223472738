import classes from './AuditTrails.module.css'



const AuditTrails = () =>{


    return (
        <div>
            <h1>Audit Trails</h1>
        </div>
    )
}

export default AuditTrails;