import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

export default function InputField({HandleInputUpdate, Name, icon_value, type, placeholder, Step = "0.01", ReadOnly = false, ReadValue, Hide = false, isReq = true}) 
{
    const handleInputChange = (e) => {
        HandleInputUpdate(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    }
    return (
        <div className="input-field">
            <div className="icon-field">
                <FontAwesomeIcon icon={icon_value} className="icon-value"></FontAwesomeIcon>
            </div>
            <div className="value-field">
                <input
                name={Name}
                onChange={handleInputChange}
                type={type}
                autoComplete="off"
                placeholder={placeholder}
                step={Step}
                value={ReadValue}
                disabled={ReadOnly}
                required={isReq}
                hidden={Hide}
                />
            </div>
        </div>
    );
}