import { useEffect, useState } from 'react';
import classes from './WorkspaceAccessList.module.css'
import { FetchBranchAll } from '../../api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faObjectGroup, faObjectUngroup } from '@fortawesome/free-solid-svg-icons';
import { objectToMap} from '../../SharedComp/Utility'

export default function WorkspaceAccessList({DataCallback, SerializeData = null}) 
{


    const [WorkspaceList, SetWorkspaceList] = useState([]);
    const [WorkspaceChecked, SetWorkspaceChecked] = useState(new Map());
    const [ToggelListTrue, SetToggelListTrue] = useState(false);

      // This is to hanle indiviual select
      const handleToggle = (branch_id) => {

            if(!WorkspaceChecked.has(branch_id))
            {
                return;
            }

            SetWorkspaceChecked(prevState => {
            const updatedState = new Map(prevState);
            updatedState.set(branch_id, !updatedState.get(branch_id));
            
            DataCallback(updatedState);

            return updatedState;
        });
    };
    // This function handle the select all stuff
    const selectAll = (val) => {

        SetToggelListTrue(val);

        let updated_list = new Map();
        for(const [mp_key, mp_val] of WorkspaceChecked.entries()) 
        {
            updated_list.set(mp_key, val);
        }

        DataCallback(updated_list);
        
        SetWorkspaceChecked(updated_list);    
    };
     // we need to query all the branch inorder to check which one is active
     const QueryWorkspace = async () => {

        try {
            const res = await FetchBranchAll('token');

            // for now prepare void list
            let init_check_list = new Map();
            for(let  i = 0; i < res.length; i++) 
            {
                if(!init_check_list.has(res[i].BranchID)) 
                    init_check_list.set(res[i].BranchID, false); 
            }
            SetWorkspaceChecked(init_check_list);

            SetWorkspaceList(res);
        } catch (err) 
        {
            throw new Error(err);
        }
    }

    function HandleAvailWorkshopSerlization() 
    {
        
        const crt_data =  new Map(objectToMap(JSON.parse(SerializeData)));

        let updated_list = new Map();
        for(let  i = 0; i < WorkspaceList.length; i++) 
        {
            const _workspace_id = WorkspaceList[i].BranchID;
            if(crt_data.has(_workspace_id))
            {
                updated_list.set(_workspace_id, crt_data.get(_workspace_id));
            } else 
            {
                updated_list.set(_workspace_id, false);
            }
        }
        SetWorkspaceChecked(updated_list);
    }

    useEffect(()=>{
        QueryWorkspace();
    }, [])

    useEffect(()=>{
        if(SerializeData) 
        {
            HandleAvailWorkshopSerlization();
        }
    }, [WorkspaceList, SerializeData])

    return (
        <div>
            <div className={`${classes.master_product_type_inner_avail_workspace_list}`}>
                <div className={`${classes.master_product_type_inner_avail_workspace_list_header}`}>
                    <h1>Accessibility</h1>
                    <button type='button' onClick={()=>{selectAll(!ToggelListTrue)}}><FontAwesomeIcon icon={ToggelListTrue ? faObjectUngroup : faObjectGroup}/></button>
                </div>
                <div className={`${classes.master_prod_avail_list}`}>
                    {
                        WorkspaceList.map((item, idx)=>(
                            <div key={idx} onClick={() => handleToggle(item.BranchID)} className={`${classes.master_prod_avail_branch}`}>
                                <input 
                                onClick={() => handleToggle(item.BranchID)}
                                checked={WorkspaceChecked.get(item.BranchID)} 
                                name={item.BranchName} 
                                type="checkbox"
                                onChange={() => handleToggle(item.BranchID)}
                                />
                                <li>{item.BranchName}</li>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}