import classes from './PaperFooter.module.css'


export default function PaperFooter({Name, Role, Phone})
{
    return (
        <div className={`${classes.paper_footer_note_wrapper}`}>

            <div className={`${classes.owner_notify}`}>
                <li>Void if not exclusive</li>
            </div>

            <div className={`${classes.owner_verify}`}>
                <li className={`${classes.owner_name}`}>{Name}</li>
                <li>{Role}</li>
                <li>{Phone}</li>
                <li>{new Date().toDateString()}</li>
                <li className={`${classes.user_sign}`}>Signature ______________________</li>
            </div>

        </div>
    )
}