import classes from './ProductionStackOrderGroupWrapper.module.css'
import ProductionStackOrderSingleCardWrapper from '../ProductionStackOrderSingleCardWrapper/ProductionStackOrderSingleCardWrapper'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {FetchSavedProductionProductOrder, RefreshProdcutionOrder} from '../../../../api'
import { useEffect } from 'react';
import { faBan, faCheckToSlot, faCircleChevronRight, faCirclePause } from '@fortawesome/free-solid-svg-icons'
import { Tooltip } from 'react-tooltip';

import ProductionStackProcessor from '../ProductionStackProcessor/ProductionStackProcessor'
import { useToast } from '../../../../ToastContext'
import { useAuth } from '../../../../AuthProvider'

export default function ProductionStackOrderGroupWrapper({DataPoint, DataRefresh_Callback}) 
{
    const [OrderProducts, SetOrderProducts] = useState([]);
    const [OpenPOProcessor, SetOpenPOProcessor] = useState(false);
    const [SelectedDataList, SetSelectedDataList] = useState(new Map());
    const [BatchMode, SetBatchMode] = useState(false);
    const [DataRefersh, SetDataRefersh] = useState(false);

    const notify = useToast();
    const auth = useAuth();

    const FetchOrderProducts = async()=>{
        try 
        {
            const res = await FetchSavedProductionProductOrder(DataPoint.PO_Hash);
            SetOrderProducts(res);

        }catch(err) 
        {

        }
    }

    const SingleProductCallback = async(data, is_mix, batch_count, pop_hash)=>
    {
        const __name = is_mix ? data.MixName : data.FProdName;

        if(!BatchMode) 
        {
            const __new_map = new Map();
            __new_map.set(__name, {POP_Hash: pop_hash, IsMix: is_mix, Pkg: data, CycleCount: batch_count});
            SetSelectedDataList(__new_map);
            SetOpenPOProcessor(true);
        }
        const existing_data = new Map(SelectedDataList);

        if(existing_data.has(__name)) 
        {
            existing_data.delete(__name);
        }else 
        {
            existing_data.set(__name, {POP_Hash: pop_hash, IsMix: is_mix, Pkg: data, CycleCount: batch_count});
        }

        SetSelectedDataList(existing_data);
    }

    const ProcessBatchSelection = async()=>{
        SetOpenPOProcessor(true);
    }

    const ApplyBatchVoid = async(val)=>
    {
        SetBatchMode(val);
        if(!val) 
        {
            SetSelectedDataList(new Map());
        }
    }

    const CheckOrderPackageState = async()=>
    {
        try 
        {

            const fd = new FormData();
            fd.append("order_hash", DataPoint.PO_Hash);
            fd.append("branch_id", DataPoint.PO_SourceBranch);

            const res = await RefreshProdcutionOrder(fd, auth.token);

            if(res.CleanHouse) 
            {
                // Global Parent Refresh
                if(res.MyStateCode === -1) 
                {
                    notify.ShowError(res.Msg);
                } else 
                {
                    notify.ShowSuccess(res.Msg);
                }
                DataRefresh_Callback(DataPoint.PO_Hash);
            } else if(res.CleanCount > 0) 
            {
                // Local Refresh
                SetDataRefersh(!DataRefersh);
            }

            if(res.MyStateCode === -1) 
            {
                notify.ShowError(res.Msg);
            }

        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }

    const CleanCloseCallback = async(val)=>
    {
        SetBatchMode(false);
        SetOpenPOProcessor(false);
        SetDataRefersh(!DataRefersh);
        SetSelectedDataList(new Map());
        CheckOrderPackageState();
    }

    useEffect(()=>{
        FetchOrderProducts();
    }, [DataRefersh])


    return (
        <div className={`${classes.prodcution_stack_order_group_wrapper_main}`}>
            <Tooltip className={`${classes.tool_quick}`} id="offical_doc" place="top" />
            {OpenPOProcessor && <ProductionStackProcessor Target={DataPoint} DataList={SelectedDataList} Close_CALLBACK={CleanCloseCallback}/>}

            <div className={`${classes.pro_stck_group_pkg_wrapper}`}>

                <div className={`${classes.production_stack_group_wrapper}`}>

                        <div className={`${classes.prodution_stock_single_card_list_wrapper}`}>

                            {
                                OrderProducts.map((item, idx)=>(
                                    <ProductionStackOrderSingleCardWrapper 
                                            key={idx} 
                                            PackageList={SelectedDataList} 
                                            SingleCard={SingleProductCallback} 
                                            Target={item}/>
                                ))
                            }

                        </div>

                    
                </div>

                <div className={`${classes.production_stack_group_footer}`}>
                    <div className={`${classes.footer_wrap_inner}`}>
                        <button onClick={()=>{ApplyBatchVoid(!BatchMode)}}><span><FontAwesomeIcon icon={BatchMode ? faCirclePause : faCheckToSlot}></FontAwesomeIcon></span><span>{BatchMode ? "Void" : "Select"}</span></button>
                        {BatchMode && SelectedDataList.size > 0 &&  <button onClick={()=>{ProcessBatchSelection()}} className={`${classes.batch_process}`}><span><FontAwesomeIcon icon={faCircleChevronRight}></FontAwesomeIcon></span><span>Process</span></button>}
                    </div>
                    <div className={`${classes.footer_wrap_inner}`}>
                       {OrderProducts.length === 0 && 
                                    <button  
                                        onClick={()=>{CheckOrderPackageState()}} 
                                        {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': "You shoudn't not see this button, If you are you have permission or things go south somewhere!"})} 
                                        className={`${classes.fail_save_purge}`}>
                                                <span><FontAwesomeIcon icon={faBan}></FontAwesomeIcon></span><span>Purge</span>
                                    </button>
                        }
                    </div>
                </div>
            </div>
            

            

        </div>
    )
}