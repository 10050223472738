import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faPenNib, faTimesCircle, faUser, faUserCog, faUserTie } from '@fortawesome/free-solid-svg-icons';
import classes from './EmployeeView.module.css'
import { useEffect, useState } from 'react';
import EmpCredit from './Credit/EmpCredit';
import EmpPenality from './Penality/EmpPenality'
import EmpAttendy from './Attendance/EmpAttendy'
import EmpAllowance from './Allowance/EmpAllowance'
import EmpBenefit from './Benefit/EmpBenefit'
import EmpPto from './PTO/EmpPto'
import DZ from './DZ/Dz';

import NotificationPopUp from '../../../../SharedComp/NotificationPopUp/NotificationPopUp';
import EmpStatus from './Status/EmpStatus';
import { getBaseURL } from '../../../../SharedComp/BaseUrlConfig';

export default function EmployeeView({Open, EmpID, EmpViewController, HandleMasterRefresh, ParentNotification}) 
{
    const HidenSalary = "ETB $$,$$$,$$$";
    const [MiniTabBar, SetMiniTabBar] = useState([true, false, false, false, false, false, false, false]);
    const [viewSalary, SetViewSalary] = useState(false);
    const [MsgPkg, SetMsgPkg] = useState(null);
    const [EmployeeData, SetEmployeeData] = useState(0);
    const [refresh, SetRefresh] = useState(false);

    function handleRef() 
    {
        SetRefresh(!refresh);
    }
    function MakeActiveTabBar(idx) 
    {
        const tab_bar_count = 8;
        let temp_bar = [];
        for(var i = 0; i < tab_bar_count; i++)
        {
            if(idx == i)
            {
                temp_bar.push(true);
            } else 
            {
                temp_bar.push(false);
            }
        }

        SetMiniTabBar(temp_bar);
    }

    function TriggerNotification(NotiData) 
    {
        SetMsgPkg({Key : Math.random() * 1000, Msg: NotiData.data.Msg, State: NotiData.data.MyStateCode})
    }

    useEffect(()=>{
        fetch(getBaseURL() + 'employee/query_employee_id/', { 
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ EmpID: EmpID }),
              })//BranchData.BranchID
                .then(res => res.json())
                .then((data) => SetEmployeeData(data[0]));

    }, [refresh])


    return (
        <div className={`${Open ? classes.employee_view_main_wrapper : classes.employee_view_main_wrapper_hide}`}>
         <div className={`${classes.employee_view_main_win}`}>
        { MsgPkg && <NotificationPopUp key={MsgPkg.Key} msg={MsgPkg.Msg} state={MsgPkg.State}/> }

                <div className={`${classes.employee_view_header}`}>
                    <div className={`${classes.employee_view_header_inner}`}>
                        <h2>Profile</h2>
                    </div>
                    <div className={`${classes.employee_view_header_inner}`}>
                        <button onClick={() => {EmpViewController(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
                    </div>
                </div>

                <div className={`${classes.employee_detail_body}`}>
                        <div className={`${classes.employee_detail_right}`}>
 {/* Image */}

                            <img className={`${classes.employee_pic}`} src="https://cdn.pixabay.com/photo/2015/03/04/22/35/avatar-659651_640.png" alt="name stuff" />

                            <div className={`${classes.employee_respon}`}>
                                <li className={`${classes.emp_name_big}`}> <FontAwesomeIcon className={`${classes.employee_resp_icon}`}  icon={faUserTie}></FontAwesomeIcon>{EmployeeData.FullName}</li>
                                <li> <FontAwesomeIcon className={`${classes.employee_resp_icon}`}  icon={faUserCog}></FontAwesomeIcon>{EmployeeData.PositionName}</li>
                            </div>

                            <div className={`${classes.employee_personal_info_action}`}>
                                <button className={`${classes.emp_p_info_edit}`}><FontAwesomeIcon icon={faPenNib}></FontAwesomeIcon></button>
                            </div>

                            <div className={`${classes.employee_personal_info}`}>
                                
{/* Name */}
                                <li>
                                     <span className={`${classes.emp_p_info_hard}`}>Name</span>
                                     
                                     <span className={`${classes.emp_p_info_value}`}> 
                                            <input type="text" value={EmployeeData.FullName} readOnly /> 
                                     </span>
                                     
                                </li>
{/* Name */}
                                <li>
                                     <span className={`${classes.emp_p_info_hard}`}>Phone</span>
                                     
                                     <span className={`${classes.emp_p_info_value}`}> 
                                            <input type="text" value={EmployeeData.PhoneNumber} readOnly /> 
                                     </span>
                                     
                                </li>
{/* City */}
                                <li>
                                     <span className={`${classes.emp_p_info_hard}`}>Email</span>
                                     
                                     <span className={`${classes.emp_p_info_value}`}> 
                                            <input type="text" value={EmployeeData.Email} readOnly /> 
                                     </span>
                                     
                                </li>
{/* Address */}
                                <li>
                                     <span className={`${classes.emp_p_info_hard}`}>Address</span>
                                     
                                     <span className={`${classes.emp_p_info_value}`}> 
                                            <input type="text" value={EmployeeData.Address} readOnly /> 
                                     </span>
                                     
                                </li>

{/* Age */}
                                <li>
                                     <span className={`${classes.emp_p_info_hard}`}>Age</span>
                                     
                                     <span className={`${classes.emp_p_info_value}`}> 
                                            <input type="text" value={EmployeeData.Age} readOnly /> 
                                     </span>
                                     
                                </li>

                                {/* <li>
                                     <span className={`${classes.emp_p_info_hard}`}>Nationality</span>
                                     
                                     <span className={`${classes.emp_p_info_value}`}> 
                                            <input type="text" value={EmpData.Age} readOnly /> 
                                            <span className={`${classes.emp_p_info_edit}`}><FontAwesomeIcon icon={faPenNib}></FontAwesomeIcon></span>
                                     </span>
                                     
                                </li> */}

                                {/* <div className={`${classes.emp_salary_wrapper}`}>
                                    <li>$ Salary 
                                        <span className={`${viewSalary ? classes.emp_salary_just_value : classes.emp_salary_just_value_hide}`}> 
                                           {viewSalary ? 
                                                (EmpData.Salary).toLocaleString('en-US', {
                                                    style: 'currency',
                                                    currency: 'ETB',
                                                })
                                             : (HidenSalary).toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'ETB',
                                            }) }
                                             </span>
                                        <span onClick={() => {SetViewSalary(!viewSalary)}} className={`${classes.emp_salary_visible}`}>{ !viewSalary ? <FontAwesomeIcon icon={faEye}></FontAwesomeIcon> : <FontAwesomeIcon icon={faEyeSlash}></FontAwesomeIcon> }</span>
                                    </li>
                                </div> */}

                            </div>
                            
                        </div>

                        
                        <div className={`${classes.employee_detail_left}`}>

                              {/* Create mini tab bar */}

                              <div className={`${classes.emp_detail_tab_bar}`}>
                                    <li><button className={`${ MiniTabBar[0] ? classes.active_tab_bar_but : ''}`} onClick={() => {MakeActiveTabBar(0)}}>Employee</button></li>
                                    <li><button className={`${ MiniTabBar[1] ? classes.active_tab_bar_but : ''}`} onClick={() => {MakeActiveTabBar(1)}}>Credit Line</button></li>
                                    <li><button className={`${ MiniTabBar[2] ? classes.active_tab_bar_but : ''}`} onClick={() => {MakeActiveTabBar(2)}}>Penality</button></li>
                                    <li><button className={`${ MiniTabBar[3] ? classes.active_tab_bar_but : ''}`} onClick={() => {MakeActiveTabBar(3)}}>Attendance</button></li>
                                    <li><button className={`${ MiniTabBar[4] ? classes.active_tab_bar_but : ''}`} onClick={() => {MakeActiveTabBar(4)}}>Allowance</button></li>
                                    <li><button className={`${ MiniTabBar[5] ? classes.active_tab_bar_but : ''}`} onClick={() => {MakeActiveTabBar(5)}}>Benefit</button></li>
                                    <li><button className={`${ MiniTabBar[6] ? classes.active_tab_bar_but : ''}`} onClick={() => {MakeActiveTabBar(6)}}>Time Off</button></li>
                                    <li><button className={`${ MiniTabBar[7] ? classes.active_tab_bar_but : ''}`} onClick={() => {MakeActiveTabBar(7)}}>Transfer</button></li>
                                    <li><button className={`${ MiniTabBar[7] ? classes.active_tab_bar_but : ''}`} onClick={() => {MakeActiveTabBar(7)}}>Danger Zone</button></li>
                              </div>

                              {/*  Emp Conditional rendering stuff */}

                              {MiniTabBar[0] && <EmpStatus      HandleMasterRefresh={HandleMasterRefresh} ReqRefresh={handleRef} MsgTrigger={TriggerNotification} EmpData={EmployeeData}/>}
                              {MiniTabBar[1] && <EmpCredit      HandleMasterRefresh={HandleMasterRefresh} ReqRefresh={handleRef} MsgTrigger={TriggerNotification} EmpData={EmployeeData}/>}
                              {MiniTabBar[2] && <EmpPenality    HandleMasterRefresh={HandleMasterRefresh} ReqRefresh={handleRef} MsgTrigger={TriggerNotification} EmpData={EmployeeData}/>}
                              {MiniTabBar[3] && <EmpAttendy     HandleMasterRefresh={HandleMasterRefresh} ReqRefresh={handleRef} MsgTrigger={TriggerNotification} EmpData={EmployeeData}/>}
                              {MiniTabBar[4] && <EmpAllowance   HandleMasterRefresh={HandleMasterRefresh} ReqRefresh={handleRef} MsgTrigger={TriggerNotification} EmpData={EmployeeData}/>}
                              {MiniTabBar[5] && <EmpBenefit     HandleMasterRefresh={HandleMasterRefresh} ReqRefresh={handleRef} MsgTrigger={TriggerNotification} EmpData={EmployeeData}/>}
                              {MiniTabBar[6] && <EmpPto         HandleMasterRefresh={HandleMasterRefresh} ReqRefresh={handleRef} MsgTrigger={TriggerNotification} EmpData={EmployeeData}/>}\
                              {MiniTabBar[7] && <DZ             HandleMasterRefresh={HandleMasterRefresh} ReqRefresh={handleRef} MsgTrigger={TriggerNotification} ParentNotification={ParentNotification} EmpData={EmployeeData}/>}

                        </div>

                </div>





            </div> 

        </div>

    );
}