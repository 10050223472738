export function GLOBAL_PopupMsgTimeout(callback, state, shouldOpen, msg) 
{
    setTimeout(() => {
        callback(state, false, msg);
      }, 4 * 1000);
}

export function GetOffsetedDateAndTime(val = null) {
  const date_value = val ? new Date(val) : new Date();
  const offsetDate = new Date(date_value.getTime() - date_value.getTimezoneOffset() * 60000);
  
  return offsetDate.toISOString().slice(0, -1);
}
export function GetOffsetedDateAndTimeUnformated(val = null) {
  const date_value = val ? new Date(val) : new Date();
  const offsetDate = new Date(date_value.getTime() - date_value.getTimezoneOffset() * 60000);
  
  return offsetDate;
}

export const Custom_DD_Style = {
  control: (provided, state) => ({
      ...provided,
      width: '100%',
      minHeight: 20,
      height: 35,
      fontWeight: 'bold',
      fontSize: '0.6em',
      background: 'var(--background-light)',
      borderColor: 'var(--background-light)',
      color: 'var(--secondary-color)',
      borderRadius: 1,
      boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
      borderColor: 'none',
      '&:hover': {
      borderColor: 'none',
      },
  }),
  option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? 'var(--background-light)' : 'var(--background-dark)', // Change background color on hover
      color:'var(--text_light_white)',
      transition: 'background-color 0.2s ease-in-out',
      cursor: 'pointer',
      boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
  }),
  singleValue: (provided) => ({
      ...provided,
      color: 'var(--text_light_white)', // Set the text color for the selected option in the dropdown
    }),
    input: (provided) => ({
      ...provided,
      color: 'var(--text_light_white)', //input text color when searching
    }),
  menu: (provided) => ({
      ...provided,
      zIndex: 100, // Increase the z-index if options are not displayed properly
      backgroundColor: 'var(--background-light)',
      fontSize: '0.6em',
      borderRadius: 0,
      fontWeight: 500,
      boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
  }),
};

export function PrepareDropDownList(data, bucket) {
  const newOptionList = [];

  for (var i = 0; i < data.length; i++) {
    
    let object = data[i];

    let value_key = null;
    let value_lable = null;

   // console.log(object);
    for (const key in object) {

      const active = String(key);
      if (active.toLowerCase().includes('name')) {

        const val = object[key];
        value_lable = val;

      } else if (active.toLowerCase().includes('id')) {

        const val = object[key];
        value_key = val;

      }

      

      if(value_lable !== null && value_key !== null) 
      {
        newOptionList.push({ value: value_key, label: value_lable  });
        break;
      }

    }

    
  }

  bucket(newOptionList);
}

export function mapToObject(map) 
{
  const obj = {};
  for (let [key, value] of map) 
  {
      if (value instanceof Map) 
      {
        obj[key] = mapToObject(value);
      } else 
      {
        obj[key] = value;
      }
  }
  return obj;
}
  
function convertToNumber(value) {
  if (!isNaN(value)) { 
      return Number(value);
  } 
  return value;
}
export function objectToMap(obj) 
{
    const map = new Map();

    for (let key in obj) {

        if (obj[key] instanceof Object && !Array.isArray(obj[key])) 
        {
            map.set(convertToNumber(key), objectToMap(obj[key]));
        } else 
        {
            map.set(convertToNumber(key), obj[key]);
        }
    }
    return map;
}