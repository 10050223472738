import { faShirt, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import classes from './RightSetting.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAuth } from '../../../../AuthProvider'
import {KeyColorSchemeBuffer, PaintColorSchemeBuffer} from '../../../ColorScheme'
import Select from 'react-select'

import MasterDataRegInput from '../../../CommonStyle/MasterDataRegInput'
import {Custom_DD_Style} from '../../../Utility'
import { Tooltip } from 'react-tooltip'

export default function RightSetting({IsOpen, Close_CALLBACK}) 
{

    const auth = useAuth();

    return (
        <div className={`${classes.right_settion_main_wrapper} ${IsOpen ? '' : classes.right_settion_main_wrapper_hide}`}>
            <Tooltip className={`${classes.tool_quick}`} id="offical_doc" place="top" />
            <div className={`${classes.right_option_top}`}>
                <h2>System</h2>
                <button onClick={()=>{Close_CALLBACK(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
            </div>

            <div className={`${classes.workspace_access_list}`}>
            { auth.userAccessworkshopList.length > 1 && <div className={`${classes.usr_option_inner_wrapper_access}`}>
                    <li>Select workspace</li>
                    <MasterDataRegInput 
                            input={
                                <Select
                                    onChange={(data)=>
                                        {
                                            auth.setActiveworkshop(data.value); 
                                            auth.setActiveWorkShopName(data.label)
                                        }}
                                    name={"dd_data"}
                                    options={auth.userAccessworkshopList}
                                    placeholder={"Workspace.."}
                                    isSearchable={true}
                                    styles={Custom_DD_Style}
                                    required ={true}
                                    />
                                } />

                </div>}
            </div>

            <div className={`${classes.personal_info}`}>
                <li> <span className={`${classes.usr_op_lable}`}>User Name</span> <span className={`${classes.option_val}`}>-</span></li>
                <li> <span className={`${classes.usr_op_lable}`}>Email</span> <span className={`${classes.option_val}`}> - </span> </li>
                <li> <span className={`${classes.usr_op_lable}`}>Phone</span> <span className={`${classes.option_val}`}> - </span> </li>
                <li> <span className={`${classes.usr_op_lable}`}>Password</span> <span className={`${classes.option_val}`}> ******** </span> </li>
            </div>

            <div className={`${classes.color_scheme_wrapper}`}>
                                    
                <div className={`${classes.key_color_scheme_wrapper}`}>
                    {
                        KeyColorSchemeBuffer.map((item, idx)=>(
                            <li {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': item.Name})} onClick={()=>{auth.SetAccentColor(item.Value)}} style={{background: `${item.Value}`}} className={`${classes.color_prview}`}> </li>
                        ))
                    }
                </div>
                <div className={`${classes.apint_color_scheme_wrapper}`}>
                    {
                        PaintColorSchemeBuffer.map((item, idx)=>(
                            <li onClick={()=>{auth.SetSurfaceColor(item.Value)}}><span>{item.Name}</span></li>
                        ))
                    }
                </div>

            </div>

        </div>
    )
}