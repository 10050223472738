import classes from './StockCard.module.css'
import { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleDown, faAngleDown, faChartArea, faChevronRight, faEllipsisVertical, faPrint } from '@fortawesome/free-solid-svg-icons';

import {FetchProductionLogDaily} from '../../SharedComp/API/Production/API_ProductionLog'

import { useToast } from '../../ToastContext'
import { useAuth } from '../../AuthProvider';

import ProductionLogGroup from './Component/ProductionLogGroup/ProductionLogGroup';

import PrintProductionLogView from './Component/PrintProductionLogView/PrintProductionLogView';

export default function StockCard() 
{
    const auth = useAuth();

    // This is the all daily production log
    // I mean all day and night
    // we gone preform post processing after the feach is compltated and we 
    // gone fill the Day and Night Shift Array
    const [DailyProductionLog, SetDailyProductionLog] = useState([]);

    // Day Shift Prodution Log Buffer
    const [DayShiftProductionLog, SetDayShiftProductionLog] = useState([]);

    // Night Shift Production Log Buffer
    const [NightShiftProductionLog, SetNightShiftProductionLog] = useState([]);

    const [ShowSummary, SetShowSummary] = useState(false);

    const [TargetDate, SetTargetDate] = useState(new Date());

    const [OpenPrintView, SetOpenPrintView] = useState(false);

    const notify = useToast();

    const HandlePrintOpen = async(val)=>
    {
        if(val) 
        {
            if(DailyProductionLog.length > 0) 
            {
                SetOpenPrintView(val);
                return;
            } else 
            {
                notify.ShowWarn("Print What? Is it empty!");
                return;
            }
        }

        SetOpenPrintView(val);
    }

    const SeprateDayNightShiftLog = async(log_buffer)=>
    {
        const __day_shift = [];
        const __night_shift = [];

        for(let i = 0; i < log_buffer.length; i++) 
        {
            if(log_buffer[i].SCM_Shift === 'Day') 
            {
                __day_shift.push(log_buffer[i]);  
            } else 
            {
                __night_shift.push(log_buffer[i]); 
            }
        }

        SetDayShiftProductionLog(__day_shift);
        SetNightShiftProductionLog(__night_shift);
    }

    const QueryDailyProductLog = async()=> 
    {
        try 
        {
            const fd = new FormData();
            fd.append("branch_id", auth.activeWorkShop);
            fd.append("target_day", TargetDate);
            const res = await FetchProductionLogDaily(fd, auth.token);
            SeprateDayNightShiftLog(res);
            SetDailyProductionLog(res);
        }catch(err) 
        {
            
        }
    }

    useEffect(()=>{
        QueryDailyProductLog();

    }, [TargetDate])

    

    

    return (
        <div className={`${classes.stock_card_main_wrapper}`}>
            <Tooltip className={`${classes.tool_quick}`} id="offical_doc" place="top" />

           {OpenPrintView && <PrintProductionLogView DataList={DailyProductionLog} TargetDate={TargetDate} Close_CALLBACK={SetOpenPrintView}/>} 


            <div className={`${classes.prodution_log_header_wrapper}`}>
                <div className={`${classes.prodution_log_header_wrapper_inner}`}>

                    <div className={`${classes.prodcution_log_head_inner}`}>
                        <h2>Log -[{new Date(TargetDate).toDateString()}]</h2>
                    </div>

                    <div className={`${classes.prodcution_log_head_inner_right}`}>
                        
                        <div className={`${classes.production_log_header_right_action_wrapper}`}>
                            <input  onChange={(e) => {
                                SetTargetDate(e.target.value);
                            }} type="date" />
                        </div>

                        <div onClick={(e)=>{SetShowSummary(!ShowSummary)}} className={`${classes.production_log_header_right_action_wrapper} ${classes.production_log_header_right_action_wrapper_summerize}`}>
                            <input checked={ShowSummary} onChange={()=>{}} type="checkbox" />
                            <span>Summary</span>
                        </div>
                        
                        {/* <div className={`${classes.production_log_header_right_action_wrapper}`}>
                            <button><FontAwesomeIcon icon={faChartArea}></FontAwesomeIcon></button>
                        </div> */}
                        <div className={`${classes.production_log_header_right_action_wrapper}`}>
                            <button onClick={()=>{HandlePrintOpen(true)}}><FontAwesomeIcon icon={faPrint}></FontAwesomeIcon></button>
                        </div>
                    </div>
                </div>
            </div>



            {!ShowSummary && <div className={`${classes.production_log_group_wrapper}`}>

               {/* we will have two group for the night and day shift */}
                <div className={`${classes.day_shift_production_log_wrapper}`}>
                    <ProductionLogGroup ShiftName={"Day Time"} DataList={DayShiftProductionLog} />
                </div>

                {/* Night shift */}

                <div className={`${classes.day_shift_production_log_wrapper}`}>
                    <ProductionLogGroup ShiftName={"Night Time"} DataList={NightShiftProductionLog} />
                </div>


            </div>}
            {ShowSummary && <div className={`${classes.production_log_group_wrapper}`}>

                {/* Summary */}

                <div className={`${classes.day_shift_production_log_wrapper_summary}`}>
                    <ProductionLogGroup ShiftName={"Summary"} DataList={DailyProductionLog} />
                </div>


            </div>} 



        </div>
    );
}