import classes from './PittyCashAuditView.module.css'
import data_win from '../../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useAuth } from '../../../../../../AuthProvider'
import { useReactToPrint } from 'react-to-print'
import { useEffect, useRef, useState } from 'react'
import DotsGif from '../../../../../../SharedComp/CssGif/DotsGif'
import { getBaseURL } from '../../../../../../SharedComp/BaseUrlConfig'

import JakLogo from '../../../../../../asset/images/favicon.png'

const PittyCashAuditView = ({Item, LocalStateBranch, Msg_Callback, Close_CALLBACK}) => {

    const auth = useAuth();

    const print_ref = useRef();
    const PrintDoc = useReactToPrint({
        documentTitle: LocalStateBranch.BranchName + "_CashLog_" + Item.PCDID + "_" + new Date().toDateString(),
        content: () => print_ref.current,
    })

    const [expenseBook, setExpeseBook] = useState([]);
    const [loading, setLoading] = useState(true);
    const [cardHolder, setCardHolder] = useState(null);

    const fetchExpense = async () => {
        try {

            const response = await fetch(getBaseURL() + "operation-data/query-pitty-cash-expense-book-id", {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({BranchID: Item.BranchID, BookID: Item.PCDID})
              });
  
              const res = await response.json();
              setExpeseBook(res);

        }catch(err) 
        {

        }
    }

    const fetchCardHolder = async () => {
        try 
        {
            const response = await fetch(getBaseURL() + 'employee/query_employee_id', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({EmpID: auth.employeeData?.SupervisorID})
            });

            const res = await response.json();
            if(res.length > 0) 
            {
                setCardHolder(res[0]);
            } else 
            {
                setCardHolder(null);
            }

        }catch(err) 
        {

        }
    }

    useEffect(()=>{
        fetchCardHolder();
    }, [])

    const handleApproval = async () => {
        try{
            const response = await fetch(getBaseURL() + "operation-data/pitty-cash-approve", {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({BranchID: Item.BranchID, BookID: Item.PCDID})
              });
  
              const res = await response.json();
              Msg_Callback(res);
        } catch(err) 
        {

        }
    }

    useEffect(()=>{
        setLoading(true);

        fetchExpense();
        setLoading(false);

    }, [auth.employeeData])

    const CurrencyFormatter = (value) => {
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'ETB',
        });
      
        return formatter.format(value);
      };



    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>


            <div className={`${data_win.data_main} ${classes.data_main}`}>

                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        JAK <span className={`${data_win.data_create_name}`}>Cash Log</span>
                    </h3>
                    <button onClick={()=>{Close_CALLBACK(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
                </div>

                {!loading ? <div className={`${classes.data_body}`}>

                    <div  className={`${classes.pitty_cash_detail_view_upper}`}>

                        <div className={`${classes.pitty_cash_detail_view_inner}`}>

                            <div ref={print_ref} className={`${classes.pr_wrapper}`}>

                                <div className={`${classes.exclusive_tag}`}>
                                    exclusive
                                </div>

                            <div className={`${classes.cash_log_head_wrapper}`}>

                                <div className={`${classes.page_header}`}>
                                    <div>
                                        <img className={`${classes.yam_logo}`} src={JakLogo} alt="" />
                                    </div>
                                    <div className={`${classes.yam_logo_name}`} >
                                        JAK AND SONS TRADING PLC <span className={`${classes.yam_group_name}`}>Yam bakery group</span>
                                    </div>
                                    <div>
                                        <img className={`${classes.yam_logo}`} src="https://yambakery.com/wp-content/uploads/elementor/thumbs/logo-wight-q7unzmwoied00tm97ojjtfi919h39u39q7u92mf1rq.png" alt="" />

                                    </div>
                                </div>

                                <div className={`${classes.cash_log_header}`}>

                                    <h1>{LocalStateBranch.BranchName}</h1>
                                    <h1>Petty Cash Log</h1>

                                </div>

                                <div className={`${classes.cash_log_header_recep}`}>

                                    <div className={`${classes.cash_log_header_cash_log_sender}`}>
                                        <li className={`${classes.info}`}>Petty cash utilized by:</li>
                                        <li className={`${classes.user_name}`}>{cardHolder ? cardHolder.FullName : '--Unknown--'}</li>
                                        <li className={`${classes.user_role}`}>{cardHolder ? cardHolder.PositionName : '--Unknown--'}</li>
                                        <li className={`${classes.user_role}`}>{cardHolder ? cardHolder.PhoneNumber : '--Unknown--'}</li>

                                        
                                    </div>

                                    <div className={`${classes.cash_log_header_cash_log_recev}`}>
                                        <li className={`${classes.info}`}>Petty cash processed by:</li>
                                        <li className={`${classes.user_name}`}>{auth.user.FirstName} {auth.user.LastName}</li>
                                        <li className={`${classes.user_role}`}>{auth.employeeData?.PositionName}</li>
                                        <li className={`${classes.user_role}`}>{auth.employeeData?.PhoneNumber}</li>
                                    </div>

                                </div>

                                <div className={`${classes.in_out_transaction_main_wrapper}`}>

                                    <div className={`${classes.in_out_transaction}`}>
                                        <li> <span className={`${classes.io_tr_tag}`}>Opening Balance: </span> <span className={`${classes.io_tr_val}`}>{CurrencyFormatter(Item.InitialAmount)}</span></li>
                                        <li><span className={`${classes.io_tr_tag}`}>Transferred In: </span> <span className={`${classes.io_tr_val}`}>{CurrencyFormatter(Item.TransferredIn)}</span></li>
                                        <li><span className={`${classes.io_tr_tag}`}>Total Starting Balance: </span> <span className={`${classes.io_tr_val}`}>{CurrencyFormatter(Item.TransferredIn + Item.InitialAmount)}</span> </li>
                                
                                    </div>

                                    
                                </div>

                            </div>

                            <div className={`${classes.cash_log_body_master_wrapper}`}>

                                <div className={`${classes.data_view_wrapper}`}>
                                    <div className={`${classes.data_view_wrapper_head}`}>
                                        <li className={`${classes.list_data_date}`}>Date</li>
                                        <li>Paid By</li>
                                        <li className={`${classes.list_data_paidfor}`}>Paid For</li>
                                        <li>Amount</li>
                                        <li>Balance</li>
                                    </div>

                                    <div className={`${classes.list_wrapper}`}>

                                        {
                                            expenseBook.map((item, idx)=>(
                                                <div key={idx} className={`${classes.list_data}`}>
                                                    <li>{new Date().toDateString()}</li>
                                                    <li>{cardHolder ? cardHolder.FullName : 'Unknown'}</li>
                                                    <li>{item.Cat}</li>
                                                    <li className={`${classes.data_list_paid}`}>({CurrencyFormatter(item.Amount)})</li>
                                                    <li className={`${classes.list_data_balance}`}>+{CurrencyFormatter(item.Balance)}</li>
                                                </div>
                                            ))
                                        }
                                        
                                    </div>

                                </div>

                               

                            </div>
                            <div className={`${classes.summer_repo}`}>
                                <div className={`${classes.last_info_sign_note}`}>
                                    <li>Invalid unless Signed</li>
                                </div>
                                <div className={`${classes.last_info}`}>
                                    <li>Date: {new Date().toDateString()}</li>
                                    <li className={`${classes.trasnfered}`}>Total Expenditure: {CurrencyFormatter(Item.InitialAmount + Item.TransferredIn - Item.TransferredOut)}</li>
                                    <li className={`${classes.trasnfered}`}>Transferred Out: {CurrencyFormatter(Item.TransferredOut)}</li>
                                    <li className={`${classes.sign}`}>Signature:_______________</li>
                                </div>
                            </div>
                        </div>

                    </div>
                    </div>

                    <div className={`${classes.action_button_wrapper}`}>

                       {!Item.IsAudited && <button onClick={(e)=>{handleApproval()}} className={`${classes.button_approve}`}>Approve</button>} 
                       {!Item.IsAudited && <button className={`${classes.button_halt}`}>Freeze</button> } 
                        <button onClick={(e)=>{PrintDoc()}} className={`${classes.button_print}`}>Print</button>
                        <button onClick={()=>{Close_CALLBACK(false)}} className={`${classes.button_dismiss}`}>Close</button>

                    </div>

                </div> : 
                    <div className={`${classes.loading_placeholder}`}>
                        <DotsGif/>
                    </div>
            }

            </div>

            

        </div>
    )
}


export default PittyCashAuditView;